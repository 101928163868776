import {ADD_COMPANY_DATA,ADD_COMPANY,UPDATE_COMPANY,UPDATE_COMPANY_DATA,SEARCH_COMPANIES,GET_COMPANIES_PAGINATION} from '../../queries/companies';
import {UPDATE_CUSTOMER,GET_CUSTOMERS,SEARCH_CUSTOMERS, ADD_CUSTOMER, ADD_CUSTOMER_DATA, UPDATE_CUSTOMER_DATA} from '../../queries/customers';
import { ADD_SELECTION, GET_SELECTIONS_PAGINATION, UPDATE_SELECTION } from '../../queries/selections';
import { ADD_TOP_HEADER_MESSAGE, GET_TOP_HEADER_MESSAGE_PAGINATION, UPDATE_TOP_HEADER_MESSAGE } from '../../queries/assetMagentoTopHeader';
import { ADD_MAGENTO_PAGE, ADD_MAGENTO_PAGE_CONTENT, GET_MAGENTO_CONTENTS_PAGINATION, GET_MAGENTO_PAGES_PAGINATION, UPDATE_MAGENTO_PAGE, UPDATE_MAGENTO_PAGE_CONTENT } from '../../queries/assetMagentoPage';
import { GET_LOOKBOOKS_PAGINATION, CREATE_LOOKBOOK, UPDATE_LOOKBOOK } from '../../queries/look_book';
import { GET_CATALOGS_PRODUCTS_PAGINATION } from '../../queries/catalogs';
import { CREATE_RATE_GROUP, UPDATE_RATE_GROUP, GET_RATE_GROUPS_PAGINATION } from '../../queries/rate_group';
import { CREATE_INFO_MANAGEMENT, GET_INFO_MANAGEMENTS_PAGINATION, UPDATE_INFO_MANAGEMENT } from '../../queries/info_management';
import {GET_ORDERS_PAGINATION,GET_SALES_ORDER,GET_RETURNED_SALES_ORDERS,GET_MARKETPLACES} from '../../queries/orders'
import {GET_PRODUCT_LITES,GET_PRODUCT_LITE } from '../../queries/products';
import { GET_WAREHOUSES,GET_LOCATIONS,GET_WAREHOUSE_BY_ID,GET_LOCATION_BY_ID } from '../../queries/warehouses';

export const addQueries = {
    'company' : ADD_COMPANY,
    'customer' : ADD_CUSTOMER,
    'selection': ADD_SELECTION,
    'assetMagentoTopHeader': ADD_TOP_HEADER_MESSAGE,
    'assetMagentoPage': ADD_MAGENTO_PAGE,
    'assetMagentoPageContent': ADD_MAGENTO_PAGE_CONTENT,
    'lookBook': CREATE_LOOKBOOK,
    'rateGroup': CREATE_RATE_GROUP,
    'infoManagement': CREATE_INFO_MANAGEMENT,
}

export const updateQueries = {
    'company' : UPDATE_COMPANY,
    'customer': UPDATE_CUSTOMER,
    'selection': UPDATE_SELECTION,
    'assetMagentoTopHeader': UPDATE_TOP_HEADER_MESSAGE,
    'assetMagentoPage': UPDATE_MAGENTO_PAGE,
    'assetMagentoPageContent': UPDATE_MAGENTO_PAGE_CONTENT,
    'rateGroup': UPDATE_RATE_GROUP,
    'infoManagement': UPDATE_INFO_MANAGEMENT,
}

export const updateAttributesQueries = {
    'company' : UPDATE_COMPANY_DATA,
    'customer' : UPDATE_CUSTOMER_DATA
}

export const addAttributesQueries= {
    'company' : ADD_COMPANY_DATA,
    'customer':ADD_CUSTOMER_DATA
}

export const getQueries = {
    'customer'                  : GET_CUSTOMERS,
    'customers'                 : GET_CUSTOMERS,
    'companies'                 : GET_COMPANIES_PAGINATION,
    'selections'                : GET_SELECTIONS_PAGINATION,
    'assetMagentoTopHeaders'    : GET_TOP_HEADER_MESSAGE_PAGINATION,
    'assetMagentoPages'         : GET_MAGENTO_PAGES_PAGINATION,
    'assetMagentoPageContents'  : GET_MAGENTO_CONTENTS_PAGINATION,
    'lookBooks'                 : GET_LOOKBOOKS_PAGINATION,
    'catalogProducts'           : GET_CATALOGS_PRODUCTS_PAGINATION,
    'rateGroups'                : GET_RATE_GROUPS_PAGINATION,
    'infoManagements'           : GET_INFO_MANAGEMENTS_PAGINATION,
    'salesOrders'               : GET_ORDERS_PAGINATION,
    'salesOrder'                : GET_SALES_ORDER,
    'salesOrderReturned'        : GET_RETURNED_SALES_ORDERS,
    'productLites'              : GET_PRODUCT_LITES,
    'productLite'               : GET_PRODUCT_LITE,
    'warehouses'                : GET_WAREHOUSES,
    'warehouse'                 : GET_WAREHOUSE_BY_ID,
    'locations'                 : GET_LOCATIONS,
    'location'                  : GET_LOCATION_BY_ID,
    'marketplaces'              : GET_MARKETPLACES
}

export const searchQueries = {
    'customer' : SEARCH_CUSTOMERS,
    'company'  : SEARCH_COMPANIES,
}