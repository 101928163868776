import TableCell from '@material-ui/core/TableCell';
import styled from 'styled-components';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import UnfoldMoreIcon from '@material-ui/icons/UnfoldMore';
import {useState,useEffect} from 'react'
const CellHead = styled(TableCell)`
    cursor : ${props => props.hasOrder ? 'pointer': 'normal'};
    width: ${props => props.width ? props.width : 'auto'};
    font-weight: 700;
`

const LabelContainer = styled.div`
    display:flex;
    align-items:center;
`


function HeadingCell(props){

    const [hasOrder,setHasOrder] = useState(false);
    const [orderBy,setOrderBy] = useState({});

    useEffect(()=>{
        let orderItem = props.orderBy?.find(e => e.column === props.field );
        if(orderItem){
            setOrderBy(orderItem);
            setHasOrder(true)
        } else {
            setOrderBy({});
            setHasOrder(false)
        }
    },[props.orderBy])


    const handleSort = (orderBy) =>{
        orderBy.order = orderBy.order == 'ASC' ? 'DESC' : 'ASC';
        props.sortCallback(orderBy)
    }

    return(
        <CellHead padding={props.padding} {...props} onClick = {hasOrder ? ()=>handleSort(orderBy) : ()=>{}} hasOrder={hasOrder} style={{
            padding: 10,
            paddingLeft: props.index === 0 ? 30 : null,
            // paddingLeft: props.label === 'Status' ? 30 : 10,
        }}> 
            <LabelContainer>
                <span>{props.label}</span>

                {hasOrder ? 
                    orderBy.order == null ?
                        <UnfoldMoreIcon style={{marginLeft:5}}/>
                    :   
                        orderBy.order == 'ASC' ?
                            <ArrowDropDownIcon style={{marginLeft:5}}/>
                        : <ArrowDropUpIcon style={{marginLeft:5}}/>
                    
                :null}                
            </LabelContainer>

        </CellHead>
    )
}
export default HeadingCell;