import React from 'react';
import {Grid, Box} from '@material-ui/core';
import Button from '../../ui/button/Button';
import colors from '../../../config/theme/colors';
import SearchBar from './SearchBar';
import styled from 'styled-components';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import InputSearch from './components/InputSearch';

const BoxCustom = styled(Box)`
    width: 100%;
    padding-bottom: 0;
`;
const CustomSelect = styled(Select)`
    .MuiSelect-root{
        padding-top: 17px;
        padding-bottom: 17px;
        width: 80px;
    }
`;
const ContainerInput = styled(Grid)`
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 250px;
    margin-bottom: 8px;
    margin-left: 16px;
`;
const ContainerInputCategories = styled(ContainerInput)`
    max-width: 300px;
`;
const GridStatus = styled(ContainerInput)`
    @media screen and (max-width: 1200px){
        margin-left: 0 !important;
        width: 100%;
        div{
            width: auto !important;
        }
        .MuiInputBase-root{
            width: 130px !important;
        }
    }
`;

export default function MasterSearch(props) {
    const selectStatus = {
        type: 'select',
        label: 'Status',
        helper: 'status',
        required: false,
        stateName: 'searchStatus',
        value: [
            {
                value: 'all',
                label: 'Tout'
            },
            {
                value: 'active',
                label: 'Actif'
            },
            {
                value: 'inactive',
                label: 'Inactif'
            },
        ]
    };

    let attributeSetup = attribute => {
        let typeInput = '';
        let currentLang = props.currentLang;
        let isEmail = false;
        const defaultLang = attribute.node.translation.translationDatas.edges[0];

        const langSelected = attribute.node.translation.translationDatas.edges.find(
            lang => lang.node.locale.code === currentLang
        );

        switch(attribute.node.attributeType.input) {
            case 'textarea':
                typeInput = 'textarea';
                break;
            case 'number': 
            case 'decimal': 
                typeInput = 'decimal';
                break;
            case 'text':
                typeInput = 'text';
                break;
            case 'mail':
                typeInput = 'text';
                isEmail = true;
                break;
            case 'link':
                typeInput = 'text';
                break;
            case 'select':
                typeInput = 'select';
                break;
            case 'image':
                typeInput = 'mediaPicker';
                break;
            case 'file':
                typeInput = 'file';
                break;
            case 'date':
                typeInput = 'date';
                break;
            default: typeInput = null;
        };
        return ({
            type: typeInput,
            label: langSelected?.node.value ?? defaultLang.node.value,
            translated: false,
            helper: {
                link: false,
            },
            isSystem: false,
            currentLang,
            stateName: attribute.node.identifier,
            email: isEmail,
            value: attribute.node.attributeOptions.edges.map((values) => {
                const langSelectedValuesDefault = values.node.translation.translationDatas.edges[0];

                const langSelectedValues = values.node.translation.translationDatas.edges.find(
                    lang => lang.node.locale.code === currentLang
                );

                return ({
                    value: values.node.id,
                    label: langSelectedValues?.node.value ?? langSelectedValuesDefault?.node?.value
                });
            })
        });
    };

    let searchImage = {
        type: 'select',
        label: 'Actif',
        helper: {
            link: false,
        },
        stateName: 'product_image',
        required: true,
        value: [
            {
                value: 'all',
                label: 'Tout'
            },
            {
                value: 'active',
                label: 'Actif'
            },
            {
                value: 'inactive',
                label: 'Inactif'
            },
        ]
    }

    let configCategories = {
        type: 'selectTree',
        label: 'Catégories',
        translated: false,
        helper: {
            link: false,
        },
        required: false,
        stateName: 'categoriesSelected',
        multiselect: true,
        data: (() => {
            let data = props.categories.filter(e => e.parent === null);
            let populateChildren = (cats, parent) => {

                let catSelected = props.allState.categoriesSelected?.find(e => e.id === parent.id);
                
                let allNames    = parent.categoryDatas.edges.filter(e => e.node.attribute.identifier === 'category_name');

                const defaultValue  = allNames.find(e => e.node.locale.code === props.currentLang);
                const value         = allNames.find(e => e.node.locale.code === props.currentLang);

                let finalValue = value?.node.value ?? defaultValue?.node.value ?? parent.libelle;


                parent.value    = parent.id;
                parent.label    = parent.isRoot ? 'Root' : finalValue;
                parent.checked  = catSelected ? true : false;

                parent.expanded = true;
                parent.children = cats.filter(e => e.parent !== null && e.parent.id === parent.id);

                for (let child of parent.children)
                    populateChildren(cats, child);
            };

            for (let parent of data)
                populateChildren(props.categories, parent);
            
            return data;
        })()
    }
    let getInternalFilters = props.attributesInternalFilter.map(attributeSetup).filter(e => e.type !== 'image' && e.type !== 'file' && e.type !== "mediaPicker")
    let deleteFilters = false
    if((props.categorieActivated ? props.allState.categoriesSelected?.length > 0 : null) || (props.allState.searchValueSku !== '') || (props.allState.searchValue !== '')){
        deleteFilters = true
    }else if(props.allState.customSearchAttributes){
        for (const [key, value] of Object.entries(props.allState.customSearchAttributes)) {
            if (value !== '' && key !== 'product_image'){
                deleteFilters = true
            }
        }
    }
    return(
        <BoxCustom pt={props.windowWidth < 1800 ? 3 : 4} style={{position: 'relative', borderBottom: props.hasBorder ? `1px solid ${colors.grey.lighter.hue800}` : 'none'}} form={props.form}>
            <Grid container direction="row" justify="center" spacing={0}>
                <Grid container direction="row" spacing={0}>
                    <Grid container direction="row" spacing={0} alignItems="flex-start" style={{padding: "0px 16px 0px 0px"}}>
                        {
                            props.categorieActivated && !props.noCategories ?
                                <ContainerInputCategories item xs={12}>
                                    <div style={{width: '100%'}}>Recherche par catégories: </div>
                                    <Grid container>
                                        <InputSearch allState={props.allState} input={configCategories} stateCallback={(evt, custom) => props.stateCallback(configCategories.stateName, evt, custom, configCategories.translated)}/>
                                    </Grid>
                                </ContainerInputCategories>
                            : null
                        }
                        
                        <ContainerInput item xs={12}>
                            <div style={{width: '100%'}}>Recherche par SKU: </div>
                            <SearchBar 
                                type="text" 
                                placeholder= "Par SKU"
                                onChange={props.handleSearchSku}
                                value={props.allState.searchValueSku}
                                style={{minHeight: 50}}
                            />  
                        </ContainerInput>
                        <ContainerInput item xs={12}>
                            <div style={{width: '100%'}}>Recherche par nom: </div>
                            <SearchBar 
                                type="text" 
                                placeholder= "Par nom"
                                onChange={props.handleNameDesc}
                                value={props.allState.searchValue}
                            />  
                        </ContainerInput>
                        <GridStatus item>
                            <div style={{width: '100%'}}>Recherche par status: </div>
                            <CustomSelect
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={props.currentStatus}
                                onChange={props.searchStatusHandler}
                                variant="outlined"
                            >
                                {
                                    selectStatus.value.map((e, index) => {
                                        return(
                                            <MenuItem value={e.value} key={`SearchStatus${index}`}>{e.label}</MenuItem>
                                        )
                                    })
                                }
                            </CustomSelect>
                        </GridStatus>
                        <GridStatus item>
                            <div style={{width: '100%'}}>Image produit: </div>
                            <CustomSelect
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={props.allState['customSearchAttributes']?.[searchImage.stateName]}
                                onChange={(evt, custom) => props.stateCallback(searchImage.stateName, evt, true, searchImage.translated)}
                                variant="outlined"
                            >
                                {
                                    selectStatus.value.map((e, index) => {
                                        return(
                                            <MenuItem value={e.value} key={`SearchImage${index}`}>{e.label}</MenuItem>
                                        )
                                    })
                                }
                            </CustomSelect>
                        </GridStatus>
                        {
                            getInternalFilters.length > 0 ?
                                getInternalFilters.map((attribute, index) => {
                                    return(
                                        <ContainerInput key={`internal-filter-${index}`} item xs={12}>
                                            <div style={{width: '100%'}}>Recherche par {attribute.label}: </div>
                                            <Grid container>
                                                <InputSearch 
                                                    value={
                                                        attribute.translated  
                                                        ? props.allState[props.currentLang][attribute.stateName] 
                                                        : props.allState['customSearchAttributes']?.[attribute.stateName]
                                                    } 
                                                    allState={props.allState}
                                                    input={attribute} 
                                                    stateCallback={(evt, custom) => props.stateCallback(attribute.stateName, evt, true, attribute.translated)}
                                                />
                                            </Grid>
                                        </ContainerInput>
                                    )
                                })
                            : null
                        }
                        <ContainerInput item style={{alignSelf: 'center'}}>
                            <Grid container alignItems="center">
                                <Button onClick={props.validateSearch} style={{marginBottom: 0}}>Filtrer</Button>
                                {
                                    deleteFilters ?
                                        <Button onClick={props.resetFilters} bgcolor={colors.red.regular} bgcolorhover={colors.red.darker} style={{marginBottom: 0, marginLeft: 8}}>Tout supprimer</Button>
                                    : null
                                }
                            </Grid>
                        </ContainerInput>
                    </Grid>
                </Grid>
            </Grid>
        </BoxCustom>
    );
}