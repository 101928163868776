import { useState } from 'react';

import styled from 'styled-components';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import PageLoader from '../../../../ui/loadings/page-loader/PageLoader';

import HeadingCell from './cells/HeadingCell'
import CellText from './cells/CellText';
import CellImage from './cells/CellImage';
import CellLink from './cells/CellLink';
import CellAvatars from './cells/CellAvatars';
import CellChips from './cells/CellChips'
import CellProfilePicture from './cells/CellProfilePicture';
import CellArrayToText from './cells/CellArrayToText'
import colors from '../../../../../config/theme/colors';
import moment from 'moment';
import OurButton from '../../../../ui/button/Button';
import StatusInfo from '../../../../ui/status-info/StatusInfo';
import { fontSize } from '@material-ui/system';
import { getDeviceZoom } from '../../../../../js/utils/getDeviceZoom';
import { Box } from '@material-ui/core';

const ButtonCustom = styled(OurButton)`
    &.delete{
        background : ${colors.red.regular};
    }
    /* Change style */
    &.edit{
        background : ${colors.red.regular};
    }
`

const CustomRow = styled(TableRow)`
    /* Ellipsis with one line */
    td{
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 200px;
    }
    background : ${props => props.style.rowBackground ? props.style.rowBackground : 'white'};
    ${props => props.style.type == 'orders' ?
        `
        background : white;
        box-sizing: border-box;
            td{
                padding:8px 16px;
            }

            ${props.rowAction ? `
                cursor:pointer;
                &:hover{
                    background : ${colors.green.lighter};
                    // & .MuiTableCell-root {
                    //     border-top: 1px solid ${colors.green.regular} !important;
                    //     border-bottom: 1px solid ${colors.green.regular} !important;
                    // }

                    & td  {
                        color: ${colors.green.regular};
                    }
                    // & td, p  {
                    //     color: ${colors.green.regular};
                    // }
                    
                    & button {
                        background-color: ${colors.green.regular} !important;
                        color: ${colors.white} !important;
                    }

                    & .MuiChip-root {
                        border: 1px solid ${colors.green.regular} !important;
                        color: ${colors.green.regular} !important;
                    }
                }
            `: null}
        `
        : null}
    ${props => props.style.type == 'cards' ?
        `
        border-radius : 10px;
        &:hover{
            box-shadow :0px 1px 9px -1px rgba(0,0,0,0.15);
        }
        `
        : null}
    ${props => props.style.type == 'striped' ?
        `   
            background : #f2f2f2;
            &:nth-of-type(odd){
                background :  white;
            }
        `
        : null}
    ${props => props.style.type == 'standard' ?
        `   
            background : white;
            td{
                padding:8px 16px;
            }

            ${props.rowAction ? `
                cursor:pointer;
                &:hover{
                    background : #F2F2F2;
                }
            `: null}
        `
        : null}
`
const CustomTable = styled(Table)`
    background-color: ${colors.black.regular};
    border: 1px solid ${colors.grey.border};
    ${props => props.style.type == 'cards' ? `
        border-spacing: 0px 10px;
        border-collapse:separate;
    `: null}
`

const HeadRow = styled(TableRow)`
    background : ${props => props.style?.headingBackground ? props.style.headingBackground : colors.black.regular};

    th{
        color : ${props => props.style?.headingColor ? props.style.headingColor : 'white'};
        /* Ellipsis with one line */
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
`

const cells = {
    'text': CellText,
    'nestedText': CellText,
    'image': CellImage,
    'link': CellLink,
    'avatars': CellAvatars,
    'profil_picture': CellProfilePicture,
    'chips': CellChips,
    'arrayToText': CellArrayToText,
}

const capitalize = (s) => {
    if (typeof s !== 'string') return ''
    return s.charAt(0).toUpperCase() + s.slice(1)
}

const Rows = (props) => {
    const getOrderStatus = (status) => {
        switch (status) {
            case 'Pending for validation':
                return {
                    label: 'En attente',
                    status: 'warning'
                }
            case 'Shipped':
                return {
                    label: 'Expédiée',
                    status: 'success'
                }
            case 'Closed':
                return {
                    label: 'Fermée',
                    status: 'success'
                }
            case 'Paid':
                return {
                    label: 'Payée',
                    status: 'success'
                }
            case 'To Ship':
                return {
                    label: 'À livrer',
                    status: 'warning'
                }
            case 'Waiting for exportation':
                return {
                    label: 'En attente',
                    status: 'warning'
                }
            case 'Partially Shipped':
                return {
                    label: process.env.REACT_APP_DISABLE_PARTALLY_SHIPPED === "true" ? "Expédiée" : 'Expédiée partiellement',
                    status: 'success'
                }
            case 'Partially Accepted':
                return {
                    label: 'Acceptée',
                    status: 'success'
                }
            case 'MP Partially Shipped':
                return {
                    label: process.env.REACT_APP_DISABLE_PARTALLY_SHIPPED === "true" ? "Expédiée" : 'Expédiée partiellement',
                    status: 'success'
                }
            case 'MP Partially Accepted':
                return {
                    label: 'Acceptée',
                    status: 'success'
                }
            case 'Declined':
                return {
                    label: 'Refusée',
                    status: 'error'
                }
            case 'Accepted':
                return {
                    label: 'Acceptée',
                    status: 'success'
                }
            case 'Manual action required':
                return {
                    label: 'Action requise',
                    status: 'error'
                }
            case 'Avoir':
                return {
                    label: 'Avoir',
                    status: 'success'
                }
            case 'Canceled':
                return {
                    label: 'Annulée',
                    status: 'error'
                }
                case 'Pre-order':
                return {
                    label: 'Précommande',
                    status: 'warning'
                }
                default:
                    return {
                        label: '-',
                        status: 'warning'
                }
            }
    }

    return (
        <CustomRow
            key={`list${capitalize(props.label)}-${props.index}`}
            style={props.style}
            rowAction={props.rowAction}
        // onClick={() => props.rowAction(props.item.id)}
        >
            {props.columns.map((column, index) => {
                let Cell = cells[column.cellType];
                let value = props.item[column.field];
                if (column.cellType === 'nestedText') {
                    let value = '';
                    column.field.forEach((item, index) => {
                        value = value ? value + '-' + props.item[item] : props.item[item]
                    })

                    return (
                        <Cell value={value} width={column.width} {...column.cellProps} />
                    )
                } else if (column.field === 'createdAt') {
                    let value = moment(props.item[column.field]).format('DD/MM/YYYY');
                    value =
                        <>
                            <span style={{
                                fontWeight: column.cellProps?.fontWeight ? column.cellProps.fontWeight : 'normal',
                            }}>{value}</span><span style={{ fontWeight: 'normal' }}> à {moment(props.item[column.field]).format('HH:mm:ss')}</span>
                        </>

                    return (
                        <Cell value={value} width={column.width} {...column.cellProps} />
                    )
                } else if (column.field === 'status') {
                    let value = <StatusInfo status={
                        getOrderStatus(props.item?.state).status
                    } statusText={getOrderStatus(props.item?.state).label} width={'auto'} style={{
                        // paddingLeft: index === 0 ? 20 : 0,
                    }} />
                    return (
                        <Cell value={value} index={index} text={getOrderStatus(props.item?.state).label} width={column.width} {...column.cellProps} />
                    )
                } else {
                    let value = column?.prefix
                        ? <>
                            <span style={{ fontWeight: 'normal' }}>
                                {column?.prefix}
                            </span>
                            <span>{props.item[column.field]}</span>
                        </>
                        : props.item[column.field];

                    return (
                        <Cell value={value} width={column.width} {...column.cellProps} />
                    )
                }
            })}

            {props.tableProps ? (
                <TableCell align='right'>
                    {
                        props.tableProps.textButton ? (
                            <OurButton margin={'0px'} height={'35px'} text={props.tableProps.textButton} fontsize={getDeviceZoom() >= 125 ? 12 : 14} onClick={() => props.tableProps.action(props.tableProps.url.replace(':id', props.item._id))} style={{
                            // <OurButton text={props.tableProps.textButton} onClick={() => props.rowAction(props.item.id)} style={{
                                // TODO: Add condition to be green on hover
                                backgroundColor: colors.black.regular,
                            }} />
                        ) : null
                    }
                    {
                        props.tableProps.textButtonSecondary ?
                            props.tableProps.typeButtonSecondary === 'edit' ? (
                                <OurButton
                                    text={'Modifier'}
                                    icon={"edit"}
                                    color={colors.blue.darker.hue300}
                                    colorHover={colors.white}
                                    bgcolorhover={colors.blue.lighter.hue600}
                                    bgcolor={colors.blue.lighter.hue900}
                                    border={`1px solid ${colors.blue.darker.hue300}`}
                                    stopPropagation={true}
                                    onClick={() => props.tableProps.handlerButtonSecondary(props.item.node.id)}
                                    disabled={false}
                                    style={{
                                        margin: 0,
                                    }}
                                />
                            ) : (
                                <ButtonCustom className={props.tableProps.typeButtonSecondary} text={props.tableProps.textButtonSecondary} onClick={() => props.tableProps.handlerButtonSecondary(props.item.node.id)} />
                            )
                            : null
                    }
                </TableCell>
            ) : null
            }

        </CustomRow>
    )
}

export default function TableView(props) {
    let { settings, items, label } = props;
    const columns = settings?.columns;

    return (
        <Box style={{
            overflow: 'auto',
        }}>
            <CustomTable style={props.settings.style}>
                <TableHead>
                    <HeadRow style={props.settings.style}>
                        {columns.map((column, index) => (
                            <HeadingCell orderBy={props.orderBy} sortCallback={props.sortCallback} index={index} width={column.cellProps?.width} label={column.label} field={column.field} />
                        ))}
                        <HeadingCell padding='checkbox'/>
                    </HeadRow>
                </TableHead>
                <TableBody>
                    {
                        props.ready && items ?
                            items.map((item, index) => (<Rows item={item} index={index} columns={columns} style={props.settings.style} label={label} rowAction={props.rowAction} tableProps={props.tableProps} />))
                            :
                            <tr>
                                <td colspan={columns?.length ? columns.length : 1}><PageLoader /></td>
                            </tr>
                    }
                </TableBody>
            </CustomTable>
        </Box>
    )
}

