import React from 'react';
import { Grid, Box, Divider } from '@material-ui/core';
import Typography from '../../../ui/typography/Typography'
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { withApollo } from 'react-apollo';
import styled from 'styled-components'
import { ALERT_ERROR, ALERT_SUCCESS } from '../../../../js/constants/alert-types';

import _ from 'lodash';

import { ROUTE_HOME } from '../../../../js/constants/route-names';
import { hasRights } from '../../../../js/utils/rights';
import { PRODUCTS, PRODUCTS_PRODUCTS, VIEW } from '../../../../js/constants/constant-rights';
import PageContainer from '../../../layouts/Container/PageContainer';
import ContentContainer from '../../../layouts/Container/ContentContainer';
import colors from '../../../../config/theme/colors'
import { getElements } from '../../../../js/utils/functions';

import noImage from '../../../../assets/images/not-found.png'
import AccordionCustom from '../../../layouts/Accordion/AccordionCustom';
import StatusInfo from '../../../ui/status-info/StatusInfo';
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';
import LayoutFormBuilder from '../../../ui/form/LayoutFormBuilder';
import productDetailConfig from './config/productDetail.config';
import { CREATE_PRODUCT_STOCK, UPDATE_PRODUCT_STOCK } from '../../../../queries/stocks';
import { GET_LOCATIONS } from '../../../../queries/warehouses';

const ProductStats = styled(Box)`
    width:100%;
    border-radius:5px;
    overflow:hidden;
    display:grid;
    grid-template-columns : repeat(4,1fr);
    gap:1px;
`

const ProductStatsItem = styled(Grid)`
    padding:16px;
    padding-bottom: 24px;
    width: 100%;
    border: 1px solid ${colors.grey.border};
    color: ${colors.black.regular};
`

const InfoText = styled(Typography)`
    strong{
        color : ${colors.blue.lighter.hue300}
    }
`

const RowContainer = styled.div`
    display: grid;
    padding: 5px 0;
    grid-template-columns: 150px 1fr;
    gap: 10px;
    min-height: 30px;

    &:last-of-type{
        padding-bottom:0;
    }
`;

const infoRow = (key, value, link) => {
    return (
        <RowContainer>
            <Typography variant="body1" style={{ fontSize: 13, color: colors.black.regular }}>
                {key}
            </Typography>
            {link ?
                <a style={{ color: colors.black.regular, fontWeight: 'bold' }} href={link.path}>
                    {value}
                </a>

                :
                <Typography variant="body1" style={{ fontSize: 13, fontWeight: 'bold', color: colors.black.regular }}>
                    {value}
                </Typography>
            }

        </RowContainer>
    )
}

const checkPlural = (text, number, genre = 'm', showNumber = true) => {
    let word = number > 1 ? text + 's' : text
    number = number === 0 ? `Aucun${genre === 'f' ? 'e' : ''}` : number;
    return (
        showNumber ?
            <>
                <strong>{number}</strong> {word}
            </>
            : word
    )
}

class ProductDetail extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            openDrawer: false,
            quantity:0,
            locations:[],
            location:null
        }
    }

    handleToggleDrawer = () => {
        this.setState({ openDrawer: !this.state.openDrawer })
    }

    goTo = (route, id = null) => {
        this.props.history.push({
            pathname : route,
            state: {id}
        });
    };

    componentDidMount() {
        const getRights = hasRights(PRODUCTS, PRODUCTS_PRODUCTS, VIEW)
        if (!getRights) {
            this.props.snack(ALERT_ERROR, `Vous n'avez pas les droits suffisants pour accéder à cette page`);
            this.goTo(ROUTE_HOME);
        }
        this.initLocations();
        this.initProduct();
    }

    getTotalStock = (product) => {
        let count = 0;
        for (let stock of product.stocks.edges) {
            count = count + stock.node.quantity
        }
        return (count)
    }

    getRealStock = (product) => {
        let activeStocks = product.stocks.edges.filter(e => e.node.location?.isActive);
        let count = 0;
        for (let stock of activeStocks) {
            count = count + stock.node.quantity
        }
        count = count - this.getReservedStock(product)
        return count;
    }

    handlerMutation = async ()=>{
        this.props.startLoading()
        try {
            const id = this.state?.product?.stocks.edges.find(e=>e.node.location.id === this.state.location)?.node?.id
            if (id) {
                await this.props.client.mutate({
                    mutation: UPDATE_PRODUCT_STOCK,
                    variables: { 
                        id,
                        quantity: this.state.quantity,
                        location: this.state.location
                    }
                });
            }else {
                await this.props.client.mutate({
                    mutation: CREATE_PRODUCT_STOCK,
                    variables: { 
                        product:this.state.product.id,
                        quantity: this.state.quantity,
                        createdAt: new Date().toISOString(),
                        updatedAt: new Date().toISOString(),
                        location: this.state.location
                    }
                });
            }
            this.handleToggleDrawer()
            this.reloadProduct()
            this.props.stopLoading()
            this.props.snack(ALERT_SUCCESS, `Le stock a été mis à jour avec succès`);
        } catch (error) {
            console.log(error)
            this.props.snack(ALERT_ERROR, `Une erreur est survenue`);
            this.props.stopLoading()
        }
    }

    getReservedStock = (product) => {
        let count = 0;
        for (let order of product.salesOrderItems.edges) {
            count = count + order.node.reservedQuantity;
        }
        return count;
    }

    initLocations = async () => {
        const locations = await this.props.client.query({
            query: GET_LOCATIONS
        });
        this.setState({
            locations:locations.data.locations.edges,
        })
    }

    initProduct = async () => {
        this.props.startLoading();
        let product = await getElements('productLite', { id: `/api/product-lites/${this.props.match.params.id}` });
        product = product.data.productLite;
        if (product.stocks.edges?.length > 0) {
            this.setState({
                quantity: product.stocks.edges[0]?.node?.quantity,
                location: product.stocks.edges[0]?.node?.location?.id
            })
        }else{
            this.setState({
                quantity: 0,
                location: this.state.locations[0]?.node?.id
            })
        }
        let stats = [
            {
                name: 'Disponible temps réel',
                icon: 'stock',
                value: this.getRealStock(product)
            },
            {
                name: 'Stock',
                icon: 'stats',
                value: this.getTotalStock(product)
            },
            {
                name: 'Reservé',
                icon: 'basket',
                value: this.getReservedStock(product)
            },
            {
                name: 'Reassort',
                icon: 'saveAlt',
                value: 'N/A'
            }
        ];
        let orderItems = product.salesOrderItems.edges
        let orderStats = {
            orders: orderItems.length,
            waiting: orderItems.filter(e => ['Waiting for exportation', 'Pending for validation'].includes(e.node.salesOrder.state)).length,
            accepted: orderItems.filter(e => e.node.salesOrder.state === 'Accepted').length,
            shipped: orderItems.filter(e => e.node.salesOrder.state === 'Shipped').length
        }


        this.props.stopLoading();

        this.setState({
            product, stats, orderStats
        })

    }

    handleInputChange = (stateName, evt, custom, translated ) => {
        const value = evt?.target?.value ?? evt;
        this.setValue(stateName, value, translated);
    };

    stateCallback = (stateName, evt, custom, translated, callback) => {
        const value = evt?.target?.value ?? evt;
        this.setState({
            [stateName]: value
        })
    }

    reloadProduct = () => {
        this.setState({ product: null,locations:[] });
        this.initLocations();
        this.initProduct();
    }

    componentDidUpdate(prevProps,prevState) {
        if (prevProps.match.params.id != this.props.match.params.id) {
            this.reloadProduct()
        }
        if (prevState.location != this.state.location) {
            let quantity =this.state.product?.stocks?.edges?.find(e=>e.node.location.id === this.state.location)?.node?.quantity
            if (quantity) {
                this.setState({
                    quantity
                })
            }
        }
    }

    render() {
        return (
            <PageContainer>
                <ContentContainer
                    headerSettings={{
                        icomoon: "picto-produit",
                        title: `Détail du produit`,
                        subtitle: 'Retrouvez ici le détail de votre produit',
                        windowWidth: this.props.windowWidth,
                        textAdd: 'Mettre à jour le stock', 
                        handlerAdd: this.handleToggleDrawer,
                    }}
                >

                    {this.state.product && this.state.stats ?
                        <AccordionCustom title={'Informations'} forcedExpanded={true}>
                            <Grid container>
                                {this.state.stats.map((stat, index) => (
                                    <ProductStatsItem item xs={3} style={{
                                        borderLeft: index !== 0 ? 'none' : '1px solid' + colors.grey.border,
                                    }}>
                                        <Typography variant="h4" style={{ fontSize: 16, color: colors.black.regular, marginBottom: 10 }}>{stat.name}</Typography>
                                        <Grid container alignItems='center'>
                                            <Typography variant="h3" style={{ fontSize: 35, color: colors.black.regular }}>
                                                {stat.value}
                                            </Typography>
                                            {/* <Icon type="mui" icon={stat.icon} color={colors.black.regular} style={{ marginRight: 10, verticalAlign: 'top', fontSize: 35, marginLeft: 10 }} /> */}
                                        </Grid>
                                    </ProductStatsItem>
                                ))}
                            </Grid>
                        </AccordionCustom>
                    : null}

                    {this.state.product ?
                        <AccordionCustom title={'Informations'} style={{ marginTop: '32px' }}>
                            <div style={{ flexGrow: 1 }}>
                                <Grid container spacing={4}>
                                    <Grid item xs={5} style={{ textAlign: 'center' }}>
                                        <img src={this.state.product.imageUrl ?? noImage} style={{ width: '100%' }} />
                                    </Grid>
                                    <Grid item xs={7}>
                                        <div style={{ display: 'grid', gap: 20 }}>
                                            <Grid container style={{
                                                marginBottom:10
                                            }}>
                                                <Grid container justify='space-between'>
                                                    <Typography variant="h1" style={{ color: colors.black.regular, fontWeight: 700, fontSize: 25, width: 'calc(100% - 110px)' }}>
                                                        {this.state.product.productName}
                                                    </Typography>
                                                </Grid>
                                                <Grid container justify='space-between' alignItems='baseline' style={{ marginTop: 8 }}>
                                                    <Box>
                                                        <Typography variant="body1">
                                                            <strong>SKU : </strong>
                                                            {this.state.product.sku}
                                                        </Typography>
                                                    </Box>
                                                    <Box>
                                                        <StatusInfo status={this.state.product.stocks.edges.reduce((acc, e) => acc + e.node.quantity, 0) > 0 ? 'success' : false} />
                                                    </Box>
                                                </Grid>
                                            </Grid>

                                            <Grid container spacing={2} style={{ paddingTop: 10, borderTop: `1px solid ${colors.grey.lighter.hue900}` }}>

                                                <Grid item xs={6}>
                                                    <Typography variant="h3" style={{ color: colors.black.regular, fontSize: 14, marginBottom: 12 }}>Stock</Typography>
                                                    <div>
                                                        {infoRow('Présent dans :', this.state.product.stocks.edges.length + ' entrepôt/s')}
                                                        {infoRow('Stock :', this.state.product.stocks.edges.reduce((acc, e) => acc + e.node.quantity, 0) + ' unités')}
                                                        <Typography variant="h3" style={{ color: colors.black.regular, fontSize: 14, marginBottom: 12,marginTop:40 }}>Liste des zones</Typography>
                                                        {this.state.product.stocks.edges.map((stock, index) => {
                                                            return (
                                                                <p style={{
                                                                    display:"flex",
                                                                    alignItems:"center",
                                                                    gap:5
                                                                }}>
                                                                    {
                                                                        stock.node.location?.isActive?
                                                                        <CheckIcon fontSize='small'/>
                                                                        :<CloseIcon fontSize='small'/>
                                                                    }
                                                                    {stock.node.location?.code} ({stock.node?.quantity})
                                                                </p>
                                                            )
                                                        })}

                                                    </div>
                                                </Grid>

                                                <Grid item xs={6}>
                                                    <Typography variant="h3" style={{ color: colors.black.regular, fontSize: 14, marginBottom: 12 }}>Commandes</Typography>

                                                    <div>
                                                        {infoRow('Présent dans :', this.state.orderStats.orders + ' commande/s')}
                                                        <Divider style={{ margin: '12px 0px' }} />
                                                        {infoRow('En attente :', this.state.orderStats.waiting)}
                                                        {infoRow('En préparation :', this.state.orderStats.accepted)}
                                                        {infoRow('Envoyé/s :', this.state.orderStats.shipped)}
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </div>
                                    </Grid>
                                </Grid>
                            </div>
                        </AccordionCustom>
                        : null}

                    <LayoutFormBuilder
                        opened={this.state.openDrawer}
                        icomoon={null}
                        forClose={() => this.handleToggleDrawer()} 
                        dataLayout={productDetailConfig(this.state.locations)} 
                        drawerWidth={this.props.drawerWidth}
                        stateCallback={this.stateCallback}
                        handleInputChange={this.handleInputChange}
                        // handleButtonGroupChange={handleButtonGroupChange}
                        stepperButtonAction={[
                            () => {
                                return true;
                            }, 
                            () => {
                                return true;
                            }, 
                        ]}
                        //errorCallback={handleFormError}
                        validateButton={true}
                        handlerMutation={this.handlerMutation}
                        allState={this.state} 

                        windowWidth={this.props.windowWidth}
                        isSublayout={false}
                    />

                </ContentContainer>
            </PageContainer>
        )
    }


}

const mapDispatchToProps = dispatch => {
    return {
        startLoading: () => dispatch({ type: 'START_LOADING' }),
        stopLoading: () => dispatch({ type: 'STOP_LOADING' }),
        snack: (type, message) => dispatch({ type: 'SNACK', payload: { type, message } })
    }
}

const mapStateToProps = state => {
    return {
        loading: state.loading,
        products: state.products,
        attributes: state.attributes,
        locales: state.locales,
    };
};

export default withRouter(withApollo(connect(mapStateToProps, mapDispatchToProps)(ProductDetail)));