import { Box, CircularProgress, Grid, Typography } from "@material-ui/core"
import colors from "../../../../config/theme/colors"
import { spaceOnNumbers } from "../../../../js/utils/spaceOnNumbers"
import OurTypography from "../../../ui/typography/Typography"
import CardCustom from "../CardCustom"
import { ArrowDropDown } from '@material-ui/icons';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import styled from "styled-components"

const BoxCustom = styled(Box)`
    color: ${props => props.statuscolor === "neutral" ? colors.grey.regular : props.statuscolor === "positive" ? colors.green.regular : colors.red.regular};
    svg circle{
        stroke: ${props => props.statuscolor === "neutral" ? colors.grey.regular : props.statuscolor === "positive" ? colors.green.regular : colors.red.regular};
    }
    div:nth-child(2) svg circle{
        stroke: #EDEEEE;
    }
    p{
        color: ${props => props.statuscolor === "neutral" ? colors.grey.regular : props.statuscolor === "positive" ? colors.green.regular : colors.red.regular};
    }
`;

const CardOrdersHub = (props) => {
  const getPercentage = (value1, value2) => {
    const percentage = Math.round((((value1 * 100) / value2)) - 100)
    if (isNaN(percentage)) {
      return "N/A"
    }
    if( percentage === Infinity) {
      return "100"
    }

    return percentage
  }

  const percent = getPercentage(props.data.value, props.data.evolution)
  const getPercentForChart = (percent) => {
    if( percent === "=") {
      return 100
    }

    if (isNaN(percent)) {
      return 0
    }

    if (percent < -100) {
      return 100
    }

    if (percent > 100) {
      return 100
    }

    return percent
  }

  const getFormatedPercent = (percent) => {
    if( percent === "=") {
      return percent
      // return percent -- // TODO: Check when there is no evolution
    }

    return percent + "%"
    
  }

  return (
    <CardCustom contentpadding={'8px 0px 8px 15px'} width={"100%"} style={{
      border: props.spacing > 0 
        ? `1px solid ${colors.grey.border}`
        : 'none',
      borderRadius: '0px',
      boxShadow: 'none',
      borderRight: props.spacing 
        ? `1px solid ${colors.grey.border} !important`
        : null,
      minHeight: 110,
      height: 110,
      lineheight: '14px',
    }}>
      <Grid container>
        <Grid item xs={props.data.hidePercent ? 12 : 6} md={props.data.hidePercent ? 12 : 6}>
          <OurTypography fontweighttext={700} style={{
            fontSize: 12,
            color: colors.black.regular,
            height: 30,
            maxWidth: 75,
            marginBottom: 10,
            lineHeight: '14px',
          }}
          >
            {props.data.title}
          </OurTypography>
          <Box>
            <OurTypography fontweighttext={700} style={{
                fontSize: 28,
                color: colors.black.regular,
                ...props.data.style
              }}>
                {spaceOnNumbers(props.data.value)} {props.data.suffix}
              </OurTypography>
              {/* <OurTypography>{props.data.evolution}</OurTypography> */}
              {
                props.data.percent && getPercentForChart(percent) ? 
                  <Box display={'flex'} alignItems="center" mt={'5px'}>
                    {
                      props.data.value < props.data.evolution  ?
                        <ArrowDropDown style={{ fill: colors.red.regular }} /> :
                        <ArrowDropUpIcon style={{ fill: colors.green.regular }} />
                    }
                    <OurTypography variant="body1" style={{ color: props.data.value < props.data.evolution ? colors.red.regular : colors.green.regular }}>
                      J -1 <span>({spaceOnNumbers(props.data.evolution)} {props.data.miniInfoSuffix})</span>
                    </OurTypography>
                  </Box>
                : null
              }
              
          </Box>
        </Grid>
        {props.data.percent && getPercentForChart(percent) && !props.data.hidePercent ? (
            <Grid item xs={6} md={6} style={{
              paddingTop: '15px'
            }}>
              <BoxCustom position="relative" maxHeight={65} display="flex" justifyContent={"center"} statuscolor={props.data.value < props.data.evolution ? 'negative' : "positive"}>
                <CircularProgress variant="determinate" thickness={3} value={getPercentForChart(percent)} size={"100%"} style={{ maxHeight: 65, maxWidth: 65, width: "100%", height: "auto", position: "absolute", zIndex: 2 }} />
                <CircularProgress
                  variant="determinate"
                  value={100}
                  style={{ maxHeight: 65, maxWidth: 65, width: "100%", height: "auto" }}
                  thickness={2.5}
                />
                <Box
                  top={0}
                  left={0}
                  bottom={0}
                  right={0}
                  position="absolute"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Typography variant="body1" color="textSecondary" style={{ fontWeight: "bold" }}>
                    {getFormatedPercent(percent)}
                    {/* {stats?.today.cartAverage === 0 ? null : stats?.today.cartAverage < stats?.yesterday.cartAverage ? '' : "+"}
                    {getPercentage(stats?.today.cartAverage, stats?.yesterday.cartAverage)} */}
                  </Typography>
                </Box>
              </BoxCustom>
            </Grid>
          ) : null}
      </Grid>
    </CardCustom>
  )
}

export default CardOrdersHub