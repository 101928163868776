import moment from "moment";

export const ordersListMapper = (orders,lang) =>{
    return new Promise((resolve,reject)=>{
        let results = orders.map(order =>{
            let item = order.node;

            return{
                ...item,
                numero : item.origin==="MP" ?  item.shoppingFeedId : item.hubIdentifier,
                orderDate : moment((item.creationDate) ? item.creationDate : item.createdAt).format('YYYY-MM-DD à HH:mm'),
                recipient : `${item.billToLastname} ${item.billToFirstname}`,
                warehouse : getDataFromItems(item.salesOrderItems,'shipments',getDataFromArray,['location.code']),
                sourceID : item.origin==="B2C" ? item.magentoId : item.marketPlaceReference,
                price:`${item.grandTotal} €`,
                platform : item.origin==="B2C" ? item.marketPlaceName ? item.marketPlaceName :'Site officiel' : item.origin==='MANUAL' ? 'HUB DXP' : item.marketPlaceName,

            }
        });


        resolve(results);
    })
}

export const ordersReturnedListMapper = (orders,lang) =>{
    return new Promise((resolve,reject)=>{
        let results = orders.map(order =>{
            let item = order.node;
            return{
                ...item,
                numero : item.id.replace('/api/sales-orders/',''),
                orderDate : moment((item.creationDate) ? item.creationDate : item.createdAt).format('YYYY-MM-DD à HH:mm'),
                recipient : `${item.billToLastname} ${item.billToFirstname}`,
                warehouse : getDataFromItems(item.salesOrderItems,'shipments',getDataFromArray,['location.code']),
                sourceID : item.origin==="B2C" ? item.magentoId : item.marketPlaceReference,
                price:`${item.grandTotal} €`,
                returnID : getDataFromItems(item.salesOrderItems,'requests',getDataFromArray,['hubIdentifier']),
                platform : item.origin==="B2C" ? item.marketPlaceName ? item.marketPlaceName :'Magento' : item.marketPlaceName,
            }
        });

        resolve(results);
})
}

    const replaceReturnId = (e) =>{
        let id = e.replace('/api/sales-order-item-r-m-as/','')
        return id;
    }

function getDataFromItems(items,keys,callback,args,isUnique=true){
    keys = keys.split('.');
    let results=[];

    for(let item of items.edges){
        let targetValue = item.node;
        for(let key of keys){
            targetValue = targetValue[key]
        }

        let result = callback.apply(null, [targetValue,...args]);
        
        results=[...results,...result]
    }

    if(isUnique){
        let uniqueValues = [];
        results = results.filter(item =>{
            if(uniqueValues.includes(item)){
                return false;
            } else{
                uniqueValues.push(item);
                return true
            }
        })
    }
    return results;
}


function getDataFromArray(array,keys,isUnique=true,callback){
    keys = keys.split('.');
    let results=[];
    for(let item of array.edges){
        let targetValue = item.node;
        for(let key of keys){
            if(targetValue === null) continue
            targetValue = targetValue[key]
        }

        if(isUnique){
            if(!results.includes(targetValue)){
                results.push(targetValue)
            }

        } else {
            results.push(targetValue)
        }

    }

    if(callback){
        results=results.map(e=>(callback(e)))
    }
    return results;
}