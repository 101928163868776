import React from 'react';
import styled from 'styled-components';
import {Box,Grid} from '@material-ui/core';
import Typography  from '../../../ui/typography/Typography';
import colors from '../../../../config/theme/colors';
import UpdateIcon from '@material-ui/icons/Update';
import StatusInfo from '../../../ui/status-info/StatusInfo';
import Button from '../../../ui/button/Button'
import noImage from '../../../../assets/images/not-found.png';

import {useHistory} from 'react-router-dom';
import { ROUTE_LOCATION_DETAIL,ROUTE_PRODUCTS_LIST_DETAIL } from '../../../../js/constants/route-names';
import StocksInfo from '../../../screens/dashboard/products/components/StocksInfo';

const CardRoot = styled(Box)`
    padding : 20px;
    background:white;
    overflow:hidden;
    position : relative;
    height:100%;
`

const CardContent = styled.div`
    display:grid;
    grid-template-columns : 170px 1fr;
    gap:10px;
    align-items:flex-start;
`
const Infos = styled.div`
    display:grid;
    gap:20px;
`
const Stock = styled.div`
    position : absolute;
    top:0;
    right:0;
    color : white;
    padding:10px;
    background : ${colors.blue.regular};

    .totalStock{
        display: grid;
        align-items: center;
        justify-content: space-between;
        grid-template-columns: auto 1fr;
        gap: 3px;
        padding-top: 5px;
    }
    
    }
`

export default function CardProductHubPicker(props){
    const history = useHistory();

    const goTo = (url,variables) =>{
        history.push({
            pathname : url,
            states : variables
        })
    }

    return(
        <CardRoot>
            <Stock>
                <div style={{fontWeight:'bold',textAlign:'center'}}>DISPO</div>
                <div className="totalStock">
                    <UpdateIcon/>
                    <span>{props.data.totalStock}</span>
                        
                </div>
            </Stock>
            <CardContent>
                <div style={{textAlign: 'center'}}>
                    {props.data.imageUrl ? 
                        <img src={props.data.imageUrl} style={{width:'auto', height: '100px'}}/>
                        
                    : 
                        <img src={noImage} style={{width:'100%'}}/>
                    }
                </div>
                <Infos>
                    <Typography variant="body2">{props.data.sku}</Typography>

                    <Typography variant="h3" style={{maxWidth:'89%'}}>{props.data.productName}</Typography>
                    
                    

                    <StatusInfo status={props.data.totalStock > 0 ? 'ONLINE' : 'DISABLED'}/>                
                </Infos>                
            </CardContent>
            <Grid container>
                <Grid item xs={12} style={{paddingTop:10}}>
                    <Typography variant="body2">
                        Présent dans <span style={{color:colors.blue.lighter.hue300}}>{props.data.warehousesCount ?? 0} entrepôts</span>
                    </Typography>
                    <StocksInfo noLink stocks={props.data.stocks.edges}/>
                    
                </Grid>
            </Grid>
            <Grid container justify="flex-end">
                <Button onClick={() => props.pickerHandler(props.data)}>Ajouter</Button>
            </Grid>


     

        </CardRoot>
    )
}