import { gql } from 'apollo-boost';

export const GET_WAREHOUSES = gql`
query warehouses ($nbperpage: Int, $cursor: String, $cursorLast: String, $code: String, $isActive: Boolean){
    warehouses (first: $nbperpage, after: $cursor, before: $cursorLast, code: $code, isActive: $isActive){
    totalCount
    pageInfo {
      startCursor
      endCursor
    }
    edges{
      node{
        id
        _id
        code
        description
        isActive
        media {
          filePath
          id
        }
        location{
            totalCount
            edges{
                node{
                    id
                    _id
                    code  
                  	isActive
                  	description
                  	media {
                      filePath
                    }
                }
            }
        }
      }
    }

  }
}
`;

export const GET_WAREHOUSE_BY_ID = gql`
query warehouse ($id: ID!){
  warehouse (id: $id){
    id
    _id
    code
    description
    isActive
    media {
      id
      filePath
      type
    }
    location{
        totalCount
        edges{
            node{
                id
                _id
                code
                isActive  
                description
            }
        }
    }
  }
}
`;

export const ADD_WAREHOUSE = gql`
mutation createWarehouse ($code: String!, $description: String, $isActive: Boolean, $media: String){
  createWarehouse (input: {code:$code, description:$description, isActive:$isActive, media:$media}){
    warehouse{
      id
      _id
      code
      description
      isActive
      media {
        filePath
        id
      }
      location{
          totalCount
          edges{
              node{
                  id
                  _id
                  code
                  isActive  
                  description
              }
          }
      }
    }
  }
}
`;

export const UPDATE_WAREHOUSE = gql`
mutation updateWarehouse ($id: ID!, $code: String!, $description: String, $isActive: Boolean, $media: String){
  updateWarehouse (input: {id: $id, code:$code, description:$description, isActive:$isActive, media:$media}){
    warehouse{
      id
      _id
      code
      description
      isActive
      media {
        filePath
        id
      }
      location{
          totalCount
          edges{
              node{
                  id
                  _id
                  code
                  isActive  
                  description
              }
          }
      }
    }
  }
}
`;

export const DELETE_WAREHOUSE = gql`
mutation deleteWarehouse ($id: ID!){
  deleteWarehouse (input: {id: $id}){
    warehouse{
      id
      _id
      code
      description
      isActive
    }
  }
}
`;

export const GET_LOCATIONS = gql`
{
  locations{
    edges{
      node{
        id
        _id
        code
        isActive
        description
        warehouse{
          code
        }
      }
    }
  }
}
`;

export const GET_LOCATION_BY_ID = gql`
query location ($id: ID!){
  location (id: $id){
    id
    _id
    code
    isActive
    description
    media {
      id
      filePath
    }
    warehouse{
      id
      code
    }
  }
}
`;

export const ADD_LOCATION = gql`
mutation createLocation ($code: String!, $warehouse: String){
  createLocation (input: {code:$code, warehouse:$warehouse}){
    location{
      id
      _id
      code
      isActive
      description
      warehouse{
        code
      }
    }
  }
}
`;

export const UPDATE_LOCATION = gql`
mutation updateLocation ($id: ID!, $code: String!, $warehouse: String){
  updateLocation (input: {id: $id, code:$code, warehouse:$warehouse}){
    location{
      id
      _id
      code
      isActive
      description
      warehouse{
        code
      }
    }
  }
}
`;

export const DELETE_LOCATION = gql`
mutation deleteLocation ($id: ID!){
  deleteLocation (input: {id: $id}){
    location{
      id
      _id
      code
      description
      isActive
    }
  }
}
`;