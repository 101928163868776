/**
 * Function used to download private media through api endpoint
 * 
 * @param {string} url 
 */
const downloadTempMedia = async (url, method = 'GET', data = null) => {
    var headers = new Headers();

    const token = localStorage.getItem('AUTH_TOKEN');
    if (token)
        headers.append('Authorization', `Bearer ${token}`);
    else throw new Error('Impossible de télécharger ce fichier. (Vous n\'êtes pas authentifié)');

    var init = { 
        method,
        headers,
        mode: 'cors',
        body: data ? JSON.stringify(data) : null
    };

    var { isSuccess, error } = await fetch(url, init)
        .then(async (response) => {
            if (!response.ok) {
                var data = await response.json()
                throw new Error(data?.message ?? 'Fichier indisponible');
            }

            const contentDisposition = response.headers.get('Content-Disposition');
            var fileName = 'sinfin';

            if (contentDisposition) {
                const match = contentDisposition.match(/filename="(.+)"/);
                if (match) {
                    fileName = match[1];
                }
            }

            var blob = await response.blob();
            blob.fileName = fileName

            return blob;
        }).then(blob => {
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = blob.fileName;
            a.click();

            //clean
            window.URL.revokeObjectURL(url);

            return { isSuccess: true, error: null };
        }).catch(error => {
            return { isSuccess: false, error: error }
        })

    if (!isSuccess) {
        throw new Error("Impossible de télécharger ce fichier. (".concat(error.message).concat(")"))
    }
};

export default downloadTempMedia;
