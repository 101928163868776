import {companiesGridMapper,companiesListMapper} from './companies';
import {customersListMapper} from './customers';
import { ordersListMapper,ordersReturnedListMapper } from './orders';
import {productsGridMapper} from './products'
import { warehousesGridMapper,warehousesListMapper } from './warehouses';
const mapperList = {
    //Companies
    'companiesGridMapper' : companiesGridMapper,
    'companiesListMapper':companiesListMapper,

    //Customers
    'customersListMapper':customersListMapper,

    //ORDERS
    'ordersListMapper':ordersListMapper,
    'ordersReturnedListMapper': ordersReturnedListMapper,

    //PRODUCTS
    'productsGridMapper' : productsGridMapper,

    //WAREHOUSES
    'warehousesGridMapper':warehousesGridMapper,
    'warehousesListMapper' : warehousesListMapper
}
export function itemMapper (formats,items,lang,customDatas){
    return new Promise(async(resolve,reject)=>{   
        let result = {};
        for(let format of formats){
            if(format.mapper){
                let mapper = mapperList[format.mapper];
                let formatItems = await mapper(items,lang,customDatas);
                result[format.view] = formatItems ;
            } else {
                result[format.view] = items;
            }
            
        }
        resolve(result);

    })

}