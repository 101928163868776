export function warehousesGridMapper(warehouses,lang){
    return new Promise((resolve,reject)=>{
        let results = warehouses.map(warehouse=>({
            ...warehouse.node
        }))
        resolve (results)
    })
}

export function warehousesListMapper(warehouses,lang){
    return new Promise((resolve,reject)=>{
        let results = warehouses.map(e=>({
            key1 : e.value,
            key1 : e.value2
        }))
        resolve (results)
    })
}