import React, { useEffect }  from 'react';

import { Box, Grid } from '@material-ui/core';
import Button from '../../../ui/button/Button';
import Typography from '../../../ui/typography/Typography';
import colors from '../../../../config/theme/colors';
import { withRouter } from 'react-router';
import { ROUTE_CATALOGS_DETAIL } from '../../../../js/constants/route-names';
import styled from 'styled-components';
import * as moment from 'moment';
import 'moment-timezone';

import SyncIcon from '@material-ui/icons/Sync';
import SyncProblemIcon from '@material-ui/icons/SyncProblem';

import emptyCatalog from '../../../../assets/images/emptyCatalog.jpg';
import StatusInfo from '../../../ui/status-info/StatusInfo';
import HTMLEllipsis from 'react-lines-ellipsis/lib/html';


const ContainerImage = styled(Box)`
    overflow: hidden;
    background: url(${props => props.backgroundimage});
    background-size: cover;
    background-position: center;
    border-radius: 4px 4px 0px 0px;
`
const ContainerStyled = styled(Grid)`
    padding-bottom: 0.5rem;
`
const LineColor = styled(Box)`
    background: ${props => props.typecatalog === 'permanent' ? colors.blue.darker.hue300 : props.typecatalog === 'ephemeral' ? colors.purple.regular : colors.pink.regular};
`
const CatalogType = styled(Typography)`
    color: ${props => props.typecatalog === 'permanent' ? colors.blue.darker.hue300 : props.typecatalog === 'ephemeral' ? colors.purple.regular : colors.pink.regular};
    font-weight: bold;
    text-transform: uppercase;
    margin-right: ${props => props.marginright};
`
const CatalogDate = styled(Typography)`
    color: ${colors.grey.regular};
    font-weight: bold;
    text-transform: uppercase;
`
const CatalogDescription = styled(Typography)`
    max-height: 40px;
    min-height: 40px;
    overflow: hidden;
    p{
        margin: 0;
    }
`

const CatalogNumbers = styled(Typography)`
    color: ${props => props.typecatalog === 'permanent' ? colors.blue.darker.hue300 : props.typecatalog === 'ephemeral' ? colors.purple.regular : colors.pink.regular};
    font-weight: bold;
`
const CatalogNbType = styled(Typography)`
    margin-top: -0.5rem;
    font-weight: bold;
    font-size: 14px;
    color: ${colors.blue.regular};
`

function CardCatalog(props){
    const [nbProducts, setNbProducts] = React.useState(0);
    const [catalogData, setCatalogData] = React.useState(null);
    const [startDate, setStartDate] = React.useState(null);
    const [endDate, setEndDate] = React.useState(null);


    const goTo = (route, id) => {
        props.history.push({
            pathname : route,
            state: {catalogId : id}
        });
    };

    let calcProduct = 0;

    useEffect(() => {
        if (catalogData !== props.data.node){
            let toDateStart = moment(props.data.node.startAt).format('L');
            let toDateEnd = moment(props.data.node.endAt).format('L');

            setCatalogData(props.data.node);
            setStartDate(toDateStart);
            setEndDate(toDateEnd);

            if (props.nbProducts){
                setNbProducts(props.nbProducts);
            }else{
                if(props.data.node.categories.length === 0){
                    setNbProducts(0);
                }
                else{
                    for (let item of props.data.node.categories){
                        if(item.productCategories?.totalCount){
                            calcProduct = calcProduct + item.productCategories.totalCount;
                            setNbProducts(calcProduct);
                        }
                    }
                }
            }
        }
    });

    const getCatalogTypeName = (identifier) => {
        switch(identifier) {
            case 'permanent':
                return 'Catalogue Permanent';
            case 'ephemeral':
                return 'Catalogue Éphémère';
            case 'thematic':
                return 'Catalogue Thématique';
            default: return 'n/a';
        }
    };
    return(
        catalogData ?
        (
            <Box width={'100%'} style={{cursor: props.inputCard ? 'inherit' : 'pointer'}} onClick={props.inputCard ? null : () => goTo(ROUTE_CATALOGS_DETAIL.replace(':id', catalogData.id.replace('/api/catalogs/', '')))}>
                <ContainerImage height={160} backgroundimage={catalogData.media ? `${process.env.REACT_APP_MEDIAS}/${catalogData.media.filePath}` : emptyCatalog} />
                <LineColor height={8} typecatalog={catalogData.type.identifier}/>
                <Box padding={'1rem 1.5rem 0.5rem 1.5rem'}>
                    <ContainerStyled 
                        container 
                        direction="row"
                        justify="space-between"
                        alignItems={props.openForm ? "flex-start" : "center"}
                    >
                        {
                            process.env.REACT_APP_MODE_SPREAD !== "hub" ?
                                <Grid item xs={props.openForm ? 8 : 10}> 
                                    <Grid container alignItems="center">
                                        <CatalogType variant="h5" component="div" typecatalog={catalogData.type.identifier} marginright={"1rem"}>
                                            {getCatalogTypeName(catalogData.type.identifier)}
                                        </CatalogType>
                                        {
                                            catalogData.type.identifier !== 'permanent' ? 
                                                <CatalogDate variant="h5" colortext={colors.grey.regular} component="div">
                                                    {startDate} - {endDate}
                                                </CatalogDate> 
                                            : <div style={{minHeight: 15, width: '44%'}} />
                                        }
                                    </Grid>
                                </Grid>
                            : null
                        }
                        <StatusInfo status={catalogData.status} width={'auto'}/>
                    </ContainerStyled>
                    <Typography variant="h4" colortext={colors.blue.regular} component="div">
                        <Box fontWeight="bold">
                            {catalogData.libelle}
                        </Box>
                    </Typography>
                    <Box pb={2} pt={1}>
                        <CatalogDescription variant="body1" component="div">
                            <HTMLEllipsis
                                unsafeHTML={catalogData.description ? catalogData.description :  null }
                                maxLine='2'
                                ellipsis='...'
                                basedOn='letters'
                            />
                        </CatalogDescription>
                    </Box>
                    {
                        catalogData.autosync ? (
                            <div style={{display: 'flex', flex: 1, alignItems: 'center', marginBottom: 20}}>
                                <div style={{width: 'auto', background: colors.green.regular, display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '4px 12px', borderRadius: 50}}>
                                    <SyncIcon style={{fill: colors.white,  borderRadius: '50%', fontSize: '1.2rem'}} />
                                    <Typography variant="body1" component="div" style={{color: colors.white, paddingLeft: 8, fontSize: 12, lineHeight: '15px'}}>
                                        Synchronisation automatique des produits
                                    </Typography>
                                </div>
                            </div>
                        ) : (
                            <div style={{display: 'flex', flex: 1, alignItems: 'center', marginBottom: 20}}>
                                <div style={{width: 'auto', background: colors.orange.regular, display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '4px 12px', borderRadius: 50}}>
                                    <SyncProblemIcon style={{fill: colors.white, borderRadius: '50%', fontSize: '1.2rem'}} />
                                    <Typography variant="body1" component="div" style={{color: colors.white, paddingLeft: 8, fontSize: 12, lineHeight: '15px'}}>
                                        Aucune synchronisation automatique des produits
                                    </Typography>
                                </div>
                            </div>
                        )
                    }
                    <Grid
                        container 
                        direction="row"
                        justify="space-between"
                        alignItems="center"
                    >
                        <Grid item lg={props.openForm ? 12 : 6} xs={12}>
                            <Grid container>
                                <Grid item>
                                    <CatalogNumbers variant="h1" typecatalog={catalogData.type.identifier}>
                                        {nbProducts}
                                    </CatalogNumbers>
                                    <CatalogNbType variant="h4">
                                        {nbProducts > 1 ? 'produits' : 'produit'}
                                    </CatalogNbType>
                                </Grid>
                                <Grid item style={{marginLeft: 32}}>
                                    <CatalogNumbers variant="h1" typecatalog={catalogData.type.identifier}>
                                        {catalogData.categories.length > 0 ? catalogData.categories.length : 0}
                                    </CatalogNumbers>
                                    <CatalogNbType variant="h4">
                                        {catalogData.categories.length > 1 ? 'catégories' : 'catégorie'}
                                    </CatalogNbType>
                                </Grid>
                            </Grid>
                        </Grid>
                        {
                            !props.configCard ? 
                                <Grid item lg={props.windowWidth > 1500 ? (props.openForm ? 12 : 6) : 6} xs={12}>
                                    <Grid container justify="flex-end">
                                        <Box mt={props.openForm ? 1 : 0}>
                                            <Button 
                                            text={props.textButton} 
                                            color={colors.blue.lighter.hue300} 
                                            border={`1px solid ${colors.blue.lighter.hue300}`}
                                            colorhover={colors.white}
                                            bgcolor={colors.white} 
                                            bgcolorHover={colors.blue.lighter.hue300}
                                            disableElevation={true} 
                                            arrow="right" 
                                            onClick={() => goTo(ROUTE_CATALOGS_DETAIL.replace(':id', catalogData.id.replace('/api/catalogs/', '')))}/>
                                        </Box>
                                    </Grid>
                                </Grid> 
                            : null
                        }
                    </Grid>
                </Box>
            </Box>
        )
        : null
    )
}

export default withRouter(CardCatalog);
