import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import colors from '../../../config/theme/colors';
import Button from '../button/Button';
import OurTypography from '../typography/Typography';
import { Box } from '@material-ui/core';

const DialogModal = (props)=>{

    return(
        <Dialog
            open={props.open}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            onClose={props.onClose ? props.onClose : null}
            maxWidth={props.maxWidth}
            PaperProps={{
                style: {
                    borderRadius: 0,
                    boxShadow: 'none',
                },
            }}
            
        >
            <Box style={props.customStyle}>
                <DialogTitle style={props.titleStyle} id="alert-dialog-title">
                    <OurTypography style={{
                        paddingBottom: 0,
                        paddingTop: 14,
                        fontSize: 33,
                        fontWeight: 700,
                        color: colors.black.regular
                    }}>{props.title}</OurTypography>
                </DialogTitle>
                <OurTypography style={{padding: '0px 24px'}}>{props.description}</OurTypography>
                
                <DialogContent>
                    {
                        props.notText ?
                            props.children
                        : 
                            <DialogContentText id="alert-dialog-description">
                                {props.children}
                            </DialogContentText>
                    }
                </DialogContent>
                
                <DialogActions style={{padding: "8px 24px"}}>
                    {
                        props.secondaryAction ? (
                            <Button 
                                onClick         = {props.secondaryAction} 
                                color           = {props.secondarycolor ? props.secondarycolor : colors.grey.regular} 
                                bgcolor         = {props.secondarybgcolor ? props.secondarybgcolor : colors.white} 
                                bgcolorhover    = {props.secondarybgcolorhover ? props.secondarybgcolorhover : colors.grey.lighter.hue900} 
                                border          = {`1px solid ${props.secondaryborder ? props.secondaryborder : colors.grey.regular}`}
                                text            = {props.secondaryText ? props.secondaryText : 'Annuler'}
                            />
                        ) : null
                    }
                    {
                        props.primaryAction ? (
                            <Button 
                                onClick         = {props.primaryAction} 
                                bgcolor         = {props.primarybgcolor ? props.primarybgcolor : colors.red.regular} 
                                bgcolorhover    = {props.primarybgcolorhover ? props.primarybgcolorhover : colors.red.darker} 
                                color           = {props.primarycolor ? props.primarycolor : colors.white} 
                                text            = {props.primaryText ? props.primaryText : 'Supprimer'}
                                disabled        = {props.primaryDisabled ? props.primaryDisabled : false}
                                autoFocus
                            />
                        ) : null
                    }
                </DialogActions>
            </Box>
        </Dialog>
    )
}

export default DialogModal;