import TableCell from '@material-ui/core/TableCell';
import Chip from '@material-ui/core/Chip';
import { Tooltip } from 'bootstrap';
import colors from '../../../../../../config/theme/colors';
function CellChips(props) {


    return (
        // <Tooltip title={'test'}>
            <TableCell
                style={{
                    width: props.width ? props.width : 'auto',
                    fontWeight: props.fontWeight ? props.fontWeight : 'normal',
                }}
            >
                {props.value ?
                    props.value.map(item => (
                        <Chip title={item} label={item} style={{ marginRight: 5, fontSize: 12, backgroundColor: colors.white, border: `1px solid ${colors.black.lighter}` }} size="small" />
                    ))
                    : null}
            </TableCell>
        // </Tooltip>
    )
}
export default CellChips;