
import CardWarehouse from "../../../../layouts/Card/cardContent/CardWarehouse"

export const listSettings = {
    grid : {
        components : CardWarehouse,
        columns : {
            xs : 1,
            sm : 2,
            laptop : 3,
            xl: 4
        }
    },
 

}

export const listMappers = [
    {
        view : 'card',
        mapper : 'warehousesGridMapper'
    },
]

export const searchSettings = (identifier, datas) => {
    // TODO: Prepare the back end for the search

    return ({
        inputs : [
            {
                placeholder : 'Rechercher par nom',
                queryField : 'code',
                type : 'text',
                label : 'Nom',
            },
            {
                placeholder : 'Rechercher par status',
                queryField : 'isActive',
                type : 'select',
                label : 'Status',
                defaultValue: 'all',
                value: [
                    { value: "all", label: 'Tous' },
                    { value: 'active', label: 'Actif' },
                    { value: 'inactive', label: 'Inactif' },
                ]
            },
        ]
    })
}

export const perPageOptions = {
    card : ['6'],
}

export const viewsOptions = {
    current : 'card',
    settings : ['card'] //Si il n'y a qu'un seul item il n'y aura pas de changement de vues
}
