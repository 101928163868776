import React from 'react';
import reactCSS from 'reactcss';
import { SketchPicker } from "react-color";
import Typography from '@material-ui/core/Typography';
import styled from 'styled-components';
import colors from '../../../config/theme/colors';

const SketchPickerCustom =  styled(SketchPicker)`
  box-shadow: inherit!important;
  border-radius: 5px!important;
  border: 1px solid lightgrey!important;
  padding: 10px!important;
  width: calc(100% - 32px)!important;
  position: relative;
  > div:first-of-type{
    width:calc(100% - 100px)!important;
    height: 100px!important;
    padding-bottom: 0!important;
    border-radius: 0 5px 5px 0;
    left: 100px;
  }
  .flexbox-fix{
    &:nth-child(2){
      margin: 10px 0;
      &>div{
        &:first-child{
          &> div{
            border-radius: 5px;
          }
        }
        &:last-child{
          width: 100px!important;
          height: 100px!important;
          position: absolute!important;
          top: 10px;
          left: 10px;
          margin: 0!important;
          &>div{
            border-radius: 5px 0 0 5px!important;
            box-shadow: inherit!important;
          }
        }
      }
    }
    &:nth-child(3){
      &> div{
        padding-left: 10px;
        &:first-child{
          padding-left:0;
        }
        &:nth-child(2){
          margin-left: 10px;
          border-left: 1px solid lightgrey;
          padding-left: 10px!important;
        }
        &> div{
          display: flex;
          flex-direction: row-reverse;
          input{
            font-size: 12px!important;
            border-radius: 4px;
            font-weight: bold;
          }
          label{
            font-size: 12px!important;
            padding-right: 5px;
            text-transform: uppercase!important;
            color: #000000!important;
            font-weight: bold;
          }
        } 
      }
    }
  }
`;

const DivColor = styled.div`
  border-radius: 5px!important;
  border: 1px solid lightgrey!important;
  background: white;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0 16px;
`;

class ColorPicker extends React.Component {
  state = {
    displayColorPicker: false,
    color: this.props.color,
  };

  handleClick = () => {
    this.setState({ displayColorPicker: !this.state.displayColorPicker })
  };

  handleClose = () => {
    this.setState({ displayColorPicker: false })
  };

  setColor = (color) => {
    this.setState({
      color
    }, () => {
      let codeColor = {};
      if (this.state.color && typeof(this.state.color) === 'string') {
        if (this.state.color.includes('#')){
          let shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
          let hex = this.state.color.replace(shorthandRegex, function(m, r, g, b) {
            return r + r + g + g + b + b;
          });
  
          let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
          codeColor = result ? {
            r: parseInt(result[1], 16),
            g: parseInt(result[2], 16),
            b: parseInt(result[3], 16)
          } : null;
        }
        else if (this.state.color.includes('rgba')) {
          let temp_color = this.state.color.replace(/^(rgb|rgba)\(/,'').replace(/\)$/,'').replace(/\s/g,'').split(',');
          codeColor.r = parseInt(temp_color[0]);
          codeColor.g = parseInt(temp_color[1]);
          codeColor.b = parseInt(temp_color[2]);
          codeColor.a = parseFloat(temp_color[3]);
        }
        else if (this.state.color.includes('rgb')){
          let temp_color = this.state.color.replace(/^(rgb|rgba)\(/,'').replace(/\)$/,'').replace(/\s/g,'').split(',');
          codeColor.r = parseInt(temp_color[0]);
          codeColor.g = parseInt(temp_color[1]);
          codeColor.b = parseInt(temp_color[2]);
        }
        this.setState({codeColor})
      }
    });

  }

  componentDidUpdate = (prevProps) => {
    if(this.props.color !== prevProps.color){
      this.setColor(this.props.color);
    }
  }
  componentDidMount = () => {
    this.setColor(this.props.color);
  }

  render() {
    

    const styles = reactCSS({
      'default': {
        color: {
          width: 30,
          height: 30,
          background: 'url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAMUlEQVQ4T2NkYGAQYcAP3uCTZhw1gGGYhAGBZIA/nYDCgBDAm9BGDWAAJyRCgLaBCAAgXwixzAS0pgAAAABJRU5ErkJggg==") left center',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center', 
          borderRadius: 4,
        },
        bgcolor: {
          width: 30,
          height: 30,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: 4,
          backgroundColor: this.state.color?.r && this.state.color?.g && this.state.color?.b && this.state.color?.a ? `rgba(${this.state.color.r}, ${this.state.color.g}, ${this.state.color.b}, ${this.state.color.a})` : this.state.color,
        },
        swatch: {
          padding: '5px 0',
          borderRadius: 4,
          display: 'flex',
          flexDirection: 'row',
          cursor: 'pointer',
          marginBottom: 5,
          width: '100%'
        },
        popover: {
          zIndex: '2',
          marginBottom: 20,
        },
        cover: {
          position: 'fixed',
          top: '0px',
          right: '0px',
          bottom: '0px',
          left: '0px',
        },
        label: {
          paddingLeft: 10,
          fontSize: 14,
        },
      },
    });

    return (
      <div style={{width: '100%', position: 'relative'}}>
        <div style={ styles.swatch } onClick={ this.handleClick }>
          <DivColor>
            <div style={ styles.color }>
              <div style={ styles.bgcolor}></div>
            </div>
            <p style={{textTransform: 'uppercase', padding: '0 16px'}}>{this.props.color}</p>
            {
              this.state.codeColor ? (
                <div style={{borderLeft: '1px solid lightgrey', paddingLeft: 10, display: 'flex'}}>
                  <p style={{padding: '0 16px'}}>R : {this.state.codeColor.r}</p>
                  <p style={{padding: '0 16px'}}>G : {this.state.codeColor.g}</p>
                  <p style={{padding: '0 16px'}}>B : {this.state.codeColor.b}</p>
                </div>
              ) : null
            }
            <p style={{color: colors.blue.lighter.hue300, textDecoration: 'underline'}}>Modifier</p>
          </DivColor>
          <Typography style={ styles.label }>{ this.props.label }</Typography>
        </div>
        { this.state.displayColorPicker ? <div style={ styles.popover }>
          <div style={ styles.cover } onClick={ this.handleClose }/>
          <SketchPickerCustom {...this.props} presetColors={[]} />
        </div> : null }
      </div>
    )
  }
}

export default ColorPicker;