import React, { useEffect } from 'react';
import { Grid, TextField, ButtonGroup, Button, Typography, Box, InputAdornment, FilledInput, Switch, RadioGroup, Radio, Checkbox, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, FormHelperText } from '@material-ui/core';
import { withRouter } from "react-router";
import Fab from '@material-ui/core/Fab';
import IconButton from '@material-ui/core/IconButton';
import AddCircleSharpIcon from '@material-ui/icons/AddCircleSharp';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import ReactQuill from 'react-quill';
import DropdownTreeSelect from 'react-dropdown-tree-select';
import 'react-dropdown-tree-select/dist/styles.css';
import './InputBuilder.scss';
import { v4 as uuidv4 } from 'uuid';
import AddCircleIcon from '@material-ui/icons/AddCircle'
import Tooltip from '@material-ui/core/Tooltip';
import CardCustom from '../../layouts/Card/CardCustom';
import TreeView from '../../ui/tree-view/TreeView';
import UploadFile from '../inputs/UploadFile';
import Mapping from '../inputs/Mapping';
import MultipleMapping from '../inputs/MultipleMapping';
import ColorPicker from '../inputs/ColorPicker';
import CheckboxItems from '../../ui/inputs/CheckboxItems';
import CardCatalog from '../../layouts/Card/cardContent/CardCatalog';
import { eventService } from '../../../js/services/event.service';
import * as moment from "moment";

import MenuItem from '@material-ui/core/MenuItem';
import styled from 'styled-components';
import colors from '../../../config/theme/colors';
import imgNotFound from '../../../assets/images/not-found.png';
import videoLogo from '../../../assets/pictos/video.png';
import { ROUTE_CATALOGS_LIST } from '../../../js/constants/route-names';
import ButtonCustom from '../../ui/button/Button';
import ButtonAdd from '../../ui/button/ButtonAdd';
import TypographyCustom from '../../ui/typography/Typography';
import SearchBarProductDynamic from '../../ui/inputs/SearchBarProductDynamic';
import SearchBar from '../../ui/search/SearchBar';

import { ALLOWED, DOCUMENTS, VIDEOS } from '../../../js/constants/medias-types';

import LayoutBuilder from './LayoutFormBuilder';
import mediaPickerConfig from './config/mediaPicker.config'
import iconSet from '../../../assets/selection.json'
import IcomoonReact from "icomoon-react";
import Pagination from '@material-ui/lab/Pagination';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import SpeechComponent from '../inputs/SpeechComponent';
import { makeStyles } from '@material-ui/core/styles';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import HighlightOff from '@material-ui/icons/HighlightOff';
import AddBoxIcon from '@material-ui/icons/AddBox';
import ChipInput from 'material-ui-chip-input';
import Chip from '@material-ui/core/Chip';
import Drawer from '@material-ui/core/Drawer';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import { getFileConfig } from '../../../js/helpers/files';
import OurButton from '../../ui/button/Button';
import OurTypography from '../../ui/typography/Typography';
import Listing from '../../layouts/Listing/Listing';
import { ArrowBack } from '@material-ui/icons';


const GridCustom = styled(Grid)`
    margin-bottom: 16px;
    border-radius: unset;
`;
const GridCustomCheckbox = styled(GridCustom)`
    padding: 0 1rem 0 1rem;
`;
const GridRadio = styled(Grid)`
    &:not(:last-child){
        margin-bottom: 0.5rem;
    }
`;
const ClickableLabel = styled.label`
    > div {
        border: 1px solid transparent;
        &:hover{
            border: 1px solid #6EAED1;
            cursor: pointer;
        }
    }
    &.active{
        > div {
            border: 1px solid #6EAED1;
        }
    }
    &.error{
        > div {
            border: 1px solid #f44336;
        }
    }
`;
const GridCatalog = styled(Grid)`
    background: ${colors.white};
    height: 100%;
`;
const ToggleButtonCustom = styled(ToggleButton)`
    width: 50%;
    padding: 0;
    height: 100%;
    border: none;
    text-align: inherit;
    padding: 8px;
    background: none !important;
`;
const CheckBoxCustom = styled.span`
    border: 1px solid ${colors.grey.regular};
    width: 20px;
    height: 20px;
    display: block;
    margin: auto;
    position: relative;
    &.active{
        &:after{
            content: '';
            width: 14px;
            height: 14px;
            position: absolute;
            top: 2px;
            left: 2px;
            background: ${colors.blue.lighter.hue300}
        }
    }
`;
const DescriptionRadio = styled(Typography)`
    p{
        margin: 0;
        margin-top: 4px;
    }
`;
const ButtonGroupCustom = styled(ButtonGroup)`
    button{
        background-color: ${colors.white};
        color: ${colors.grey.regular};
        text-transform: inherit;
        padding: 12px 30px;
        &.selected{
            background-color: ${colors.blue.lighter.hue300};
            color: ${colors.white};            
        }
    }
`;
const BoxClose = styled(Box)`
    width: 80px;
    padding-bottom: 0;
    color: ${colors.black.regular};
    font-size: 16px;
    display: flex;
    align-items: center;
    font-weight: bold;
    &:hover{
        text-decoration: underline;
    }
    span{
        margin-left: 4px;
    }
`;
const TextFieldCustom = styled(TextField)`
    input, .MuiSelect-select{
        font-size: 16px;
        padding: 16px;
        border-radius: 0;
        border-color: ${colors.grey.border};
        -webkit-text-fill-color:inherit;
        box-shadow:none !important;
        &:-webkit-autofill:{
            box-shadow:none !important;
        }
        @media screen and (max-width: 1450px){
            font-size: 14px;
            padding: 14px;
        }
        @media screen and (max-width: 1280px){
            font-size: 13px;
            padding: 13px;
        }
        @media screen and (max-width: 1000px){
            font-size: 13px;
            padding: 13px;
        }
    }
    & > p{
        margin: 0;
    }
`;
const TextFieldCustomSmall = styled(TextFieldCustom)`
    input, .MuiSelect-select{
        font-size: 14px;
        white-space: break-spaces;
        padding: ${props => props.usechip ? "14px 16px" : "16px"};
        padding-bottom: ${props => props.usechip ? "12px" : "16px"};
        padding-right: ${props => props.select ? "30px" : "16px"};
    }
`
const MenuItemCustom = styled(MenuItem)`
    color: ${colors.black.regular.regular};
    &.Mui-selected{
        background-color: ${colors.black.regular} !important;
        color: white;
    }
`;
const FilledInputCustom = styled(FilledInput)`
    background: white!important;
    border: 1px solid rgba(0, 0, 0, 0.23);
    border-radius: 4px;
    &.Mui-focused{
        border: 1px solid rgba(0, 0, 0, 0.87);
    }
    &:before, &:after{
        border: 0!important;
    }
    &:hover{
        background: white;
    }
    input{
        padding: 16px;
    }
`;
const LinkText = styled(Typography)`
    color: ${colors.grey.regular} !important;
    cursor: pointer;
    margin-top: 0.5rem;
    text-decoration: underline;
`;
const HelperText = styled(Typography)`
    color: rgba(0, 0, 0, 0.54);
    margin: 0;
    font-size: 0.75rem;
    margin-top: 3px;
    text-align: left;
    font-family: soleil, sans-serif;
    font-weight: 400;
    line-height: 1.66;
`;
const SelectLang = styled(TextField)`
    fieldset{
        border: none;
    }
    input, .MuiSelect-select{
        font-size: 14px;
        padding: 0px 16px !important;
        margin-right: 16px;
        color: ${colors.black.regular};
        font-weight: 700;
        border-radius: 0;
        &:focus{
            background: none;
        }
        @media screen and (max-width: 1450px){
            font-size: 14px;
            line-height: 18px;
        }
        @media screen and (max-width: 1280px){
            font-size: 13px;
            line-height: 17px;
        }
        @media screen and (max-width: 960px){
            font-size: 12px;
            line-height: 16px;
        }
    }
    svg{
        fill: ${colors.black.regular};
    }
    & > p{
        margin: 0;
    }
`;
const StyledFab = styled(Fab)`
    background-color: ${props => props.checkerror ? 'red' : colors.blue.regular};
`;
const BoxCustomProductSelector = styled(Box)`
    /* & .hasStock{
        background-color: red;
    } */
`;
const HiddenUploadFile = styled(TextFieldCustom)`
    display: none;
`;
const ContainerFileUpload = styled.div`
    background-size: ${props => props.fullwidth ? 'cover' : 'contain'} !important;
    width: 100%;
    height: ${props => props.fullwidth ? '110px' : '192px'};
    @media screen and (max-width: 1450px){
        height: ${props => props.fullwidth ? '110px' : '180px'};
    }
    @media screen and (max-width: 1280px){
        height: ${props => props.fullwidth ? '110px' : '150px'};
    }
    @media screen and (max-width: 1000px){
        height: ${props => props.fullwidth ? '110px' : '110px'};
    }
    position: relative;
    background-position: ${props => 'center'} !important;
`;
const ClickToUpload = styled.label`
    width: 100%;
    height: 100%;
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    &:after{
        content: '${props => props.textcontent}';
        position: absolute;
        margin: auto;
        left: 50%;
        top: 45%;
        transform: translate(-50%, -50%);
        font-size: 80px;
        color: white;
    }
`;
const SwitchCustom = styled(Switch)`
    .Mui-checked{
        color: ${colors.blue.lighter.hue150};
        & + .MuiSwitch-track{
            background-color: ${colors.blue.lighter.hue300};
        }
    }
`;
const LabelFab = styled.label`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
`;
const SimpleImage = styled.img`
    max-width:80%;
    margin:0 auto;
    display : block;
    margin-bottom:50px;
`;
const SimpleVideo = styled.video`
    max-width:80%;
    margin:0 auto;
    display : block;
    margin-bottom:50px;
`;
const PaginationCustom = styled(Pagination)`
    ul{
        justify-content: center; 
        margin-top: 24px;
    }
`;

const Suggestion = styled(Typography)`
    position : absolute;
    bottom:-35px;
    left :0;
    height:auto;
    width : 100%;
    display : flex;
    align-items:center;
    font-size:16px;
    color : ${colors.grey.lighter.hue600};
    // padding-left:37.82px;
    span.tag{
        z-index:15;
        cursor:pointer;
        border : dotted 1px ${colors.grey.lighter.hue600};
        padding: 2px 5px;
        margin-left:8px;
        transition : all .1s;
        &:hover{
            color : ${colors.blue.lighter.hue300};
            border-color:${colors.blue.lighter.hue300};
        }
    }
`;
const ToolbarRightSide = styled(Grid)`
    position: absolute;
    right: 10px;
    width: auto;
    top: 5px;
    display: flex;
    flex-direction: row;
    align-items:center;
    @media screen and (max-width: 1450px){
        right: 4px;
        top: 1px;
    }
`;
const ChipCustom = styled(Chip)`
    height: 20px;
    margin-right: 4px;
    margin-bottom: 4px;
    span{
        font-size: 12px;
    }
`;
const ChipInputCustom = styled(ChipInput)`
    .MuiInputBase-input{
        min-width: auto;
    }
`;
const TableHeadCustom = styled(TableHead)`
    & th {
        color: ${colors.grey.lighter.hue600};
        /* font-style: italic; */
        padding-bottom: 6px;
    }
`;

function InputBuilder(props) {

    const [buttonGroupValue, setButtonGroupValue] = React.useState(null);
    const [switchValue, setSwitchValue] = React.useState(null);
    const [showPassword, setShowPassword] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState(null);
    const [seeError, setSeeError] = React.useState(false);
    const [openMediaPicker, setOpenMediaPicker] = React.useState(false);
    const [openProductPicker, setOpenProductPicker] = React.useState(false);
    const [quillInfos, setQuillInfos] = React.useState(null);
    const [quillIndex, setQuillIndex] = React.useState(null);
    const [rowVariantValue, setRowVariantValue] = React.useState(null);
    const [state, setState] = React.useState({});
    const goTo = route => props.history.push(route);

    const handleMenuItemClick = (event, value) => setButtonGroupValue(value);
    const handleSwitchChange = (event) => setSwitchValue(!switchValue);
    const handleClickShowPassword = () => setShowPassword(!showPassword);
    const handleMouseDownPassword = event => event.preventDefault();
    const handleOpenMediaPicker = () => setOpenMediaPicker(!openMediaPicker);
    const maxFileSize = process.env.REACT_APP_FILE_UPLOAD_MAX ? process.env.REACT_APP_FILE_UPLOAD_MAX : 30000000

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const passwordRegex = /^(?=.{8,})(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*]).*$/;

    const checkError = (value = null) => {
        let val = value === null ? props.value : value;
        let error = false;
        let message = null;
        let errorType = null
        // let row = null;
        switch (props.input.type) {
            case 'text':
                if (!val && props.input.required) {
                    // required
                    error = true;
                    message = 'Ce champs est requis';
                }

                if (val && !props.input.noSpecificValidation && props.input.email && !emailRegex.test(val)) {
                    // invalid email
                    error = true;
                    message = `Cette adresse email est invalide`;
                }

                break;
            case 'password':
                if (!val && props.input.required) {
                    // required
                    error = true;
                    message = 'Ce champs est requis';
                }

                if (val && !props.input.noSpecificValidation && !props.input.confirm && !passwordRegex.test(val)) {
                    // invalid password
                    error = true;
                    message = `Le mot de passe doit contenir au moins 8 caractères, dont un chiffre, une minuscule, une majuscule et un caractère spécial parmis : !@#$%^&*`;
                }

                break;
            case 'textarea':
            case 'textareaSimple':
                if (!val && props.input.required) {
                    // required
                    error = true;
                    message = 'Ce champs est requis';
                }

                break;
            case 'radio':
                if (!val && props.input.required) {
                    // required
                    error = true;
                    message = 'Ce champs est requis';
                }
                if (val) {
                    setSeeError(false);
                }

                break;
            case 'radioCatalog':
                if (!val && props.input.required) {
                    // required
                    error = true;
                    message = 'Ce champs est requis';
                }
                if (val) {
                    setSeeError(false);
                }

                break;
            case 'multiSelectCatalog':
                if (!val && props.input.required) {
                    // required
                    error = true;
                    message = 'Ce champs est requis';
                }
                if (val) {
                    setSeeError(false);
                }

                break;
            case 'select':
                if (!val && props.input.required) {
                    // required
                    error = true;
                    message = 'Ce champs est requis';
                }

                break;
            case 'selectTree':
                if (!val?.length && props.input.required) {
                    // required
                    error = true;
                    message = 'Ce champs est requis';
                }

                break;
            case 'rowsVariant':
                let arrayOfError = [];
                for (let value of val) {
                    if (props.input.hasPrice && value.price.value === "") {
                        // required
                        arrayOfError.push(true)
                    }
                    if (value.image.value?.filePath === null) {
                        // required
                        arrayOfError.push(true)
                    }
                    for (let attribute of value.allAttr) {
                        if (attribute.values === null || attribute.values === "") {
                            arrayOfError.push(true)
                        }
                    }
                }
                if (arrayOfError.includes(true)) {
                    error = true;
                    message = props.input.hasPrice ? "Vous devez remplir les champs prix et attributs" : "Vous devez remplir les champs attributs"
                } else {
                    setSeeError(false);
                }
                break;
            case 'productSelector':
                if (!val && props.input.required) {
                    // required
                    error = true;
                    message = 'Vous devez choisir un produit';
                }

                break;
            case 'mediaPicker':
                if (!val && props.input.required) {
                    // required
                    error = true;
                    message = 'Ce champs est requis';
                }
                if (val) {
                    setSeeError(false);
                }

                break;
            case 'file':
                if (!val && props.input.required) {
                    // required
                    error = true;
                    message = 'Ce champs est requis';
                } else if (val?.file) {
                    if (val.file.size > maxFileSize) {
                        error = true;
                        message = 'Fichier trop volumineux (30 Mo max)';
                    }
                } else {
                    setSeeError(false);
                }
            case 'oneImage':
                if (val?.file) {
                    if (val.file.size > maxFileSize) {
                        error = true;
                        message = 'Fichier trop volumineux (30 Mo max)';
                    }
                } else {
                    setSeeError(false);
                }

                break;
            case 'date':
                if (!val && props.input.required) {
                    // required
                    error = true;
                    message = 'Ce champs est requis';
                }

                if (val && !moment(val).isValid()) {
                    // invalid
                    error = true;
                    message = 'La date est invalide';
                }

                break;
            case 'rows':
                for (let value of val) {
                    if (props.input.filter) {
                        if (value.code === "" || value.code.length === 0 || value.values === "" || value.operator === "") {
                            error = true;
                            message = 'Vous devez remplir tous les champs';
                        }
                    } else {
                        console.log(value);
                        if (value.code.length === 0) {
                            error = true;
                            message = 'Vous devez remplir le champ attributs fichier';
                        }
                        if (value.values === "") {
                            error = true;
                            message = 'Vous devez remplir le champ attributs Spread';
                        }
                    }
                }

                break;
            default: return;
        }

        setErrorMessage(error ? message : null);
        if (props.errorCallback) {
            props.errorCallback(error);
        }
    };

    // ComponentDidUpdate like
    useEffect(() => {
        if (props.input.type === 'buttonGroup') {
            if (buttonGroupValue === null) {
                setButtonGroupValue(props.value);
            }

            props.handleButtonGroupChange(props.input.stateName, buttonGroupValue);
        }

        if (props.input.type === 'switch') {
            if (switchValue === null) {
                setSwitchValue(props.value);
            }

            props.handleButtonGroupChange(props.input.stateName, switchValue);
        }
    }, [buttonGroupValue, switchValue]);

    // ComponentDidMount like
    useEffect(() => {
        checkError();

        let subscription = eventService.get().subscribe((data) => {
            setSeeError(true);
            if (data && props.input.stateName === data.stateName) {
                setErrorMessage(data.errorMessage);
                setSeeError(true)
            }
        });

        return () => subscription.unsubscribe();
    }, [props.value]);

    const useStyles = makeStyles({
        select: {
            marginTop: 40,
            "& ul": {
                // backgroundColor: 'r',
                padding: 0,

            },
            "& li": {
                fontSize: 14,
                color: colors.black.regular
            },
            "& li.MuiListItem-root.Mui-selected, & li.MuiListItem-root.Mui-selected:hover": {
                backgroundColor: colors.black.regular,
                color: colors.white
            },
            "& li.MuiListItem-root:hover": {
                backgroundColor: colors.grey.lighter.hue900,
                // color: colors.white
            },
        },
        inputRoot: {
            minHeight: 51,
            paddingTop: "10px !important",
            paddingBottom: 1,
            paddingRight: 30,
            // "& .MuiChip-root": {
            //     marginBottom: "0 !important",
            // },
            "& .MuiInputBase-input": {
                minWidth: "100%",
            }
        },
    });

    const classes = useStyles();
    switch (props.input.type) {
        case 'text':
            return (
                <GridCustom item xs={props.xs ? props.xs : 12} style={{ position: "relative" }}>
                    <Box style={{ display: "flex", gap: 10, alignItems: "center" }}>
                        <TextFieldCustom
                            id={props.input.stateName}
                            variant="outlined"
                            value={props.value ?? props.input.defaultValue ?? ''}
                            helperText={(seeError || props.error) ? errorMessage : null}
                            fullWidth={props.input.rightButton ? false : true}
                            onChange={evt => {
                                setSeeError(true);
                                checkError(evt.target.value);
                                props.stateCallback(evt);
                            }}
                            name={props.input.stateName}
                            type={props.input.type}
                            placeholder={props.input.textarea ?? props.input.label}
                            disabled={props.input.disabled ? true : false}
                            error={((seeError && errorMessage) || props.error) ? true : false}
                            style={{
                                width: props.input.rightButton ? '90%' : props.input.translation && props.allState.currentLang !== "fr_FR" ? '90%' : '100%',
                                borderRadius: 0,
                                ...props.style
                            }}
                            inputProps={{
                                maxLength: 255,
                                spellCheck: true,
                                lang: 'fr'
                            }}
                            spellCheck={true}
                            lang="fr"
                        />
                        {
                            props.input.rightButton ?
                                <Tooltip
                                    title={props.value ? props.input.rightButton.textTooltip : props.input.rightButton.textTooltipDisabled}
                                >
                                    <Grid
                                        container
                                        alignItems="center"
                                        justifyContent="center"
                                        style={{ width: '10%', height: 51, minWidth: 'auto', padding: 0, position: 'absolute', top: 0, right: 0, cursor: props.value ? 'pointer' : 'inherit' }}
                                        onClick={props.value ? props.input.rightButton.callback : null}
                                    >
                                        <IcomoonReact
                                            iconSet={iconSet}
                                            color={props.value ? colors.blue.darker.hue300 : colors.grey.lighter.hue600}
                                            size={35}
                                            icon={props.input.rightButton.icon}
                                        />
                                    </Grid>
                                </Tooltip>
                                : null
                        }
                    </Box>

                    {
                        seeError && errorMessage ? null :
                            props.input.helper.link
                                ? <LinkText variant="body2" onClick={() => goTo(props.input.helper.route)}>{props.input.helper.text}</LinkText>
                                : <HelperText variant="body2">{props.input.helper.text}</HelperText>
                    }
                </ GridCustom>
            );
        case 'number':
        case 'decimal':
            return (
                <GridCustom item xs={props.xs ? props.xs : 12} style={{ position: "relative" }}>
                    <TextFieldCustom
                        id={props.input.stateName}
                        variant="outlined"
                        value={props.value ?? props.input.defaultValue ?? ''}
                        helperText={seeError ? errorMessage : null}
                        fullWidth={props.input.rightButton ? false : true}
                        onChange={evt => {
                            setSeeError(true);
                            checkError(evt.target.value);
                            props.stateCallback(evt);
                        }}
                        name={props.input.stateName}
                        type={'number'}
                        placeholder={props.input.label}
                        disabled={props.input.disabled ? true : false}
                        error={seeError && errorMessage ? true : false}
                        style={{ width: props.input.rightButton ? '90%' : '100%' }}
                    />

                    {
                        seeError && errorMessage ? null :
                            props.input.helper.link
                                ? <LinkText variant="body2" onClick={() => goTo(props.input.helper.route)}>{props.input.helper.text}</LinkText>
                                : <HelperText variant="body2">{props.input.helper.text}</HelperText>
                    }
                </ GridCustom>
            );
        case 'password':
            return (
                <GridCustom item xs={props.xs ? props.xs : 12}>
                    <FilledInputCustom
                        id={props.input.stateName}
                        variant="outlined"
                        color="secondary"
                        value={props.value}
                        fullWidth
                        onChange={evt => {
                            setSeeError(true);
                            checkError(evt.target.value);
                            props.stateCallback(evt);
                        }}
                        style={seeError && errorMessage ? { borderColor: 'red' } : null}
                        name={props.input.stateName}
                        type={showPassword ? 'text' : 'password'}
                        placeholder={props.input.label}
                        disabled={props.input.disabled ? true : false}
                        visible="false"
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                >
                                    {showPassword ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                            </InputAdornment>
                        }
                    />

                    {
                        !seeError ? null :
                            <span className="error-label">{errorMessage}</span>
                    }

                    {
                        seeError && errorMessage ? null :
                            props.input.helper.link
                                ? <LinkText variant="body2" style={{
                                    textDecoration: 'none',
                                    cursor: 'default',
                                    marginTop: 10,
                                }}>{props.input.helper.text} ? Cliquez <span
                                    style={{ fontWeight: 700, cursor: "pointer", textDecoration: 'underline' }}
                                    onClick={() => goTo(props.input.helper.route)}
                                >ici</span></LinkText>
                                : <HelperText variant="body2">{props.input.helper.text}</HelperText>
                    }
                </ GridCustom>
            );
        case 'select':
            if (props.input.stateName === 'langState' || props.input.stateName === 'currentLang' || props.input.stateName === "currentCollection") {
                return (
                    <GridCustom item xs={12} style={{ display: 'flex', justifyContent: props.input.stateName === 'langState' || props.input.stateName === "currentCollection" ? 'flex-start' : 'flex-end', alignItems: 'center', ...props.customStyle }}>
                        {props.input.stateName === 'langState' || props.input.stateName === "currentCollection" ?
                            null : <div style={{ color: colors.black.regular.regular }}>-</div>
                            // null : <div style={{ color: colors.black.regular.regular }}>{props.t("content.content.choiceLanguage")}</div>
                        }
                        <SelectLang
                            id={props.input.stateName}
                            variant="outlined"
                            color="secondary"
                            select
                            // fullWidth
                            value={props.value ? props.value : '-1'}
                            onChange={evt => {
                                setSeeError(true);
                                checkError(evt.target.value);
                                props.stateCallback(evt);
                            }}
                            helperText={props.input.helper.text}
                            disabled={props.input.disabled ? true : false}
                            SelectProps={{
                                MenuProps: {
                                    classes: {
                                        paper: classes.select
                                    },
                                    elevation: 0,
                                    PaperProps: {
                                        square: true,
                                        style: {
                                            border: `0.5px solid ${colors.grey.border}`,
                                            fontWeight: 700,
                                        }
                                    }
                                }
                            }}
                        >
                            {props?.input?.value.map(option => (
                                <MenuItem key={option?.value} value={option?.value}>
                                    {option?.label}
                                </MenuItem>
                            ))}
                        </SelectLang>
                    </GridCustom>
                );
            }
            else {
                console.log('props.value', props.value);
                return (
                    <GridCustom item xs={12}>
                        <TextFieldCustom
                            id={props.input.stateName}
                            variant="outlined"
                            color="secondary"
                            select
                            fullWidth
                            SelectProps={{
                                multiple: props.input.multiselect,
                                MenuProps: {
                                    elevation: 0,
                                    PaperProps: {
                                        square: true,
                                        style: {
                                            border: `1px solid ${colors.grey.border}`,
                                        }
                                    }
                                }
                            }}
                            placeholder={props.input.label}
                            value={props.value ? props.value : 'all'}
                            // onChange={props.stateCallback}
                            onChange={evt => {
                                setSeeError(true);
                                checkError(evt.target.value);
                                props.stateCallback(evt);
                            }}
                            helperText={seeError ? errorMessage : null}
                            disabled={props.input.disabled ? true : false}
                            error={seeError && errorMessage ? true : false}
                        >
                            {props.input.value?.map((option, index) => {
                                return (
                                    <MenuItemCustom key={`OptionSelect${index}`} value={option.value} disabled={option.disabled}>
                                        {option.label}
                                    </MenuItemCustom>
                                )
                            })}
                        </TextFieldCustom>
                        {
                            seeError && errorMessage ? null :
                                props.input.helper.link
                                    ? <LinkText variant="body2" onClick={() => goTo(props.input.helper.route)}>{props.input.helper.text}</LinkText>
                                    : <HelperText variant="body2">{props.input.helper.text}</HelperText>
                        }
                    </GridCustom>
                );
            }
        case 'dropdown':
            return (
                <GridCustom item xs={12} onClick={(e) => { e.stopPropagation() }} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', ...props.customStyle }}>
                    <SelectLang
                        id={props.input.stateName}
                        variant="outlined"
                        color="secondary"
                        select
                        // fullWidth
                        value={props.value ? props.value : '-1'}
                        onChange={evt => {
                            setSeeError(true);
                            checkError(evt.target.value);
                            props.stateCallback(evt);
                        }}
                        helperText={props.input.helper.text}
                        disabled={props.input.disabled ? true : false}
                        SelectProps={{
                            MenuProps: {
                                classes: {
                                    paper: classes.select
                                },
                                elevation: 0,
                                PaperProps: {
                                    square: true,
                                    style: {
                                        border: `0.5px solid ${colors.grey.border}`,
                                        fontWeight: 700,
                                    }
                                }
                            }
                        }}
                    >
                        {props?.input?.value.map(option => (
                            <MenuItem key={option?.value} value={option?.value}>
                                {option?.label}
                            </MenuItem>
                        ))}
                    </SelectLang>
                </GridCustom>
            );
        case 'radio':
            return (
                <GridCustom item xs={12}>
                    <RadioGroup
                        id={props.input.stateName}
                        aria-label={props.input.stateName}
                        variant="outlined"
                        color="secondary"
                        value={props.value ? props.value : ''}
                        onChange={evt => {
                            setSeeError(true);
                            checkError(evt.target.value);
                            props.stateCallback(evt);
                        }}
                    >
                        <Grid container direction="column">
                            {props.input.value.map((option, index) => {
                                let image = "";
                                const imagesFolder = require.context('../../../assets/images', true);
                                try {
                                    image = imagesFolder('./' + option.image);
                                } catch (e) {
                                    image = imgNotFound;
                                }
                                return (
                                    <GridRadio key={`Radio${option.value}${index}`} item xs={12}>
                                        <ClickableLabel htmlFor={`Radio${option.value}-${index}`} className={!seeError ? props.value === option.value ? 'active' : null : 'error'}>
                                            <CardCustom paddingtop={'18px'} paddingbottom={'18px'} contentpadding={'16px !important'}>
                                                <Grid container alignItems={'center'} justify={'space-between'}>
                                                    <Grid item>
                                                        <Grid container direction={'row'}>
                                                            <img src={typeof image === "string" ? image : image?.default} width={75} height={75} />
                                                            <Box pl={2}>
                                                                <Typography variant={'h4'}>{option.label}</Typography>
                                                                <DescriptionRadio variant={'body2'} dangerouslySetInnerHTML={{ __html: option.description }} />
                                                            </Box>
                                                        </Grid>
                                                    </Grid>
                                                    {
                                                        !props.input.disabled ?
                                                            <Box mr={2}>
                                                                <Radio color="primary" value={option.value} id={`Radio${option.value}-${index}`} disabled={props.input.disabled ? true : false} />
                                                            </Box> : ''
                                                    }
                                                </Grid>
                                            </CardCustom>
                                        </ClickableLabel>
                                    </GridRadio>
                                )
                            })}
                        </Grid>
                    </RadioGroup>
                    {
                        !seeError ? null :
                            <span className="error-label">{errorMessage}</span>
                    }
                </GridCustom>
            );
        case 'radioCatalog':
            return (
                <GridCustom item xs={12}>
                    {
                        !props.input.isEmpty ?
                            <div>
                                <RadioGroup
                                    id={props.input.stateName}
                                    aria-label={props.input.stateName}
                                    variant="outlined"
                                    color="secondary"
                                    value={props.value ? props.value : ''}
                                    onChange={evt => {
                                        setSeeError(true);
                                        checkError(evt.target.value);
                                        props.stateCallback(evt);
                                    }}
                                    disabled={props.input.disabled ? true : false}
                                >
                                    <Grid container direction="row" spacing={4} justify={'center'}>
                                        {props.input.value.map((option, index) => {
                                            return (
                                                <Grid key={`RadioCatalog${option.values.node.id}${index}`} item xs={6}>
                                                    <ClickableLabel htmlFor={`RadioCatalog${option.values.node.id}-${index}`} className={!seeError ? props.value === option.values.node.id ? 'active' : null : 'error'}>
                                                        <GridCatalog container alignItems={'center'} justify={'flex-start'} direction="column">
                                                            <Grid container direction={'row'}>
                                                                <Box width={'100%'}>
                                                                    <CardCatalog data={option.values} configCard={option.smallCard} openForm={true} inputCard={true} />
                                                                </Box>
                                                            </Grid>
                                                            {
                                                                !props.input.disabled ?
                                                                    <Box mt={2} textAlign={'center'}>
                                                                        <Radio color="primary" value={option.values.node.id} id={`RadioCatalog${option.values.node.id}-${index}`} disabled={props.input.disabled ? true : false} />
                                                                    </Box> : ''
                                                            }
                                                        </GridCatalog>
                                                    </ClickableLabel>
                                                </Grid>
                                            )
                                        })}
                                    </Grid>
                                </RadioGroup>
                                {
                                    !seeError ? null :
                                        <span className="error-label">{errorMessage}</span>
                                }
                                {
                                    props.input.paginationInfo?.countPageCatalogs > 1 ?
                                        (<PaginationCustom onChange={(event, page) => { props.input.paginationInfo.onChange(event, page, 'catalog') }} page={props.input.paginationInfo.page} count={props.input.paginationInfo.countPageCatalogs} color="primary" />)
                                        :
                                        null
                                }
                            </div>
                            :
                            <Grid container direction="column" spacing={4} justify={'center'} alignItems={'center'}>
                                <TypographyCustom variant={'h4'} colortext={colors.red.regular} style={{ width: '60%', textAlign: 'center', marginBottom: '8px' }}>Pas de catalogue disponible, cliquez sur ce bouton pour être redirigé vers la création de catalogue.</TypographyCustom>
                                <ButtonCustom bgcolor={colors.blue.lighter.hue300} shadowcolor={colors.blue.darker.hue300} text={'Créer un catalogue'} onClick={() => goTo(ROUTE_CATALOGS_LIST)} className="button" />
                            </Grid>
                    }

                </GridCustom>
            );
        case 'multiSelectCatalog':
            return (
                <GridCustom item xs={12}>
                    {
                        !props.input.isEmpty ?
                            <div>
                                <ToggleButtonGroup
                                    id={props.input.stateName}
                                    aria-label={props.input.stateName}
                                    variant="outlined"
                                    color="secondary"
                                    value={props.value ? props.value : ''}
                                    onChange={
                                        (evt, newSelection) => {
                                            setSeeError(true);
                                            checkError(newSelection);
                                            props.stateCallback(newSelection);
                                        }
                                    }
                                    disabled={props.input.disabled ? true : false}
                                    style={{ width: '100%' }}
                                >
                                    {/* <Grid container direction="row" spacing={4} justify={'center'}> */}
                                    {props.input.value.map((option, index) => {
                                        return (
                                            <ToggleButtonCustom color="primary" value={option.values} aria-label={`MultiSelectCatalog${option.values.node.id}-${index}`} id={`MultiSelectCatalog${option.values.node.id}-${index}`} disabled={props.input.disabled ? true : false} style={{ width: "50%" }}>
                                                <Grid key={`MultiSelectCatalog${option.values.node.id}${index}`} item xs={12}>
                                                    {/* <ClickableLabel htmlFor={`MultiSelectCatalog${option.values.node.id}-${index}`} className={!seeError ? props.value === option.values.node.id ? 'active' : null :'error'}> */}
                                                    <GridCatalog container alignItems={'center'} justify={'flex-start'} direction="column">
                                                        <Grid container direction={'row'}>
                                                            <Box width={'100%'}>
                                                                <CardCatalog data={option.values} configCard={option.smallCard} openForm={true} inputCard={true} />
                                                            </Box>
                                                            <Box pt={2} pb={2} width={'100%'} style={{ textAlign: 'center' }}>
                                                                <CheckBoxCustom className={props.value.find(e => e.node.id === option.values.node.id) ? 'active' : null} />
                                                            </Box>
                                                        </Grid>
                                                    </GridCatalog>
                                                    {/* </ClickableLabel> */}
                                                </Grid>
                                            </ToggleButtonCustom>
                                            // <Grid key={`MultiSelectCatalog${option.values.node.id}${index}`} item xs={6}>
                                            //     <ClickableLabel htmlFor={`MultiSelectCatalog${option.values.node.id}-${index}`} className={!seeError ? props.value === option.values.node.id ? 'active' : null :'error'}>
                                            //         <GridCatalog container alignItems={'center'} justify={'flex-start'} direction="column">
                                            //             <Grid container direction={'row'}>
                                            //                 <Box width={'100%'}>
                                            //                     <CardCatalog data={option.values} configCard={option.smallCard} openForm={true} inputCard={true}/>
                                            //                 </Box>
                                            //             </Grid>
                                            //             {
                                            //                 !props.input.disabled ?
                                            //                 <Box mt={2} textAlign={'center'}>
                                            //                     <ToggleButton color="primary" value={option.values.node.id} aria-label={`MultiSelectCatalog${option.values.node.id}-${index}`} id={`MultiSelectCatalog${option.values.node.id}-${index}`} disabled={props.input.disabled ? true : false}>
                                            //                         <div style={{height: 20, width: 20, border: "1px solid black"}}></div>
                                            //                     </ToggleButton>
                                            //                 </Box> : ''
                                            //             }
                                            //         </GridCatalog>
                                            //     </ClickableLabel>
                                            // </Grid>
                                        )
                                    })}
                                    {/* </Grid> */}
                                </ToggleButtonGroup>
                                {
                                    !seeError ? null :
                                        <span className="error-label">{errorMessage}</span>
                                }
                                {
                                    props.input.paginationInfo?.countPageCatalogs > 1 ?
                                        (<PaginationCustom onChange={(event, page) => { props.input.paginationInfo.onChange(event, page, 'catalog') }} page={props.input.paginationInfo.page} count={props.input.paginationInfo.countPageCatalogs} color="primary" />)
                                        :
                                        null
                                }
                            </div>
                            :
                            <Grid container direction="column" spacing={4} justify={'center'} alignItems={'center'}>
                                <TypographyCustom variant={'h4'} colortext={colors.red.regular} style={{ width: '60%', textAlign: 'center', marginBottom: '8px' }}>Pas de catalogue disponible, cliquez sur ce bouton pour être redirigé vers la création de catalogue.</TypographyCustom>
                                <ButtonCustom bgcolor={colors.blue.lighter.hue300} shadowcolor={colors.blue.darker.hue300} text={'Créer un catalogue'} onClick={() => goTo(ROUTE_CATALOGS_LIST)} className="button" />
                            </Grid>
                    }

                </GridCustom>
            );
        case 'treeView':
            return (
                <>
                    {props.input.name ? <h3>{props.input.name}</h3> : null}
                    {
                        props.input.stateNameQuery ?
                            <SearchBar style={{ marginBottom: 8 }} value={state[props.input.stateNameQuery] || ''} placeholder={props.input.placeholderSearch} onChange={(evt) => setState({
                                ...state,
                                [props.input.stateNameQuery]: evt.target?.value
                            })} />
                            : null
                    }
                    <GridCustom item xs={12}>
                        {props.input.treeData
                            ? <TreeView
                                reducedTree={true}
                                typeOfTree={props.input.typeOfTree}
                                canDrop={props.input.canDrop}
                                canDrag={props.input.canDrag}
                                dataTree={props.input.treeData}
                                expand={props.expandNodes}
                                editCat={props.treeEditCat}
                                copyCategories={props.copyCategories}
                                onChange={treeData => props.handleTreeChange(props.input.stateName, treeData)}
                                shouldCopy={props.input.shouldCopy}
                                addSubcategory={props.treeAddCat}
                                handleChangeCategorie={props.handleChangeCategorie}
                                selectedCategorie={props.selectedCategorie}
                                stateName={props.input.stateName}
                                canModify={true}
                                canAdd={true}
                                searchQuery={state[props.input.stateNameQuery]}
                            />
                            : <p>Chargement...</p>
                        }
                    </GridCustom>
                </>
            );
        case 'checkboxItem':
            return (
                <GridCustomCheckbox item xs={12}>
                    <CheckboxItems
                        currentLang={props.allState.currentLang}
                        selectedCategorie={props.selectedCategorie}
                        productByCategorie={props.productByCategorie}
                        selectedProductByCategorie={props.selectedProductByCategorie}
                        setSelectedProducts={props.setSelectedProducts}
                        resetReloadListing={props.resetReloadListing}
                        gridItems={props.gridItems}
                        loadGrid={props.loadGrid}
                        addProducts={props.addProducts}
                        pagination={props.allState.paginationCheckboxItem}
                        allState={props.allState}
                        stateCallback={props.stateCallback}
                    />
                </GridCustomCheckbox>
            );
        case 'rows':
            let hasNew = props.allState.values?.find(e => e.new === true)
            return (
                <Grid container direction="row" spacing={0}>
                    {
                        hasNew && props.input.dictionary ?
                            <Grid container alignItems="center">
                                <ReportProblemOutlinedIcon style={{ fill: colors.red.regular, fontSize: '1.5rem' }} /><Typography style={{ color: colors.red.regular, paddingLeft: 8 }}>Mettre un texte d'erreur lors de l'ajout d'une valeur</Typography>
                            </Grid>
                            : null
                    }
                    {
                        props.value?.length > 0 ?
                            <>
                                <Grid item xs={5} style={{ paddingBottom: 16, paddingRight: 20, textAlign: "center" }}>
                                    {
                                        props.input.labelsNames ?
                                            <label><b>{props.input.labelsNames.first}</b></label>
                                            : null
                                    }
                                </Grid>
                                <Grid item xs={props.input.filter ? 2 : 5} style={{ paddingBottom: 16, paddingRight: props.input.filter ? 20 : 0, textAlign: "center" }}>
                                    {
                                        props.input.labelsNames ?
                                            <label><b>{props.input.labelsNames.second}</b></label>
                                            : null
                                    }
                                </Grid>
                                {
                                    props.input.filter ?
                                        <Grid item xs={4} style={{ paddingBottom: 16, textAlign: "center", paddingRight: 20 }}>
                                            <label><b>{props.input.labelsNames.third}</b></label>
                                        </Grid>
                                        : null
                                }
                            </>
                            : <Grid item xs={12} style={{ marginBottom: 16 }}>Aucune valeur</Grid>
                    }
                    {
                        props.value.map((row, i) => {
                            if (row.deleted)
                                return null;
                            return (
                                <Grid key={i} item xs={12} style={{ marginBottom: 15 }}>
                                    <CardCustom paddingbottom={row.typingChips && !props.input.filter && !row.typingChips.includes('|%|') ? "50px" : "16px"} paddingtop="16px">
                                        {/* {
                                            props.input.dictionary ?
                                            <Grid container direction="row" spacing={0} alignItems={"center"}>
                                                
                                                <label style={{cursor:"pointer"}}>
                                                    <Checkbox
                                                        checked={row.checkbox}
                                                        onChange={e => {
                                                            row.checkbox = e.target.checked;
                                                            row.changed = true;
                                                            props.stateCallback(props.value);
                                                        }}
                                                        inputProps={{ 'aria-label': 'primary checkbox' }}
                                                    />
                                                    Définir comme mot-clé <sup>(1)</sup>
                                                </label>
                                            </Grid>
                                            : null
                                        } */}
                                        <Grid container direction="row" spacing={0}>
                                            <Grid item xs={5} style={{ paddingRight: 20 }}>
                                                {
                                                    props.input.dictionary ?
                                                        <div style={{ position: 'relative' }}>
                                                            <ChipInput
                                                                color="secondary"
                                                                variant="outlined"
                                                                value={row.code}
                                                                fullWidth
                                                                fullWidthInput
                                                                disabled={props.input.disabled}
                                                                // InputProps={{
                                                                //     onBlur: () => {
                                                                //         setTimeout(function(){
                                                                //             setTypingChips("");
                                                                //             setShowButton(false);
                                                                //         }, 100)
                                                                //     },
                                                                // }}
                                                                inputValue={row.typingChips}
                                                                onDelete={(chip, index) => {
                                                                    row.changed = true;
                                                                    row.code.splice(index, 1)
                                                                    props.stateCallback(props.value)
                                                                    setSeeError(true);
                                                                    checkError(props.value);
                                                                }}
                                                                onAdd={(chip) => {
                                                                    row.changed = true;
                                                                    row.code.push(chip);
                                                                    row.typingChips = "";
                                                                    row.showButton = false;
                                                                    props.stateCallback(props.value)
                                                                    setSeeError(true);
                                                                    checkError(props.value);
                                                                }}
                                                                onUpdateInput={(e) => {
                                                                    row.typingChips = e.target.value;
                                                                    if (e.target.value) {
                                                                        row.showButton = true;
                                                                    } else {
                                                                        row.showButton = false;
                                                                    }
                                                                    props.stateCallback(props.value)
                                                                }}
                                                                classes={{ inputRoot: classes.inputRoot }}
                                                            />
                                                            <Suggestion>
                                                                {row.typingChips && !row.typingChips.includes('|%|') ?
                                                                    <>
                                                                        <span>Suggestion valeur joker : </span>
                                                                        <span
                                                                            className="tag"
                                                                            onClick={() => {
                                                                                if (!row.code.includes(`|%|${row.typingChips}|%|`)) {
                                                                                    // simulateKeyPress()
                                                                                    row.code.push(`|%|${row.typingChips}|%|`)
                                                                                }
                                                                                row.showButton = false;
                                                                                row.typingChips = "";
                                                                                props.stateCallback(props.value);
                                                                            }
                                                                            }
                                                                        >
                                                                            |%|{row.typingChips}|%|
                                                                        </span>
                                                                    </>
                                                                    : null}
                                                            </Suggestion>
                                                            {
                                                                row.showButton && row.typingChips ?
                                                                    <div onClick={() => {
                                                                        if (!row.code.includes(row.typingChips)) {
                                                                            // simulateKeyPress()
                                                                            row.code.push(row.typingChips)
                                                                            row.showButton = false;
                                                                            row.typingChips = ""
                                                                            props.stateCallback(props.value);
                                                                        }
                                                                    }}>
                                                                        <AddBoxIcon
                                                                            style={{ color: colors.blue.lighter.hue300, fontSize: 35, cursor: "pointer", position: "absolute", top: "50%", right: 8, transform: 'translateY(-50%)' }}
                                                                        />
                                                                    </div>
                                                                    : null
                                                            }
                                                            {
                                                                row.code?.length > 0 && !row.showButton && !props.input.disabled ?
                                                                    <div onClick={() => {
                                                                        row.changed = true;
                                                                        row.code = [];
                                                                        props.stateCallback(props.value);
                                                                        setSeeError(true);
                                                                        checkError(props.value);
                                                                    }}>
                                                                        <HighlightOff
                                                                            style={{ width: 25, cursor: "pointer", position: "absolute", top: "50%", right: 8, transform: 'translateY(-50%)' }}
                                                                        />
                                                                    </div>
                                                                    : null
                                                            }

                                                        </div>
                                                        :
                                                        props.input.filter ?
                                                            <TextFieldCustomSmall
                                                                variant="outlined"
                                                                color="secondary"
                                                                value={row.code}
                                                                fullWidth
                                                                disabled={props.input.disabled}
                                                                onChange={e => {
                                                                    row.changed = true;
                                                                    let getAttribute = props.input.attributes.edges.find(attr => attr.node.id === e.target.value)
                                                                    row.code = e.target.value;
                                                                    if (e.target.value === 'IN' || e.target.value === 'NOT IN') {
                                                                        row.values = []
                                                                    } else {
                                                                        row.values = ''
                                                                    }
                                                                    row.attributeType = getAttribute;
                                                                    props.stateCallback(props.value);
                                                                    setSeeError(true);
                                                                    checkError(props.value);
                                                                }}
                                                                select
                                                                placeholder={props.input.labelsNames ? null : 'Code'}
                                                            >
                                                                {props.input.attributes.edges.filter(e => e.node.attributeType.input !== 'image' && e.node.attributeType.input !== 'file').map((option, index) => {
                                                                    let getTranslation = option.node.translation.translationDatas.edges.find(e => e.node.locale.code === props.allState.currentLang)
                                                                    return (
                                                                        <MenuItem key={`OptionSelect${index}`} value={option.node.id}>
                                                                            {getTranslation.node.value || option.node.translation.translationDatas.edges[0].node.value}
                                                                        </MenuItem>
                                                                    )
                                                                })}
                                                            </TextFieldCustomSmall>
                                                            :
                                                            <TextFieldCustom
                                                                variant="outlined"
                                                                color="secondary"
                                                                value={row.code}
                                                                fullWidth
                                                                disabled={props.input.disabled ? props.input.disabled : !props.input.dictionary ? !row.new : null}
                                                                onChange={e => {
                                                                    if (props.input.dictionary) {
                                                                        row.changed = true;
                                                                    }
                                                                    row.code = e.target.value;
                                                                    props.stateCallback(props.value);
                                                                    setSeeError(true);
                                                                    checkError(props.value);
                                                                }}
                                                                type='text'
                                                                placeholder={props.input.labelsNames ? null : 'Code'}
                                                            />
                                                }
                                            </Grid>
                                            <Grid item xs={props.input.filter ? 2 : 5} style={{ paddingRight: props.input.filter ? 20 : 0 }}>
                                                {
                                                    props.input.dictionarySelect ?
                                                        <TextFieldCustom
                                                            id={props.input.stateName}
                                                            variant="outlined"
                                                            color="secondary"
                                                            select
                                                            fullWidth
                                                            value={props.input.currentLang ? row.values[props.input.currentLang] || '' : row.values || ''}
                                                            onChange={e => {
                                                                row.changed = true;
                                                                if (props.input.currentLang) {
                                                                    row.values[props.input.currentLang] = e.target.value;
                                                                } else {
                                                                    row.values = e.target.value;
                                                                }
                                                                props.stateCallback(props.value);
                                                                setSeeError(true);
                                                                checkError(props.value);
                                                            }}
                                                            disabled={props.input.disabled ? true : false}
                                                            error={seeError && errorMessage ? true : false}
                                                        >
                                                            {props.allState.currentAttribute.options.map((option, index) => {
                                                                let getTranslation = option.locales.find(e => e.code === props.allState.currentLang)
                                                                return (
                                                                    <MenuItem key={`OptionSelect${index}`} value={option.id}>
                                                                        {getTranslation.value || option.locales[0].value}
                                                                    </MenuItem>
                                                                )
                                                            })}
                                                        </TextFieldCustom>
                                                        :
                                                        props.input.filter ?
                                                            <TextFieldCustomSmall
                                                                variant="outlined"
                                                                color="secondary"
                                                                value={row.operator}
                                                                fullWidth
                                                                disabled={props.input.disabled}
                                                                onChange={e => {
                                                                    row.changed = true;
                                                                    row.operator = e.target.value;
                                                                    if (e.target.value === 'IN' || e.target.value === 'NOT IN') {
                                                                        row.values = []
                                                                    } else {
                                                                        row.values = ''
                                                                    }
                                                                    props.stateCallback(props.value);
                                                                    setSeeError(true);
                                                                    checkError(props.value);
                                                                }}
                                                                select
                                                                placeholder={props.input.labelsNames ? null : 'Code'}
                                                            >
                                                                {props.input.operators.map((option, index) => {
                                                                    return (
                                                                        <MenuItem key={`OptionSelect${index}`} value={option.id}>
                                                                            {option.label}
                                                                        </MenuItem>
                                                                    )
                                                                })}
                                                            </TextFieldCustomSmall>
                                                            :
                                                            <TextFieldCustom
                                                                variant="outlined"
                                                                color="secondary"
                                                                value={props.input.currentLang ? row.values[props.input.currentLang] || '' : row.values || ''}
                                                                fullWidth
                                                                disabled={props.input.disabled}
                                                                onChange={e => {
                                                                    row.changed = true;
                                                                    if (props.input.currentLang) {
                                                                        row.values[props.input.currentLang] = e.target.value;
                                                                    } else {
                                                                        row.values = e.target.value;
                                                                    }
                                                                    props.stateCallback(props.value);
                                                                    setSeeError(true);
                                                                    checkError(props.value);
                                                                }}
                                                                type='text'
                                                                placeholder={props.input.labelsNames ? null : 'Valeur'}
                                                            />
                                                }
                                            </Grid>
                                            {
                                                props.input.filter ?
                                                    <Grid item xs={props.input.filter ? 4 : 5}>
                                                        {
                                                            (row.operator === "IN" || row.operator === "NOT IN") && row.attributeType?.node.attributeType.input !== "select" ?
                                                                <ChipInputCustom
                                                                    color="secondary"
                                                                    variant="outlined"
                                                                    value={row.values || []}
                                                                    fullWidth
                                                                    fullWidthInput
                                                                    disabled={props.input.disabled}
                                                                    inputValue={row.typingChips}
                                                                    onDelete={(chip, index) => {
                                                                        row.changed = true;
                                                                        row.values.splice(index, 1)
                                                                        props.stateCallback(props.value)
                                                                        setSeeError(true);
                                                                        checkError(props.value);
                                                                    }}
                                                                    onAdd={(chip) => {
                                                                        row.changed = true;
                                                                        row.values.push(chip);
                                                                        row.typingChips = "";
                                                                        row.showButton = false;
                                                                        props.stateCallback(props.value)
                                                                        setSeeError(true);
                                                                        checkError(props.value);
                                                                    }}
                                                                    onUpdateInput={(e) => {
                                                                        row.typingChips = e.target.value;
                                                                        if (e.target.value) {
                                                                            row.showButton = true;
                                                                        } else {
                                                                            row.showButton = false;
                                                                        }
                                                                        props.stateCallback(props.value)
                                                                    }}
                                                                    classes={{ inputRoot: classes.inputRoot }}
                                                                />
                                                                :
                                                                <TextFieldCustomSmall
                                                                    variant="outlined"
                                                                    color="secondary"
                                                                    value={
                                                                        props.input.currentLang
                                                                            ? row.values[props.input.currentLang]
                                                                                ? row.values[props.input.currentLang]
                                                                                : (row.operator === "IN" || row.operator === 'NOT IN') ? [] : ''
                                                                            : row.values
                                                                                ? row.values
                                                                                : (row.operator === "IN" || row.operator === 'NOT IN') ? [] : ''
                                                                    }
                                                                    fullWidth
                                                                    disabled={props.input.disabled}
                                                                    onChange={e => {
                                                                        row.changed = true;
                                                                        let data = e.target.value;
                                                                        if (props.input.currentLang) {
                                                                            row.values[props.input.currentLang] = data;
                                                                        } else {
                                                                            row.values = data;
                                                                        }
                                                                        props.stateCallback(props.value);
                                                                        setSeeError(true);
                                                                        checkError(props.value);
                                                                    }}
                                                                    SelectProps={{
                                                                        multiple: row.attributeType?.node.attributeType.input === "select" ? row.operator === "IN" || row.operator === 'NOT IN' : false,
                                                                        renderValue: (selected) => {
                                                                            if (row.operator === "IN" || row.operator === 'NOT IN') {
                                                                                return (
                                                                                    <div className={classes.chips}>
                                                                                        {selected.map((value) => {
                                                                                            let getName = row.attributeType?.node.attributeOptions.edges.find(e => e.node.id === value)
                                                                                            let getTranslation = getName.node.translation.translationDatas.edges.find(e => e.node.locale.code === props.allState.currentLang)
                                                                                            return (
                                                                                                <ChipCustom key={value} label={getTranslation.node.value || getName.node.translation.translationDatas.edges[0].node.value} className={classes.chip} />
                                                                                            )
                                                                                        }
                                                                                        )}
                                                                                    </div>
                                                                                )
                                                                            } else {
                                                                                let getName = row.attributeType?.node.attributeOptions.edges.find(e => e.node.id === selected)
                                                                                let getTranslation = getName.node.translation.translationDatas.edges.find(e => e.node.locale.code === props.allState.currentLang)
                                                                                return getTranslation.node.value || getName.node.translation.translationDatas.edges[0].node.value
                                                                            }
                                                                        }
                                                                    }}
                                                                    select={row.attributeType?.node.attributeType.input === "select" ? true : false}
                                                                    type={row.attributeType?.node.attributeType.input || 'text'}
                                                                    usechip={row.operator === "IN" || row.operator === 'NOT IN'}
                                                                >
                                                                    {
                                                                        row.attributeType?.node.attributeType.input === "select" && row.attributeType?.node.attributeOptions.edges.length > 0 ?
                                                                            row.attributeType?.node.attributeOptions.edges.map((attribute, index) => {
                                                                                let getTranslation = attribute.node.translation.translationDatas.edges.find(e => e.node.locale.code === props.allState.currentLang)
                                                                                return (
                                                                                    <MenuItem key={`option-select-attribute${index}`} value={attribute.node.id}>
                                                                                        {getTranslation.node.value || attribute.node.translation.translationDatas.edges[0].node.value}
                                                                                    </MenuItem>
                                                                                )
                                                                            })
                                                                            : null
                                                                    }
                                                                </TextFieldCustomSmall>
                                                        }
                                                    </Grid>
                                                    : null
                                            }

                                            <Grid item xs={props.input.filter ? 1 : 2} style={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center'
                                            }}>
                                                {
                                                    !props.input.disabled ? (
                                                        <Tooltip title="Supprimer">
                                                            <DeleteIcon
                                                                style={{ color: '#FF6565', cursor: 'pointer' }}
                                                                onClick={() => {
                                                                    if (!row.new) {
                                                                        row.deleted = true;
                                                                        props.stateCallback(props.value);
                                                                    } else {
                                                                        props.stateCallback(props.value.filter(e => e.id !== row.id));
                                                                    }
                                                                }}
                                                            />
                                                        </Tooltip>
                                                    ) : null
                                                }
                                            </Grid>
                                        </Grid>
                                        {
                                            (row.operator === "IN" || row.operator === "NOT IN") && row.attributeType?.node.attributeType.input !== "select" ?
                                                <Grid container style={{ marginTop: 8 }}>
                                                    <Typography>Utilisez la touche Entrée pour valider les valeurs</Typography>
                                                </Grid>
                                                : null
                                        }
                                    </CardCustom>
                                </Grid>
                            );
                        })
                    }

                    {
                        seeError && errorMessage ?
                            <TypographyCustom variant="body2" colortext={colors.red.regular} style={{ marginBottom: "18px", width: "100%" }}>{errorMessage}</TypographyCustom>
                            : null
                    }
                    {!props.input.disabled ? (
                        <div
                            style={{
                                height: 21,
                                color: '#59C870',
                                textDecoration: 'underline',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                cursor: 'pointer'
                            }}
                            onClick={() => {
                                props.value.push({
                                    new: true,
                                    code: props.input.dictionary ? [] : '',
                                    values: props.input.currentLang ? {} : props.input.filter ? [] : null,
                                    operator: props.input.filter ? '' : null,
                                    attributeType: null,
                                    id: uuidv4(),
                                    checkbox: false,
                                    typingChips: null,
                                    showButton: false,
                                });
                                // setSeeError(true);
                                // checkError(props.value);
                                props.stateCallback(props.value);
                            }}
                        >
                            <AddCircleIcon fontSize="small" style={{ marginRight: 10 }} />
                            <span>Ajouter une ligne</span>
                        </div>
                    ) : null
                    }
                    {
                        props.value?.length > 0 && props.input.dictionary ?
                            <>
                                <Grid container style={{ marginTop: 16 }}>Afin de définir une valeur clé pour rechercher tous les enregistrements utilisant cette valeur, entouré la de |%|.</Grid>
                                <Grid><strong>Exemple : </strong> |%|Valeur|%|</Grid>
                            </>
                            : null
                    }
                </Grid>
            );
        case 'rowsVariant':
            return (
                <Grid container direction="row" spacing={0}>
                    {
                        props.value?.map((row, i) => {
                            if (row.deleted)
                                return null;

                            return (
                                <Grid key={i} item xs={12} style={seeError ? { border: 'solid 1px red', marginBottom: 15 } : { border: 'none', marginBottom: 15 }}>
                                    <CardCustom paddingbottom="16px" paddingtop="16px">
                                        <Grid container>
                                            <TypographyCustom variant="body2" colortext="#333333" style={{ marginBottom: "18px" }}>Variant {row.deleted ? i - 1 : i + 1}</TypographyCustom>
                                        </Grid>
                                        <Grid container direction="row" spacing={0} style={{ position: 'relative' }}>
                                            {
                                                row.allAttr.map((variant, index) => {
                                                    return (
                                                        <Grid item xs={4} style={{ paddingRight: 20, marginTop: 8 }} key={`VariantProduct${index}`}>
                                                            <span>{variant.label}</span>
                                                            <TextFieldCustom
                                                                id={props.input.stateName}
                                                                variant="outlined"
                                                                color="secondary"
                                                                select={variant.type === "select" ? true : false}
                                                                fullWidth
                                                                type={variant.type}
                                                                value={variant.values ? variant.values : variant.valuesForSelect.length > 0 ? variant.valuesForSelect[0].node.id : ''}
                                                                onChange={e => {
                                                                    variant.values = e.target.value;
                                                                    if (!row.new) {
                                                                        row.updated = true;
                                                                    }
                                                                    props.stateCallback(props.value);
                                                                    setSeeError(true);
                                                                    checkError(props.value, row);
                                                                }}
                                                                // helperText={seeError ? errorMessage : null}
                                                                disabled={props.input.disabled ? true : false}
                                                                // error={seeError && errorMessage ? true : false}
                                                                style={{ marginTop: "8px" }}
                                                            >
                                                                {
                                                                    variant.type === 'select' ?
                                                                        variant.valuesForSelect.map((option, index) => {
                                                                            return (
                                                                                <MenuItem key={`OptionSelect${index}`} value={option.node.id} disabled={option.disabled}>
                                                                                    {option.node.translation.translationDatas.edges[0].node.value}
                                                                                </MenuItem>
                                                                            )
                                                                        })
                                                                        : null
                                                                }
                                                            </TextFieldCustom>
                                                        </Grid>
                                                    )
                                                })
                                            }
                                            {
                                                props.input.hasPrice ?
                                                    <Grid item xs={4} style={{ paddingRight: 20, marginTop: 8 }}>
                                                        <span>Prix</span>
                                                        <TextFieldCustom
                                                            variant="outlined"
                                                            color="secondary"
                                                            value={row.price.value || ''}
                                                            fullWidth
                                                            onChange={e => {
                                                                // row.price.changed = true;
                                                                row.price.value = e.target.value;
                                                                if (!row.new) {
                                                                    row.updated = true;
                                                                }
                                                                props.stateCallback(props.value);
                                                                setSeeError(true);
                                                                checkError(props.value, row);
                                                            }}
                                                            type='text'
                                                            placeholder='Prix'
                                                            style={{ marginTop: "8px" }}
                                                        />
                                                    </Grid>
                                                    : null
                                            }
                                            <Grid item xs={4} style={{ paddingRight: 20, marginTop: 8 }}>
                                                <span>Image</span>
                                                <GridCustom item xs={props.xs ? props.xs : 12}>
                                                    <HiddenUploadFile
                                                        id={props.input.stateName + i}
                                                        type="file"
                                                        variant="outlined"
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}

                                                        helperText={props.input.helper?.link ? <Box><LinkText variant="h5">{props.input.helper?.text}</LinkText></Box> : props.input.helper?.text}
                                                        hidden={true}
                                                    />
                                                    <ContainerFileUpload style={{
                                                        background: `url(${row.image.value?.data ?
                                                            ['video/mpeg', 'video/webm', 'video/mp4'].includes(row.image.value.types) ?
                                                                videoLogo
                                                                : row.image.value.filePath ?
                                                                    process.env.REACT_APP_API_ROOT + '/medias/' + row.image.value.filePath
                                                                    : row.image.value.data

                                                            : row.image.value?.filePath
                                                                ? process.env.REACT_APP_API_ROOT + '/medias/' + row.image.value.filePath : `${colors.grey.lighter.hue900}`
                                                            }) no-repeat center`,
                                                    }} fullwidth={props.input.fullwidth}>
                                                        <ClickToUpload>
                                                            {
                                                                row.image.value && row.image.value.filePath
                                                                    ? <>
                                                                        <Fab color="primary" size="small" disabled={props.input.disabled ? true : false} onClick={() => { handleOpenMediaPicker(); setRowVariantValue(row) }}>
                                                                            <LabelFab >
                                                                                <EditIcon />
                                                                            </LabelFab>
                                                                        </Fab>
                                                                        <Fab color="primary" size="small" style={{ marginLeft: 5 }} onClick={() => {
                                                                            row.image.value.id = props.input.defaultImage?.id || null
                                                                            row.image.value.data = props.input.defaultImage || null
                                                                            row.image.value.filePath = props.input.defaultImage?.filePath || null
                                                                            if (!row.new) {
                                                                                row.updated = true;
                                                                            }
                                                                            props.stateCallback(props.value)
                                                                            setSeeError(true);
                                                                            checkError(props.value, row);
                                                                        }}>
                                                                            <DeleteIcon style={{ color: 'red' }} />
                                                                        </Fab>
                                                                    </>
                                                                    : <StyledFab color="primary" size="small" disabled={props.input.disabled ? true : false}>
                                                                        <LabelFab onClick={() => {
                                                                            handleOpenMediaPicker()
                                                                        }
                                                                        }>
                                                                            <AddIcon />
                                                                        </LabelFab>
                                                                    </StyledFab>
                                                            }
                                                        </ClickToUpload>
                                                    </ContainerFileUpload>

                                                    <LayoutBuilder
                                                        opened={openMediaPicker}
                                                        dataLayout={mediaPickerConfig(
                                                            (value) => {
                                                                if (!row?.new) {
                                                                    let getRow = props.value.find(
                                                                        e => e.idVariant === row.idVariant
                                                                    )
                                                                    getRow.image.value = value;
                                                                } else {
                                                                    let getRow = props.value.find(
                                                                        e => e.id === row.id
                                                                    )
                                                                    getRow.image.value = value;
                                                                }
                                                                row.updated = true;
                                                                props.stateCallback(props.value);
                                                                setSeeError(true);
                                                                checkError(props.value, row);
                                                            },
                                                            props.input.stateName,
                                                            props.input.cardSize ? props.input.cardSize : 3,
                                                            handleOpenMediaPicker,
                                                        )}
                                                        forClose={() => {
                                                            handleOpenMediaPicker()
                                                        }}

                                                    />

                                                </GridCustom>
                                            </Grid>
                                            <Grid item xs={2} style={{
                                                position: 'absolute',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                right: '-20px',
                                                top: '50%',
                                                transform: 'translate(-50%)'
                                            }}>
                                                <Tooltip
                                                    title="Supprimer"
                                                    style={{ marginTop: "8px" }}
                                                >
                                                    <DeleteIcon
                                                        style={{ color: '#FF6565', cursor: 'pointer' }}
                                                        onClick={() => {
                                                            if (!row.new) {
                                                                row.deleted = true;
                                                                row.updated = true;
                                                                props.stateCallback(props.value.filter(
                                                                    e => e.idVariant !== row.idVariant
                                                                ));
                                                            } else {
                                                                props.stateCallback(props.value.filter(e => e.id !== row.id));
                                                            }
                                                        }}
                                                    />
                                                </Tooltip>
                                            </Grid>
                                        </Grid>
                                        {
                                            !seeError ? null :
                                                <span className="error-label">{errorMessage}</span>
                                        }
                                    </CardCustom>
                                </Grid>
                            );
                        })
                    }

                    <div
                        style={{
                            height: 21,
                            color: '#59C870',
                            textDecoration: 'underline',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            cursor: 'pointer'
                        }}
                        onClick={() => {
                            // props.input.variantsValidated ? props.value.concat(props.input.variantsValidated.values) : props.value
                            let allAttr =
                                props.input.attributes.map((selectedAttr) => {
                                    return ({
                                        label: selectedAttr.label,
                                        type: selectedAttr.node.attributeType.input,
                                        identifier: selectedAttr.node.identifier,
                                        id: selectedAttr.node.id,
                                        valuesForSelect: selectedAttr.node.attributeOptions.edges,
                                        // code: '',
                                        values: selectedAttr.node.attributeOptions.edges.length > 0 ? selectedAttr.node.attributeOptions.edges[0].node.id : '',
                                    })
                                })
                            // let addVariantRow = props.input.variantsValidated ? props.value.concat(props.input.variantsValidated.values) : props.value;
                            props.value.push({
                                id: uuidv4(),
                                new: true,
                                updated: false,
                                price: {
                                    value: props.input.defaultPrice.value || null
                                },
                                image: {
                                    id: props.input.defaultImage?.id || null,
                                    value: {
                                        data: props.input.defaultImage,
                                        filePath: props.input.defaultImage?.filePath,
                                    }
                                    // id: props.input.defaultImage.id,
                                },
                                allAttr
                            },
                            )
                            setSeeError(true);
                            checkError(props.value);
                            props.stateCallback(props.value);
                        }}
                    >
                        <AddCircleIcon fontSize="small" style={{ marginRight: 10 }} />
                        <span>Ajouter une variante</span>
                    </div>
                </Grid>
            );
        case 'selectTree':
            if (!props.input.multiselect) {
                return (
                    <GridCustom item xs={12}>
                        <DropdownTreeSelect
                            id={props.input.stateName}
                            data={props.input.data}
                            mode="radioSelect"
                            inlineSearchInput={true}
                            className={seeError && errorMessage ? 'error singleSelectTree' : 'singleSelectTree'}
                            disabled={props.input.disabled ? true : false}
                            texts={{ placeholder: props.input.data.length > 0 ? 'Rechercher...' : 'Chargement...', noMatches: 'Aucun résultat' }}
                            onChange={(currentNode, selectedNodes) => {
                                setSeeError(true);
                                checkError(currentNode);
                                props.stateCallback(currentNode.value);
                            }}
                        />
                        {
                            !seeError ? props.input.helper.link
                                ? <LinkText variant="body2" onClick={() => goTo(props.input.helper.route)}>{props.input.helper.text}</LinkText>
                                : <HelperText variant="body2">{props.input.helper.text}</HelperText>
                                : <span className="error-label">{errorMessage}</span>
                        }
                    </GridCustom>
                );
            } else {
                return (
                    <GridCustom item xs={12}>
                        <DropdownTreeSelect
                            className={seeError && errorMessage ? 'error multiSelectTree' : 'multiSelectTree'}
                            id={props.input.stateName}
                            data={props.input.data}
                            mode="hierarchical"
                            keepOpenOnSelect={true}
                            inlineSearchInput={true}
                            disabled={props.input.disabled ? true : false}
                            texts={{ placeholder: props.input.data.length > 0 ? 'Rechercher...' : 'Chargement...', noMatches: 'Aucun résultat' }}
                            onChange={(currentNode, selectedNodes) => {
                                setSeeError(true);
                                checkError(selectedNodes);
                                props.stateCallback(selectedNodes);
                            }}
                        />
                        {
                            !seeError ? props.input.helper.link
                                ? <LinkText variant="body2" onClick={() => goTo(props.input.helper.route)}>{props.input.helper.text}</LinkText>
                                : <HelperText variant="body2">{props.input.helper.text}</HelperText>
                                : <span className="error-label">{errorMessage}</span>
                        }
                        {/* { 
                            props.input.showSelected
                                ? props.value 
                                    ? props.value.map((attr,index) =>{
                                        let attrSelected = ''
                                        let firstAttr = false
                                        index > 0 
                                            ? attrSelected = '- ' + attr.label : attrSelected = attr.label; firstAttr = true
                                        return(
                                            <TypographyCustom key={`AttributsSelected${index}`} variant={'body2'} style={{display: 'inline-block'}}>{firstAttr ? <span>{attrSelected}&nbsp;</span> : attrSelected}</TypographyCustom>
                                        )
                                    }) : null
                                : null
                        } */}
                    </GridCustom>
                );
            }
        case 'textarea':
            const modules = {
                toolbar: [
                    ['bold', 'italic', 'underline'],
                ],
            };
            const formats = [
                'bold', 'italic', 'underline',
            ];

            function insertProduct() {
                let editor = quillInfos.getEditor();
                const getIndex = editor.selection.savedRange;
                setQuillIndex(getIndex)
                setOpenProductPicker(true);
            }

            function getProductSku(value) {
                const refQuill = quillInfos.getEditor();
                const cursorPosition = quillIndex.index;
                const textToInsert = `<product sku="${value.sku}" idProduct="${value.id}"/> `;
                refQuill.insertText(cursorPosition, textToInsert);
                refQuill.setSelection(cursorPosition + textToInsert.length);
            }

            if (!window.delays)
                window.delays = {};
            return (
                <GridCustom item xs={12}>
                    <Drawer transitionDuration={400} anchor="right" open={openProductPicker} onClose={() => setOpenProductPicker(false)} style={{ minWidth: 300 }}>
                        <Grid container style={{ width: 300, padding: 20 }}>
                            <TypographyCustom variant="body2" colortext="#333333">Choisir le produit à importer :</TypographyCustom>
                            <SearchBarProductDynamic
                                key={`ProductSelector`}
                                allProducts={true}
                                isMulti={false}
                                currentLang={'fr_FR'}
                                style={{
                                    position: 'relative',
                                    top: 15,
                                    marginBottom: 5,
                                    width: 300
                                }}
                                onSelect={(value) => {
                                    setOpenProductPicker(false);
                                    getProductSku(value);
                                }}
                            />
                        </Grid>
                    </Drawer>
                    <div style={{ position: 'relative' }}>
                        <ToolbarRightSide>
                            {props.input.isContent ?
                                <Button className="spread-insert-product" style={{ width: "auto", minWidth: 'auto' }} onClick={() => insertProduct()}>
                                    <LocalOfferIcon style={{ width: 20, height: 20 }} />
                                </Button>
                                : null
                            }
                            <SpeechComponent value={props.value || ""} callback={props.stateCallback} />
                        </ToolbarRightSide>
                    </div>
                    <ReactQuill
                        className={seeError && errorMessage ? 'error' : ''}
                        value={props.value || ""}
                        key={"quill-editor"}
                        theme="snow"
                        modules={modules}
                        formats={formats}
                        readOnly={props.input.disabled}
                        ref={(el) => {
                            setQuillInfos(el)
                        }}
                        onChange={(content, delta, source, editor) => {
                            clearTimeout(window.delays[props.input.stateName]);
                            window.delays[props.input.stateName] = setTimeout(() => {
                                props.stateCallback(content);
                                setSeeError(true);
                                checkError(content);
                            }, 200);
                        }}
                        placeholder={props.input.helper.text}
                    />

                    {
                        !seeError ? null :
                            <span className="error-label">{errorMessage}</span>
                    }
                </GridCustom>
            );
        case 'textareaSimple':
            if (!window.delays)
                window.delays = {};

            return (
                <GridCustom item xs={12}>
                    <TextFieldCustom
                        id={props.input.stateName}
                        variant="outlined"
                        value={props.value ? props.value : ''}
                        helperText={seeError ? errorMessage : null}
                        fullWidth
                        multiline
                        rows={4}
                        onChange={evt => {
                            setSeeError(true);
                            checkError(evt.target.value);
                            props.stateCallback(evt);
                        }}
                        name={props.input.stateName}
                        placeholder={props.input.label}
                        disabled={props.input.disabled ? true : false}
                        error={seeError && errorMessage ? true : false}
                    />

                    {
                        seeError && errorMessage ? null :
                            props.input.helper.link
                                ? <LinkText variant="body2" onClick={() => goTo(props.input.helper.route)}>{props.input.helper.text}</LinkText>
                                :
                                <>
                                    <HelperText variant="body2">
                                        {props.input.helper.text}
                                    </HelperText>
                                    {props.input.helper.charCount ?
                                        <HelperText variant="body2">
                                            {`${props.value ? props.value.length : 0} / ${props.input.helper.maxChar}`}
                                        </HelperText>
                                        : null}
                                </>
                    }
                </GridCustom>
            );
        case 'date':
            return (
                <GridCustom item xs={12}>
                    <TextFieldCustom
                        id={props.input.stateName}
                        type={props.input.getTime ? "datetime-local" : "date"}
                        variant="outlined"
                        fullWidth
                        inputProps={{
                            step: "1",
                            min: props.input.minDate ? props.allState[props.input.minDate] : null
                        }}
                        defaultValue={props.input.getTime ? moment(props.value).format().slice(0, 19) : props.value}
                        error={seeError && errorMessage ? true : false}
                        onChange={(evt) => {
                            props.stateCallback(evt);
                            setSeeError(true);
                            checkError(evt.target.value);
                        }}
                        helperText={seeError && setErrorMessage ? null : props.input.helper.link ? <Box><LinkText variant="h5">{props.input.helper.text}</LinkText></Box> : props.input.helper.text}
                        disabled={props.input.disabled ? true : false}
                        value={props.value ?? ''}
                    />

                    {
                        !seeError ? null :
                            <span className="error-label">{errorMessage}</span>
                    }
                </GridCustom>
            );
        case 'buttonGroup':
            return (
                <GridCustom item xs={12}>
                    <ButtonGroupCustom color="primary" aria-label="outlined primary button group">
                        {props.input.value.map((option, index) =>
                        (
                            <Button
                                key={index}
                                value={option.value}
                                selected={option.value === props.value}
                                className={option.value === props.value ? 'selected' : ''}
                                onClick={event => handleMenuItemClick(event, option.value)}
                                disabled={props.input.disabled ? true : false}
                            >
                                {option.label}
                            </Button>
                        )
                        )}
                    </ButtonGroupCustom>
                </GridCustom>
            );
        case 'switch':
            return (
                <GridCustom item xs={12}>
                    <SwitchCustom
                        checked={switchValue ? switchValue : false}
                        className={switchValue ? 'checked' : ''}
                        onChange={event => handleSwitchChange(event)}
                        disabled={props.input.disabled ? true : false}
                        color="primary"

                        name="checkedB"
                        // value={switchValue || ''}
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                    />
                </GridCustom>
            );
        case 'hour':
            return (
                <GridCustom item xs={12}>
                    <TextFieldCustom
                        id={props.input.stateName}
                        type="time"
                        defaultValue="07:30"
                        variant="outlined"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        inputProps={{
                            step: 300, // 5 min
                        }}
                        helperText={props.input.helper.link ? <Box><LinkText variant="h5">{props.input.helper.text}</LinkText></Box> : props.input.helper.text}
                        disabled={props.input.disabled ? true : false}
                    />
                </GridCustom>
            );
        case 'oneImage':
            return (
                <GridCustom item xs={props.xs ? props.xs : 12}>
                    <HiddenUploadFile
                        id={props.input.stateName}
                        type="file"
                        variant="outlined"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        accept="image/*, application/*"
                        onChange={event => {
                            event.preventDefault();

                            let file = event.target.files[0];
                            let reader = new FileReader();

                            reader.readAsDataURL(file);

                            event.target.value = null;

                            reader.onloadend = () => {
                                let allowedTypes = ALLOWED;
                                let documentsTypes = DOCUMENTS.concat(VIDEOS);
                                setSeeError(true);
                                checkError(file.size);
                                if (file.size > maxFileSize) {
                                } else {
                                    if (allowedTypes.includes(file.type)) {
                                        let value = {
                                            data: documentsTypes.includes(file.type) ? getFileConfig(file.type).image : reader.result,
                                            file: file,
                                            changed: true
                                        };
                                        props.stateCallback(value);
                                    }
                                }
                            };
                        }}
                        helperText={props.input.helper.link ? <Box><LinkText variant="h5">{props.input.helper.text}</LinkText></Box> : props.input.helper.text}
                        hidden={true}
                    />
                    {console.log('props', props)}
                    <ContainerFileUpload style={{
                        background: `url(${(props.value?.changed ?? false)
                            ? (props.value?.data ?? `${colors.grey.lighter.hue900}`)
                            : (props.value?.data ?? null)
                                ? `${(props.value?.base ?? null)
                                    ? props.value.base
                                    : `${process.env.REACT_APP_API_ROOT}/`}${(props.value?.data ?? '')}`
                                : `${colors.grey.lighter.hue900}`
                            }) no-repeat center`,
                        border: seeError ? `2px solid ${colors.red.regular}` : '2px solid transparent'
                    }} fullwidth={props.input.fullwidth}>
                        <ClickToUpload>
                            {
                                props.value && props.value.data
                                    ? <>
                                        <Fab color="primary" size="small" disabled={props.input.disabled ? true : false}>
                                            <LabelFab htmlFor={props.input.stateName}>
                                                <EditIcon />
                                            </LabelFab>
                                        </Fab>
                                        <Fab color="primary" size="small" style={{ marginLeft: 5 }} onClick={() => props.stateCallback({
                                            changed: true,
                                            file: null,
                                            data: null
                                        })}>
                                            <DeleteIcon style={{ color: 'red' }} />
                                        </Fab>
                                    </>
                                    : <Fab color="primary" size="small" disabled={props.input.disabled ? true : false}>
                                        <LabelFab htmlFor={props.input.stateName}>
                                            <AddIcon />
                                        </LabelFab>
                                    </Fab>
                            }
                        </ClickToUpload>
                    </ContainerFileUpload>

                    {
                        !seeError ? null :
                            <span className="error-label">{errorMessage}</span>
                    }
                </GridCustom>
            )
        case 'image':
            const maximumImages = props.input.maxImageNumber;
            let countImage = 0;
            return (
                <>
                    {
                        props.value ?
                            props.value.map((imageContainer, index) => {
                                countImage = countImage + 1;
                                if (countImage <= maximumImages) {
                                    return (
                                        <GridCustom item xs={4} key={`imageContainer${index}`}>
                                            <HiddenUploadFile
                                                id={`imageUpload${index}`}
                                                type="file"
                                                variant="outlined"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                accept="image/*"
                                                onChange={event => {
                                                    event.preventDefault();

                                                    let file = event.target.files[0];
                                                    let reader = new FileReader();

                                                    reader.readAsDataURL(file);

                                                    reader.onloadend = () => {
                                                        imageContainer.original = reader.result
                                                        imageContainer.thumbnail = reader.result
                                                        imageContainer.changed = true

                                                        props.stateCallback(props.value);
                                                    };
                                                }}
                                                helperText={props.input.helper.link ? <Box><LinkText variant="h5">{props.input.helper.text}</LinkText></Box> : props.input.helper.text}
                                                hidden={true}
                                            />
                                            <ContainerFileUpload style={{
                                                background: `url(${imageContainer.original}) no-repeat center`,
                                            }}>
                                                <ClickToUpload>
                                                    {
                                                        imageContainer.original
                                                            ? <>
                                                                <Fab color="primary" size="small">
                                                                    <LabelFab htmlFor={`imageUpload${index}`}>
                                                                        <EditIcon />
                                                                    </LabelFab>
                                                                </Fab>
                                                                <Fab color="primary" size="small" style={{ marginLeft: 5 }} onClick={() => {
                                                                    let deleteArray = props.value.filter(
                                                                        id => id.id_image !== imageContainer.id_image
                                                                    )
                                                                    props.stateCallback(deleteArray);
                                                                }}>
                                                                    <DeleteIcon style={{ color: 'red' }} />
                                                                </Fab>
                                                            </>
                                                            : <Fab color="primary" size="small">
                                                                <LabelFab htmlFor={props.input.stateName}>
                                                                    <AddIcon />
                                                                </LabelFab>
                                                            </Fab>
                                                    }
                                                </ClickToUpload>
                                            </ContainerFileUpload>
                                        </GridCustom>
                                    )
                                } else {
                                    return null
                                }
                            }
                            )
                            : ''
                    }
                    {
                        countImage < maximumImages ?
                            <GridCustom item xs={4}>
                                <HiddenUploadFile
                                    id={'imageNew'}
                                    type="file"
                                    variant="outlined"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    accept="image/*"
                                    onChange={event => {
                                        event.preventDefault();

                                        let file = event.target.files[0];
                                        let reader = new FileReader();

                                        reader.readAsDataURL(file);

                                        reader.onloadend = () => {
                                            let value = {
                                                original: reader.result,
                                                thumbnail: reader.result,
                                                changed: true,
                                                newImage: true,
                                            };
                                            props.value.push(value)
                                            props.stateCallback(props.value);
                                        };
                                    }}
                                    helperText={props.input.helper.link ? <Box><LinkText variant="h5">{props.input.helper.text}</LinkText></Box> : props.input.helper.text}
                                    hidden={true}
                                />
                                <ContainerFileUpload style={{
                                    background: `${colors.grey.lighter.hue900} no-repeat center`,
                                }}>
                                    <ClickToUpload>
                                        <Fab color="primary" size="small">
                                            <LabelFab htmlFor={'imageNew'}>
                                                <AddIcon />
                                            </LabelFab>
                                        </Fab>
                                    </ClickToUpload>
                                </ContainerFileUpload>
                            </GridCustom> : null
                    }
                </>
            );
        case 'file':
        case 'mediaPicker':
            let value = props.value;
            if (value?.node) {
                value = value.node;
            }
            return (

                <GridCustom item xs={props.xs ? props.xs : 12}>
                    <HiddenUploadFile
                        id={props.input.stateName}
                        type="file"
                        variant="outlined"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        accept={ALLOWED}
                        helperText={props.input.helper.link ? <Box><LinkText variant="h5">{props.input.helper.text}</LinkText></Box> : props.input.helper.text}
                        hidden={true}
                    />
                    <ContainerFileUpload style={{
                        background: `url(${DOCUMENTS.concat(VIDEOS).includes(value?.type)
                            ? getFileConfig(value?.type).image
                            : value?.data
                                ? ['video/mpeg', 'video/webm', 'video/mp4'].includes(value.types)
                                    ? videoLogo
                                    : process.env.REACT_APP_API_ROOT + '/medias/' + value.data
                                : value?.filePath
                                    ? process.env.REACT_APP_MEDIAS + '/' + value.filePath
                                    : `${colors.grey.lighter.hue900}`
                            }) no-repeat center`,
                    }} fullwidth={props.input.fullwidth}>
                        <ClickToUpload>
                            {
                                value && (value.data || value.filePath)
                                    ? <>
                                        <Fab color="primary" size="small" disabled={props.input.disabled ? true : false} onClick={() => { handleOpenMediaPicker() }}>
                                            <LabelFab >
                                                <EditIcon />
                                            </LabelFab>
                                        </Fab>
                                        <Fab color="primary" size="small" style={{ marginLeft: 5 }} onClick={() => { props.input.handleMediaPicker(null, props.input.stateName) }}>
                                            <DeleteIcon style={{ color: 'red' }} />
                                        </Fab>
                                    </>
                                    : <StyledFab color="primary" checkerror={seeError && errorMessage ? seeError : null} size="small" disabled={props.input.disabled ? true : false}>
                                        <LabelFab onClick={() => { handleOpenMediaPicker() }}>
                                            <AddIcon />
                                        </LabelFab>
                                    </StyledFab>
                            }
                        </ClickToUpload>
                    </ContainerFileUpload>

                    {
                        !seeError ? null :
                            <span className="error-label">{errorMessage}</span>
                    }

                    <LayoutBuilder
                        opened={openMediaPicker}
                        dataLayout={mediaPickerConfig(
                            props.input.handleMediaPicker,
                            props.input.stateName,
                            props.input.cardSize ? props.input.cardSize : 3,
                            handleOpenMediaPicker,
                            props.input.translated,
                            props.input.allowedTypes ? props.input.allowedTypes : null
                        )}
                        forClose={() => { handleOpenMediaPicker() }}

                    />

                </GridCustom>
            )
        case 'uploadFile':
            return (
                <UploadFile allState={props.allState} stateCallback={props.stateCallback} input={props.input} />
            );
        case 'mapper':
            return (
                <GridCustom item xs={12}>
                    <Mapping allState={props.allState} stateCallback={props.stateCallback} input={props.input} category={props.input.isCategory} />
                </GridCustom>
            );
        case 'mapper-multiple':
            return (
                <GridCustom item xs={12}>
                    <MultipleMapping allState={props.allState} stateCallback={props.stateCallback} input={props.input} category={props.input.isCategory} />
                </GridCustom>
            );
        case 'simpleImage':
            return (
                <Grid item xs={12}>
                    <SimpleImage src={DOCUMENTS.concat(VIDEOS).includes(props.input.mediaType) ? props.input.imagepath : `${process.env.REACT_APP_API_ROOT}/medias/${props.input.imagepath}`} style={{ maxHeight: DOCUMENTS.concat(VIDEOS).includes(props.input.mediaType) ? 100 : null }} />
                </Grid>
            );
        case 'colorPicker':
            return (
                <Grid item xs={12}>
                    <ColorPicker
                        color={props.allState[props.input.stateName] ? props.allState[props.input.stateName].hex : '#ffffff'}
                        onChange={(color) => {
                            props.stateCallback(color, 'color')
                        }}
                    />
                </Grid>
            );
        case 'productSelector':
        case 'productSelector2':
            return (
                <Grid item xs={12}>
                    {state?.isSelectingProducts ? (
                        // <h1>Modal to select products</h1>
                        <BoxCustomProductSelector>
                            <Typography variant="h5" onClick={() => {
                                setState(prev => ({ ...prev, isSelectingProducts: false }))
                            }} component="div">
                                <BoxClose pb={2} style={{ cursor: 'pointer', marginBottom: 16 }}>
                                    <ArrowBack /> <span>Retour</span>
                                </BoxClose>
                            </Typography>
                            <Listing
                                label='produits'
                                settings={props.input.listing.listingSettings}
                                cardContainerProps={{
                                    style: { height: '100%' }
                                }}
                                perPageOptions={props.input.listing.perPageOptions}
                                mappers={props.input.listing.listMappers}
                                identifier='productLites'
                                searchSettings={props.input.listing.searchSettings}
                                viewsOptions={{
                                    current: 'card',
                                    settings: ['card'] //Si il n'y a qu'un seul item il n'y aura pas de changement de vues
                                }}
                                cardProps={{
                                    ...props.input.listing.cardProps,
                                    action: (product) => {
                                        props.input.listing.cardProps.action(product)
                                        setState(prev => ({ ...prev, isSelectingProducts: false }))
                                    }
                                }}
                            />
                        </BoxCustomProductSelector>
                    ) : props.input?.selectedProducts?.length !== 0 ? (
                            <>
                                <TableContainer>
                                    <Table aria-label="simple table">
                                        <TableHeadCustom>
                                            <TableRow>
                                                <TableCell align="center">SKU</TableCell>
                                                <TableCell align="left">Nom du produit</TableCell>
                                                <TableCell align="center">Qté</TableCell>
                                                <TableCell align="center">Prix HT</TableCell>
                                                <TableCell align="center">TVA</TableCell>
                                                <TableCell align="center">Total</TableCell>
                                            </TableRow>
                                        </TableHeadCustom>
                                        <TableBody style={{
                                            border: `1px solid ${colors.grey.border}`
                                        }}>
                                            {props.input.selectedProducts.map((product, index) => (
                                                <TableRow key={product?.id}>
                                                    <TableCell component="th" scope="row" style={{
                                                        fontWeight: 700,
                                                        fontSize: 14,
                                                        textAlign: 'center',
                                                        maxWidth: 125,
                                                    }}>
                                                        {/* Tooltip */}
                                                        <Tooltip title={product?.sku} placement="top" arrow>
                                                            <div style={{
                                                                overflow: 'hidden',
                                                                textOverflow: 'ellipsis',
                                                                whiteSpace: 'nowrap',
                                                            }}>
                                                                {product?.sku}
                                                            </div>
                                                        </Tooltip>
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        {
                                                            product?.custom ?
                                                                <>
                                                                    <TextField
                                                                        className="product-selector-textfield-number"
                                                                        type="text"
                                                                        variant="outlined"
                                                                        value={product?.name}
                                                                        onChange={(e) => { props.input?.listing?.handleProductName(e, index) }}
                                                                        error={product?.name === null || product?.name === ''}
                                                                        helperText={''}
                                                                    />
                                                                </>
                                                                : (
                                                                    <Tooltip title={product?.productName} placement="top" arrow>
                                                                        <div>
                                                                        {product?.productName.length > 69
                                                                            ? product?.productName.substring(0, 69) + '...'
                                                                            : product?.productName}
                                                                        </div>
                                                                    </Tooltip>
                                                                )
                                                        }
                                
                                                    </TableCell>
                                                    <TableCell align="right" style={{position: 'relative'}}>
                                                        <ButtonGroup size="small" color="primary" aria-label="outlined primary button group" style={{
                                                            borderRadius: 0,
                                                            paddingTop: 5,
                                                            paddingBottom: 5,
                                                        }}>
                                                            <Button onClick={(e) => props.input?.listing?.handleQty(e, '-', index)} style={{
                                                                borderRadius: 0,
                                                                maxWidth: 24
                                                            }}>-</Button>
                                                            <Button disabled style={{ border: '1px solid rgba(43, 69, 83, 0.5)', color: '#000000' }}><b style={{ paddingRight: '5px' }}> {product?.quantity} </b></Button>
                                                            <Button onClick={(e) => props.input?.listing?.handleQty(e, '+', index)} style={{
                                                                borderRadius: 0,
                                                                maxWidth: 24
                                                            }}>+</Button>
                                                        </ButtonGroup>
                                                        <OurTypography style={{
                                                            textAlign: 'center',
                                                            color: colors.grey.lighter.hue600,
                                                            fontSize: 8,
                                                            fontWeight: 400,
                                                            position: 'absolute',
                                                            bottom: 0,
                                                            left: '50%',
                                                            transform: 'translateX(-50%)'
                                                        }}>({product?.totalStock} en stock)</OurTypography>
                                                    </TableCell>
                                                    <TableCell align="right">
                                                        <TextField
                                                            style={{
                                                                paddingTop: 7,
                                                                paddingBottom: 7,
                                                                fontWeight: 700,
                                                                paddingRight: 2,
                                                            }}
                                                            className="product-selector-textfield-number"
                                                            type="number"
                                                            variant="outlined"
                                                            value={product?.unitPrice}
                                                            onChange={(e) => { props.input?.listing?.handleUnitPrice(e, index) }}
                                                            error={isNaN(product?.unitPrice)}
                                                            defaultValue={0}
                                                            helperText={''}
                                                        />
                                                    </TableCell>
                                                    <TableCell align="right">
                                                        <TextField
                                                            style={{
                                                                paddingTop: 7,
                                                                paddingBottom: 7,
                                                                fontWeight: 700,
                                                                paddingRight: 2,
                                                            }}
                                                            className="product-selector-textfield-number"
                                                            type="number"
                                                            variant="outlined"
                                                            value={product?.unitTVA}
                                                            onChange={(e) => { props.input?.listing?.handleUnitTVA(e, index) }}
                                                            error={isNaN(product?.unitTVA)}
                                                            defaultValue={0}
                                                            helperText={''}
                                                        />
                                                    </TableCell>
                                                    <TableCell align="right" style={{
                                                        textAlign: 'center'
                                                    }}> {
                                                        props.input?.avoir ? '-' : null
                                                    }{isNaN(Math.round(product?.totalPrice * 100) / 100) 
                                                        ? '-' 
                                                        : Math.round(product?.totalPrice * 100) / 100} 
                                                        €
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                
                                        </TableBody>
                                    </Table>
                                    
                                </TableContainer>
                                <Grid container direction='column' style={{
                                    with: 'auto'
                                }}>
                                    <Grid container alignItems='center' style={{
                                        marginLeft: 18,
                                        marginTop: 16,
                                        display: 'inline-flex',
                                        width: 'auto'
                                    }}>
                                        <AddCircleSharpIcon style={{ color: colors.green.regular, marginRight: '10px', fontSize: 16 }} />
                                        <OurTypography style={{
                                            color: colors.green.regular,
                                            textDecoration: 'underline',
                                            cursor: 'pointer'
                                        }} onClick={() => {
                                            setState(prev => ({ ...prev, isSelectingProducts: true }))
                                        }}>
                                            Ajouter un produit
                                        </OurTypography>
                                    </Grid>
                                    <Grid container alignItems='center' style={{
                                        marginLeft: 18,
                                        marginTop: 5,
                                        display: 'inline-flex',
                                        width: 'auto'
                                    }}>
                                        <AddCircleSharpIcon style={{ color: colors.green.regular, marginRight: '10px', fontSize: 16 }} />
                                        <OurTypography style={{
                                            color: colors.green.regular,
                                            textDecoration: 'underline',
                                            cursor: 'pointer'
                                        }} onClick={() => {
                                            props.input?.listing?.addCustomProduct()
                                        }}>
                                            Ajouter un produit personnalisé
                                        </OurTypography>
                                    </Grid>
                                </Grid>
                            </>
                    ) : (
                        <Box style={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                        }}>
                            <Typography variant="h3" fontweighttext={'bold'} style={{ margin: 'auto', marginBottom: '10px', fontSize: 33, marginTop: '2rem', textAlign: 'center', lineHeight: '35px' }}>
                                {
                                    props.input?.avoir ? 'Votre avoir ne contient aucun produit' : 'Votre commande ne contient aucun produit'
                                }
                            </Typography>
                            <Typography variant="h3" style={{ marginBottom: '2rem', width: '100%', marginTop: '20px', textAlign: 'center', color: colors.grey.lighter.hue800, fontSize: 16 }}>
                                Cliquez sur le bouton ci-dessous pour ajouter vos produits
                            </Typography>
                            <Box style={{ width: '100%', display: 'flex', alignItems: 'center', flexDirection: 'column', gap: '10px' }}>
                                <OurButton style={{ margin: 0, padding: "14px 32px", boxSizing: 'border-box', height: '100%', width: 300 }} text={'+ Ajouter un produit'} bgcolor={colors.green.regular} onClick={() => {
                                    setState(prev => ({ ...prev, isSelectingProducts: true }))
                                }} shadowcolor={colors.green.darker} disabled={false} />
                                <OurButton style={{ margin: 0, padding: "14px 32px", boxSizing: 'border-box', height: '100%', width: 300 }} text={'+ Ajouter un produit personnalisé'} bgcolor={colors.green.regular} onClick={() => {
                                    props.input?.listing?.addCustomProduct()
                                }} shadowcolor={colors.green.darker} disabled={false} />
                            </Box>
                        </Box>
                    )
                    }

                    {
                        seeError && errorMessage ? null :
                            props.input.helper.link
                                ? <LinkText variant="body2" onClick={() => goTo(props.input.helper.route)}>{props.input.helper.text}</LinkText>
                                : <HelperText variant="body2">{props.input.helper.text}</HelperText>
                    }
                </Grid>
            );
        case 'variantAdd':
            return (
                <Grid item xs={12}>
                    {
                        props.value?.values?.length > 0
                            ? props.value.values.map((attr, index) => {
                                let variantIndex = index + 1
                                let imagePath = process.env.REACT_APP_API_ROOT + '/medias/' + attr.image.value?.filePath
                                return (
                                    <Grid container direction="column" style={{ marginBottom: 32 }} key={`VariantProductElement${index}`}>
                                        <Grid container>
                                            Variant {variantIndex}
                                        </Grid>
                                        <Grid container style={{ marginTop: 10 }}>
                                            <CardCustom style={{ width: '100%' }}>
                                                <Grid container>
                                                    <Grid item xs={3}>
                                                        <img src={attr.image?.value?.filePath ? imagePath : props.allState.imagesSelected[0].original} width={'100%'} />
                                                    </Grid>
                                                    <Grid item xs={3} style={{ marginLeft: '12px' }}>
                                                        <TypographyCustom variant="body2" style={{ fontWeight: 'bold' }} colortext="#333333">Attributs</TypographyCustom>
                                                        {attr.allAttr.map((attribut, index) => {

                                                            if (attribut.type === "select") {
                                                                let getValueAttr = attribut.valuesForSelect.find(e => e.node.id === attribut.values)
                                                                let translatedAttr = getValueAttr.node?.translation.translationDatas.edges.find(e => e.node.locale.code === props.allState.currentLang)
                                                                return (
                                                                    <TypographyCustom key={`AttributVariant${index}`} variant="body2" style={{ marginLeft: 10, marginTop: 6 }}>{translatedAttr ? translatedAttr.node.value : getValueAttr.node?.translation.translationDatas.edges[0].node.value}</TypographyCustom>
                                                                )
                                                            } else {
                                                                return (
                                                                    <TypographyCustom key={`AttributVariant${index}`} variant="body2" style={{ marginLeft: 10, marginTop: 6 }}>{attribut.type === "date" ? moment(attribut.values).format('DD/MM/YYYY') : attribut.values}</TypographyCustom>
                                                                )
                                                            }
                                                        })}
                                                    </Grid>
                                                    {
                                                        props.input.hasPrice ?
                                                            <Grid item xs={3}>
                                                                <TypographyCustom variant="body2" style={{ fontWeight: 'bold' }} colortext="#333333">Prix</TypographyCustom>
                                                                <TypographyCustom variant="body2" style={{ marginLeft: 10, marginTop: 6 }}>{attr.price ? attr.price.value : null} €</TypographyCustom>
                                                            </Grid>
                                                            : null
                                                    }
                                                </Grid>
                                            </CardCustom>
                                        </Grid>
                                    </Grid>
                                )
                            }) : <TypographyCustom variant="body2">Vous n’avez pas encore de variante produit</TypographyCustom>
                    }
                    <div style={{ marginTop: 16, color: '#59C870', display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={() => { setSeeError(true); checkError(props.value); props.input.handleClick('addVariant') }} >
                        <AddCircleIcon fontSize="large" style={{ marginRight: 10 }} />
                        <span style={{ color: '#000000', fontSize: '16px', fontWeight: 'bold' }}>{props.value?.values?.length > 0 ? 'Ajouter/Modifier une variante produit' : 'Ajouter une variante produit'}</span>
                    </div>
                    {!seeError ? null : <span className="error-label">{errorMessage}</span>}
                </Grid>
            );
        case 'addCompany':
            const companys = props.allState.companyChildrens;
            const lang = props.allState.currentLang;
            return (
                <>
                    {companys ? companys.map((company, index) => {
                        const image = company[lang] ? company[lang].company_image?.filePath : company.company_image?.filePath ? company.company_image?.filePath : null;
                        const contacts = company.companyCustomers?.toAdd ? company.companyCustomers?.toAdd.length : company.companyCustomers?.length;
                        return (
                            <Grid item xs={12}>
                                <CardCustom contentpadding="20px" marginbottom="15px" >
                                    <Grid container>
                                        <Grid item xs={8}>
                                            <Grid container alignItems="center">
                                                {image ? (<img src={`${process.env.REACT_APP_MEDIAS}/${image}`} width={70} style={{ marginRight: 25 }} />)
                                                    : (<IcomoonReact iconSet={iconSet} color={colors.blue.lighter.hue300} size={70} icon="icon-business" style={{ marginRight: 25 }} />)
                                                }
                                                <div>
                                                    <Typography style={{ color: colors.blue.regular }} variant="h3">
                                                        {company[lang] ? company[lang].company_name : company.company_name}
                                                    </Typography>
                                                    <Typography style={{ color: colors.blue.lighter.hue300 }} variant="body2">{`${contacts} ${contacts > 1 ? 'contacts' : 'contact'}`}</Typography>
                                                </div>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={4} >
                                            <Grid container alignItems="center" style={{ height: '100%', justifyContent: 'flex-end' }}>
                                                <EditIcon onClick={() => props.input.callback('edit', index)} style={{ cursor: 'pointer', color: colors.blue.darker.hue300, marginRight: 20 }} />
                                                <DeleteIcon onClick={() => props.input.callback('delete', index)} style={{ cursor: 'pointer', color: colors.red.regular }} />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </CardCustom>
                            </Grid>
                        )
                    }) : null}
                    <Grid item xs={12}>
                        <ButtonAdd bgcolor="transparent" dotted={true} onClick={() => props.input.callback('add')} text="Ajouter une filiale" />
                    </Grid>
                </>
            )
        default:
            return null;
    }
}

export default withRouter(InputBuilder);