export function productsGridMapper(products,lang){
    return new Promise((resolve,reject)=>{
        let results = products.map(product=>(
            {
                ...product.node,
                totalStock : getTotalStock(product.node.stocks.edges),
                warehousesCount : product.node.stocks?.edges?.length
            }
        ));
        resolve (results)
    })
}

function getTotalStock(stocks){
    let total = 0;
    for(let stock of stocks){
        total = total + stock.node.quantity
    }
    return total;
}