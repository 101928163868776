import { FORM } from "../../../../../js/constants/form-types";
import OurButton from "../../../../ui/button/Button";

export default function editWarehouseConfig(warehouse) {
    var obj = {
        titleForm: 'Modifier un entrepôt',
        subTitleForm: `Veuillez compléter les champs ci-dessous pour modifier votre entrepôt`,
        langSelect: false,
        drawerType: 'drawer',
        noForm: true,
        component: OurButton,
        textButtonValidate: 'Modifier',
        formConfig: {
            type: FORM,
            children: [
                {
                    labelName: 'Principal',
                    isOptionnal: false,
                    optionsInputs: [
                        {
                            type: 'oneImage',
                            label: "Image de l'entrepôt",
                            translated: false,
                            helper: {
                                text: "Choisir une image de l'entrepôt",
                                link: false,
                            },
                            required: false,
                            stateName: 'formImage'
                        },
                        {
                            type: 'text',
                            label: 'Code',
                            helper: {
                                text: "Code de l'entrepôt",
                                link: false,
                            },
                            required: true,
                            disabled: false,
                            stateName: 'warehouseCode',
                        },
                        {
                            type: 'text',
                            label: 'Description',
                            helper: {
                                text: "Description de l'entrepôt",
                                link: false,
                            },
                            required: false,
                            stateName: 'warehouseDescription',
                        },
                        {
                            type: 'switch',
                            label: 'Actif',
                            helper: {
                                text: "Voulez-vous activer l'entrepôt ?",
                                link: false,
                            },
                            required: false,
                            stateName: 'warehouseIsActive',
                        },
                    ]
                },
            ]
        }
    }
    return obj;
}