import colors from '../theme/colors';
import { createMuiTheme } from '@material-ui/core/styles';

const THEME_SPREAD = createMuiTheme({
    typography: {
      "fontFamily": "soleil, sans-serif",
      "fontWeightLight": 300,
      "fontWeightRegular": 400,
      "fontWeightMedium": 500,
      h1: {
        "fontSize": 30,
        "fontWeight": "normal",
        "lineHeight": "39px",
        "color": colors.grey.lighter.hue600,
      },
      h2: {
        "fontSize": 20,
        "fontWeight": "bold",
        "lineHeight": "25px",
        "color": colors.blue.regular,
      },
      h3: {
        "fontSize": 18,
        "fontWeight": "bold",
        "lineHeight": "23px",
        "color": colors.blue.regular,
      },
      h4: {
        "fontSize": 16,
        "fontWeight": "normal",
        "lineHeight": "26px",
        "color": colors.blue.regular,
      },
      h5: {
        "fontSize": 12,
        "fontWeight": "normal",
        "lineHeight": "15px",
        "color": colors.blue.lighter.hue300,
      },
      body1: {
        "fontSize": 14,
        "fontWeight": "normal",
        "lineHeight": "19px",
        "color": colors.grey.regular,
      },
      body2: {
        "fontSize": 13,
        "fontWeight": "normal",
        "lineHeight": "15px",
        "color": colors.grey.regular,
      },
    },
    palette: {
      primary: {
        main: colors.blue.regular,
      },
      secondary: {
        main: colors.grey.regular,
      },
      terciary: {
        main: colors.blue.lighter.hue300,
      }
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 1250,
        lg: 1400,
        xl: 1920,
      },
    },
  });

  export default THEME_SPREAD;