import { FORM, STEPPER } from "../../../../../js/constants/form-types";
import { getDeviceZoom } from "../../../../../js/utils/getDeviceZoom";
import OurButton from "../../../../ui/button/Button";
import ProductListing from './productListing.config.js'

export const ordersFormConfig = (
    typeOrder,
    bill,
    handleAddProducts, 
    selectedProducts,
    handleProductName,
    handleUnitPrice,
    handleUnitTVA,
    calcPrice,
    handleQty,
    addCustomProduct
) => ({
    titleForm: typeOrder === 'order' ? "Créer une commande" : "Créer un avoir",
    subTitleForm: 
        typeOrder === 'order' 
            ? 'Veuillez compléter les champs ci-dessous pour créer votre commande' 
            : 'Veuillez compléter les champs ci-dessous pour créer votre avoir',
    langSelect: false,
    drawerType: 'drawer',
    noForm: true,
    component: OurButton,
    formConfig: {
        type: STEPPER,
        finalStep: {
            title: 'Et voilà !',
            subtitle: typeOrder === 'order'
                ? 'Votre commande est désormais configurée'
                : 'Votre avoir est désormais configurée',
            textButton: typeOrder === 'order' 
                ? 'Créer cette commande'
                : 'Créer cet avoir',
        },
        children:[
            {
                labelName: 'Informations générales',
                isOptionnal: false,
                optionsInputs: (() => {
                    if(bill){
                        return [
                            {
                                type: 'text',
                                label: 'Prénom',
                                helper: {
                                    text: 'Indiquer le prénom du client ici',
                                    link: false,
                                },
                                required: true,
                                stateName: 'billFirstname',
                            },
                            {
                                type: 'text',
                                label: 'Nom',
                                translated: false,
                                helper: {
                                    text: "Indiquer le nom du client ici",
                                    link: false,
                                },
                                required: true,
                                stateName: 'billLastname',
                            },
                            {
                                type: 'text',
                                label: 'Adresse postale',
                                textarea: 'Ligne 1',
                                translated: false,
                                helper: {
                                    text: false,
                                    link: false,
                                },
                                required: true,
                                stateName: 'billAddress1',
                            },
                            {
                                type: 'text',
                                // label: 'Adresse postale',
                                textarea: 'Ligne 2',
                                translated: false,
                                helper: {
                                    text: "Indiquer la ville du client ici",
                                    link: false,
                                },
                                required: true,
                                stateName: 'billAddress2',
                            },
                            {
                                xs: 6,
                                type: 'text',
                                label: 'Ville',
                                translated: false,
                                helper: {
                                    text: "Indiquer l’adresse du client ici",
                                    link: false,
                                },
                                required: true,
                                stateName: 'billCity',
                            },
                            {
                                xs: 6,
                                type: 'text',
                                label: 'Code Postal',
                                translated: false,
                                helper: {
                                    text: "Indiquer le CP du client ici",
                                    link: false,
                                },
                                required: true,
                                stateName: 'billPostalCode',
                            },
                            {
                                xs: 6,
                                type: 'text',
                                label: 'Adresse mail',
                                translated: false,
                                helper: {
                                    text: "Indiquer l’adresse mail du client ici",
                                    link: false,
                                },
                                required: true,
                                stateName: 'billMail',
                            },
                            {
                                xs: 6,
                                type: 'text',
                                label: 'Numéro de téléphone',
                                translated: false,
                                helper: {
                                    text: "Indiquer le numéro de tel du client ici",
                                    link: false,
                                },
                                required: true,
                                stateName: 'billTelephone',
                            },
                            {
                                type: 'switch',
                                label: 'Informations de livraison différentes',
                                helper: {
                                    text: 'Information de livraison',
                                    link: false,
                                },
                                required: true,
                                stateName: 'isActive',
                                value: [
                                    {
                                        value: true,
                                        label: 'Actif',
                                    },
                                    {
                                        value: false,
                                        label: 'Inactif',
                                    },
                                ]
                            },
                            // 
                            {
                                type: 'text',
                                label: 'Prénom',
                                helper: {
                                    text: 'Indiquer le prénom du client ici',
                                    link: false,
                                },
                                required: true,
                                stateName: 'shipFirstname',
                            },
                            {
                                type: 'text',
                                label: 'Nom',
                                translated: false,
                                helper: {
                                    text: "Indiquer le nom du client ici",
                                    link: false,
                                },
                                required: true,
                                stateName: 'shipLastname',
                            },
                            {
                                type: 'text',
                                label: 'Adresse postale',
                                textarea: 'Ligne 1',
                                translated: false,
                                helper: {
                                    text: false,
                                    link: false,
                                },
                                required: true,
                                stateName: 'shipAddress1',
                            },
                            {
                                type: 'text',
                                // label: 'Adresse postale',
                                textarea: 'Ligne 2',
                                translated: false,
                                helper: {
                                    text: "Indiquer la ville du client ici",
                                    link: false,
                                },
                                required: true,
                                stateName: 'shipAddress2',
                            },
                            {
                                xs: 6,
                                type: 'text',
                                label: 'Ville',
                                translated: false,
                                helper: {
                                    text: "Indiquer l’adresse du client ici",
                                    link: false,
                                },
                                required: true,
                                stateName: 'shipCity',
                            },
                            {
                                xs: 6,
                                type: 'text',
                                label: 'Code Postal',
                                translated: false,
                                helper: {
                                    text: "Indiquer le CP du client ici",
                                    link: false,
                                },
                                required: true,
                                stateName: 'shipPostalCode',
                            },
                            {
                                xs: 6,
                                type: 'text',
                                label: 'Adresse mail',
                                translated: false,
                                helper: {
                                    text: "Indiquer l’adresse mail du client ici",
                                    link: false,
                                },
                                required: true,
                                stateName: 'shipMail',
                            },
                            {
                                xs: 6,
                                type: 'text',
                                label: 'Numéro de téléphone',
                                translated: false,
                                helper: {
                                    text: "Indiquer le numéro de tel du client ici",
                                    link: false,
                                },
                                required: true,
                                stateName: 'shipTelephone',
                            },
                        ]
                    }
                    return [
                        {
                            type: 'text',
                            label: 'Prénom',
                            helper: {
                                text: 'Indiquer le prénom du client ici',
                                link: false,
                            },
                            required: true,
                            stateName: 'billFirstname',
                        },
                        {
                            type: 'text',
                            label: 'Nom',
                            translated: false,
                            helper: {
                                text: "Indiquer le nom du client ici",
                                link: false,
                            },
                            required: true,
                            stateName: 'billLastname',
                        },
                        {
                            type: 'text',
                            label: 'Adresse postale',
                            textarea: 'Ligne 1',
                            translated: false,
                            helper: {
                                text: false,
                                link: false,
                            },
                            required: true,
                            stateName: 'billAddress1',
                        },
                        {
                            type: 'text',
                            // label: 'Adresse postale',
                            textarea: 'Ligne 2',
                            translated: false,
                            helper: {
                                text: "Indiquer la ville du client ici",
                                link: false,
                            },
                            required: true,
                            stateName: 'billAddress2',
                        },
                        {
                            xs: 6,
                            type: 'text',
                            label: 'Ville',
                            translated: false,
                            helper: {
                                text: "Indiquer l’adresse du client ici",
                                link: false,
                            },
                            required: true,
                            stateName: 'billCity',
                        },
                        {
                            xs: 6,
                            type: 'text',
                            label: 'Code Postal',
                            translated: false,
                            helper: {
                                text: "Indiquer le CP du client ici",
                                link: false,
                            },
                            required: true,
                            stateName: 'billPostalCode',
                        },
                        {
                            xs: 6,
                            type: 'text',
                            label: 'Adresse mail',
                            translated: false,
                            helper: {
                                text: "Indiquer l’adresse mail du client ici",
                                link: false,
                            },
                            required: true,
                            stateName: 'billMail',
                        },
                        {
                            xs: 6,
                            type: 'text',
                            label: 'Numéro de téléphone',
                            translated: false,
                            helper: {
                                text: "Indiquer le numéro de tel du client ici",
                                link: false,
                            },
                            required: true,
                            stateName: 'billTelephone',
                        },
                        {
                            type: 'switch',
                            label: 'Informations de livraison différentes',
                            helper: {
                                text: 'Information de livraison',
                                link: false,
                            },
                            required: true,
                            stateName: 'isActive',
                            value: [
                                {
                                    value: true,
                                    label: 'Actif',
                                },
                                {
                                    value: false,
                                    label: 'Inactif',
                                },
                            ]
                        },
                    ]
                })()
            },
            {
                labelName: 'Produits',
                isOptionnal: false,
                avoir: false,
                optionsInputs: [
                    {
                        type: 'productSelector2',
                        label : '',
                        required : false,
                        stateName : 'orderProducts',
                        // products: [],
                        selectedProducts: selectedProducts,
                        listing: {
                            listingSettings: ProductListing().listSettings,
                            perPageOptions: ProductListing().perPageOptions,
                            listMappers: ProductListing().listMappers,
                            searchSettings: ProductListing().searchSettings,
                            handleAddProduct: handleAddProducts,
                            handleProductName: handleProductName,
                            handleUnitPrice: handleUnitPrice,
                            handleUnitTVA: handleUnitTVA,
                            calcPrice: calcPrice,
                            handleQty: handleQty,
                            addCustomProduct: addCustomProduct,
                            cardProps : {
                                action: handleAddProducts,
                                isLayout: true,
                                paths: {
                                    id: '_id',
                                    title: 'productName',
                                    image: 'imageUrl',
                                    ref: 'sku',
                                    status: 'status',
                                    mainInfo: 'totalStock',
                                    summary: [
                                        {
                                            label: 'Entrepôt :',
                                            path: 'warehousesCount'
                                        },
                                        {
                                            label: 'Dispo en réel :',
                                            path: null
                                        },
                                        {
                                            label: 'En stock :',
                                            path: 'totalStock'
                                        },
                                        {
                                            label: 'Reservé :',
                                            path: null
                                        },
                                    ]
                                }
                            }
                        },
                        helper: {
                            text: null,
                            link: false
                        }
                    }
                ]
            },
            {
                labelName: 'Informations complémentaire',
                isOptionnal: true,
                optionsInputs: (() => {
                    return [
                        {
                            type: 'text',
                            label: 'Note complémentaire',
                            helper: {
                                text: 'Ajouter une note ici',
                                link: false,
                            },
                            required: false,
                            stateName: 'infos',
                        },
                        {
                            type: 'text',
                            label: 'Marketplace',
                            helper: {
                                text: 'Marketplace de la commande',
                                link: false,
                            },
                            required: false,
                            stateName: 'marketPlaceName',
                        },
                    ]
                })()
            }
        ]
    }
})

export const ordersEditFormConfig = () => ({
    titleForm: 'Modifier la commande',
    subTitleForm: 'Modifier les informations de la commande dans le HUB (les modifications ne remontent pas vers les marketplaces)',
    langSelect: false,
    drawerType: 'drawer',
    noForm: true,
    component: OurButton,
    formConfig: {
        type: STEPPER,
        finalStep: {
            title: 'Et voilà !',
            subtitle: 'Votre commande est désormais prête',
            textButton: 'Modifier cette commande'
        },
        children:[
            {
                labelName: 'Informations de facturation',
                isOptionnal: false,
                optionsInputs: (() => {
                    return [
                        {
                            type: 'text',
                            label: 'Prénom',
                            helper: {
                                text: 'Indiquer le prénom du client ici',
                                link: false,
                            },
                            required: true,
                            stateName: 'billFirstname',
                        },
                        {
                            type: 'text',
                            label: 'Nom',
                            translated: false,
                            helper: {
                                text: "Indiquer le nom du client ici",
                                link: false,
                            },
                            required: true,
                            stateName: 'billLastname',
                        },
                        {
                            type: 'text',
                            label: 'Adresse postale',
                            textarea: 'Ligne 1',
                            translated: false,
                            helper: {
                                text: false,
                                link: false,
                            },
                            required: true,
                            stateName: 'billAddress1',
                        },
                        {
                            type: 'text',
                            // label: 'Adresse postale',
                            textarea: 'Ligne 2',
                            translated: false,
                            helper: {
                                text: "Indiquer la ville du client ici",
                                link: false,
                            },
                            required: false,
                            stateName: 'billAddress2',
                        },
                        {
                            xs: 6,
                            type: 'text',
                            label: 'Ville',
                            translated: false,
                            helper: {
                                text: "Indiquer l’adresse du client ici",
                                link: false,
                            },
                            required: true,
                            stateName: 'billCity',
                        },
                        {
                            xs: 6,
                            type: 'text',
                            label: 'Code Postal',
                            translated: false,
                            helper: {
                                text: "Indiquer le CP du client ici",
                                link: false,
                            },
                            required: true,
                            stateName: 'billPostalCode',
                        },
                        {
                            xs: 6,
                            type: 'text',
                            label: 'Adresse mail',
                            translated: false,
                            helper: {
                                text: "Indiquer l’adresse mail du client ici",
                                link: false,
                            },
                            required: false,
                            stateName: 'billMail',
                        },
                        {
                            xs: 6,
                            type: 'text',
                            label: 'Numéro de téléphone',
                            translated: false,
                            helper: {
                                text: "Indiquer le numéro de tel du client ici",
                                link: false,
                            },
                            required: false,
                            stateName: 'billTelephone',
                        }
                    ]
                })()
            },
            {
                labelName: 'Informations de livraison',
                isOptionnal: false,
                optionsInputs: (() => {
                    return [
                        {
                            type: 'text',
                            label: 'Prénom',
                            helper: {
                                text: 'Indiquer le prénom du client ici',
                                link: false,
                            },
                            required: true,
                            stateName: 'shipFirstname',
                        },
                        {
                            type: 'text',
                            label: 'Nom',
                            translated: false,
                            helper: {
                                text: "Indiquer le nom du client ici",
                                link: false,
                            },
                            required: true,
                            stateName: 'shipLastname',
                        },
                        {
                            type: 'text',
                            label: 'Adresse postale',
                            textarea: 'Ligne 1',
                            translated: false,
                            helper: {
                                text: false,
                                link: false,
                            },
                            required: true,
                            stateName: 'shipAddress1',
                        },
                        {
                            type: 'text',
                            // label: 'Adresse postale',
                            textarea: 'Ligne 2',
                            translated: false,
                            helper: {
                                text: "Indiquer la ville du client ici",
                                link: false,
                            },
                            required: false,
                            stateName: 'shipAddress2',
                        },
                        {
                            xs: 6,
                            type: 'text',
                            label: 'Ville',
                            translated: false,
                            helper: {
                                text: "Indiquer l’adresse du client ici",
                                link: false,
                            },
                            required: true,
                            stateName: 'shipCity',
                        },
                        {
                            xs: 6,
                            type: 'text',
                            label: 'Code Postal',
                            translated: false,
                            helper: {
                                text: "Indiquer le CP du client ici",
                                link: false,
                            },
                            required: true,
                            stateName: 'shipPostalCode',
                        },
                        {
                            xs: 6,
                            type: 'text',
                            label: 'Adresse mail',
                            translated: false,
                            helper: {
                                text: "Indiquer l’adresse mail du client ici",
                                link: false,
                            },
                            required: false,
                            stateName: 'shipMail',
                        },
                        {
                            xs: 6,
                            type: 'text',
                            label: 'Numéro de téléphone',
                            translated: false,
                            helper: {
                                text: "Indiquer le numéro de tel du client ici",
                                link: false,
                            },
                            required: false,
                            stateName: 'shipTelephone',
                        }
                    ]
                })()
            },
            {
                labelName: 'Informations complémentaire',
                isOptionnal: true,
                optionsInputs: (() => {
                    return [
                        {
                            type: 'text',
                            label: 'Note complémentaire',
                            helper: {
                                text: 'Ajouter une note ici',
                                link: false,
                            },
                            required: false,
                            stateName: 'infos',
                        },
                        {
                            type: 'text',
                            label: 'Numéro fiscal',
                            translated: false,
                            helper: {
                                text: "Indiquer le numéro fiscal du client ici",
                                link: false,
                            },
                            required: false,
                            stateName: 'customerTincode',
                        },
                        {
                            type: 'text',
                            label: 'Marketplace',
                            helper: {
                                text: 'Marketplace de la commande',
                                link: false,
                            },
                            required: false,
                            stateName: 'marketPlaceName',
                        },
                    ]
                })()
            }
        ]
    }
})


export default function ordersListingConfig(identifier,datas,mappers){
    let config = {
        listSettings : {

            table : {
                style : {
                    type : 'orders',
                    cursor : 'default',
                },
                columns : [
                    {
                        label : 'Statut',
                        field : 'status',
                        cellType : 'text',
                        cellProps : {
                            fontSize: getDeviceZoom() >= 125 ? 12 : 15,
                        }
                    },
                    {
                        label : 'Date de la commande',
                        field : 'createdAt',
                        cellType : 'text',
                        cellProps : {
                            fontWeight: 700,
                            fontSize: getDeviceZoom() >= 125 ? 12 : 15,
                        }
                    },
                    {
                        label : 'Reference',
                        field : 'numero',
                        cellType : 'text',
                        cellProps : {
                            fontSize: getDeviceZoom() >= 125 ? 12 : 15,
                        }
                    },
                    {
                        label : 'Destinataire',
                        field : 'recipient',
                        cellType : 'text',
                        prefix: 'Par ',
                        cellProps : {
                            fontWeight: 700,
                            fontSize: getDeviceZoom() >= 125 ? 12 : 15,
                        }
                    },
                    {
                        label : 'Entrepôts',
                        field : 'warehouse',
                        cellType : 'text',
                        cellProps : {
                            fontWeight: 700,
                            fontSize: getDeviceZoom() >= 125 ? 12 : 15,
                        }
                    },
                    {
                        label : 'Plateforme',
                        field : 'platform',
                        cellType : 'text',
                        prefix: 'Par ',
                        cellProps : {
                            fontWeight: 700,
                            fontSize: getDeviceZoom() >= 125 ? 12 : 15,
                        }
                    },
                    {
                        label : 'Total (EUR)',
                        field : 'price',
                        cellType : 'text',
                        cellProps : {
                            fontWeight: 700,
                            fontSize: getDeviceZoom() >= 125 ? 12 : 15,
                        }
                    },
                ]   
            }
        },
        
        
        
        perPageOptions : {
            'table' : ['24','48','96']
        }
        
    }    

    const getMapper=(identifier)=>{
        switch(identifier){
            case 'salesOrderReturned' : 
                return 'ordersReturnedListMapper';
            
            default :
                return 'ordersListMapper'
        }
    }

    let mapper = getMapper(identifier);

    config.listMappers = [
        
        {
            view : 'table',
            mapper : mapper
        }
    ]
    config.searchSettings = {
        // eslint-disable-next-line
        inputs : [
            {
                placeholder : 'Rechercher par statut',
                queryField : 'state',
                type : 'select',
                mapper : null,
                callback : null,
                label : 'Statut',
                value: [
                    { value: 'all', label:'Tout'},
                    { value: 'Pending for validation', label: 'Pending for validation' },
                    { value: 'Shipped', label: 'Shipped' },
                    { value: 'Closed', label: 'Closed' },
                    { value: 'Paid', label: 'Paid' },
                    { value: 'To Ship', label: 'To Ship' },
                    { value: 'Waiting for exportation', label: 'Waiting for exportation' },
                    { value: 'Partially Shipped', label: 'Partially Shipped' },
                    { value: 'Partially Accepted', label: 'Partially Accepted' },
                    { value: 'MP Partially Accepted', label: 'MP Partially Accepted'},
                    { value: 'MP Partially Shipped', label: 'MP Partially Shipped'},
                    { value: 'Declined', label: 'Declined' },
                    { value: 'Accepted', label: 'Accepted' },
                    { value: 'Manual action required', label: 'Manual action required' },
                    { value: 'Pre-order', label: 'Précommande' },
                ]
            },
            {
                placeholder : 'Rechercher par n° de CE',
                queryField : 'hubIdentifier',
                type : 'text',
                label : 'N° de CE',
            },
            {
                placeholder : 'Rechercher par Shoppingfeed ID',
                queryField : 'shoppingFeedId',
                type : 'text',
                label : 'Shoppingfeed ID',
            },
            identifier === 'salesOrderReturned' 
            ? 
                {
                    placeholder : 'Rechercher par n° de retour',
                    queryField : 'returnIdentifier',
                    type : 'text',
                    label : 'N° de retour',
                    
                }
            : null,
            {
                queryField : 'importedAt',
                type : 'date',
                label : 'Date',
                placeholder : 'Rechercher par date',
        
            },
            {
                queryField : 'billToFirstname',
                type : 'text',
                label : 'Prénom du destinataire',
                placeholder : 'Rechercher par prénom'
        
            },
            {
                queryField : 'billToLastname',
                type : 'text',
                label : 'Nom du destinataire',
                placeholder : 'Rechercher par nom'
        
            },
            {
                queryField : 'billToEmail',
                type : 'text',
                label : 'Email du destinataire',
                placeholder : 'Rechercher par email'
        
            },
            {
                placeholder : 'Rechercher par source',
                queryField : 'origin',
                type : 'select',
                label : 'Source',
                value: [
                    { value: 'all', label: 'Tout' },
                    { value: 'B2C', label: 'Site officiel' },
                    { value: 'MP', label: 'Marketplace' },
                    
                ]
            },
            datas.origin ? 
                {
                    placeholder : 'Rechercher par n° de source',
                    queryField :datas.origin === 'B2C' ? 'magentoId' : 'marketPlaceReference',
                    type : 'text',
                    label : 'N° de source',
    
                }
            : null,
            
            datas.origin === 'MP' && datas.marketplaces?
                {
                    placeholder : 'Rechercher par marketplaces',
                    queryField : 'marketPlaceName_list',
                    type : 'select',
                    label : 'Marketplaces',
                    multiselect:false,

                    value: datas.marketplaces.map(marketplace =>(
                        {value : marketplace.node.marketPlaceName,label : marketplace.node.marketPlaceName}
                    ))
                }
            : null,
            ,
            {
                placeholder : 'Rechercher par produits (SKU)',
                queryField : 'productSkus',
                type : 'text',
                label : 'Produits',
            },
            {
                placeholder : 'Rechercher par code',
                queryField : 'discountCodes',
                type : 'text',
                label : 'Code promo',
            },
            {
                placeholder : 'Rechercher par n° de facture',
                queryField : 'facture::hubIdentifier',
                type : 'text',
                label : 'N° de facture',
            }
        ]
    }

    if(identifier === 'salesOrderReturned'){
        config.listSettings.table.columns.splice(6,0,{
            label : 'Numéro de retour',
            field : 'returnID',
            cellType : 'chips',
        })
    }

    config.orderBy = [
        {
            column : 'orderDate',
            key : 'importedAt',
            order : 'DESC' 
        }
    ]

    return config;
}