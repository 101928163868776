import React from 'react';
import PageContainer from '../../../layouts/Container/PageContainer';
import ContentContainer from '../../../layouts/Container/ContentContainer';
import { START_LOADING, STOP_LOADING, SNACK } from '../../../../js/constants/action-types';
import { connect } from 'react-redux';

import Listing from '../../../layouts/Listing/Listing';
import { listSettings, listMappers, perPageOptions, viewsOptions, searchSettings } from './config/warehousesListing.config';
import StatsBar from '../../../layouts/StatsBar/StatsBar';
import PageLoader from '../../../ui/loadings/page-loader/PageLoader';
import { getGlobalStats } from '../../../../js/helpers/stats';
import LayoutFormBuilder from '../../../ui/form/LayoutFormBuilder';
import newWarehouseConfig from './config/newWarehouse.config';
import newLocationConfig from './config/newLocation.config';
import { ADD_LOCATION, ADD_WAREHOUSE } from '../../../../queries/warehouses';
import { withApollo } from 'react-apollo';
import { ADD_MEDIA } from '../../../../queries/medias';
import axios from 'axios';
import Fetch from '../../../../js/utils/fetch';
import { ALERT_ERROR } from '../../../../js/constants/alert-types';
import { eventService } from '../../../../js/services/event.service';

class ListWarehouses extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentLang: props.locales[0].node.code,
            statsIsLoading: true,
            stats: [],
            errors: {},
            openForm: false,
            typeForm: 'new',
            // pickedDate: new Date(), // Put the date of the "pickedDropdownOption" Dropdown Option
            // pickedDropdownOption: 1
        }
    }

    componentDidMount() {
        getGlobalStats('orders', new Date()).then(value => {
            this.setState({
                statsIsLoading: false,
                stats: value
            })
        })
    }

    // componentDidUpdate(prevProps, prevState) {
    //     if (prevState.pickedDate != this.state.pickedDate) {
    //         this.setState({
    //             statsIsLoading: true,
    //         })
    //         // getGlobalStats(this.state.pickedDate).then(value => {
    //         //     this.setState({
    //         //         statsIsLoading: false,
    //         //         stats: value
    //         //     })
    //         // })
    //     }
    // }

    stateCallback = (state, evt) => {
        const value = evt?.target?.value ?? evt;
        this.setState({
            [state]: value
        })
    }

    handlerMutation = async () => {
        if (this.state.typeForm === 'addWarehouse') {
            this.props.startLoading();
            try {
                const media = await this.saveMedia();
                const mediaId = `/api/media-objects/${media.id}`;
                await this.props.client.mutate({
                    mutation: ADD_WAREHOUSE,
                    variables: {
                        code: this.state?.warehouseCode,
                        description: this.state?.warehouseDescription,
                        // isActive: this.state?.warehouseIsActive,
                        media: mediaId,
                    }
                })
                this.props.snack('success', 'Entrepôt créé avec succès');
                this.handleToggleDrawer('openForm');
                this.props.stopLoading();
            } catch (error) {
                console.log(error);
                this.props.snack('error', 'Une erreur est survenue');
            }

        } else if (this.state.typeForm === 'addLocation') {
            try {
                this.props.startLoading();
                const media = await this.saveMedia();
                const mediaId = `/api/media-objects/${media.id}`;
                await this.props.client.mutate({
                    mutation: ADD_LOCATION,
                    variables: {
                        code: this.state?.locationCode,
                        description: this.state?.locationDescription,
                        // isActive: this.state?.locationIsActive,
                        media: mediaId,
                        warehouse: this.state?.locationWarehouseToLink,
                    }
                })
                this.props.snack('success', 'Zone créée avec succès');
                this.handleToggleDrawer('openForm');
                this.props.stopLoading();
            } catch (error) {
                console.log(error);
                this.props.snack('error', 'Une erreur est survenue');
            }


        }
    }

    handleToggleDrawer = (drawer) => {
        this.setState({
            [drawer]: !this.state[drawer]
        })
    }

    handleFormError = (stateName, error) => {
        let errors = this.state.errors;

        errors[stateName] = error;

        this.setState({ errors });
    };

    handleButtonGroupChange = (stateName, value) => {
        this.setState({
            [stateName]: value
        });
    };

    getWarehouses = (warehouses) => {
        this.setState({
            warehouses: warehouses
        })
    }

    hasErrors = () => {
        if (this.state.errors) {
            for (let error in this.state.errors) {
                if (this.state.errors[error])
                    return true;
            }
        }
        return false;
    };

    saveMedia = async () => {

        let resultMedia;
        let formData = new FormData();


        let media = this.state.formImage;
        formData.append('file', media.file);
        formData.append('type', media.file.type);
        formData.append('name', this.state.fileName ? this.state.fileName : media.file.name.replaceAll(/ /g, "_").toLowerCase());
        formData.append('alt', this.state.fileAlt);
        resultMedia = await axios.post(`${process.env.REACT_APP_API}/media-objects`,
            formData,
            {
                headers: {
                    "Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN"),
                }
            },
        );
        return resultMedia.data;

    }

    render() {
        // TODO: Put the good dates
        // const dropdownOptions = [
        //     { value: 1, label: 'Uno', date: new Date('1995-12-17T03:24:00') },
        //     { value: 2, label: 'Dos', date: new Date() },
        //     { value: 3, label: 'Tres', date: new Date() },
        // ]

        return (
            <PageContainer>
                <ContentContainer
                    headerSettings={{
                        icomoon: "icon-business",
                        title: 'Liste des entrepôts',
                        subtitle: 'Liste de vos entrepôts',
                        windowWidth: this.props.windowWidth,
                        textAdd: '+ Nouvel entrepôt',
                        handlerAdd: () => {
                            this.setState({
                                typeForm: 'addWarehouse'
                            })
                            this.handleToggleDrawer('openForm')
                        },
                        textThirdButton: '+ Nouvelle zone',
                        handlerThirdButton: () => {
                            this.setState({
                                typeForm: 'addLocation'
                            })
                            this.handleToggleDrawer('openForm')
                        },
                    }}
                >
                    <StatsBar
                        data={this.state.stats}
                        windowWidth={this.props.windowWidth}
                        statsIsLoading={this.state.statsIsLoading}
                        customPadding={'0px'}
                        spacing={0}
                    // dropdownOptions={dropdownOptions} 
                    // selectedOption={this.state.pickedDropdownOption} 
                    // stateCallback={(e) => {
                    //     this.setState({
                    //         pickedDate: dropdownOptions.find(option => option.value === e.target.value).date,
                    //         pickedDropdownOption: e.target.value
                    //     })
                    // }} 
                    />


                    <Listing
                        label='produits'
                        settings={listSettings}
                        cardContainerProps={{
                            style: { height: '100%' }
                        }}
                        perPageOptions={perPageOptions}
                        mappers={listMappers}
                        currentLang={this.state.currentLang}
                        identifier='warehouses'
                        viewsOptions={viewsOptions}
                        searchSettings={searchSettings('warehouses', this.state)}
                        listingCallback={this.getWarehouses}
                        cardContainerProps={{
                            spacing: 3
                        }}
                    />

                    <LayoutFormBuilder
                        opened={this.state.openForm}
                        icomoon={null}
                        forClose={() => { this.handleToggleDrawer('openForm') }}
                        dataLayout={
                            this.state.typeForm === 'addWarehouse'
                                ? newWarehouseConfig()
                                : this.state.typeForm === 'addLocation'
                                    ? newLocationConfig(this.state.warehouses)
                                    : newLocationConfig(this.state.warehouses)
                        }
                        drawerWidth={this.props.drawerWidth}
                        stateCallback={this.stateCallback}
                        handleButtonGroupChange={this.handleButtonGroupChange}
                        stepperButtonAction={[
                            () => {
                                // if (this.hasErrors()) {
                                //     this.props.snack(ALERT_ERROR, 'Veuillez vérifier les champs invalides');
                                //     this.setState({ seeErrors: true });
                                //     eventService.fire();
                                //     return false;
                                // }
                                return true;
                            },
                        ]}
                        errorCallback={this.handleFormError}
                        // stepperSkip={3}
                        validateButton={true}
                        handlerMutation={() => { this.handlerMutation() }}
                        allState={this.state}
                        windowWidth={this.props.windowWidth}
                        isSublayout={false}
                    />
                </ContentContainer>
            </PageContainer>
        )
    }
}

const mapStateToProps = state => {
    return {
        loading: state.loading,
        products: state.products,
        locales: state.locales,
        attributes: state.attributes
    };
};

const mapDispatchToProps = dispatch => {
    return {
        startLoading: () => dispatch({ type: START_LOADING }),
        stopLoading: () => dispatch({ type: STOP_LOADING }),
        snack: (type, message) => dispatch({ type: SNACK, payload: { type, message } })
    }
};

export default withApollo(connect(mapStateToProps, mapDispatchToProps)(ListWarehouses));