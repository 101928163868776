import Stepper from '@material-ui/core/Stepper';
import styled from 'styled-components';
import colors from '../../../config/theme/colors';

const StepperCustom = styled(Stepper)`
    padding: 0;
    border: 0.5px solid ${colors.grey.lighter.hue700};
    background: ${colors.white};
    &.endSteps{
        background-color: ${colors.green.regular};
        .MuiStep{
            &-completed{
                background-color: ${colors.green.regular} !important;
                svg{
                    circle{
                        fill: ${colors.green.regular} !important;
                    }
                }
                &:not(:last-child){
                    &:before{
                        border-color: ${colors.green.regular} !important;
                        border-left-color: transparent !important;
                    }
                    &:after{
                        border-color: ${colors.green.regular} !important;
                    }
                }
            }
            &Icon-completed{
                path{
                    color: ${colors.green.regular} !important;
                }
            }
        }
    }
    .MuiStep{
        &-horizontal{
            flex: 1 1 auto;
            border-radius: 0 20px 20px 0;
            padding: 5px;
            background-color: ${colors.black.lighter};
            height: 46px;
            justify-content: center;
            display: flex;
            font-size: 16px;
            position: relative;
            &:not(:last-child){
                &:after,
                &:before{
                    position:absolute;
                    content:"";
                    height:0;
                    width:1px;
                    top:0;
                    right: -23px;
                    border: 23px solid white;
                    border-left-color: transparent !important;
                }
                &:before{
                    right:-20px;
                    border: 23px solid rgba(0,0,0,0.2);
                }
            }
            &.active{
                &:after,
                &:before{
                    position:absolute;
                    content:"";
                    height:0;
                    width:1px;
                    top:0;
                    right: -22px;
                    border: 23px solid white;
                    border-left-color: transparent !important;
                }
                &:before{
                    right:-24px;
                    border: 23px solid rgba(0,0,0,0.2);
                }
            }
            span{
                color: ${colors.white};
                font-weight: bold;
                width: auto;
                padding: 0;
                font-size: 16px;
                line-height: 1.5;
            }
            > span{
                padding-right: 11px;
            }
            svg{
                circle{
                    fill: ${colors.black.lighter};
                }
                text{
                    fill: ${colors.white};
                    font-size: 16px;
                }
            }
            .MuiStepLabel-horizontal{
                justify-content: center;
            }
            .MuiStepLabel-labelContainer > span {
                display: inline-block;
            }
            &:last-child{
                border-radius: 0;
                &:after,
                &:before{
                    display: none;
                }
            }
            @media screen and (max-width: 1450px){
                padding: 5px 0 5px 2px;
                span{
                    font-size: 12px;
                }
                .MuiStepLabel-horizontal{
                    justify-content: initial;
                }
            }
            @media screen and (max-width: 1280px){
                padding: 2px 0 2px 2px;
                height: 28px;
                &:not(:last-child){
                    &:after,
                    &:before{
                        position:absolute;
                        content:"";
                        height:0;
                        width:1px;
                        top:0;
                        right: -14px ;
                        border: 14px solid white;
                        border-left-color: transparent !important;
                    }
                    &:before{
                        right:-12px 
                        border: 14px solid rgba(0,0,0,0.2);
                    }
                }
                &.active{
                    &:after,
                    &:before{
                        position:absolute;
                        content:"";
                        height:0;
                        width:1px;
                        top:0;
                        right: -14px;
                        border:      14px solid white ;
                        border-left-color: transparent !important;
                    }
                    &:before{
                        right:-16px !important;
                        border: 14px solid rgba(0,0,0,0.2);
                    }
                }
            }
        }
        &-completed{
            border-radius: inherit;
            span{
                color: ${colors.white};
                &.MuiStepLabel-iconContainer{
                    position: relative;
                    background-color: white;
                    border-radius: 50px;
                    max-width: 20px;
                    max-height: 20px;
                }
            }
            &:after,
            &:before{
                position:absolute;
                content:"";
                height:0;
                width:1px;
                top:0;
                right: -24px;
                border: 23px solid ${colors.black.lighter} !important;
                border-left-color: transparent !important;
            }
            &:before{
                right:-22px;
                border: 23px solid rgba(0,0,0,0.2) !important;
            }
            @media screen and (max-width: 1280px){
                &:after,
                &:before{
                    position:absolute;
                    content:"";
                    height:0;
                    width:1px;
                    top:0;
                    right: -14px !important;
                    border:      14px solid ${colors.black.lighter} !important;
                    border-left-color: transparent !important;
                }
                &:before{
                    right:-12px !important;
                    border: 14px solid rgba(0,0,0,0.2) !important;
                }
            }
            @media screen and (max-width: 1000px){
                &>span{
                    margin: 2px;
                    max-width: 20px;
                }
                span.MuiStepLabel-labelContainer{
                    display: none;
                }
            }
        }
        &Connector-horizontal.Mui-disabled{
            &+div{
                background-color: ${colors.white}; 
                svg{
                    circle{
                        fill: ${colors.white};
                    }
                    text{
                        fill: ${colors.grey.lighter.hue600};
                    }
                }
                span{
                    color: ${colors.grey.lighter.hue600};
                }
            }
        }
        &Connector-active + div {
            background-color: ${colors.black.lighter};
            span{
                color: ${colors.white};
            }
        }  
        &Connector-horizontal{
            display: none;
        }
        &Icon-completed{
            border-radius: 50px;
            height: 23px;
            width: 23px;
            position: relative;
            top: -2px;
            left: -2px;
            path{
                color: ${colors.black.lighter};
            }
        }
    }
    button{
        color: ${colors.black.regular};
        position: relative;
        z-index: 9;
        text-transform: inherit;
        font-weight: bold;
        font-size: 14px;
        opacity: 1;
    }
    .MuiTabs-indicator{
        top: 0;
        height: 100%;
        border-top: 2px solid ${colors.black.lighter};
        background-color: ${colors.grey.lighter.hue980}
    }
`;

export default StepperCustom;