import React from 'react';
import { connect } from "react-redux";
import { SNACK, START_LOADING, STOP_LOADING } from '../../../js/constants/action-types';
import axios from '../../../js/utils/axios';
import {Grid, Typography} from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import PageLoader from '../loadings/page-loader/PageLoader';
import HighlightOff from '@material-ui/icons/HighlightOff';

import Tooltip from '@material-ui/core/Tooltip';
import colors from '../../../config/theme/colors';
import { makeStyles } from '@material-ui/core/styles';

const useStylesBootstrap = makeStyles(theme => ({
    arrow: {
        color: colors.blue.regular,
    },
    tooltip: {
        backgroundColor: colors.blue.regular,
        fontSize: 14,
        padding: 10,
        textAlign: 'left',
        import: {},
        toolTipListener: true,
        tooltipSelected: null,
    },
}));

function BootstrapTooltip(props) {
    const classes = useStylesBootstrap();
    return <Tooltip arrow classes={classes} {...props} />;
}

class MultipleMapping extends React.Component {  
    state = {
        headers: [],
        options: [],
        import: {},
        ftpReady: false,
        preLoad: false,
        existentReady: true,
    };
    
    getHeader = (locale, options) => {
        return new Promise(async (resolve, reject) => {
            let headersFormData = new FormData();
            let mapOn = this.props.input.mapOn + '_import';
            if(!this.props.allState[locale.node.code][mapOn]){
                this.setState({ftpReady: false});
                resolve();
            } else {
                this.setState({ftpReady: true});
                if (this.props.allState['importSep'] === "xml"){
                    headersFormData.append('xml', this.props.allState[locale.node.code][mapOn]);

                }else{
                    headersFormData.append('csv', this.props.allState[locale.node.code][mapOn]);
                }
                headersFormData.append('separator', this.props.allState['importSep']);
                let resultHeaders = await axios(this.props.allState['importSep'] === "xml" 
                    ? `${process.env.REACT_APP_API_ROOT}/api/export/simplexml/structure` 
                    : `${process.env.REACT_APP_API_ROOT}/api/export/csv/structure`, 'post', headersFormData);

                this.setState({
                    headers: resultHeaders.message
                }, () => {
                    this.setState({
                        [locale.node.code]: {
                            headers: (this.state.headers || []).map(e => ({
                                used: false,
                                label: e,
                                id: e
                            })),
                            options
                        }
                    }, () => {
                        let values = this.state[locale.node.code].options;
                        let mapper = [];
                        let i = 0;

                        for(let header of this.state[this.props.allState.currentLang].headers){
                            if(this.props.allState.existentMapper && this.state.existentReady){
                                if(this.props.allState.existentMapper.length === this.state[locale.node.code].headers.length){
                                    mapper[header.id] = this.props.allState.existentMapper[i] ;
                                }
                            }
                            for (let option of this.state[locale.node.code].options){
                                if(option.label.toLowerCase() === header.label.toLowerCase() || option.identifier?.toLowerCase() === header.id.toLowerCase()){
                                    mapper[header.id] = [option.id];
                                }
                            }
                            i++;
                        }
                        this.props.stateCallback(mapper, null, true, null);
                        this.setState({existentReady: false})
                    });
                });
                resolve();
            }
        });
    };

    getValue = (label) => {
        if(!this.props.allState.existentMapper){
            if(!this.state.preLoad){
                for(let option of this.state[this.props.allState.currentLang].options){
                    if(option.label === label){
                        let values = this.props.allState[this.props.allState.currentLang][this.props.input.stateName];
                        values[option.label] = [option.id];
                        this.props.stateCallback(values, null, true, null);
                        return values[option.label];
                    }
                }
                return [];
            }
            else{
                for(let option of this.state[this.props.allState.currentLang].options){
                    let values = this.props.allState[this.props.allState.currentLang][this.props.input.stateName];
                    values[option.label] = [];
                    this.props.stateCallback(values, null, true, null);
                    return [];
                }
            }
        }
    };

    componentDidMount() {
        if (this.props.input?.staticOptions) {
            const { allState, input } = this.props;
            let options = input.staticOptions;

            for(let locale of this.props.locales){
                if(allState[locale.node.code][input.mapOn] !== null){
                    if(typeof(allState[locale.node.code][input.mapOn]) === "string"){
                        this.getHeader(locale, options)
                    }
                    else{
                        if(allState[locale.node.code][input.mapOn]?.headers){
                            this.setState({
                                [locale.node.code]: {
                                    headers: (allState[locale.node.code][input.mapOn].headers || []).map(e => ({
                                        used: false,
                                        label: e,
                                        id: e
                                    })),
                                    options
                                }
                            });
                        }
                    }
                }
            }
        } else {
            let options = this.props.category
                ? [ 
                    {
                        id: 'libelle',
                        label: 'Identifiant'
                    }, {
                        id: 'parent',
                        label: `Parent`
                    }
                ]: [
                    {
                        id: 'sku',
                        label: 'Code article'
                    }, {
                        id: 'libelle',
                        label: 'Catégorie'
                    }, {
                        id: 'attributeGroup',
                        label: `Groupe d'attributs`
                    }
                ];
    
            let currentLang = this.props.locales[0].node.code;
    
            let attributes = this.props.category 
                ? this.props.attributes.category.attributes.edges
                : this.props.attributes.product.attributes.edges;
    
            for (let attribute of attributes) {
                const defaultLang = attribute.node.translation.translationDatas.edges[0];
    
                const langSelected = attribute.node.translation.translationDatas.edges.find(
                    lang => lang.node.locale.code === currentLang
                );
                if(attribute.node.status){
                    options.push({
                        id: attribute.node.id,
                        identifier: attribute.node.identifier,
                        label: langSelected?.node.value ?? defaultLang.node.value
                    });
                }
            }
            for(let locale of this.props.locales){
                if(this.props.allState[locale.node.code][this.props.input.mapOn] !== null){
                    if(typeof(this.props.allState[locale.node.code][this.props.input.mapOn]) === "string"){
                        this.getHeader(locale, options)
                    }
                    else{
                        this.setState({
                            [locale.node.code]: {
                                headers: (this.props.allState[locale.node.code][this.props.input.mapOn]?.headers || []).map(e => ({
                                    used: false,
                                    label: e,
                                    id: e
                                })),
                                options
                            }
                        }, () => {
                            let values = this.state[this.props.allState.currentLang]?.options;
                            for(let header of this.state[this.props.allState.currentLang]?.headers){
                                for (let option of values){
                                    if(option.label.toLowerCase() === header.label.toLowerCase() || option.identifier?.toLowerCase() === header.id.toLowerCase()){
                                        values[header.id] = [option.id];
                                        this.stateCallback(values, null, true, () => {
                                            this.construct();
                                        });
                                    }
                                }
                            }
                        });
                    }
                }
            }
        }
    }

    componentDidUpdate(prevProps) {
        if(this.props !== prevProps){
            if (this.props.input?.staticOptions) {
                let options = this.props.input.staticOptions;
                let currentLang = this.props.locales[0].node.code;
                for(let locale of this.props.locales){
                    if(this.props.allState[locale.node.code][this.props.input.mapOn] !== null){
                        if(typeof(this.props.allState[locale.node.code][this.props.input.mapOn]) === "string" && !this.state.ftpReady){
                            this.getHeader(locale, options);
                        }
                    }
                }
            } else {
                let options = this.props.category
                    ? [ 
                        {
                            id: 'libelle',
                            label: 'Identifiant'
                        }, {
                            id: 'parent',
                            label: `Parent`
                        }
                    ]: [
                        {
                            id: 'sku',
                            label: 'Code article'
                        }, {
                            id: 'libelle',
                            label: 'Catégorie'
                        }, {
                            id: 'attributeGroup',
                            label: `Groupe d'attributs`
                        }
                    ];
        
                let currentLang = this.props.locales[0].node.code;
        
                let attributes = this.props.category 
                    ? this.props.attributes.category.attributes.edges
                    : this.props.attributes.product.attributes.edges;
        
                for (let attribute of attributes) {
                    const defaultLang = attribute.node.translation.translationDatas.edges[0];
        
                    const langSelected = attribute.node.translation.translationDatas.edges.find(
                        lang => lang.node.locale.code === currentLang
                    );
                    if(attribute.node.status){
                        options.push({
                            id: attribute.node.id,
                            identifier: attribute.node.identifier,
                            label: langSelected?.node.value ?? defaultLang.node.value
                        });
                    }
                }

                for(let locale of this.props.locales){
                    if(this.props.allState[locale.node.code][this.props.input.mapOn] !== null){
                        if(typeof(this.props.allState[locale.node.code][this.props.input.mapOn]) === "string" && !this.state.ftpReady){
                            this.getHeader(locale, options);
                        }
                    }
                }
            }
        }
    }

    construct = (mapper = []) => {
        const { allState, stateCallback } = this.props;
        const { headers } = this.state;
        for (let header of headers) {
            let value = allState.importValues[header.id];
            mapper.push(!value || value === '' || value === '-1' ? null : value);
        }
        stateCallback(mapper, null, true, null);
    };

    render() {
        const { allState, stateCallback, input } = this.props;
        if(this.state[allState.currentLang] && this.state.ftpReady){
            let allStateCurrent = allState[allState.currentLang]; 
            return (
                <div style={{ paddingTop: 15, paddingBottom: 15, backgroundColor: 'rgb(250, 251, 251)' }}>
                    {
                        this.state[allState.currentLang].headers.map((header, i) => {
                            let value = allStateCurrent[input.stateName][header.id] 
                                ? allStateCurrent[input.stateName][header.id] 
                                : allState.existentMapper 
                                    ? allState.existentMapper[i] 
                                    : null;

                            return (
                                <div 
                                    key={`attribute_${i}`} 
                                    style={{
                                        padding: 10,
                                        backgroundColor: (!value || value === '' || value === '-1' || value.length === 0 ) ? '#fff' : 'rgb(224, 241, 251)',
                                        width: '100%',
                                        margin: '0 auto',
                                        marginBottom: 5
                                    }}
                                >
                                    <Grid container style={{position: 'relative'}}>
                                        <Grid item xs={4} style={{ 
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'center',
                                            alignItems: 'flex-start',
                                            paddingRight: 20,
                                            borderRightWidth: 1,
                                            borderRightStyle: 'solid',
                                            fontWeight: (!value || value === '' || value === '-1' || value.length === 0 ) ? 'normal' : 'bold'
                                        }}>
                                            { header.label }
                                        </Grid>
                                        <Grid item xs={8} style={{ 
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'center',
                                            alignItems: 'flex-start',
                                            paddingLeft: 20
                                        }}>
                                            <FormControl style={{maxWidth: "calc(100% - 30px)", width:"100%"}}>
                                                <BootstrapTooltip 
                                                    title={
                                                        value?.length > 0 ?
                                                            value.map((data, index) => {
                                                                let getLabel =  this.state[allState.currentLang].options.find(e => e.id  === data)
                                                                return <span style={{display: 'block'}}>{getLabel.label}</span>
                                                            })
                                                        : null
                                                    } 
                                                    disableHoverListener={value?.length > 0 ? true : false} 
                                                    disableFocusListener={true} 
                                                    disableTouchListener={true}
                                                    disableTriggerFocus={true} 
                                                    open={this.state.toolTipListener && this.state.tooltipSelected === header.id && value?.length > 0 ? true : false}
                                                    arrow
                                                    aria-label="add-attributes"
                                                >
                                                    <Select
                                                        value={ value || this.getValue(header.label) || [] }
                                                        multiple
                                                        onChange={(evt) => {
                                                            this.state[allState.currentLang].options = 
                                                                this.state[allState.currentLang].options.map(e => {
                                                                    if (e.id === evt.target.value)
                                                                        e.used = true; // make option unavailable
                                                                    if (e.id === allStateCurrent[input.stateName][header.id])
                                                                        e.used = false; // make old option available
                                                                    
                                                                    return e;
                                                                });
                                                            let values = allStateCurrent[input.stateName];
                                                            values[header.id] = evt.target.value;
                                                            stateCallback(values, null, true, () => {
                                                                this.construct();
                                                            });
                                                        }}
                                                        onMouseEnter={()=> { this.setState({ toolTipListener: true, tooltipSelected: header.id }) }}
                                                        onMouseLeave={()=> { this.setState({ toolTipListener: false }) }}
                                                        onOpen={() => {this.setState({ toolTipListener: false }) }}
                                                        onClose={() => { this.setState({ toolTipListener: true, tooltipSelected: null }) }}
                                                    >
                                                        <MenuItem value="-1">
                                                            <em>Aucun</em>
                                                        </MenuItem>
                                                        {
                                                            this.state[allState.currentLang].options.map((e, i) => (
                                                                <MenuItem key={`header-${i}`} disabled={e.used} value={e.id}>{ e.label }</MenuItem>
                                                            ))
                                                        }
                                                    </Select>
                                                </BootstrapTooltip>
                                            </FormControl>
                                        </Grid>
                                        {
                                            value && value.length > 0 ?
                                                <HighlightOff 
                                                    style={{width: 25, cursor: "pointer", position:"absolute",top: "50%", right: 0, transform: 'translateY(-50%)'}} 
                                                    onClick={() => {
                                                        let values = allStateCurrent[this.props.input.stateName];
                                                        values[header.id] = [];
                                                        stateCallback(values, null, true, () => {this.construct()} );
                                                    }}
                                                />
                                            : null
                                        }
                                    </Grid>
                                </div>
                            );
                        })
                    }
                </div>
            );
        }

        else if(!this.state.ftpReady){
            return <PageLoader />
        }

        else{
            return <Typography style={{background: "white", padding: 15, width: "100%", display: "block"}}>Aucun Mapper, veuillez revoir l'étape précédente.</Typography>
        }
    }
}


const mapStateToProps = state => {
    return {
        loading: state.loading,
        products: state.products,
        attributes: state.attributes, 
        locales: state.locales,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        startLoading: () => dispatch({ type: START_LOADING }),
        stopLoading: () => dispatch({ type: STOP_LOADING }),
        snack: (type, message) => dispatch({ type: SNACK, payload: { type, message }})
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(MultipleMapping);