export const SETTINGS                                   = "settings";
export const SETTINGS_USERS                             = "users";
export const SETTINGS_CURRENCIES                        = "currencies";
export const SETTINGS_LANGUAGES                         = "languages";

export const BRAND                                      = "brand";
export const BRAND_FLOW                                 = "my_flow";
export const BRAND_GUIDELINE                            = "guideline";

export const CONNECTORS                                 = "connectors";
export const CONNECTORS_CONNECTORS                      = "connectors";
export const CONNECTORS_MEDIAS                          = "medias";
export const CONNECTORS_CONTENTS                        = "contents";
export const CONNECTORS_EMAILS                          = "emails";
export const CONNECTORS_CMS                             = "cms";
export const CONNECTORS_SOCIAL_NETWORK                  = "social_networks";
export const CONNECTORS_MARKETPLACES                    = "marketplaces";
export const CONNECTORS_RETAILERS                       = "retailers";
export const CONNECTORS_BRANDS                          = "brands";
export const CONNECTORS_DASHBOARD                       = "dashboard";

export const PRODUCTS                                   = "products";
export const PRODUCTS_ATTRIBUTES                        = "attributes";
export const PRODUCTS_PRODUCTS                          = "products";
export const PRODUCTS_CATEGORIES                        = "categories";
export const PRODUCTS_DASHBOARD                         = "dashboard";

export const CONTENTS                                   = "contents";
export const CONTENTS_MODELS                            = "models";
export const CONTENTS_CONTENTS                          = "contents";
export const CONTENTS_CATEGORIES                        = "categories";
export const CONTENTS_DASHBOARD                         = "dashboard";

export const MEDIAS                                     = "medias";
export const MEDIAS_TAGS                                = "tags";
export const MEDIAS_MEDIAS                              = "medias";
export const MEDIAS_CATEGORIES                          = "categories";
export const MEDIAS_DASHBOARD                           = "dashboard";

export const CATALOGS                                   = "catalogs";
export const CATALOGS_CATALOGS                          = "catalogs";
export const CATALOGS_DASHBOARD                         = "dashboard";

export const BUILDER                                    = "builder";
export const BUILDER_ASSETS                             = "assets";
export const BUILDER_DASHBOARD                          = "dashboard";

export const BROADCAST                                  = "broadcast";
export const BROADCAST_RETAILERS                        = "retailers";
export const BROADCAST_CAMPAIGN                         = "campaign";
export const BROADCAST_ASSETS                           = "assets";
export const BROADCAST_CALENDAR                         = "calendar";

export const DASHBOARD                                  = "dashboards";
export const DASHBOARD_CAMPAIGN                         = "campaign";
export const DASHBOARD_ASSETS                           = "assets";
export const DASHBOARD_RETAILERS                        = "retailers";
export const DASHBOARD_STATISTICS                       = "statistics";

export const CRM_STATISTICS                             = "crm_stats";
export const CRM_STATISTICS_MY_STATS                    = "my_stats";
export const CRM_STATISTICS_BY_CUSTOMER                 = "by_customer";
export const CRM_STATISTICS_BY_RESOURCE                 = "by_resource";
export const CRM_STATISTICS_BY_CAMPAIGN                 = "by_campaign";

export const CRM_AGENCIES                               = "crm_agencies";
export const CRM_AGENCIES_POLES                         = "poles";
export const CRM_AGENCIES_RATES                         = "rates";
export const CRM_AGENCIES_TAX_RATES                     = "tax_rates";
export const CRM_AGENCIES_PAYMENT_TERMS                 = "payment_rates";
export const CRM_AGENCIES_PAYMENT_DEADLINES             = "payment_deadlines";

export const CRM_OFFERS                                 = "crm_offers";
export const CRM_OFFERS_DASHBOARD                       = "dashboard";
export const CRM_OFFERS_OFFERS                          = "offers";
export const CRM_OFFERS_MODELES                         = "modeles";
export const CRM_OFFERS_PROJECTS                        = "projects";

export const CRM_MEDIAS                                 = "crm_medias";
export const CRM_MEDIAS_DASHBOARD                       = "dashboard";
export const CRM_MEDIAS_DAM                             = "dam";

export const CRM_CONTACT                                = "crm_contact";
export const CRM_CONTACT_DASHBOARD                      = "dashboard";
export const CRM_CONTACT_SOCIETIES                      = "societies";
export const CRM_CONTACT_CLIENTS                        = "clients";
export const CRM_CONTACT_LEADS                          = "leads";
export const CRM_CONTACT_CONTACTS                       = "contacts";

export const CRM_SETTINGS                               = "crm_settings";
export const CRM_SETTINGS_USERS                         = "users";
export const CRM_SETTINGS_LANGUAGES                     = "languages";

export const VIEW                                       = "view";
export const CREATE                                     = "create";
export const UPDATE                                     = "update";
export const DELETE                                     = "delete";
export const IMPORT                                     = "import";
export const EXPORT                                     = "export";