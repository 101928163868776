import React from 'react';
import styled from 'styled-components';
import colors from '../../../../config/theme/colors';
import { Grid, Box } from '@material-ui/core';
import Typography from '../../typography/Typography'
import Button from '../../../ui/button/Button';
import { withRouter } from 'react-router';
import imgNotFound from '../../../../../src/assets/images/not-found.png';

const SpanColor = styled.span`
  color: ${props => props.colorhover};
  font-weight: bold;
`
const BoxCustom = styled(Box)`
    &:hover{
        background: ${colors.grey.lighter.hue980};
        transition: all 150ms cubic-bezier(0.4,0,0.2,1) 0ms;
        h4{
            color: ${props => props.colorhover};
        }
    }
`;
const Title = styled(Typography)`
    {
        color: ${props => props.colortext};
    }
`;
const Description = styled(Typography)`
    {
        color: ${props => props.colortext};
    }
`;


class CardDrawerBuilder extends React.Component{  
    constructor(props){
        super(props)
        this.state = {
            tabState: 0,
        }
    }  
    handleChange = (event, value) => {
        this.setState({ tabState: value });
    };

    handleClickConnect = (id,idType) => {
        this.props.handlerSetup(id, idType)
    }

    componentDidMount(){
        this.setState({
            tabState: 0,
        });
    }

    render() {
        return (
            <div style={{paddingTop: 48}}>
                {
                this.props.dataCard.length > 0 ?
                    this.props.dataCard.map((child, index) =>  {
                        let image;  
                        if(child.isLocaleImage){
                            const imagesFolder = require.context('../../../../assets/images', true);
                            try{
                                image = imagesFolder('./' + child.logo);
                                image = typeof image === "string" ? image : image?.default;
                            } catch(e){
                                image = imgNotFound;    
                            }                        
                        }
                        return(
                            <BoxCustom 
                            index={index} 
                            key={`FormBuilder${index}`} 
                            colorhover={child.colorhover} 
                            mb={2} 
                            p={2} 
                            // onClick={child.onClick ? child.onClick : () => this.handleClickConnect(child.id)}
                            >
                            <Grid container>
                                <Grid item xs={8}>
                                    <Grid container alignItems={'center'} style={{height: '100%'}}>
                                        <Grid item xs={2}>
                                            <img src={image ? image : child.picto ? child.picto : child.logo ? `${child.customLogo ? process.env.REACT_APP_MEDIAS : process.env.REACT_APP_API_ROOT}/${child.logo}` : imgNotFound} width={"100%"} style={{maxWidth: 80, maxHeight: 80, filter: child.secondaryStyle ? 'grayscale(1)' : 'none', opacity: child.secondaryStyle ? '0.3' : '1'}} alt={child.libelle} />
                                        </Grid>
                                        <Grid item xs={10}>
                                            <Box ml={2}>
                                                {child.bicoloreText ? 
                                                    <Typography variant={"h4"} style={{fontWeight: "bold"}}>
                                                        {child.libelle} <SpanColor colorhover={child.colorhover}>{child.bicoloreText}</SpanColor>
                                                    </Typography>
                                                :
                                                    <Title variant={"h4"} style={{fontWeight: "bold"}} colortext={child.secondaryStyle ? colors.grey.lighter.hue600 : colors.blue.regular}>
                                                        {child.libelle}
                                                    </Title>   
                                                }
                                                <Box mt={1}>
                                                    {
                                                        child.isTextArea ?
                                                            <Description variant={"body2"} 
                                                                colortext={child.secondaryStyle ? colors.grey.lighter.hue600 : colors.grey.regular} 
                                                                dangerouslySetInnerHTML={{__html: child.secondaryText ? child.isActive ? child.secondaryText + ' ' + child.libelle : child.secondaryText : child.description}} 
                                                            />
                                                        : 
                                                            <Description variant={"body2"} colortext={child.secondaryStyle ? colors.grey.lighter.hue600 : colors.grey.regular}>
                                                                {child.secondaryText ? child.isActive ? child.secondaryText + ' ' + child.libelle : child.secondaryText : child.description}
                                                            </Description>
                                                    }
                                                </Box>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={4}>
                                    <Grid container justify={"center"} alignItems={"center"} style={{height: "100%"}}>
                                        <Button 
                                            text={child.textButton ? child.textButton : `Se connecter`} 
                                            bgcolor={child.secondaryStyle ? 'transparent' : colors.white} 
                                            color={child.secondaryStyle ? colors.blue.lighter.hue300 : colors.green.regular} 
                                            colorhover={child.secondaryStyle ? colors.blue.lighter.hue300 : colors.white} 
                                            bgcolorhover={child.secondaryStyle ? 'unset' : colors.green.regular} 
                                            bgColor="transparent" 
                                            disableElevation={true} 
                                            border={child.secondaryStyle ? 'none' : `1px solid ${colors.green.regular}`} 
                                            arrow="right" 
                                            disabled={child.disabled}
                                            onClick={child.onClick ? child.onClick : () => this.handleClickConnect(child.id, child.attributeGroup.id)}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </BoxCustom>
                        )
                    })
                :   
                    <Grid container direction="column" justify="center" alignItems="center">
                        <Grid>
                            <Typography variant={"h2"} colortext={ colors.red.regular }>{this.props.dataCard.textNoData}</Typography>
                        </Grid>
                        <Grid>
                            <Button 
                                text={this.props.dataCard.textButtonNoData ? this.props.dataCard.textButtonNoData : `Voir`} 
                                bgcolor={colors.white} 
                                color={colors.green.regular} 
                                colorhover={colors.white} 
                                bgcolorhover={colors.green.regular} 
                                bgColor="transparent" 
                                disableElevation={true} 
                                border={`1px solid ${colors.green.regular}`} 
                                arrow="right" 
                                onClick={this.props.dataCard.onClickNoData ? this.props.dataCard.onClickNoData : null}
                            />
                        </Grid>
                    </Grid>
                }
            </div>
        )
    }
    goTo = route => {
        this.props.history.push(route);
    };
}
export default withRouter(CardDrawerBuilder);