import { CARD, FORM } from "../../../../../js/constants/form-types";
import { getDeviceZoom } from "../../../../../js/utils/getDeviceZoom";
import OurButton from "../../../../ui/button/Button";
import ProductListing from './productListing.config.js'

export const exportComptaConfig = (state) => ({
    titleForm: "Export comptabilité",
    subTitleForm: 'Veuillez compléter les champs ci-dessous pour créer votre export',
    langSelect: false,
    drawerType: 'drawer',
    component: OurButton,
    formConfig: {
        type: FORM,
        children:[
            {
                optionsInputs:[
                {
                    type: 'text',
                    label: 'Nom du fichier',
                    helper: {
                        text: "Indiquer le nom du fichier d'export ici",
                        link: false,
                    },
                    required: true,
                    stateName: 'fileName',
                },
                {
                    type: 'date',
                    label: 'Date de début',
                    helper: {
                        text: "Indiquer la date de début ici",
                        link: false,
                    },
                    required: true,
                    stateName: 'startDate',
                },
                {
                    type: 'date',
                    label: 'Date de fin',
                    helper: {
                        text: "Indiquer la date de fin ici",
                        link: false,
                    },
                    required: true,
                    stateName: 'endDate',
                },
                {
                    type: 'select',
                    label: 'Source',
                    helper: {
                        text: "Indiquer la source ici",
                        link: false,
                    },
                    required: true,
                    stateName: 'origin',
                    value:[
                        { value: 'all', label: 'Toutes les sources' },
                        { value: 'B2C', label: 'Site officiel' },
                        { value: 'MP', label: 'Marketplace' },
                    ]
                },
                state.origin === 'MP' && {
                    type: 'select',
                    label: 'Marketplace',
                    helper: {
                        text: "Indiquer la marketplace ici",
                        link: false,
                    },
                    required: false,
                    stateName: 'marketPlaceName',
                    value: state.marketplaceschoices
                },
                {
                    type: 'select',
                    label: 'Status',
                    helper: {
                        text: "Indiquer le statut des commandes ici",
                        link: false,
                    },
                    required: true,
                    stateName: 'statusExport',
                    value:[
                        {
                            value : "all",
                            label : "Tous les statuts",
                        },
                        {
                            value : "shipped",
                            label : "Expédié",
                        },
                        {
                            value : "waiting",
                            label : "En attente",
                        },
                        {
                            value : "accepted",
                            label : "Accepté",
                        },
                        {
                            value : "avoir",
                            label : "Avoir",
                        },
                        {
                            value : "actionRequired",
                            label : "Action requise",
                        },
                        {
                            value : "refused",
                            label : "Refusé",
                        },
                        {
                            value : "closed",
                            label : "Fermé",
                        },
                    ]
                },
            ]}
        ]
    }
})
