import React from 'react';
import { Box, Grid, Typography, Tooltip } from '@material-ui/core';
import Button from '../../../ui/button/Button';
import styled from 'styled-components';
import _ from 'lodash';

const ContainerCard = styled(Box)`
    display: block;
    height: 100%;
    padding: 16px;
`

export default function CardGroupRate(props){
    return(
        <ContainerCard>
            <Grid container style={{position: 'relative', height: '100%'}}>
                <Grid container>
                    <Typography variant="h3">{props.data.node.code}</Typography>
                </Grid>
                <Grid container style={{marginTop: 8}}>
                    <Typography variant="body2">Devise : {props.data.node.currency.libelle}</Typography>
                </Grid>
                <Grid container justify={"flex-end"}>
                    <Button onClick={() => props.handlerButton(props.data.node)} text={props.textButton}></Button>
                </Grid>
            </Grid>
        </ContainerCard>
    );
}