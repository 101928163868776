import { Grid } from '@material-ui/core';
import React from 'react';
import TopPanel from '../TopPanel/TopPanel';
import colors from '../../../config/theme/colors';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import ItemContainer from './ItemContainer';

const BackLink =  styled.div`
    color : ${colors.blue.lighter.hue300};
    cursor:pointer;
    &:hover{
        text-decoration:underline;
    }
`
export default function ContentContainer(props){
    const history = useHistory();

    let defaultParams={
        colorIcomoon : colors.blue.lighter.hue300,
        gradientColor1:colors.menu.regular,
        gradientColor2:colors.menu.darker,
        hasBorder:true,
        buttonAvailable:true
    }
    const goBack = () =>{
        history.goBack();
    }
    
    const showBackLink = props.showBackLink ?? false;
    let topSettings = {...defaultParams,...props.headerSettings}
    return(
        <>
            {props.headerSettings ? <TopPanel {...topSettings}/> : null}
            {showBackLink ? 
                <Grid container style={{padding: 32}}>
                    <BackLink onClick={goBack}>
                        {`< Retour`}
                    </BackLink>                    
                </Grid>

            : null}
            <Grid container style={{padding: 32}}>
                {props.isItem ? 
                    <ItemContainer {...props.itemProps}>{props.children}</ItemContainer>
                : props.children
                
                }
            </Grid>
        </>

    )
}