import {FORM} from '../../../../../js/constants/form-types';
import OurButton from '../../../../ui/button/Button';

const productDetailConfig = (locations)=> {
    return{
        titleForm: "Modifier le stock",
        subTitleForm: "Veuillez compléter les champs ci-dessous pour modifier le stock",
        langSelect: false,
        drawerType: 'drawer',
        noForm: true,
        component: OurButton,
        formConfig: {
            type: FORM,
            children: [
                {
                    labelName:"Mise à jour du stock",
                    isOptionnal: false,
                    optionsInputs: [
                        {
                            type: 'number',
                            label: 'Quantité',
                            helper: {
                                text: 'Quantité de produits',
                                link: false,
                            },
                            required: true,
                            stateName: 'quantity',
                            translated: false,
                        },
                        {
                            type: 'select',
                            label: 'Zone',
                            helper: {
                                text: 'Zone du stock',
                                link: false,
                            },
                            required: true,
                            stateName: 'location',
                            translated: false,
                            value: 
                            locations.map(e => {
                                return(
                                    {
                                        value : e.node.id,
                                        label : e.node.code,
                                        disabled : !e.node.isActive,
                                    }
                                )
                            })
                        }
                ]
                },
            ]
        }
}};

export default productDetailConfig;