import React from 'react';
import Chip from '@material-ui/core/Chip';
import styled from 'styled-components';
import colors from '../../../../../config/theme/colors'

const Div = styled.div`
    display: flex;
    align-items: center;
    p{
        margin: 0;
        margin-right: 16px;
        color: ${colors.blue.regular};
    }
    &>div{
        margin-right: 10px;
        &:last-of-type{
            margin-right: 30px;
        }
    }
`;

export default function ChipMedias(props){
    const categories=props.categories;
    const [value, setValue] = React.useState(props.defaultTab?props.defaultTab:0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return(
        <Div>   
            <p>Filtrer par :</p>
            {categories.map((categorie,index)=>
                categorie ? 
                    <Chip key={`ChipMedia${index}`} color="primary" variant={value === index ? 'default' : 'outlined'} label={categorie.libelle} onClick={(event) => {handleChange(event, index); props.handleChangeTab(categorie.type);}}></Chip>
                : null
            )}
        </Div> 
    )
}