import React from "react";
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { connect } from "react-redux";
import * as moment from "moment";
import { START_LOADING, STOP_LOADING, SNACK } from '../../../js/constants/action-types';
import { ALERT_SUCCESS, ALERT_INFO, ALERT_WARNING, ALERT_ERROR } from "../../../js/constants/alert-types";
import Button from "../../ui/button/Button";
import './Demo.scss';
import colors from '../../../config/theme/colors';
import TopPanel from '../../layouts/TopPanel/TopPanel';

function Demo(props) {
    const [expanded, setExpanded] = React.useState('button');

    const handleChange = panel => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (
        <div className="demo">
            <h1>Démonstration des composants</h1>

            <ExpansionPanel expanded={expanded === 'button'} onChange={handleChange('button')}>
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} className="panel-summary">
                    Bouton
                </ExpansionPanelSummary>
                <ExpansionPanelDetails className="panel-details">
                    <pre>
                        <code>
                            &lt;Button text=[string] bgcolor=[string] onClick=[callback] disabled /&gt;
                        </code>
                    </pre>

                    <Button text="Bouton désactivé" disabled />
                    <Button text="Bouton par défaut" />
                    <Button text="Bouton couleur spécifique" bgcolor={colors.blue.lighter.hue300} bgcolorhover={colors.blue.regular} />
                    <Button text="Bouton élévation" bgcolor={colors.green.regular} bgcolorhover={colors.green.darker} shadowcolor={colors.green.darker} />
                    <Button text="Grosse alerte" bgcolor={colors.red.regular} bgcolorhover={colors.red.darker} onClick={() => alert('Hello')} />
                </ExpansionPanelDetails> 
            </ExpansionPanel>

            <ExpansionPanel expanded={expanded === 'loading-overlay'} onChange={handleChange('loading-overlay')}>
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} className="panel-summary">
                    LoadingOverlay
                </ExpansionPanelSummary>
                <ExpansionPanelDetails className="panel-details">
                    <p>Utilisé une fois dans l'app à la racine. Utilise le state 'loading' du store.</p>
                    <pre>
                        <code>
                            &lt;LoadingOverlay /&gt;
                        </code>
                    </pre>

                    <Button text="Afficher" onClick={() => {
                        props.startLoading();
                        setTimeout(props.stopLoading, 3000);
                    }} />
                </ExpansionPanelDetails> 
            </ExpansionPanel>

            <ExpansionPanel expanded={expanded === 'snack'} onChange={handleChange('snack')}>
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} className="panel-summary">
                    Snack
                </ExpansionPanelSummary>
                <ExpansionPanelDetails className="panel-details">
                    <p>Utilisé une fois dans l'app à la racine. Utilise le state 'snacking' du store.</p>
                    <p>Utiliser la méthode <code>snack(type, message) du store.</code></p>
                    <pre>
                        <code>
                            &lt;Snack message=[snackMsg] type=[snackType] open=[snacking] onClose=[callback] /&gt;
                        </code>
                    </pre>

                    <Button text="Succès" bgColor="#4CAF50" onClick={() => {
                        props.snack(ALERT_SUCCESS, `GG mon pote !`);
                    }} />

                    <Button text="Erreur" bgColor="#F44336" onClick={() => {
                        props.snack(ALERT_ERROR, `Kesta foutu ?!`);
                    }} />

                    <Button text="Warning" bgColor="#FF9800" onClick={() => {
                        props.snack(ALERT_WARNING, `Fais attention à ton stockage !`);
                    }} />

                    <Button text="Info" bgColor="#2196F3" onClick={() => {
                        props.snack(ALERT_INFO, `Nous sommes le ${moment().format('LL')}`);
                    }} />
                </ExpansionPanelDetails> 
            </ExpansionPanel>

            <ExpansionPanel expanded={expanded === 'colorsPalette'} onChange={handleChange('colorsPalette')}>
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} className="panel-summary">
                    Colors Palette
                </ExpansionPanelSummary>
                <ExpansionPanelDetails className="panel-details" style={{
                    flexDirection: 'column'
                }}>
                    <p>Palette de couleurs</p>
                    <div>
                        <Button className="palette_button" text="colors.grey.regular" bgcolor={colors.grey.regular} />
                        <Button className="palette_button" text="colors.grey.lighter.hue600" bgcolor={colors.grey.lighter.hue600} />
                        <Button className="palette_button" text="colors.grey.lighter.hue800" bgcolor={colors.grey.lighter.hue800} />
                        <Button className="palette_button" text="colors.grey.lighter.hue900" bgcolor={colors.grey.lighter.hue900} color={colors.grey.regular} />
                        <Button className="palette_button" text="colors.grey.lighter.hue980" bgcolor={colors.grey.lighter.hue980} color={colors.grey.regular} />
                    </div>
                    <div>
                        <Button className="palette_button" text="colors.blue.regular" bgcolor={colors.blue.regular} />
                        <Button className="palette_button" text="colors.blue.lighter.hue300" bgcolor={colors.blue.lighter.hue300} />
                        <Button className="palette_button" text="colors.blue.lighter.hue600" bgcolor={colors.blue.lighter.hue600} />
                        <Button className="palette_button" text="colors.blue.lighter.hue900" bgcolor={colors.blue.lighter.hue900} color={colors.blue.regular} />
                    </div>
                    <div>
                        <Button className="palette_button" text="colors.green.regular" bgcolor={colors.green.regular} />
                        <Button className="palette_button" text="colors.green.darker" bgcolor={colors.green.darker} />
                    </div>
                    <div>
                        <Button className="palette_button" text="colors.red.regular" bgcolor={colors.red.regular} />
                        <Button className="palette_button" text="colors.red.darker" bgcolor={colors.red.darker} />
                    </div>
                    <div>
                        <Button className="palette_button" text="colors.orange.regular" bgcolor={colors.orange.regular} />
                        <Button className="palette_button" text="colors.orange.dark" bgcolor={colors.orange.darker} />
                    </div>
                    <div>
                        <Button className="palette_button" text="colors.menu.regular" bgcolor={colors.menu.regular} />
                        <Button className="palette_button" text="colors.menu.darker" bgcolor={colors.menu.darker} />
                    </div>
                    <div>
                        <Button className="palette_button" text="colors.white" bgcolor={colors.white} color={colors.black.regular} />
                        <Button className="palette_button" text="colors.black.regular" bgcolor={colors.black.regular} />
                    </div>
                </ExpansionPanelDetails>
            </ExpansionPanel>

            <ExpansionPanel expanded={expanded === 'topPanel'} onChange={handleChange('topPanel')}>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} className="panel-summary">
                    TopPanel
                </ExpansionPanelSummary>
                <ExpansionPanelDetails className="panel-details" style={{
                    flexDirection: 'column'
                }}>
                    <TopPanel icomoon="picto-connector" colorIcomoon={colors.blue.lighter.hue300} title="Titre" subtitle="Sous-titre de description" gradientColor1={colors.menu.regular} gradientColor2={colors.menu.darker} />
                    <code>
                        &lt;TopPanel title=[string] subtitle=[string] icomoon=[string] colorIcomoon=[color] gradientColor1=[color] gradientColor2=[color] /&gt;
                    </code>
                    <TopPanel 
                        icomoon="picto-connector"
                        colorIcomoon={colors.blue.lighter.hue300}
                        title="Titre" subtitle="Sous-titre de description" 
                        gradientColor1={colors.menu.regular} 
                        gradientColor2={colors.menu.darker} 
                        handlerAdd={''} 
                        textAdd={'Ajouter'} 
                        
                    />
                </ExpansionPanelDetails>
            </ExpansionPanel>
        </div>  
    );
};

const mapDispatchToProps = dispatch => {
    return {
        startLoading: () => dispatch({ type: START_LOADING }),
        stopLoading: () => dispatch({ type: STOP_LOADING }),
        snack: (type, message) => dispatch({ type: SNACK, payload: { type, message }})
    }
}

const mapStateToProps = state => {
    return {
        loading: state.loading
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Demo);
