import { STEPPER } from "../../../../../js/constants/form-types";
import OurButton from "../../../../ui/button/Button";
import Mapping from "../../../../ui/inputs/Mapping";
import UploadFile from "../../../../ui/inputs/UploadFile";


const stocksFormConfig =  {
    titleForm: `Importer des contenus`,
    subTitleForm: 'Veuillez compléter les champs ci-dessous pour importer vos contenus',
    langSelect: false,
    drawerType: 'drawer',
    noForm: true,
    component: OurButton,
    formConfig: {
        type: STEPPER,
        finalStep: {
            title: 'Bientôt prêt !',
            subtitle: 'Votre fichier est prêt à être importé :)',
            textButton: 'Importer le fichier'
        },
        children: [
            {
                labelName: 'Choix du fichier',
                isOptionnal: false,
                component: UploadFile,
                typeUpload:'csv',
                pictos:'csv',
                optionsInputs: [{
                    type: 'uploadFile',
                    translated: false,
                    required: true,
                    typeUpload: "csv",
                    stateName: `upload_ZIP`,
                    multipleStates: true,
                    noMapping: true
                }]
            }
        ]
    }
};

export default stocksFormConfig;