import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Grid} from '@material-ui/core';
import Typography from '../../../ui/typography/Typography';
import styled from 'styled-components';

const GridCustom = styled(Grid)`
    height: 300px;
`;

const PageLoader = (props) => {
    return (
        <GridCustom container justify='center' direction='column' alignItems='center' style={props.style ? props.style : null}>
            <CircularProgress color="inherit" size={'5rem'} 
            style={{padding: 0}}
            />
            <Typography variant="h3" style={{marginTop:32,textAlign:'center',width:'100%'}}>Chargement en cours...</Typography>
        </GridCustom>
    );
};
export default PageLoader;