import React from 'react';
import { Grid } from '@material-ui/core';

import { withRouter } from 'react-router';
import { connect } from "react-redux";
import {ROUTE_HOME } from '../../../../js/constants/route-names';
import colors from '../../../../config/theme/colors';
import { withApollo } from 'react-apollo';

import { hasRights } from '../../../../js/utils/rights';
import { START_LOADING, STOP_LOADING, SNACK } from '../../../../js/constants/action-types';
import { ALERT_SUCCESS, ALERT_ERROR } from '../../../../js/constants/alert-types';
import { PRODUCTS, PRODUCTS_PRODUCTS, VIEW, CREATE, IMPORT, EXPORT } from '../../../../js/constants/constant-rights';
import '../../../navigation/DrawerLeft.scss';
import PageContainer from '../../../layouts/Container/PageContainer';
import ContentContainer from '../../../layouts/Container/ContentContainer';
import Listing from '../../../layouts/Listing/Listing';
import productListingConfig from './config/productListing.config';
import { getElements } from '../../../../js/utils/functions';

class ListProducts extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            currentLang: props.locales[0].node.code,

        }
        this.errors = {}
    }

    getLocations = async() =>{
        let locations = await getElements('locations');
        console.log(locations)
        this.setState({locations : locations.data.locations})
    }

    componentDidMount() {
        const getRights = hasRights(PRODUCTS, PRODUCTS_PRODUCTS, VIEW)
        if (!getRights){
            this.props.snack(ALERT_ERROR, `Vous n'avez pas les droits suffisants pour accéder à cette page`);
            this.goTo(ROUTE_HOME);
        }
        this.getLocations()
    }
    render(){
        let {listSettings,listMappers,perPageOptions,searchSettings} = productListingConfig({
            locations : this.state.locations
        });
        
        return(
            <PageContainer>
                <ContentContainer
                    headerSettings={{
                        icomoon:"picto-produit",
                        title:'Liste des produits',
                        subtitle:'Liste de vos produits',
                        windowWidth:this.props.windowWidth,
                        buttonAvailable:false
                    }}
                >
                    <Listing
                        label = 'produits'
                        settings = {listSettings}
                        cardContainerProps = {{
                            style:{height:'100%'}
                        }}
                        perPageOptions = {perPageOptions}
                        mappers = {listMappers}
                        currentLang = {this.state.currentLang}
                        identifier = 'productLites'
                        searchSettings={searchSettings}
                        viewsOptions = {{
                            current : 'card',
                            settings : ['card'] //Si il n'y a qu'un seul item il n'y aura pas de changement de vues
                        }}
                    />

                    
                </ContentContainer>
            </PageContainer>
        )
    }


}

const mapStateToProps = state => {
    return {
        loading: state.loading,
        products: state.products,
        locales: state.locales,
        attributes: state.attributes
    };
};

const mapDispatchToProps = dispatch => {
    return {
        startLoading: () => dispatch({ type: START_LOADING }),
        stopLoading: () => dispatch({ type: STOP_LOADING }),
        snack: (type, message) => dispatch({ type: SNACK, payload: { type, message }})
    }
};

export default withApollo(withRouter((connect(mapStateToProps, mapDispatchToProps)(ListProducts))));