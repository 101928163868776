import React from 'react';

import { Grid } from '@material-ui/core';
import Button from '../../../../ui/button/Button';
import colors from '../../../../../config/theme/colors';

import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

import { withApollo } from 'react-apollo';
import { useMutation } from '@apollo/react-hooks';
import { GET_TAXRATES, ADD_TAXRATE, UPDATE_TAXRATE } from '../../../../../queries/tax_rates';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function ValidateTaxes(props){
    const newTaxes = props.newTaxes;
    const changesTaxes = props.changesTaxes;

    const [open, setOpen] = React.useState(false);
    const [textSnack, setTextSnack] = React.useState('La mise à jour des taxes s\'est déroulée avec succès');
    const [typeSnack, setTypeSnack] = React.useState('success');

    const [addTax] = useMutation(ADD_TAXRATE, {refetchQueries: [{query: GET_TAXRATES}]});
    const [updateTax] = useMutation(UPDATE_TAXRATE, {refetchQueries: [{query: GET_TAXRATES}]});

    const handleClick = () => {
        setOpen(true);
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };
    
    const save = () => {
        setOpen(false);
        var validate = true;
        for (let tax of newTaxes) {
            if(tax.code && tax.rate && tax.countryCode && validate === true){
                addTax({ variables: { "code": tax.code, "rate": parseFloat(tax.rate), "countryCode": tax.countryCode, "rateGroup": tax.rateGroup === "" ? null : tax.rateGroup } }).then(result => {
                    props.handleResetData();
                })
                setTextSnack('La mise à jour des taxes s\'est déroulée avec succès');
                setTypeSnack('success');
            }else{
                setTextSnack('Tous les champs sont requis');
                setTypeSnack('error');
                validate = false;
            }
            handleClick();
        }
        for (let tax of changesTaxes) {
            if(tax.data.code && tax.data.rate && tax.data.countryCode && validate === true){
                updateTax({ variables: { "id": tax.id,  "code": tax.data.code, "rate": parseFloat(tax.data.rate), "countryCode": tax.data.countryCode, "rateGroup": tax.data.rateGroup === "" ? null : tax.data.rateGroup}}).then(result => {
                    props.handleResetData();
                });
                setTextSnack('La mise à jour des taxes s\'est déroulée avec succès');
                setTypeSnack('success');
            }else{
                setTextSnack('Tous les champs sont requis');
                setTypeSnack('error');
                validate = false;
            }
            handleClick();
        }
    }

    return(
        <>
            <Grid container direction="column" style={{alignItems: "flex-end", paddingTop: 16}} spacing={0}>
                <Grid item>
                    <Button bgcolor={colors.blue.lighter.hue300} shadowcolor={colors.blue.regular} onClick={save} disabled={props.validate ?  false : true}>Sauvegarder</Button>
                </Grid>
            </Grid>
            <Snackbar open={open} autoHideDuration={10000} onClose={handleClose}>
                <Alert onClose={handleClose} severity={typeSnack}>
                    {textSnack}
                </Alert>
            </Snackbar>
        </>
    );
}

export default withApollo(ValidateTaxes);