import { gql } from 'apollo-boost';



//----- Récupération des contents
//--- FILTRES exclusion contents variantes : contents (exists:{parent:false}){
export const GET_CONTENTS = gql`
{
  contents (first: 50){
    edges{
      node{
        id
        sku
        status
        attributeGroup {
          id
          identifier
        }
        contentCategories{
          edges{
            node{
              id
              libelle
              contentCategoryDatas{
                edges{
                  node{
                    value
                    attribute{
                      identifier
                    }
                    media{
                      filePath
                    }
                    locale{
                      code
                    }
                  }
                }
              }
              catalog{
                id
              	libelle
              }
            }
          }
        }
        contentDatas{
          edges{
            node{
              id
              value
              attributeOption{
                id
                identifier
              }
              attribute{
                identifier
                isSystem
              }
              locale{
                code
              }
              media{
                id
                filePath
                type
                size
                category{
                  edges{
                    node{
                      id
                      libelle
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
`;

export const GET_CONTENTS_CURSOR = gql`
{
  contents (first: 10000, exists:{parent:false}){
    edges{
      cursor
    }
  }
}
`;

export const GET_CONTENTS_PAGINATION = gql`
query contents ($nbperpage: Int, $cursor: String, $skulist: [String]){
  contents (first: $nbperpage, after: $cursor, sku_list: $skulist, exists:{parent:false}){
    edges{
      cursor
      node{       
        id
        sku
        status
        parent{
          id
        }
        attributeGroup {
          id
          identifier
        }
        contentCategories{
          id
          libelle
          contentCategoryDatas{
            edges{
              node{
                value
                attribute{
                  identifier
                }
                media{
                  filePath
                }
                locale{
                  code
                }
              }
            }
          }
          catalog{
            id
            libelle
          }
        }
        contentDatas{
          edges{
            node{
              id
              value
              attributeOption{
                id
                identifier
              }
              attribute{
                identifier
                isSystem
              }
              locale{
                code
              }
              media{
                id
                filePath
                type
                size
                category{
                  edges{
                    node{
                      id
                      libelle
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    totalCount
  }
}
`;

export const GET_CONTENTS_TOTAL = gql`
{
  contents{
    totalCount
  }
}
`;

export const SEARCH_CONTENTS = gql`
query contentDataSearch ($attribute: String, $needle: String) {
  contentDatas(attribute: $attribute, value: $needle) {
    edges {
      node {
        id
        value
        content {
          id
          sku
          status
          parent{
            id
          }
          contentCategories {
            id
            libelle
            contentCategoryDatas {
              edges {
                node {
                  value
                  attribute {
                    identifier
                  }
                  media {
                    filePath
                  }
                  locale {
                    code
                  }
                }
              }
            }
            catalog {
              id
              libelle
            }
          }
          contentDatas {
            edges {
              node {
                value
                id
                attributeOption {
                  id
                  identifier
                  translation {
                    translationDatas {
                      edges {
                        node {
                          locale {
                            id
                            code
                          }
                          value
                        }
                      }
                    }
                  }
                }
                attribute {
                  id
                  identifier
                  isSystem
                  translation {
                    id
                    translationDatas {
                      edges {
                        node {
                          id
                          value
                          locale {
                            id
                            code
                          }
                        }
                      }
                    }
                  }
                  attributeType {
                    id
                    input
                  }
                }
                media {
                  id
                  filePath
                  type
                  size
                  category {
                    edges {
                      node {
                        id
                        libelle
                      }
                    }
                  }
                }
                locale {
                  id
                  code
                }
              }
            }
          }
        }
      }
    }
  }
}
`;

export const SEARCH_CONTENTS_SKU = gql`
query contentDataSearch ($attribute: String, $needle: String) {
  contentDatas(attribute: $attribute, value: $needle) {
    edges {
      node {
        content {
          id
          sku
        }
      }
    }
  }
}`;

export const SEARCH_CONTENTS_SKU_BY_CAT = gql`
query contentCategories($idlist: [Int]){
  contentCategories(id_list: $idlist) {
    id
    contents {
      edges{
        node{
          id
          sku
        }
      }
    }
  }
}
`;

//----- Récupération des contents pour le sélecteur
export const GET_SELECT_CONTENTS = gql`
{
  contents(first: 50){
    edges{
      node{
        id
        sku
        contentCategories{
          id
          libelle
          contentCategoryDatas{
            edges{
              node{
                value
                attribute{
                  identifier
                }
                media{
                  filePath
                }
                locale{
                  code
                }
              }
            }
          }
          catalog{
            id
            libelle
          }
        }
        contentDatas{
          edges{
            node{
              value
              attribute{
                identifier
              }
              media{
                filePath
              }
              locale{
                code
              }
            }
          }
        }
      }
    }
  }
}
`;

//----- Récupération des données d'un content
/*
 Exemple de variables
  {
    "id" : "/api/contents/1"
  }
*/
export const GET_CONTENT_DATA = gql`
query contentDatas($id: ID!) {
  content(id: $id) {
    id
    sku
    status
    parent{
      id
    }
    attributeGroup{
      id
    }
    contentCategories{
      id
      libelle
      contentCategoryDatas{
        edges{
          node{
            id
            value
            attribute{
              id
              identifier
              attributeType {
                id
                input
              }
            }
            locale{
              id
              code
            }
          }
        }
      }
      catalog{
        id
        libelle
      }
    }
    contentDatas{
      edges{
        node{
          value
          id
          attributeOption{
            id
            identifier
            translation{
              translationDatas{
                edges{
                  node{
                    locale{
                      id
                      code
                    }
                    value
                  }
                }
              }
            }
          }
          attribute{
            id
            identifier
            isSystem
            translation{
              id
              translationDatas{
                edges{
                  node{
                    id
                    value
                    locale{
                      id
                      code
                    }
                  }
                }
              }
            }
            attributeType {
              id
              input
            }
          }
          media{
            id
            filePath
            type
            size
            category{
              edges{
                node{
                  id
                  libelle
                }
              }
            }
          }
          locale{
            id
            code
          }
        }
      }
    }
  }
}
`;

//----- Ajouter un content
/*
Exemple de variables
  {
    "sku" : "Contenu de démo",
    "attributeGroup" : "/api/attribute-groups/150",
    "contentCategories": [
      "/api/content-categories/2"
    ],
    "createdAt": "2020-08-27",
    "updatedAt": "2020-08-27",
    "status": true
  }
*/
export const ADD_CONTENT = gql`
mutation AddContent($sku: String!, $attributeGroup: String!,  $contentCategories: [String], $createdAt: String!, $updatedAt: String!, $status: Boolean!){
	createContent(input: {sku:$sku, attributeGroup:$attributeGroup, contentCategories:$contentCategories, createdAt:$createdAt, updatedAt:$updatedAt, status:$status}){
    content{
      id
      sku
      attributeGroup{
        id
      }
      contentCategories {
        id
      }
    }
  }  
}`;
  
//----- Modifier un content
/*
Exemple de variables
  {
    "id" : "/api/contents/1",
    "attributeGroup" : "/api/attribute-groups/150",
    "contentCategories": [
      "/api/content-categories/2"
    ],
    "status": false
  }
*/
export const UPDATE_CONTENT = gql`
mutation UpdateContent($id: ID!, $sku: String, $attributeGroup: String,  $contentCategories: [String], $status: Boolean){
	updateContent(input: {id: $id, sku:$sku, attributeGroup:$attributeGroup, contentCategories:$contentCategories, status:$status}){
    content{
      id
      sku
      attributeGroup{
        id
      }
      contentCategories {
        id
      }
    }
  }  
}`;

//----- Supprimer un content
/*
Exemple de variables
  {
    "id" : "/api/contents/1"
  }
*/
export const DELETE_CONTENT = gql`
mutation DeleteContent($id: ID!){
	deleteContent(input: {id:$id}){
    content{
      id
    }
  }  
}`;


//--------------------
//----- DONNEES PRODUITS 
//--------------------

//----- Ajouter une valeur d'attribut à un content

/*
 Exemple de variables
 {
  "value": "TEST",
	"content": "/api/contents/1",
  "attribute": "/api/attributes/12", 
	"locale": "/api/locales/2"
}
*/
export const ADD_CONTENT_DATA = gql`
mutation AddContentData($value: String, $content: String!, $attribute: String!, $locale: String, $attributeOption: String, $media: String){
	createContentData(input: {value:$value, content:$content, attribute:$attribute, locale:$locale, attributeOption:$attributeOption, media:$media}){
    contentData{
      id
      value
      content{
        id
      }
      attribute{
        id
      }
      locale{
        id
      }
      media{
        id
      }
      attributeOption{
        id
      }
    }
  }  
}`;


//----- Modifier une valeur d'attribut d'un content
/*
 Exemple de variables
 {
	"id": "/api/content-datas/68",
  "value": "TEST edition",
	"content": "/api/contents/1",
  "attribute": "/api/attributes/12", 
	"locale": "/api/locales/2"
}
*/
export const UPDATE_CONTENT_DATA = gql`
mutation UpdateContentData($id: ID!, $value: String, $content: String!, $attribute: String!, $locale: String, $attributeOption: String, $media: String){
	updateContentData(input: {id:$id, value:$value, content:$content, attribute:$attribute, locale:$locale, attributeOption:$attributeOption, media:$media}){
    contentData{
      id
      value
      content{
        id
      }
      attribute{
        id
      }
      locale{
        id
      }
      media{
        id
      }
      attributeOption{
        id
      }
    }
  }  
}`;

//----- Supprimer une valeur d'attribut d'un content
/*
 Exemple de variables
  {
    "id" : "/api/content-datas/68"
  }
*/
export const DELETE_CONTENT_DATA = gql`
mutation DeleteContentData($id: ID!){
	deleteContentData(input: {id:$id}){
    contentData{
      id
    }
  }  
}`;