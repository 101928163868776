import {getTraductionAttributs} from '../utils/functions';
import { ROUTE_CRM_CONTACTS_SOCIETIES_DETAILS } from '../constants/route-names';
export const companiesGridMapper = (companies)=>{
    return new Promise((resolve,reject)=>{
        let results = companies;
        resolve (results)
    })
}
export const companiesListMapper = (companies,lang) =>{
    return new Promise((resolve,reject)=>{
        let results = companies.map((company,index)=>{
            let attributes = company.node.companyDatas.edges;
            return {
                id : company.node.id,
                libelle : company.node.libelle,
                children : company.node.children.totalCount,
                contact : company.node.customers.totalCount,
                name : getTraductionAttributs('company_name',attributes,lang),
                image: getTraductionAttributs('company_image', attributes, lang,'image')?.filePath,
                type : getTraductionAttributs('company_type', attributes, lang),
                link : {
                    pathname : ROUTE_CRM_CONTACTS_SOCIETIES_DETAILS.replace(':id',company.node.libelle),
                    state : {idCompany : company.node.id}
                } 
            }
        })
        resolve(results);
    })
}