import React from 'react';
import { Box, Grid, TextField } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import styled from 'styled-components';
import colors from '../../../config/theme/colors';

const ContainerSearchBar = styled(Grid)`
    border: 1px solid ${colors.grey.regular};
    border-radius: 5px;
    position: relative;
    .search-icon{
        position:absolute;
        margin: 14px 16px;
        fill: ${colors.grey.lighter.hue600};
        @media screen and (max-width: 1400px){
            margin: 7px 12px;
            width: 0.9em;
            height: 0.9em;
        }
    }
    .enter-icon{
        right: 0;
        position:absolute;
        margin: 9px 16px;
        @media screen and (max-width: 1400px){
            margin: 7px 12px;
            width: 0.9em;
            height: 0.9em;
        }
    }
    input{
        padding: 12px 12px 12px 54px;
        @media screen and (max-width: 1400px){
            font-size: 14px;
            padding: 10px 10px 8px 44px;
        }
    }
    .MuiInput-underline:before, .MuiInput-underline:after{
        display: none;
    }
`

const TextFieldCustom = styled(TextField)`
    input, .MuiSelect-select{
        font-size: 16px;
        padding: 16px 16px 16px 54px;
        @media screen and (max-width: 1450px){
            font-size: 14px;
            padding: 14px 14px 14px 54px;
        }
        @media screen and (max-width: 1280px){
            font-size: 13px;
            padding: 13px 13px 13px 54px;
        }
    }
    & > p{
        margin: 0;
    }
`;


export default function SearchBar(props){
    return(
        <Box component="div" width={'100%'} style={props.style ? props.style : null}>
            <Grid container>
                <Grid item xs={12}>
                    <ContainerSearchBar container direction="row">
                        <SearchIcon className="search-icon"/>
                        <TextFieldCustom
                            id={props.id ? props.id : 'searchBar'}
                            variant="standard"
                            color="secondary"
                            fullWidth
                            onChange={(event) => props.onChange(event, props.type)}
                            name={'searchBar'}
                            type={'text'}
                            value={props.value}
                            placeholder={props.placeholder ? props.placeholder :'Rechercher'}
                            onFocus={props.onFocus ? (event)=>props.onFocus(event) : null}
                        />
                        {/* <KeyboardReturnIcon className="enter-icon"/> */}
                    </ContainerSearchBar>
                </ Grid>      
            </Grid>
        </Box>
    );
}