
import { Grid } from '@material-ui/core';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import InputBuilder from '../../../../ui/form/InputBuilder';
import styled from 'styled-components'
import Button from '../../../../ui/button/Button';
import Typography from '../../../../ui/typography/Typography';
import colors from '../../../../../config/theme/colors'
import SearchIcon from '@material-ui/icons/Search';
import AccordionCustom from '../../../Accordion/AccordionCustom';

const CustomAccordionDetails = styled(AccordionDetails)`
    display : block;
`
export default function     Search(props){
    const {searchValue} = props;
    const handleInput= (event,input) =>{
        const value = event?.target?.value ?? event;
        props.handleSearchInput(value,input);
    }
    const createInputProps = (input) =>{
        return({
            ...input,
            type : input.type ?? 'text',
            label : input.placeholder,
            helper: {
                text: props.helperText,
                link: input.helperLink ?? false,
            },

        })
    }
    return(
        <AccordionCustom title={'Filtres et Recherche'} defaultExpanded={false} custompadding={'20px 20px 20px 20px'} style={{
            marginBottom: '20px',
        }}>
            <div style={{width: '100%'}}>
                <Grid container  spacing={2}>
                    {props.settings.inputs.map(input =>{
                        if(input){
                            let inputProps = input.type === 'spacing' ? null : createInputProps(input)
                            return(
                                <Grid item xs={input.colum ?? 3} style={{paddingTop :0, paddingBottom:0 }}>
                                    {input.type != 'spacing' ? 
                                        <>
                                            <Typography variant="body2" style={{paddingBottom:6, color: colors.black.regular}}>{input.label}</Typography>
                                            <InputBuilder input={inputProps} stateCallback={(event)=>handleInput(event,input)} value={searchValue[input.queryField]}/>                                        
                                        </>
                                    : null}


                                </Grid>
                            )                                
                        } else{
                            return(null)
                        }

                    })}
                </Grid>
                <Grid container justify="flex-end">
                    <Button bgcolor={colors.red.regular} bgcolorhover={colors.red.darker} disabled={!Object.keys(searchValue).length >0} onClick={props.clearSearch} style={{marginRight:10}}>Effacer les filtres</Button>
                    <Button onClick={props.handleSearch} style={{backgroundColor: colors.black.regular}}>Filtrer</Button>
                </Grid>                    
            </div>
        </AccordionCustom>

        // <Accordion style={{marginBottom:20}}>
        //     <AccordionSummary
        //     expandIcon={<ExpandMoreIcon />}
        //     aria-controls="panel1a-content"
        //     id="panel1a-header"
        //     style={{
        //         alignItems:'center',
        //         color:colors.blue.regular,

        //     }}
        //     >
        //         <SearchIcon style={{marginRight:8,fontSize:18}}/>
        //         RECHERCHE
        //     </AccordionSummary>
        //     <CustomAccordionDetails>
        //         <div style={{padding : 20,paddingTop:0}}>
        //             <Grid container  spacing={2}>
        //                 {props.settings.inputs.map(input =>{
        //                     if(input){
        //                         let inputProps = input.type === 'spacing' ? null : createInputProps(input)
        //                         return(
        //                             <Grid item xs={input.colum ?? 3} style={{paddingTop :0, paddingBottom:0 }}>
        //                                 {input.type != 'spacing' ? 
        //                                     <>
        //                                         <Typography variant="body2" style={{paddingBottom:6}}>{input.label}</Typography>
        //                                         <InputBuilder input={inputProps} stateCallback={(event)=>handleInput(event,input)} value={searchValue[input.queryField]}/>                                        
        //                                     </>
        //                                 : null}


        //                             </Grid>
        //                         )                                
        //                     } else{
        //                         return(null)
        //                     }

        //                 })}
        //             </Grid>
        //             <Grid container justify="flex-end">
        //                 <Button bgcolor={colors.red.regular} bgcolorhover={colors.red.darker} disabled={!Object.keys(searchValue).length >0} onClick={props.clearSearch} style={{marginRight:10}}>Effacer les filtres</Button>
        //                 <Button onClick={props.handleSearch}>Filtrer</Button>
        //             </Grid>                    
        //         </div>

                    
        //     </CustomAccordionDetails>
        // </Accordion>
    )
}