import { useEffect } from "react"
import { useState } from "react"
import { useHistory, withRouter } from "react-router"
import { ROUTE_ORDERS_DETAIL, ROUTE_PRODUCTS_LIST_DETAIL } from "../../../../js/constants/route-names"
import { getGlobalStats } from "../../../../js/helpers/stats"
import ContentContainer from "../../../layouts/Container/ContentContainer"
import PageContainer from "../../../layouts/Container/PageContainer"
import Listing from "../../../layouts/Listing/Listing"
import StatsBar from "../../../layouts/StatsBar/StatsBar"
import PageLoader from "../../../ui/loadings/page-loader/PageLoader"
import stocksListingConfig from "./config/listStocks.config"
import LayoutFormBuilder from "../../../ui/form/LayoutFormBuilder"
import stocksFormConfig from "./config/stocksListing.config"
import { connect } from "react-redux"
import { SNACK, START_LOADING, STOP_LOADING } from "../../../../js/constants/action-types"
import { ALERT_ERROR, ALERT_SUCCESS } from "../../../../js/constants/alert-types"
import request from '../../../../js/utils/fetch';
import { CircularProgress } from "@material-ui/core"

const ListStocks = (props) => {
  const history = useHistory();
  const [statsIsLoading, setStatsIsLoading] = useState(true)
  const [typeForm, setTypeForm] = useState('stock')
  const [openDrawer, setOpenDrawer] = useState(false)
  const [state, setState] = useState({
    "upload_CSV":null,
    "media": null,
    "separator":","
})

  
  // const [pickedDate, setPickedDate] = useState(new Date())
  // const [pickedDropdownOption, setPickedDropdownOption] = useState(1)
  const [stats, setStats] = useState([])
  const [lastStockUpdate, setLastStockUpdate] = useState('')

  useEffect(() => {
    setStatsIsLoading(true)
    getGlobalStats('orders', new Date()).then(value => {
      setStatsIsLoading(false)
      setStats(value)
    })

    request(`${process.env.REACT_APP_API}/stock/import/last`).then((value) => {
      if (value['updated_at']) 
        setLastStockUpdate(new Date(value['updated_at']).toLocaleDateString() + ' à ' + new Date(value['updated_at']).toLocaleTimeString())
      else 
        setLastStockUpdate('N/A')
    })
  }, [])

  // // TODO: Put the good dates
  // const dropdownOptions = [
  //     { value: 1, label: 'Uno', date: new Date('1995-12-17T03:24:00') },
  //     { value: 2, label: 'Dos', date: new Date() },
  //     { value: 3, label: 'Tres', date: new Date() },
  // ]

  const { listSettings, listMappers, perPageOptions, searchSettings } = stocksListingConfig()

  const handleToggleDrawer = (stateName) => {
    setOpenDrawer(!openDrawer)
  }

  const goTo = (url, state) => {
      history.push({
          pathname: url._id,
          state: state,
      })
  }

  const stateCallback = (value) => {
    console.log('value.medias.contentUrl',value.medias.contentUrl);
    setState({...state,contentUrl:value.medias.contentUrl })
  }

  const handleInputChange = (stateName,value) => {
    let getState = {...state}
    getState[stateName] = value
    setState(getState)
  }

  const handlerMutation = async () => {
    props.startLoading();

    try {
        await request(`${process.env.REACT_APP_API_ROOT}/api/stock/import`, 'post', {file:state.contentUrl}, undefined, true);
        props.snack(ALERT_SUCCESS, `Votre fichier a été importé avec succès.`, 6000);
        handleToggleDrawer();
        props.stopLoading();
    } catch (e) {
        props.snack(ALERT_ERROR, `L'import a échoué !`);
        props.stopLoading();
    }
  };

  return (
    <PageContainer openForm={false} drawerWidth={props.drawerWidth}>
      <ContentContainer
        headerSettings={{
          icomoon: "picto-produit",
          title: 'Liste des stocks',
          subtitle: 'Vue d’ensemble pour avoir une visualisation de votre stock en temps réel',
          windowWidth: props.windowWidth,
          textAdd: '+ Importer les stocks', 
          handlerAdd: handleToggleDrawer,
          secondaryButton: {
            text: 'Exporter les stocks',
            action: () => {
              window.open(`${process.env.REACT_APP_API}/public/stock`, '_blank').focus();
            }
          },
          
        }}
      >
        <StatsBar 
            data={stats}
            statsIsLoading={statsIsLoading}
            customPadding={'0px'}
            spacing={0}
            // dropdownOptions={dropdownOptions} 
            // selectedOption={pickedDropdownOption} 
            // stateCallback={(e) => {
            //     setPickedDate(dropdownOptions.find(option => option.value === e.target.value).date)
            //     setPickedDropdownOption(e.target.value)
            // }} 
        />
        <h3>Dernière mise à jours des stocks: {lastStockUpdate ? lastStockUpdate : <CircularProgress size={10} />}</h3>
        <Listing
          label='produits'
          settings={listSettings}
          cardContainerProps={{
            style: { height: '100%' }
          }}
          perPageOptions={perPageOptions}
          mappers={listMappers}
          rowAction={goTo}
          // currentLang={this.state.currentLang}
          identifier='productLites'
          searchSettings={searchSettings}
          viewsOptions={{
            current: 'card',
            settings: ['card', 'table'] //Si il n'y a qu'un seul item il n'y aura pas de changement de vues
          }}
          pagination={true}
          // queryVariables={{
          //   warehouse: this.state.warehouse.id
          // }}
          tableProps={{
              textButton : 'Voir le produit',
              action: goTo,
              url: ROUTE_PRODUCTS_LIST_DETAIL
          }}
          cardProps={{
            actionData: goTo,
            // url: ROUTE_PRODUCTS_LIST_DETAIL,
            paths: {
              id: '_id',
              title: 'productName',
              image: 'imageUrl',
              ref: 'sku',
              mainInfo: 'totalStock',
              summary: [
                {
                  label: 'Entrepôt :',
                  path: 'warehousesCount'
                },
                {
                  label: 'Dispo en réel :',
                  path: null
                },
                {
                  label: 'En stock :',
                  path: 'totalStock'
                },
                {
                  label: 'Reservé :',
                  path: null
                },
              ]
            }
          }}
          // listingCallback={this.handleListingCallback}

        />

        <LayoutFormBuilder
                        opened={openDrawer}
                        icomoon={ null }
                        forClose={() => {handleToggleDrawer()}} 
                        dataLayout={stocksFormConfig} 
                        drawerWidth={props.drawerWidth}
                        stateCallback={stateCallback}
                        handleInputChange={handleInputChange}
                        // handleButtonGroupChange={handleButtonGroupChange}
                        stepperButtonAction={[
                            () => {
                                return true;
                            }, 
                            () => {
                                return true;
                            }, 
                        ]}
                        //errorCallback={handleFormError}
                        validateButton={true}
                        handlerMutation={() => {handlerMutation()}}
                        allState={state} 

                        windowWidth={props.windowWidth}
                        isSublayout={false}
                    />

      </ContentContainer>
    </PageContainer>
  )
}

const mapDispatchToProps = dispatch => {
  return {
      startLoading: () => dispatch({ type: START_LOADING }),
      stopLoading: () => dispatch({ type: STOP_LOADING }),
      snack: (type, message) => dispatch({ type: SNACK, payload: { type, message } })
  }
}

const mapStateToProps = state => {
  return {
      loading: state.loading,
      user: state.user,
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ListStocks));