import colors from '../../config/theme/colors';
import Ppt from '../../assets/pictos/file-types/picto_ppt.png';
import Pdf from '../../assets/pictos/file-types/picto_pdf.png';
import Csv from '../../assets/pictos/file-types/picto_csv.png';
import Doc from '../../assets/pictos/file-types/picto_doc.png';
import Xls from '../../assets/pictos/file-types/picto_xls.png';
import Mp4 from '../../assets/pictos/file-types/picto_mp4.png';
import WebM from '../../assets/pictos/file-types/picto_webm.png';
import Mpeg from '../../assets/pictos/file-types/picto_mpeg.png';
import NotFound from '../../assets/images/not-found.png';


export const getFileConfig = (type) => {
    switch(type){
        case "image/png":
        case "image/gif":
        case "image/jpeg":
        case "image/jpg":
            return {
                image: null,
                color: colors.blue.lighter.hue300
            }
        case "text/csv":
            return{
                image: Csv,
                color: colors.red.darker
            }
        case "application/vnd.ms-excel":
        case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
        case "application/vnd.oasis.opendocument.spreadsheet":
            return{
                image: Xls,
                color: colors.red.darker
            }
        case "application/msword":
        case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        case "application/vnd.oasis.opendocument.text":
            return{
                image: Doc,
                color: colors.red.darker
            }
        case "application/vnd.oasis.opendocument.presentation":
        case "application/vnd.ms-powerpoint":
            return {
                image: Ppt,
                color: colors.red.darker
            }
        case "application/pdf":
            return {
                image: Pdf,
                color: colors.red.darker
            }
        case "video/mp4":
            return {
                image: Mp4,
                color: colors.grey.regular
            }
        case "video/webm":
            return {
                image: WebM,
                color: colors.grey.regular
            }
        case "video/mpeg":
            return {
                image: Mpeg,
                color: colors.grey.regular
            }
        default:
            return {
                image: null,
                color: colors.black.regular
            }
    }
}