import React from 'react';
import {Grid} from '@material-ui/core';
import Typography from '../../../ui/typography/Typography';
import CardCustom from '../../../layouts/Card/CardCustom';
import CardCurrencies from '../../../layouts/Card/cardContent/CardCurrencies';
import ValidateCurrencies from './validate/ValidateCurrencies';
import TopPanel from '../../../layouts/TopPanel/TopPanel';
import colors from '../../../../config/theme/colors';
import PageLoader from "../../../ui/loadings/page-loader/PageLoader";
import { withApollo } from 'react-apollo';
import { GET_CURRENCIES } from '../../../../queries/currencies';

import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

import { hasRights } from '../../../../js/utils/rights';
import { SETTINGS, SETTINGS_CURRENCIES, VIEW, CREATE, UPDATE, DELETE} from '../../../../js/constants/constant-rights';
import { ROUTE_HOME } from '../../../../js/constants/route-names';
import { SNACK } from '../../../../js/constants/action-types';
import { ALERT_ERROR } from '../../../../js/constants/alert-types';
import { connect } from "react-redux";
import { withRouter } from "react-router";

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}


class SettingsCurrencies extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            NbCurrencies: 0,
            NbCurrenciesTotal: 0,
            NbCurrenciesInitial: 0,
            changes: [],
            currencies: [],
            existentCurrencies: [],
            validate: false,
            noCurrency: true,
            loading: true,
            textSnack: 'La suppression de la devise s\'est déroulée avec succès',
            typeSnack: 'success',
            openSnack: false,
        }
    }
    handleInputChange = (stateName, evt, id) => {
        const value = evt.target.value;
        this.setState({
            [`${stateName}-${id}`]: value
        });
    };

    handleGetCurrencies(){
        this.props.client.query({
            query: GET_CURRENCIES,
            fetchPolicy: 'no-cache'
        }).then(result =>{
            this.setState({
                currencies: []
            })
            if(result.data.currencies.edges.length > 0){
                this.setState({
                    existentCurrencies: result.data.currencies.edges,
                    NbCurrenciesInitial: result.data.currencies.edges.length,
                    NbCurrenciesTotal: result.data.currencies.edges.length,
                    noCurrency: false
                }, () => {
                    this.setState({loading: false}, () => {
                        this.forceUpdate();
                    });
                });
            }
            else{
                this.setState({loading: false}, () => {
                    this.forceUpdate();
                });
            }
        });
    }

    handleAddCurrency = () => {
        this.setState({
            NbCurrenciesTotal: this.state.NbCurrenciesTotal + 1,
        }, () => {
            this.state.currencies.push({
                "libelle": '',
                "code" : '',
                "id": this.state.NbCurrenciesTotal,
            });
    
            this.setState({validate: false});
            this.forceUpdate();
        });
    }

    handleRemoveExistentCurrency = () => {
        if(this.state.NbCurrenciesInitial === 1){
            this.setState({
                NbCurrenciesInitial: 0,
                noCurrency: true,
            });
        } else {
            this.setState({
                NbCurrenciesInitial: this.state.NbCurrenciesInitial - 1,
            });
        }
        this.setState({openSnack: true})
        this.handleGetCurrencies();
        this.forceUpdate();
    }

    handleRemoveCurrency = (index) => {
        this.setState({
            NbCurrenciesTotal: this.state.NbCurrenciesTotal - 1,
        });

        let i = 0;
        for (let currency of this.state.currencies) {
            if(i>index){
                currency.id = currency.id - 1;
            }
            i++;
        }
        this.state.currencies.splice(index,1);
        this.forceUpdate();
    }

    handleResetData = () => {
        this.handleGetCurrencies();
        this.setState({changes: []})
    }

    handleCheckValidate = () => {
        let changes = this.state.changes;
        let currencies = this.state.currencies;
        let success = true;
        for (let item of changes) {
            if (success) {
                if(item.data.libelle === '' || item.data.code === ''){
                    success = false;
                }
            }
            
        }
        for (let currency of currencies) {
            if (success) {
                if(currency.libelle === '' || currency.code === ''){
                    success = false;
                }
            }
        }
        if(success){
            this.setState({validate: true});
        }
        else{
            this.setState({validate: false});
        }
    }

    componentDidMount = () => {
        const getRights = hasRights(SETTINGS, SETTINGS_CURRENCIES, VIEW)
        if (!getRights){
            this.props.snack(ALERT_ERROR, `Vous n'avez pas les droits suffisants pour accéder à cette page`);
            this.goTo(ROUTE_HOME);
        }
        this.handleGetCurrencies();
    }

    handleCloseSnack = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        this.setState({openSnack: false});
    };


    render() {
        return (
            <div>
                <TopPanel 
                icomoon="picto-devises" 
                colorIcomoon={colors.blue.lighter.hue300} 
                title="Gérer les devises" 
                subtitle="Gestion de vos devises (création / modification / suppression)"  
                handlerAdd={!this.state.loading ? this.handleAddCurrency : null} 
                textAdd={hasRights(SETTINGS, SETTINGS_CURRENCIES, CREATE) ? "Ajouter une devise" : null}
                gradientColor1={colors.menu.regular} 
                gradientColor2={colors.menu.darker} 
                buttonAvailable={true}
                hasBorder={true}
                windowWidth={this.props.windowWidth}
                />
                <Grid container direction="column" justify="center" spacing={0} style={{marginTop: 16}}>
                    <Grid container direction="row" spacing={4}>
                        {
                            !this.state.loading ?
                                (
                                    this.state.noCurrency ? 
                                        this.state.currencies.length === 0 ? 
                                            (<p style={{marginLeft: 15}}>Aucune devise existante pour l'instant, vous pouvez en créer une dès maintenant.</p>) 
                                        :
                                            null
                                    : 
                                        (
                                            this.state.existentCurrencies.map((currency, i) => {
                                                return(
                                                    <React.Fragment key={`ContainerDevis${i}`}>
                                                        <Grid container style={{paddingLeft: 16, paddingTop: 16}}>
                                                            <Typography>Devise n°{i+1}</Typography>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <CardCustom paddingbottom="8px" paddingtop="32px">
                                                                <CardCurrencies
                                                                    windowWidth={this.props.windowWidth}
                                                                    type="existing"
                                                                    handleInputChange = {(data) => {
                                                                        let changes = this.state.changes;
                                                                        let found = false;
                                                                        for (let item of changes) {
                                                                            if (item.id === data.id) {
                                                                                item.data = data.data;
                                                                                this.handleCheckValidate();
                                                                                found = true;
                                                                            }
                                                                        }
                                                                        
                                                                        if (!found)
                                                                            changes.push(data);
                                                                        
                                                                        this.setState({ changes: [].concat(changes) });
                                                                    }} 
                                                                    handleRemove={hasRights(SETTINGS, SETTINGS_CURRENCIES, DELETE) ? this.handleRemoveExistentCurrency : null} 
                                                                    currency={currency.node}
                                                                    disabled={hasRights(SETTINGS, SETTINGS_CURRENCIES, UPDATE) ? null : 'disabled'}
                                                                />
                                                            </CardCustom>
                                                        </Grid>
                                                    </React.Fragment>
                                                )
                                            })
                                        )
                                ) 
                                : 
                                (<PageLoader />)
                        }

                        {
                            this.state.currencies.map((currency, index) =>
                                <React.Fragment key={`ContainerCurrency${index}`}>
                                    <Grid container style={{paddingLeft: 16, paddingTop: 16}}>
                                        <Typography>Devise n°{currency.id}</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <CardCustom paddingbottom="8px" paddingtop="32px">
                                            <CardCurrencies
                                                windowWidth={this.props.windowWidth}
                                                type="new"
                                                handleInputChange={(data) => {
                                                    for (let currency of this.state.currencies) {
                                                        if (currency.id === data.id) {
                                                            currency.libelle = data.data.libelle;
                                                            currency.code = data.data.code;
                                                            this.handleCheckValidate();
                                                        }
                                                    }
                                                }} 
                                                handleRemove={() => this.handleRemoveCurrency(index)} 
                                                currency={currency} 
                                            />
                                        </CardCustom>
                                    </Grid>
                                </React.Fragment>
                            )
                        }
                    </Grid>
                </Grid>
                {
                    this.state.existentCurrencies.length > 0 || this.state.currencies.length > 0 ? (
                        <ValidateCurrencies newCurrencies={this.state.currencies} changesCurrencies={this.state.changes} validate={this.state.validate} handleResetData={this.handleResetData} />
                    ) : null
                }

                
                <Snackbar open={this.state.openSnack} autoHideDuration={10000} onClose={this.handleCloseSnack}>
                    <Alert onClose={this.handleCloseSnack} severity={this.state.typeSnack}>
                        {this.state.textSnack}
                    </Alert>
                </Snackbar>
            </div>
        );
    }
    goTo = route => this.props.history.push(route);
}
const mapDispatchToProps = dispatch => {
    return {
        snack: (type, message) => dispatch({ type: SNACK, payload: { type, message }})
    }
}

export default withRouter(withApollo(connect(null, mapDispatchToProps)(SettingsCurrencies)));