import React from 'react';
import PageContainer from '../../../layouts/Container/PageContainer';
import ContentContainer from '../../../layouts/Container/ContentContainer';
import Listing from '../../../layouts/Listing/Listing';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { ROUTE_ORDERS_DETAIL } from '../../../../js/constants/route-names'
import { setValue } from '../../../../js/helpers/form';
import { getElements } from '../../../../js/utils/functions';
import colors from '../../../../config/theme/colors';
import DialogModal from '../../../ui/dialog/DialogModal';
import { Box, Button, ButtonGroup, FormControlLabel, Grid, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tabs, TextareaAutosize, TextField, Typography } from '@material-ui/core';
import { ALERT_ERROR, ALERT_SUCCESS } from '../../../../js/constants/alert-types'
import request from '../../../../js/utils/fetch';
import { SNACK } from '../../../../js/constants/action-types';
import Switch from '@material-ui/core/Switch';
import CardCustom from '../../../layouts/Card/CardCustom';
import productListingConfig from './config/productListing.config';
import OurButton from '../../../ui/button/Button';


function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <Grid
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
      >
        

        {value === index && (
        <Box p={2}>
          {children}
        </Box>
      )}

      </Grid>
    );
}

class NewOrder extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentLang: props.locales[0].node.code,
            type: props.noStatus,
            startDate: '',
            endDate: '',
            popup: false,
            yearlyExport: false,
            yearExport: '',
            possibleYear: [],
            sameBillAndShip: true,
            addDatePayment: false,
            tabValue: 0,
            products: [],
            infos:'',
            billToFirstname: '',
            billToLastname: '',
            billToAddress: '',
            billToCity: '',
            billToPostcode: '',
            billToPhone: '',
            billToEmail: '',
            shipToFirstname: '',
            shipToLastname: '',
            shipToAddress: '',
            shipToCity: '',
            shipToPostcode: '',
            shipToPhone: '',
            shipToEmail: '',
            marketPlaceName: null,
            datePayment: null,
            datePaymentDate: null,
            error: {
                billToFirstname: '',
                billToLastname: '',
                billToAddress: '',
                billToCity: '',
                billToPostcode: '',
                billToPhone: '',
                billToEmail: '',
                shipToFirstname: '',
                shipToLastname: '',
                shipToAddress: '',
                shipToCity: '',
                shipToPostcode: '',
                shipToPhone: '',
                shipToEmail: '',
            }
        }
    }

    goTo = (id) => {
        let path = ROUTE_ORDERS_DETAIL.replace(':id', id);
        this.props.history.push({ pathname: path })
    }

    getIdentifierByType = (type) => {
        switch (type) {
            case 'returned':
                return 'salesOrderReturned'
            default:
                return 'salesOrders'
        }
    }

    getQueryVariables = (identifier) => {
        let queryvariables = {};
        switch (identifier) {
            case 'salesOrderReturned':
                queryvariables.hasCodeRaisonRetour = true;
                break;
        }

        return queryvariables;
    }

    handleInput = (value, input) => {
        let values = setValue(this.state, input.stateName ?? input.queryField, value)
        this.setState({ ...this.state, ...values })
    }

    resetStates = () => {
        this.setState({
            origin: null
        })
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.noStatus != this.props.noStatus) {
            this.resetStates()
            this.setState({ type: this.props.noStatus })
        }
    }

    initMarketplaces = async () => {
        let marketplaces = await getElements('marketplaces')
        this.setState({
            marketplaces: marketplaces.data.getMarketPlaceNameSalesOrders.edges
        })
    }

    componentDidMount() {
        this.initMarketplaces();
        var date = new Date();
        var month = (date.getMonth() + 1).toString()
        var day = date.getDate().toString()
        var month = (month.length === 1) ? '0' + month : month;
        var day = (day.length === 1) ? '0' + day : day;
        var startDate = date.getFullYear() + '-' + month + '-01';
        var endDate = date.getFullYear() + '-' + month + '-' + day;
        var yearExport = date.getFullYear();

        var possibleYear = [(yearExport - 2), (yearExport - 1), yearExport]


        this.setState({
            startDate: startDate,
            endDate: endDate,
            yearExport: yearExport,
            possibleYear: possibleYear
        })
    }

    handleToggleDialog = (stateName) => {
        this.setState({ [stateName]: false });
        this.setState({ [stateName]: !this.state[stateName] });
    };

    handleAddProduct = (product) => {
        var newArray = this.state.products.slice()
        product.quantity = 1
        product.unitPrice = null
        product.unitTVA = null
        product.totalPrice = 0
        product.id = product._id
        newArray.push(product)
        this.setState({ products: [...this.state.products, product] })
        this.handleToggleDialog('popup');
    };

    addCustomProduct = () => {
        var timestamp = Date.now();
        var product = {};
        var newArray = this.state.products.slice()
        product.quantity = 1
        product.unitPrice = null
        product.unitTVA = null
        product.totalPrice = 0
        product.name = ""
        product.id = "custom_".concat(timestamp)
        product.sku = "custom_".concat(timestamp)
        product.custom = true
        newArray.push(product)
        this.setState({ products: [...this.state.products, product] })
    };

    handleQty = (e, type, productIndex) => {
        var products = [...this.state.products];

        if (type === "+") {
            products[productIndex].quantity = products[productIndex].quantity + 1
            this.calcPrice(productIndex)
        }

        if (type === "-") {
            products[productIndex].quantity = products[productIndex].quantity - 1
            if (products[productIndex].quantity < 1) {
                products.splice(productIndex, 1);
            } else {
                this.calcPrice(productIndex)
            }
        }

        this.setState({ products: products })
    }

    handleProductName = (e, productIndex) => {
        var products = [...this.state.products];

        products[productIndex].name = e.target.value

        this.setState({ products: products })
    }

    handleUnitPrice = (e, productIndex) => {
        var products = [...this.state.products];

        products[productIndex].unitPrice = parseFloat(e.target.value)

        this.setState({ products: products })
        this.calcPrice(productIndex)
    }

    handleUnitTVA = (e, productIndex) => {
        var products = [...this.state.products];

        products[productIndex].unitTVA = parseFloat(e.target.value)

        this.setState({ products: products })
        this.calcPrice(productIndex)
    }

    calcPrice = (productIndex) => {
        var products = [...this.state.products];

        products[productIndex].totalPrice = (products[productIndex].unitPrice + products[productIndex].unitTVA) * products[productIndex].quantity

        this.setState({ products: products })
    }

    handleCreation = () => {
        var postData = {
            billToFirstname: this.state.billToFirstname,
            billToLastname: this.state.billToLastname,
            billToAddress: this.state.billToAddress,
            billToCity: this.state.billToCity,
            billToPostcode: this.state.billToPostcode,
            billToPhone: this.state.billToPhone,
            billToEmail: this.state.billToEmail,
            shipToFirstname: this.state.shipToFirstname,
            shipToLastname: this.state.shipToLastname,
            shipToAddress: this.state.shipToAddress,
            shipToCity: this.state.shipToCity,
            shipToPostcode: this.state.shipToPostcode,
            shipToPhone: this.state.shipToPhone,
            shipToEmail: this.state.shipToEmail,
            datePayment: null,
            products: this.state.products,
            infos: this.state.infos,
            marketPlaceName: this.state.marketPlaceName
        }

        if (this.state.sameBillAndShip) {
            postData.shipToFirstname = this.state.billToFirstname;
            postData.shipToLastname = this.state.billToLastname;
            postData.shipToAddress = this.state.billToAddress;
            postData.shipToCity = this.state.billToCity;
            postData.shipToPostcode = this.state.billToPostcode;
            postData.shipToPhone = this.state.billToPhone;
            postData.shipToEmail = this.state.billToEmail;
        }

        if (this.state.addDatePayment) {
            if (this.state.tabValue === 0) {
                postData.datePayment = this.state.datePayment
            }

            if (this.state.tabValue === 1) {
                var date = new Date(this.state.datePaymentDate)
                date = date.toLocaleDateString("fr")
                postData.datePayment = date
            }
        }

        if (this.validateData(postData)) {
            var url = this.props?.avoir ? 'avoir' : 'new'
            request(`${process.env.REACT_APP_API}/sales-orders/${url}`, 'post', postData).then(
                (data) => {
                    if (data.success) {
                        this.props.snack(ALERT_SUCCESS, `Création réussie`);
    
                        this.goTo(data.salesOrder)
                    } else {
                        this.props.snack(ALERT_ERROR, `Création impossible`);
                    }
                }
            );
        } else {
            this.props.snack(ALERT_ERROR, `Merci de remplir le formulaire`)
        }

    }

    a11yProps(index) {
        return {
          id: `vertical-tab-${index}`,
          'aria-controls': `vertical-tabpanel-${index}`,
        };
    }

    handleTabChange = (event, newValue) => {
        this.setState({tabValue: newValue})
    };
      

    validateData = (datas) => {
        var errors = {
            billToFirstname: '',
            billToLastname: '',
            billToAddress: '',
            billToCity: '',
            billToPostcode: '',
            billToPhone: '',
            billToEmail: '',
            shipToFirstname: '',
            shipToLastname: '',
            shipToAddress: '',
            shipToCity: '',
            shipToPostcode: '',
            shipToPhone: '',
            shipToEmail: '',
        }

        var nbError = 0;

        for (const data in datas) {
            if (datas[data] === '') {
                errors[data] = 'Merci de remplir ce champ';   
                nbError++; 
            }
        }

        this.setState({error: errors})

        if(nbError === 0) return true;

        return false;
    }

    render() {

        let { listSettings, listMappers, perPageOptions, searchSettings } = productListingConfig({
            locations: this.state.locations
        });
        return (
            <PageContainer openForm={false} drawerWidth={this.props.drawerWidth}>
                <ContentContainer
                    headerSettings={{
                        icomoon: "picto-produit",
                        title: this.props?.avoir ? 'Nouvel avoir' : 'Nouvelle commande',
                        subtitle: this.props?.avoir ? 'Créer un avoir' : 'Créer une commande',
                        windowWidth: this.props.windowWidth,
                        buttonAvailable: true,
                    }}

                >
                    <CardCustom style={{ width: '100%' }}>
                        <Grid container>
                            <Typography variant="h1" fontweighttext={'bold'} style={{ marginBottom: '1.5rem', color: '#000000', width: '100%' }}>
                                {
                                    this.props?.avoir ? 'Créer un avoir' : 'Créer une commande'
                                }
                            </Typography>


                            <Typography variant="h3" fontweighttext={'bold'} style={{ marginBottom: '0.5rem', width: '100%' }}>
                                Informations de facturation
                            </Typography>


                            <Grid container spacing={5}>
                                <Grid item xs={6} md={4} lg={3} style={{ display: 'flex', flexDirection: 'column' }}>
                                    <span style={{ width: '100%', color: '#808F98', fontSize: 14, fontFamily: 'soleil, sans-serif', fontWeight: 'normal', lineHeight: '19px', marginTop: '12px' }}>Prénom</span>
                                    <TextField
                                        error={this.state.error.billToFirstname !== ''}
                                        helperText={this.state.error.billToFirstname}
                                        type="text"
                                        required={true}
                                        value={this.state.billToFirstname}
                                        onChange={(e) => this.setState({ billToFirstname: e.target.value })}
                                    />
                                </Grid>
                                <Grid item xs={6} md={4} lg={3} style={{ display: 'flex', flexDirection: 'column' }}>
                                    <span style={{ width: '100%', color: '#808F98', fontSize: 14, fontFamily: 'soleil, sans-serif', fontWeight: 'normal', lineHeight: '19px', marginTop: '12px' }}>Nom</span>
                                    <TextField
                                        error={this.state.error.billToLastname !== ''}
                                        helperText={this.state.error.billToLastname}
                                        type="text"
                                        required={true}
                                        value={this.state.billToLastname}
                                        onChange={(e) => this.setState({ billToLastname: e.target.value })}
                                    />
                                </Grid>
                            </Grid>

                            <Grid container spacing={5} style={{ paddingTop: 24 }}>
                                <Grid item xs={6} md={4} lg={3} style={{ display: 'flex', flexDirection: 'column' }}>
                                    <span style={{ width: '100%', color: '#808F98', fontSize: 14, fontFamily: 'soleil, sans-serif', fontWeight: 'normal', lineHeight: '19px', marginTop: '12px' }}>Adresse</span>
                                    <TextField
                                        error={this.state.error.billToAddress !== ''}
                                        helperText={this.state.error.billToAddress}
                                        type="text"
                                        required={true}
                                        value={this.state.billToAddress}
                                        onChange={(e) => this.setState({ billToAddress: e.target.value })}
                                    />
                                </Grid>
                                <Grid item xs={6} md={4} lg={3} style={{ display: 'flex', flexDirection: 'column' }}>
                                    <span style={{ width: '100%', color: '#808F98', fontSize: 14, fontFamily: 'soleil, sans-serif', fontWeight: 'normal', lineHeight: '19px', marginTop: '12px' }}>Ville</span>
                                    <TextField
                                        error={this.state.error.billToCity !== ''}
                                        helperText={this.state.error.billToCity}
                                        type="text"
                                        required={true}
                                        value={this.state.billToCity}
                                        onChange={(e) => this.setState({ billToCity: e.target.value })}
                                    />
                                </Grid>
                                <Grid item xs={6} md={4} lg={3} style={{ display: 'flex', flexDirection: 'column' }}>
                                    <span style={{ width: '100%', color: '#808F98', fontSize: 14, fontFamily: 'soleil, sans-serif', fontWeight: 'normal', lineHeight: '19px', marginTop: '12px' }}>Code postal</span>
                                    <TextField
                                        error={this.state.error.billToPostcode !== ''}
                                        helperText={this.state.error.billToPostcode}
                                        type="text"
                                        required={true}
                                        value={this.state.billToPostcode}
                                        onChange={(e) => this.setState({ billToPostcode: e.target.value })}
                                    />
                                </Grid>
                            </Grid>


                            <Grid container spacing={5} style={{ paddingTop: 24 }}>
                                <Grid item xs={6} md={4} lg={3} style={{ display: 'flex', flexDirection: 'column' }}>
                                    <span style={{ width: '100%', color: '#808F98', fontSize: 14, fontFamily: 'soleil, sans-serif', fontWeight: 'normal', lineHeight: '19px', marginTop: '12px' }}>Numéro de téléphone</span>
                                    <TextField
                                        error={this.state.error.billToPhone !== ''}
                                        helperText={this.state.error.billToPhone}
                                        type="text"
                                        required={true}
                                        value={this.state.billToPhone}
                                        onChange={(e) => this.setState({ billToPhone: e.target.value })}
                                    />
                                </Grid>
                                <Grid item xs={6} md={4} lg={3} style={{ display: 'flex', flexDirection: 'column' }}>
                                    <span style={{ width: '100%', color: '#808F98', fontSize: 14, fontFamily: 'soleil, sans-serif', fontWeight: 'normal', lineHeight: '19px', marginTop: '12px' }}>Adresse mail</span>
                                    <TextField
                                        error={this.state.error.billToEmail !== ''}
                                        helperText={this.state.error.billToEmail}
                                        type="text"
                                        required={true}
                                        value={this.state.billToEmail}
                                        onChange={(e) => this.setState({ billToEmail: e.target.value })}
                                    />
                                </Grid>
                            </Grid>


                            <Typography variant="h3" fontweighttext={'bold'} style={{ marginBottom: '0.5rem', width: '100%', marginTop: '3.5rem' }}>
                                Informations de livraison
                            </Typography>

                            <FormControlLabel
                                style={{ marginTop: '24px', marginBottom: '12px' }}
                                control={
                                    <Switch
                                        checked={this.state.sameBillAndShip}
                                        value={this.state.sameBillAndShip}
                                        onChange={(e) => { this.setState({ sameBillAndShip: !this.state.sameBillAndShip }) }}
                                        color="primary"
                                    />
                                }
                                label="Utiliser les informations de facturation ? "
                            />

                            {
                                this.state.sameBillAndShip ? '' : (<>
                                    <Grid container spacing={5}>
                                        <Grid item xs={6} md={4} lg={3} style={{ display: 'flex', flexDirection: 'column' }}>
                                            <span style={{ width: '100%', color: '#808F98', fontSize: 14, fontFamily: 'soleil, sans-serif', fontWeight: 'normal', lineHeight: '19px', marginTop: '12px' }}>Prénom</span>
                                            <TextField
                                                error={this.state.error.shipToFirstname !== ''}
                                                helperText={this.state.error.shipToFirstname}
                                                type="text"
                                                required={true}
                                                value={this.state.shipToFirstname}
                                                onChange={(e) => this.setState({ shipToFirstname: e.target.value })}
                                            />
                                        </Grid>
                                        <Grid item xs={6} md={4} lg={3} style={{ display: 'flex', flexDirection: 'column' }}>
                                            <span style={{ width: '100%', color: '#808F98', fontSize: 14, fontFamily: 'soleil, sans-serif', fontWeight: 'normal', lineHeight: '19px', marginTop: '12px' }}>Nom</span>
                                            <TextField
                                                error={this.state.error.shipToLastname !== ''}
                                                helperText={this.state.error.shipToLastname}
                                                type="text"
                                                required={true}
                                                value={this.state.shipToLastname}
                                                onChange={(e) => this.setState({ shipToLastname: e.target.value })}
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid container spacing={5} style={{ paddingTop: 24 }}>
                                        <Grid item xs={6} md={4} lg={3} style={{ display: 'flex', flexDirection: 'column' }}>
                                            <span style={{ width: '100%', color: '#808F98', fontSize: 14, fontFamily: 'soleil, sans-serif', fontWeight: 'normal', lineHeight: '19px', marginTop: '12px' }}>Adresse</span>
                                            <TextField
                                                error={this.state.error.shipToAddress !== ''}
                                                helperText={this.state.error.shipToAddress}
                                                type="text"
                                                required={true}
                                                value={this.state.shipToAddress}
                                                onChange={(e) => this.setState({ shipToAddress: e.target.value })}
                                            />
                                        </Grid>
                                        <Grid item xs={6} md={4} lg={3} style={{ display: 'flex', flexDirection: 'column' }}>
                                            <span style={{ width: '100%', color: '#808F98', fontSize: 14, fontFamily: 'soleil, sans-serif', fontWeight: 'normal', lineHeight: '19px', marginTop: '12px' }}>Ville</span>
                                            <TextField
                                                error={this.state.error.shipToCity !== ''}
                                                helperText={this.state.error.shipToCity}
                                                type="text"
                                                required={true}
                                                value={this.state.shipToCity}
                                                onChange={(e) => this.setState({ shipToCity: e.target.value })}
                                            />
                                        </Grid>
                                        <Grid item xs={6} md={4} lg={3} style={{ display: 'flex', flexDirection: 'column' }}>
                                            <span style={{ width: '100%', color: '#808F98', fontSize: 14, fontFamily: 'soleil, sans-serif', fontWeight: 'normal', lineHeight: '19px', marginTop: '12px' }}>Code postal</span>
                                            <TextField
                                                error={this.state.error.shipToPostcode !== ''}
                                                helperText={this.state.error.shipToPostcode}
                                                type="text"
                                                required={true}
                                                value={this.state.shipToPostcode}
                                                onChange={(e) => this.setState({ shipToPostcode: e.target.value })}
                                            />
                                        </Grid>
                                    </Grid>


                                    <Grid container spacing={5} style={{ paddingTop: 24 }}>
                                        <Grid item xs={6} md={4} lg={3} style={{ display: 'flex', flexDirection: 'column' }}>
                                            <span style={{ width: '100%', color: '#808F98', fontSize: 14, fontFamily: 'soleil, sans-serif', fontWeight: 'normal', lineHeight: '19px', marginTop: '12px' }}>Numéro de téléphone</span>
                                            <TextField
                                                error={this.state.error.shipToPhone !== ''}
                                                helperText={this.state.error.shipToPhone}
                                                type="text"
                                                required={true}
                                                value={this.state.shipToPhone}
                                                onChange={(e) => this.setState({ shipToPhone: e.target.value })}
                                            />
                                        </Grid>
                                        <Grid item xs={6} md={4} lg={3} style={{ display: 'flex', flexDirection: 'column' }}>
                                            <span style={{ width: '100%', color: '#808F98', fontSize: 14, fontFamily: 'soleil, sans-serif', fontWeight: 'normal', lineHeight: '19px', marginTop: '12px' }}>Adresse mail</span>
                                            <TextField
                                                error={this.state.error.shipToEmail !== ''}
                                                helperText={this.state.error.shipToEmail}
                                                type="text"
                                                required={true}
                                                value={this.state.shipToEmail}
                                                onChange={(e) => this.setState({ shipToEmail: e.target.value })}
                                            />
                                        </Grid>
                                    </Grid>
                                </>)
                            }

                            {
                                this.props?.avoir ? null : (
                                    <>
                                        <Typography variant="h3" fontweighttext={'bold'} style={{ marginBottom: '0.5rem', width: '100%', marginTop: '3.5rem' }}>
                                            Ajouter une date de paiement ?
                                            <Switch
                                                checked={this.state.addDatePayment}
                                                value={this.state.addDatePayment}
                                                onChange={(e) => { this.setState({ addDatePayment: !this.state.addDatePayment }) }}
                                                color="primary"
                                            />
                                        </Typography>

                                        {
                                            this.state.addDatePayment ? 
                                            (<Grid container>
                                                <Grid item xs={3} md={2} lg={2}>
                                                    <Tabs
                                                        orientation="vertical"
                                                        variant="scrollable"
                                                        value={this.state.tabValue}
                                                        onChange={this.handleTabChange}
                                                        aria-label="Vertical tabs example"
                                                        style={{borderRight: '1px solid #DDD'}}
                                                    >
                                                        <Tab label="Saisie libre" {...this.a11yProps(0)} />
                                                        <Tab label="Date" {...this.a11yProps(1)} />
                                                    </Tabs>
                                            </Grid>
                                            <Grid item xs={9} md={10} lg={10}>
                                                <TabPanel value={this.state.tabValue} index={0}>
                                                    <Grid item xs={6} md={4} lg={3} style={{ display: 'flex', flexDirection: 'column' }}>
                                                        <span style={{ width: '100%', color: '#808F98', fontSize: 14, fontFamily: 'soleil, sans-serif', fontWeight: 'normal', lineHeight: '19px', marginTop: '12px' }}>Date de paiement</span>
                                                        <TextField
                                                            type="text"
                                                            required={false}
                                                            value={this.state.datePayment}
                                                            onChange={(e) => this.setState({ datePayment: e.target.value })}
                                                        />
                                                    </Grid>
                                                </TabPanel>
                                                <TabPanel value={this.state.tabValue} index={1}>
                                                    <Grid item xs={6} md={4} lg={3} style={{ display: 'flex', flexDirection: 'column' }}>
                                                        <span style={{ width: '100%', color: '#808F98', fontSize: 14, fontFamily: 'soleil, sans-serif', fontWeight: 'normal', lineHeight: '19px', marginTop: '12px' }}>Date de paiement</span>
                                                        <TextField
                                                            type="date"
                                                            required={false}
                                                            value={this.state.datePaymentDate}
                                                            onChange={(e) => this.setState({ datePaymentDate: e.target.value })}
                                                        />
                                                    </Grid>
                                                </TabPanel>
                                            </Grid>


                                                
                                            </Grid>) : null
                                        }
                                    </>
                                )
                            }

                            <Typography variant="h3" fontweighttext={'bold'} style={{ marginBottom: '0.5rem', width: '100%', marginTop: '3.5rem' }}>
                                Sélectionnez vos produits
                            </Typography>

                            <Button variant="contained" color='primary' onClick={(e) => this.setState({ popup: true })}>Ajouter un produit</Button>
                            <Button variant="contained" color='primary' onClick={(e) => this.addCustomProduct()} style={{marginLeft: 12}}>Ajouter un produit personnalisé</Button>

                            <TableContainer>
                                <Table aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Sku</TableCell>
                                            <TableCell align="left">Nom</TableCell>
                                            <TableCell align="center">Quantité</TableCell>
                                            <TableCell align="right">Prix unitaire (€)</TableCell>
                                            <TableCell align="right">TVA (€)</TableCell>
                                            <TableCell align="right">Total (€)</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>

                                        {
                                            (this.state.products.length !== 0) ? (<>

                                                {this.state.products.map((product, index) => (
                                                    <TableRow key={product.id}>
                                                        <TableCell component="th" scope="row">
                                                            {product.sku}
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            {
                                                                product.custom ? 
                                                                <>
                                                                <TextField
                                                                    type="text"
                                                                    value={product.name}
                                                                    onChange={(e) => { this.handleProductName(e, index) }}
                                                                    error={product.name === null || product.name === '' }
                                                                    helperText={product.name === null || product.name === '' ? "Merci de saisir le nom" : ""}
                                                                />
                                                                </>
                                                                : product.productName
                                                            }
                                                            
                                                        </TableCell>
                                                        <TableCell align="right">
                                                            <ButtonGroup size="small" color="primary" aria-label="outlined primary button group">
                                                                <Button onClick={(e) => this.handleQty(e, '-', index)}>-</Button>
                                                                <Button disabled style={{ border: '1px solid rgba(43, 69, 83, 0.5)', color: '#000000' }}><b style={{ paddingRight: '5px' }}> {product.quantity} </b>  ({product.totalStock} en stock)</Button>
                                                                <Button onClick={(e) => this.handleQty(e, '+', index)}>+</Button>
                                                            </ButtonGroup>
                                                        </TableCell>
                                                        <TableCell align="right">
                                                            <TextField
                                                                type="number"
                                                                value={product.unitPrice}
                                                                onChange={(e) => { this.handleUnitPrice(e, index) }}
                                                                error={product.unitPrice === null || product.unitPrice === '' }
                                                                helperText={product.unitPrice === null || product.unitPrice === '' ? "Merci de saisir le prix" : ""}
                                                            />
                                                        </TableCell>
                                                        <TableCell align="right">
                                                            <TextField
                                                                type="number"
                                                                value={product.unitTVA}
                                                                onChange={(e) => { this.handleUnitTVA(e, index) }}
                                                                error={product.unitTVA === null || product.unitTVA === '' }
                                                                helperText={product.unitTVA === null || product.unitTVA === '' ? "Merci de saisir la tva" : ""}
                                                            />
                                                        </TableCell>
                                                        <TableCell align="right"> {
                                                            this.props?.avoir ? '-' : null
                                                        }{Math.round(product.totalPrice * 100) / 100} </TableCell>
                                                    </TableRow>
                                                ))}


                                            </>) :
                                                <TableRow>
                                                    <TableCell component="th" scope="row" colSpan={6}>
                                                        <Typography variant="h3" fontweighttext={'bold'} style={{ marginBottom: '2rem', width: '100%', marginTop: '2rem', textAlign: 'center' }}>
                                                            
                                                            {
                                                                this.props?.avoir ? 'Votre avoir ne contient aucun produit' : 'Votre commande ne contient aucun produit'
                                                            }
                                                        </Typography>
                                                    </TableCell>

                                                </TableRow>
                                        }

                                    </TableBody>
                                </Table>
                            </TableContainer>

                            <Typography variant="h3" fontweighttext={'bold'} style={{ marginBottom: '0.5rem', width: '100%', marginTop: '3.5rem' }}>
                                Informations complémentaires
                            </Typography>

                            <Grid container>
                                <TextareaAutosize 
                                    style={{width: "100%", height: 60}}
                                    value={this.state.infos}
                                    onChange={(e) => this.setState({infos: e.target.value})}
                                    minRows={3}
                                    placeholder="Informations complémentaires"                              
                                />
                            </Grid>

                            {
                                (this.state.products.length === 0) ?
                                    (
                                        <OurButton disabled variant='contained' color={colors.green.regular}>
                                            {
                                                this.props?.avoir ? 'Créer l\'avoir' : 'Créer la commande'
                                            }
                                        </OurButton>
                                    ) : (
                                        <OurButton variant='contained' onClick={(e) => this.handleCreation()}>
                                            {
                                                this.props?.avoir ? 'Créer l\'avoir' : 'Créer la commande'
                                            }
                                        </OurButton>
                                    )
                            }




                            {this.state.popup ? (
                                <DialogModal
                                    style={{ maxWidth: '200px' }}
                                    open={this.state.popup}
                                    title={'Ajouter un produit'} maxWidth={1200}
                                    secondaryAction={() => { this.handleToggleDialog('popup') }} secondarycolor={colors.grey.regular} secondarybgcolor={colors.white} secondarybgcolorhover={colors.grey.lighter.hue900} secondaryborder={`${colors.grey.lighter.hue900}`}
                                >

                                    <Listing
                                        label='produits'
                                        settings={listSettings}
                                        cardContainerProps={{
                                            style: { height: '100%' }
                                        }}
                                        perPageOptions={perPageOptions}
                                        mappers={listMappers}
                                        currentLang={this.state.currentLang}
                                        identifier='productLites'
                                        searchSettings={searchSettings}
                                        viewsOptions={{
                                            current: 'card',
                                            settings: ['card'] //Si il n'y a qu'un seul item il n'y aura pas de changement de vues
                                        }}
                                        cardProps={this.handleAddProduct}
                                    />
                                </DialogModal>
                            ) : null
                            }

                        </Grid>
                    </CardCustom>
                </ContentContainer>
            </PageContainer>
        )

    }
}
const mapStateToProps = state => {
    return {
        loading: state.loading,
        products: state.products,
        locales: state.locales,
        attributes: state.attributes
    };
};

const mapDispatchToProps = dispatch => {
    return {
        snack: (type, message) => dispatch({ type: SNACK, payload: { type, message } })
    }
};


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NewOrder));

