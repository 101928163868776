import React from 'react';

import { Box, Divider, Grid } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '../../../ui/typography/Typography';
import colors from '../../../../config/theme/colors';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';

import styled from 'styled-components';
import StatusInfo from '../../../ui/status-info/StatusInfo';
import Button from '../../../ui/button/Button';
import { ROUTE_COMMUNITIES_MINISITES_DETAIL, ROUTE_SALES_ESHOP_DETAIL, ROUTE_COMMUNITIES_PERMANENTS_POSTS, ROUTE_COMMUNITIES_GAMES_DETAIL, ROUTE_SALES_SHOPS_DETAILS, ROUTE_SALES_SOCIAL_COMMERCE_DETAILS, ROUTE_SALES_MARKETPLACES_DETAILS, ROUTE_COMMUNITIES_SOCIALS_DETAIL, ROUTE_SALES_APPLICATIONS_DETAILS, ROUTE_COMMUNITIES_CMS_DETAIL, ROUTE_WAREHOUSE_DETAIL } from '../../../../js/constants/route-names';
// import { ASSET_APPLICATION, ASSET_GAME, ASSET_MINISITE, EPHEMERAL_ASSET } from '../../../../js/constants/assets-types';
import { withRouter } from 'react-router';
import HTMLEllipsis from 'react-lines-ellipsis/lib/html';
import iconSet from "../../../../assets/selection.json";
import IcomoonReact from "icomoon-react";
// import { withTranslation } from 'react-i18next';
import ImgNotFound from '../../../../assets/images/socials/image-fake.png';
import OurButton from '../../../ui/button/Button';
import responsiveHOC from 'react-lines-ellipsis/lib/responsiveHOC';
import TextEllipsis from './TextEllipsis';
import AutorenewSharpIcon from '@material-ui/icons/AutorenewSharp';

const AssetDescr = styled(Typography)`
    font-size:13px;
    line-height:17px;
    margin-top:4px;
    min-height:34px;
    width:100%;
    p{
        margin: 0;
    }
`

const ContainerImage = styled(Box)`
    overflow: hidden;
    background: url(${props => props.backgroundimage});
    background-size: cover;
    background-position: center;
    height: 216px;
    width: 100%;
`
const ContainerBackground = styled(Box)`
    overflow: hidden;
    background: ${props => props.backgroundcolorcard};
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
`
const LineColor = styled(Box)`
    background: ${props => props.typeasset === 'permanent' ? colors.blue.darker.hue300 : props.typeasset === 'ephemeral' ? colors.purple.regular : colors.pink.regular};
    width: 100%;
`

const TypeAsset = styled(Typography)`
    font-weight: bold;
`
const AssetType = styled(Typography)`
    color: ${colors.black.regular.regular};
    font-weight: bold;
`

const ResponsiveEllipsis = responsiveHOC()(HTMLEllipsis)

const useRowStyles = makeStyles({
    root: {
        '& > *': {
            borderBottom: 'unset',
        },
    },
});

const useStylesBootstrap = makeStyles(theme => ({
    arrow: {
        color: colors.black.regular.regular,
    },
    tooltip: {
        backgroundColor: colors.black.regular.regular,
        fontSize: 14,
    },
}));


function BootstrapTooltip(props) {
    const classes = useStylesBootstrap();
    return <Tooltip arrow classes={classes} {...props} />;
}

function CardAssets(props) {
    const goTo = (route, id) => {
        props.history.push({
            pathname: route,
            state: { assetId: id }
        });
    };

    const classes = useRowStyles();

    return (
        <Box width={'100%'} style={{ paddingBottom: '0px', padding: '20px' }}>
            {/* <ContainerImage height={160} width={'100%'} backgroundimage={ImgNotFound} /> */}
            <ContainerImage height={160} width={'100%'} backgroundimage={props.data.media?.filePath 
                ? `${process.env.REACT_APP_MEDIAS}/${props.data.media?.filePath}` 
                : ImgNotFound} 
            />
            <Grid container>
                <Grid item xs={12} style={{ paddingTop: '16px' }}>
                    <Grid container>
                        {/* Name and asset type */}
                        <Grid container justifyContent='space-between' sm={12}>
                            <Grid item>
                                <Typography variant="h3" style={{ fontSize: '22px' }}>Site {props.data.code}</Typography>
                            </Grid>
                        </Grid>
                        {/* Description
                        <Grid item sm={12} style={{marginTop: '8px'}}>
                            <AssetDescr variant="body2" component="div">
                                <TextEllipsis
                                    text={props.data.description}
                                    maxLine={2}
                                    ellipsis='...'
                                    hasModal={false}
                                />
                            </AssetDescr>
                        </Grid> */}
                    </Grid>
                    {/* <Divider /> */}

                    <Grid container style={{ padding: '14px 0px 0px 0px' }}>
                        <Grid item sm={5} direction='column' style={{ display: 'flex', gap: '5px' }}>
                            <Typography variant='div'>Statut :</Typography>
                            {/* <Typography variant='div'>Synchronisation :</Typography> */}
                        </Grid>
                        <Grid item sm={7} direction='column' style={{ display: 'flex', gap: '5px', fontWeight: 'bold' }}>
                            <StatusInfo status={props.data.isActive ? 'success' : 'error'} statusText={props.data.isActive ? 'Actif' : 'Inactif'} />
                            {/* <Typography variant='div'>
                                <StatusInfo icon={
                                    <AutorenewSharpIcon style={{ color: colors.green.regular, marginLeft: '-5px', marginRight: '-5px', fontSize: 22 }} />
                                } status={'success'} statusText={'Automatique'} />
                            </Typography> */}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} style={{ marginTop: '15px' }}>
                    <OurButton variant="contained" color="secondary" size="small" style={{ marginTop: "1%", fontWeight: 'normal', padding: '12px', width: '100%', color: colors.white, marginBottom: '0px', backgroundColor: colors.black.regular }} onClick={(e) => {
                        goTo(ROUTE_WAREHOUSE_DETAIL.replace(':id', props.data._id))
                    }}>
                        Voir l'entrepôt
                    </OurButton>
                </Grid>
            </Grid>
        </Box>
    )
}
export default (withRouter(CardAssets));