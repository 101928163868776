import React from 'react';
import { withRouter } from "react-router";
import { connect } from "react-redux";
import {
  Switch,
  Route
} from "react-router-dom";
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';

import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';

import Appbar from '../../navigation/Appbar';
import DrawerLeft from '../../navigation/DrawerLeft';

import './Dashboard.scss';
import { START_LOADING, STOP_LOADING, SET_USER, SET_GUIDELINE } from '../../../js/constants/action-types';
import { GET_SYSTEM_TYPOS } from "../../../queries/brand_guideline";
import { withApollo } from 'react-apollo';

import { 
  ROUTE_HOME,
  ROUTE_ORDERS_LIST,
  ROUTE_ORDERS_NEW,
  ROUTE_ORDERS_AVOIR,
  ROUTE_ORDERS_LIST_PREORDER,
  ROUTE_ORDERS_LIST_RETURN,
  ROUTE_ORDERS_LIST_DELAY,
  ROUTE_ORDERS_DETAIL,
  ROUTE_SETTINGS,
  ROUTE_SETTINGS_LANGUAGES,
  ROUTE_SETTINGS_CURRENCIES,
  ROUTE_SETTINGS_USERS,
  ROUTE_SETTINGS_DETAIL_USER,
  ROUTE_SETTINGS_SUBSCRIPTIONS,
  ROUTE_SETTINGS_ACCESS,
  ROUTE_SETTINGS_TAXRATES,
  ROUTE_SETTINGS_GROUPRATES,
  ROUTE_PRODUCTS_LIST,
  ROUTE_WAREHOUHSES,
  ROUTE_WAREHOUSE_DETAIL,
  ROUTE_LOCATION_DETAIL,
  ROUTE_PRODUCTS_LIST_DETAIL,
  ROUTE_ORDERS_STOCKS,
  ROUTE_DASHBOARD_HUB,
  ROUTE_ORDERS_TRANSFER
} from '../../../js/constants/route-names';

import Homepage from './homepage/Homepage';



import SettingsLanguages from './settings/SettingsLanguages';
import SettingsCurrencies from './settings/SettingsCurrencies';
import SettingsUsers from './settings/SettingsUsers';
import UserDetail from './settings/UserDetail';
import SettingsSubscriptions from './settings/SettingsSubscriptions';
import SettingsAccess from './settings/SettingsAccess';
import ListOrders from './orders/ListOrders';
import OrderDetails from './orders/OrderDetails';
import SettingsTaxRates from './settings/SettingsTaxRates';
import SettingsGroupRates from './settings/SettingsGroupRates';
import ListProducts from './products/ListProducts'
import ListWarehouses from './warehouses/ListWarehouses';
import WarehouseDetails from './warehouses/WarehouseDetails';
import LocationDetails from './warehouses/LocationDetails';
import ProductDetail from './products/ProductDetail';
import NewOrder from './orders/NewOrder';
import LayerBreadCrumb from '../../navigation/LayerBreadCrumb';
import ListStocks from './orders/ListStocks';
import DashboardStatsHub from './stats/DashboardStatsHub';
import ListAwaitingBankTransfer from "./orders/ListAwaitingBankTransfer";

function Dashboard(props) {
    const [open, setOpen] = React.useState(window.innerWidth <= 1000 ? false : true);
    const [typoSystem, setTypoSystem] = React.useState([]);

    const handleDrawerToggle = () => setOpen(!open);

    var drawerWidth = 300;

    if(window.innerWidth > 1600){
      open ? drawerWidth = 300 : drawerWidth = 96;
    }
    if(window.innerWidth <= 1600 && window.innerWidth > 1400){
      open ? drawerWidth = 240 : drawerWidth = 96;
    }
    else if(window.innerWidth <= 1400 && window.innerWidth > 1000){
      open ? drawerWidth = 180 : drawerWidth = 96;
    }
    else if(window.innerWidth <= 1000){
      open ? drawerWidth = 240 : drawerWidth = 0;
    }

    const useStyles = makeStyles(theme => ({
      root: {
        display: 'flex',
        minHeight: '100vh',
      },
      hide: {
        display: 'none',
      },
      drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        ...theme.mixins.toolbar,
        justifyContent: 'flex-end',
        '@media (max-width:1000px)': {
          minHeight: '40px',
        }
      },
      content: {
        width: `calc(100% - ${drawerWidth}px)`,
        flexGrow: 1,
        paddingTop: window.location.pathname !== '/' ? '20px' : null,
        paddingBottom: theme.spacing(4),
        // paddingBottom: window.location.pathname !== '/' ? theme.spacing(4) : null,
        transition: theme.transitions.create('margin', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        // "& > div > div": {
        //   paddingLeft: theme.spacing(4),
        //   paddingRight: theme.spacing(2),
        // },
        "& > div > h1": {
          paddingLeft: theme.spacing(4),
          paddingRight: theme.spacing(2),
          paddingTop: theme.spacing(2)
        },
        // "& > div > div.MuiBox-root": {
        //   paddingLeft: theme.spacing(4),
        //   paddingRight: theme.spacing(2),
        // },
        "& > div > div.MuiGrid-root": {
          paddingLeft: theme.spacing(4),
          paddingRight: theme.spacing(4),
        }
      },
      contentShift: {
        transition: theme.transitions.create('margin', {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
      },
    }));
    const classes = useStyles();

    const makeArrayFonts = () => {
      props.client.query({
        query: GET_SYSTEM_TYPOS,
        fetchPolicy: 'no-cache'
      }).then(result =>{
        let array = [];
        for(let font of result.data.typographySystems.edges){
            array.push({font: font.node.value});
        }
        setTypoSystem(array)
        return(array);
      });
    }
    let pathname = props.location.pathname
    let customState = props.location?.state?.customState
    let startBreadcrumb = { url: ROUTE_HOME, identifier: 'Home' }


    return (
      <div className={classes.root}>
        <CssBaseline />
        {
          pathname !== '/' ?
            <Appbar openCallback={handleDrawerToggle} opened={open} user={props.user} windowWidth={props.windowWidth} drawerWidth={drawerWidth} />
            : null
        }
      
        <DrawerLeft openCallback={handleDrawerToggle} pathname={pathname} opened={open} windowWidth={props.windowWidth} drawerWidth={drawerWidth} />
      
        <main
          className={clsx(classes.content, {
            [classes.contentShift]: open,
          })}
          style={{position:"relative"}}
        >
          {
            pathname !== '/' ?
              <section className={classes.drawerHeader} />
              : null
          }

          <Switch>
            <Route path={`${ROUTE_HOME}`} exact>
              <LayerBreadCrumb bc={[startBreadcrumb]}>
                <Homepage user={props.user} windowWidth={props.windowWidth}/>
              </LayerBreadCrumb>
            </Route>
            
            <Route path={`${ROUTE_DASHBOARD_HUB}`} exact>
              <LayerBreadCrumb bc={[startBreadcrumb]}>
                <DashboardStatsHub windowWidth={props.windowWidth}/>
              </LayerBreadCrumb>
            </Route>


            <Route path={`${ROUTE_ORDERS_LIST}`} exact>
              <LayerBreadCrumb bc={[startBreadcrumb, { url: ROUTE_ORDERS_LIST, identifier: 'Commandes' }]}>
                <ListOrders windowWidth={props.windowWidth} noStatus={"all"}/>
              </LayerBreadCrumb>
            </Route>
            <Route path={`${ROUTE_ORDERS_TRANSFER}`} exact>
              <LayerBreadCrumb bc={[startBreadcrumb, { url: ROUTE_ORDERS_TRANSFER, identifier: 'Virements en attente' }]}>
                <ListAwaitingBankTransfer windowWidth={props.windowWidth} noStatus={"all"}/>
              </LayerBreadCrumb>
            </Route>
            <Route path={`${ROUTE_ORDERS_NEW}`} exact>
              <LayerBreadCrumb bc={[startBreadcrumb, { url: ROUTE_ORDERS_NEW, identifier: 'Création de la commande' }]}>
                <NewOrder windowWidth={props.windowWidth} noStatus={"all"}/>
              </LayerBreadCrumb>
            </Route>
            <Route path={`${ROUTE_ORDERS_STOCKS}`} exact>
              <LayerBreadCrumb bc={[startBreadcrumb, { url: ROUTE_ORDERS_STOCKS, identifier: 'Stocks' }]}>
                <ListStocks windowWidth={props.windowWidth} />
              </LayerBreadCrumb>
            </Route>
            <Route path={`${ROUTE_ORDERS_AVOIR}`} exact>
              <LayerBreadCrumb bc={[startBreadcrumb, { url: ROUTE_ORDERS_NEW, identifier: "Création de l'avoir" }]}>
                <NewOrder windowWidth={props.windowWidth} noStatus={"all"} avoir={true}/>
              </LayerBreadCrumb>
            </Route>
            <Route path={`${ROUTE_ORDERS_LIST_PREORDER}`} exact>
              <LayerBreadCrumb bc={[startBreadcrumb, { url: ROUTE_ORDERS_LIST_PREORDER, identifier: 'Preorder' }]}>
                <ListOrders windowWidth={props.windowWidth} noStatus={'preorder'}/>
              </LayerBreadCrumb>
            </Route>
            <Route path={`${ROUTE_ORDERS_LIST_DELAY}`} exact>
              <LayerBreadCrumb bc={[startBreadcrumb, { url: ROUTE_ORDERS_LIST_DELAY, identifier: 'Delay' }]}>
                <ListOrders windowWidth={props.windowWidth} noStatus={'delayed'}/>
              </LayerBreadCrumb>
            </Route>
            <Route path={`${ROUTE_ORDERS_LIST_RETURN}`} exact>
              <LayerBreadCrumb bc={[startBreadcrumb, { url: ROUTE_ORDERS_LIST_RETURN, identifier: 'Retours' }]}>
                <ListOrders windowWidth={props.windowWidth} noStatus={'returned'}/>
              </LayerBreadCrumb>
            </Route>
            <Route path={`${ROUTE_ORDERS_DETAIL}`} exact>
              <LayerBreadCrumb bc={[startBreadcrumb, { url: ROUTE_ORDERS_LIST, identifier: 'Commandes' }, { url: ROUTE_ORDERS_DETAIL, identifier: 'Détails de la commande' }]}>
                <OrderDetails drawerWidth={drawerWidth} />
              </LayerBreadCrumb>
            </Route>

            <Route path={`${ROUTE_WAREHOUHSES}`} exact>
              <LayerBreadCrumb bc={[startBreadcrumb, { url: ROUTE_WAREHOUHSES, identifier: 'Entrepôts' }]}>
                  <ListWarehouses drawerWidth={drawerWidth} windowWidth={props.windowWidth} /> 
              </LayerBreadCrumb>
            </Route>
            <Route path={`${ROUTE_WAREHOUSE_DETAIL}`} exact>
              <LayerBreadCrumb bc={[startBreadcrumb, { url: ROUTE_WAREHOUHSES, identifier: 'Entrepôts' }, { url: ROUTE_WAREHOUSE_DETAIL, identifier: "Détails de l'entrepôt" }]}>
                <WarehouseDetails drawerWidth={drawerWidth} windowWidth={props.windowWidth} /> 
              </LayerBreadCrumb>
            </Route>

            <Route path={`${ROUTE_LOCATION_DETAIL}`} exact>
              <LayerBreadCrumb bc={[startBreadcrumb, { url: ROUTE_WAREHOUHSES, identifier: 'Entrepôts' }, { url: ROUTE_WAREHOUSE_DETAIL, identifier: "Détails de l'entrepôt" }, { url: ROUTE_LOCATION_DETAIL, identifier: "Détails de la zone" }]}>
                <LocationDetails drawerWidth={drawerWidth} /> 
              </LayerBreadCrumb>
            </Route>

            <Route path={`${ROUTE_PRODUCTS_LIST}`} exact>
              <ListProducts drawerWidth={drawerWidth} />
            </Route>
            <Route path={`${ROUTE_PRODUCTS_LIST_DETAIL}`} exact>
              <ProductDetail drawerWidth={drawerWidth} />
            </Route>

            



            <Route path={`${ROUTE_SETTINGS_LANGUAGES}`} exact>
              <SettingsLanguages windowWidth={props.windowWidth} />
            </Route>
            <Route path={`${ROUTE_SETTINGS_CURRENCIES}`} exact>
              <SettingsCurrencies windowWidth={props.windowWidth} />
            </Route>
            <Route path={`${ROUTE_SETTINGS_GROUPRATES}`} exact>
              <SettingsGroupRates windowWidth={props.windowWidth} />
            </Route>
            <Route path={`${ROUTE_SETTINGS_TAXRATES}`} exact>
              <SettingsTaxRates windowWidth={props.windowWidth} />
            </Route>
            <Route path={`${ROUTE_SETTINGS_USERS}`} exact>
              <SettingsUsers drawerWidth={drawerWidth} windowWidth={props.windowWidth} user={props.user} />
            </Route>
            <Route path={`${ROUTE_SETTINGS_DETAIL_USER}`} exact>
              <UserDetail drawerWidth={drawerWidth} user={props.user} />
            </Route>
            <Route path={`${ROUTE_SETTINGS_ACCESS}`} exact>
              <SettingsAccess />
            </Route>
            <Route path={`${ROUTE_SETTINGS_SUBSCRIPTIONS}`} exact>
              <SettingsSubscriptions />
            </Route>



            <Route path={`${ROUTE_SETTINGS}`}>
              <Typography variant="body1">
                Paramètres
              </Typography>
            </Route>
          </Switch>
        </main>

      </div>
    );
}

const mapDispatchToProps = dispatch => {
  return {
    startLoading: () => dispatch({ type: START_LOADING }),
    stopLoading: () => dispatch({ type: STOP_LOADING }),
    setUser: user => dispatch({ type: SET_USER, payload: { user }}),
    setGuideline: guideline => dispatch({ type: SET_GUIDELINE, payload: { guideline }})
  }
}

const mapStateToProps = state => {
  return {
    loading: state.loading,
    user: state.user,
    guideline: state.guideline,
  };
};

export default withRouter(withApollo(connect(mapStateToProps, mapDispatchToProps)(Dashboard)));