import React from 'react';
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import 'moment/locale/fr';
import { withRouter } from "react-router";
import { matchPath } from "react-router";
import { withStyles } from '@material-ui/core/styles';

import Drawer from '@material-ui/core/Drawer';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';

import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Tooltip from '@material-ui/core/Tooltip';

import iconSet from "../../assets/selection.json";
import IcomoonReact from "icomoon-react";

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';

import styled from 'styled-components';
import colors from '../../config/theme/colors';
import './DrawerLeft.scss';
import { SET_NAVIGATION } from '../../js/constants/action-types';
import setUpDrawer from './drawer.config.js';
import _ from 'lodash';

// import {withTranslation} from 'react-i18next';
import { getTraductionAttributs } from '../../js/utils/functions';
// import i18n from '../../i18n';
import { Box, Grid } from '@material-ui/core';
import { ROUTE_HOME } from '../../js/constants/route-names';

const isCRM = process.env.REACT_APP_MODE_CRM === 'true';

var drawerWidth = 300;

if(window.innerWidth <= 1600 && window.innerWidth>1400){
    drawerWidth = 240;
}
else if(window.innerWidth <= 1400 && window.innerWidth > 1000){
    drawerWidth = 210;
}
else{
    drawerWidth = 240;
}

const styles = theme => ({
    root: {
        display: 'flex',
        '&>main': {
        },
    },
    appBar: {
        transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: `${drawerWidth}px`,
        flexShrink: 0,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerOpen: {
        width: `${drawerWidth}px`,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        boxShadow: 'none !important',
        border: `0.5px solid ${colors.grey.lighter.hue700}`,
        background:isCRM ? colors.crmNavBackground : colors.white,
        overflowX: `hidden`,
        height: '100%',
        paddingTop : isCRM ? 120 : 0,
        ['@media (max-width:1000px)']: { // eslint-disable-line no-useless-computed-key
            background: 'rgba(0,0,0,0)',
            boxShadow: 'initial',
        }
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        border: `0.5px solid ${colors.grey.lighter.hue700}`,
        overflowX: 'hidden',
        background: isCRM ? colors.crmNavBackground : colors.white,
        width: theme.spacing(7) + 1,
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(0),
        },
        height: '100%',
        paddingTop : isCRM ? 120 : 0,
        '& .MuiAccordionSummary-expandIcon': {
            display: 'none',
        },
        '& .MuiListItemText-root': {
            display: 'none',
        },
        '& p': {
            whiteSpace: 'pre-wrap',
            textAlign: 'center',
            width: '100%',
            fontSize: 13
        },
        '& .activePanel':{
            borderLeft:'none!important'
        },
        ['@media (max-width:1000px)']: { // eslint-disable-line no-useless-computed-key
            background: 'rgba(0,0,0,0)',
            boxShadow: 'initial',
        }
    },
    itemOpen: {
        padding: `0.5rem 1.5rem 0.5rem 2.5rem`,
    },
    itemClose: {
        padding: `0.5rem 1rem 0.5rem 2.2rem`,
        width: `auto`,
    },
    itemTextOpen: {
        display: `block`,
    },
    itemTextClose: {
        display: `none`,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: -drawerWidth,
    },
    contentShift: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    },
});

const AccordionCustom = styled(Accordion)`
    box-shadow: none;
    margin-bottom: 0 !important;
    background: transparent;
    &:before{
        display: none;
    }
    border-bottom: 0.5px solid ${colors.grey.lighter.hue900};
    &.Mui-expanded{
        margin: 0 !important;
    }
`;

const AccordionCustomSub = styled(AccordionCustom)`
    border-bottom: 0;
`;

// '+'
const AccordionSummaryCustom = styled(AccordionSummary)`
    padding: 4px 16px;
    min-height: 40px;

    .MuiIconButton-root {
        padding: 4px;

        .MuiSvgIcon-root {
            font-size: 1rem;   
        }
    }
    &> .MuiAccordionSummary-content{
        margin: 7px 0;
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    &.Mui-expanded{
        min-height: inherit;
        
        .MuiAccordionSummary-content {
            margin: 7px 0;
        }
        p{
            color: ${colors.black.regular};
        }
    }
    &:hover{
        p{
            color: ${colors.black.regular};
        }
        .MuiIconButton-root svg{
            /* background: ${colors.grey.lighter.hue980}; */
        }
        svg{
            color: ${colors.black.regular};
        }
    }
    svg{
        color: ${isCRM ? colors.white : colors.black.regular.regular};
    }
    p{
        font-weight: bold;
        font-size: 12px;
    }
    @media screen and (max-width: 1400px){
        padding: 0 10px;
        &> .MuiAccordionSummary-content{
            margin: 10px 0;
        }
    }
`;

const AccordionSummaryCustomSub = styled(AccordionSummaryCustom)`
    /* background: ${colors.grey.lighter.hue980}; */
    padding-left: 40px !important;
    border: 0;
    min-height: auto;
    padding-top: 6px;
    padding-bottom: 6px;
    @media screen and (max-width: 1400px){
        padding-left: 24px!important;
    }
    p{
        color: ${colors.black.regular};
        font-size: 14px;
        line-height: 18px;
        font-weight: normal;
    }
    &:hover{
        background:${colors.blue.lighter.hue600}
    }
    svg{
        path{
            fill: ${colors.blue.lighter.hue200} !important;
        }
    }
    span{
        color: ${colors.black.regular} !important;
    }
    &.activeNav{
        background: ${colors.blue.lighter.hue600} !important;
        svg{
            path{
                fill: ${colors.black.regular} !important;
            }
        }
        p{
            color: ${colors.black.regular} !important;
            font-weight: bold;
        }
    }
    &> .MuiAccordionSummary-content{
        margin: 0;
    }
`

const AccordionDetailsCustom = styled(AccordionDetails)`
    padding: 0;
`;
const AccordionDetailsSubCustom = styled(AccordionDetailsCustom)`
    padding: 16px 0px !important;
    /* background: ${colors.grey.lighter.hue980}; */
`;

const ListCustom = styled(List)`
    width: 100%;
    padding: 0;
`;

const ListItemCustom = styled(ListItem)`
    &:hover{
        background-color: unset;
        /* background-color: ${isCRM ? `#a6cde3`: colors.grey.lighter.hue980}; */
        /* svg{
            path{
                fill: ${colors.black.regular};
            }
        }
        span{
            color: ${colors.black.regular};
        } */
    }
    @media screen and (max-width: 1400px){
        padding-left: 10px!important;
        padding-right: 10px !important;
        max-width: 100%;
    }
`;
const ListItemSubTitleCustom = styled(ListItemCustom)`
    background: ${colors.blue.regularv2} !important;
    &:hover{
        background:${colors.blue.regularv2} !important;
    }
`;

const ListItemSubCustom = styled(ListItemCustom)`
    /* background: ${colors.grey.lighter.hue980}; */
    padding-left: 40px !important;
    @media screen and (max-width: 1400px){
        padding-left: 24px!important;
    }
    &:hover{
        background:${colors.blue.lighter.hue600}
    }
    svg{
        path{
            fill: ${colors.blue.lighter.hue200} !important;
        }
    }
    span{
        color: ${colors.black.regular} !important;
    }
    &.activeNav{
        background: ${colors.blue.lighter.hue600} !important;
        svg{
            path{
                fill: ${colors.black.regular} !important;
            }
        }
        span{
            color: ${colors.black.regular} !important;
        }
    }
`;

const ListItemSubSubCustom = styled(ListItemSubCustom)`
    padding-left: 85px !important;
    min-height: 44px;
    /* background: ${colors.grey.lighter.hue980} !important; */
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    @media screen and (max-width: 1400px){
        padding-left: 70px!important;
    }
    span{
        color: ${colors.blue.regularv2}
    }
    &.activeNav, &:hover{
        /* background: ${colors.grey.lighter.hue980} !important; */
        /* svg{
            path{
                fill: ${colors.black.regular} !important;
            }
        }
        span{
            color: ${colors.black.regular} !important;
            font-weight: bold;
        } */
    }
`;

const TypographyCustom = styled(Typography)`
    color: ${isCRM ? colors.white : colors.black.regular};
    &:first-letter{
        text-transform: capitalize
    }
`;

const ListItemTextCustom = styled(ListItemText)`
    span{
        color: ${isCRM ? colors.white : colors.black.regular};
        font-size: 14px;
        @media screen and (max-width: 1400px){
            line-height: 18px;
        }
    }
`;

const ListItemSubTextCustom = styled(ListItemTextCustom)`
    position: relative;
    margin: 0;
    &:before{
        content:"";
        background: ${colors.blue.lighter.hue200}; 
        width: 1.5px;
        height: 100%;
        min-height: 44px;
        position: absolute;
        left: -16px;
        top: 50%;
        transform: translateY(-50%);
    }
`

const ListItemIconCustom = styled(ListItemIcon)`
    svg{
        color: ${colors.white};
        border-radius: 5px;
        ${ isCRM ?
        `path{
            fill : ${colors.white} !important
        }`
        :
        `path{
            fill : ${colors.black.regular.regular}
        }`}
    }
    min-width: auto !important;
`;

const DrawerCustom = styled(Drawer)`
    width: ${props => props.drawerwidth ? props.drawerwidth : null}px;
    transition: all 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
    box-shadow: none;
    &#closed{
        @media screen and (max-width: 1400px){
            .MuiListItem-gutters{
                justify-content: center;
            }
        }
    }
    .MuiDrawer-paper{
        width: ${props => props.drawerwidth ? `${props.drawerwidth}px` : null};
    }
    .MuiListItem-root{
        width: ${props => props.drawerwidth ? `${props.drawerwidth}px` : null};
    }
`;

const SwipeableDrawerCustom = styled(SwipeableDrawer)`
    .MuiPaper-root{
        position: relative;
        width: calc(100% - 2px);
    }
`;

const EncartTopLeft = styled(Box)`
    left: 0;
    width: 100%;
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow:hidden;
    transition: all 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
    img{
        display: inline-block;
        padding-right: 10px;
        padding-left: 10px;
        width: 120px;
        height: auto;
        max-width: 100%;
    }
    .MuiTypography-h2{
        color: ${colors.black.regular.regular};
        display: inline-block;
        padding-right: 20px;
    }
`;

const useStylesBootstrap = makeStyles(theme => ({
    arrow: {
        color: colors.black.regular.regular,
    },
    tooltip: {
        backgroundColor: colors.black.regular.regular,
        fontSize: 14,
    },
}));

function BootstrapTooltip(props) {
    const classes = useStylesBootstrap();
    return <Tooltip arrow classes={classes} {...props} />;
}

class DrawerLeft extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            currentLang: this.props.locales?.[0]?.node?.code,
            expanded: null,
            expandedSub: null,
            menuItems: null,
            subMenuItems: null,
            childActive: null,
            detailActive: null,
            assetName: '...',
            asset: JSON.parse(localStorage.getItem('ASSET_CURRENT')) || null,
        };
        window.setAssetNameMenu = this.setAssetNameMenu
    }

    handleChange = panel => (event, expanded) => {
        this.setState({
            expanded: expanded ? panel : false,
        });
    };

    handleChangeSub = panel => (event, expandedSub) => {
        this.setState({
            expandedSub: expandedSub ? panel : false,
        });
    };

    goTo = (route, customState) => {
        this.props.history.push({
            pathname : route,
            state: {customState : customState}
        });
    };

    setAssetNameMenu= (asset) => {
        // let getName = getTraductionAttributs('asset_store_name', asset.assetDatas?.edges, i18n.language)
        // localStorage.setItem('ASSET_CURRENT', JSON.stringify(asset))
        // this.setState({
        //     asset: asset,
        //     assetName: getName
        // })
    }

    checkPath = (path) => {
        let pathFound = false;
        for(let item of this.state.menuItems){
            if(item.allRoutes.indexOf(path)> -1 || (!item.isHome && path.includes(item.route)))  {
                pathFound = true
                this.setState({
                    expanded: item.route,
                })
                let getChilds = item.childrens?.filter(e => e?.childrensStructure);
                let parentRoute = this.getParent(getChilds, path);
                let parentRouteSub = this.getParent(getChilds, path, true);
                let isDetail = path.includes('/detail/');
                let isPriceDetail = path.includes('prices/list/detail');                
                if (parentRoute || item.childrens.find(e => e?.route === path) || isDetail){
                    if (isDetail && !isPriceDetail) {
                        let parentSetup = item.childrens.find(e => e?.route === parentRoute)
                        this.setState({
                            childActive: parentSetup,
                            detailActive: item.childrens.find(e => path?.includes(e?.route)),
                            expandedSub: parentRouteSub
                        })
                    } 
                    else if (isPriceDetail) {
                        localStorage.removeItem('ASSET_CURRENT')
                        this.setState({
                            assetName: '...',
                            childActive: item.childrens.find(e => e?.route === '/products/prices'),
                            expandedSub: parentRouteSub,
                            detailActive: null
                        })
                    } 
                    else if (item.childrens.find(e => e?.route === path)){
                        localStorage.removeItem('ASSET_CURRENT')
                        this.setState({
                            assetName: '...',
                            childActive: item.childrens.find(e => e?.route === path),
                            expandedSub: parentRouteSub,
                            detailActive: null
                        })
                    }else{
                        let parentSetup = item.childrens.find(e => e?.route === parentRoute)
                        localStorage.removeItem('ASSET_CURRENT')
                        this.setState({
                            assetName: '...',
                            childActive: parentSetup,
                            detailActive: null
                        })
                    }
                }else{
                    localStorage.removeItem('ASSET_CURRENT')
                    this.setState({
                        assetName: '...',
                        childActive: null,
                        detailActive: null
                    })
                }
            }
        }if(!pathFound){
            localStorage.removeItem('ASSET_CURRENT')
            this.setState({
                expanded: null,
                childActive: null,
                detailActive: null
            })
        }
    };

    isPathChildren = (path,childrens) =>{
        for(let children of childrens){
            const match = matchPath(path, {
                path: children,
                exact: true,
                strict: false
            });
            if(match){
                return true;
            }          
        }
        return false;
    };

    getParent = (childrens, path, isSub =false) =>{
        if (isSub){
            for(let children of childrens){
                let match = children.childrensStructure.filter(e => e.childrensStructure)
                if(match){
                    for(let children of match){
                        for(let sub of children.childrens){
                            const getMatch = matchPath(path, {
                                path: sub,
                                exact: true,
                                strict: false
                            });
                            if (getMatch){
                                const valueParent = children.childrensStructure.find(e => e.route === getMatch.path || getMatch.path.includes(e.route))
                                return valueParent?.parent;
                            }
                        }
                    }
                } 
            }

            return false;
        }else{
            for(let children of childrens){
                const match = children.childrensStructure.find(e => e.route === path)
                if(match){
                    return match.parent;
                } 
            }
        }
       
        return false
    }

    componentDidUpdate(prevProps){
        if(prevProps.location.pathname !== this.props.location.pathname){
            this.props.setNavigation(this.props.location.pathname)
            if (JSON.parse(localStorage.getItem('ASSET_CURRENT'))){
                this.setAssetNameMenu(JSON.parse(localStorage.getItem('ASSET_CURRENT')))
            }
            this.checkPath(this.props.location.pathname)
            this.setState({
                menuItems : setUpDrawer(isCRM)
            })
        }
        if (prevProps.attributes !== this.props.attributes){
            this.setState({
                menuItems : setUpDrawer(isCRM)
            })
        }
    }

    componentDidMount(){
        this.setState({
            menuItems : setUpDrawer(isCRM)
        }, () => {
            if (JSON.parse(localStorage.getItem('ASSET_CURRENT'))){
                this.setAssetNameMenu(JSON.parse(localStorage.getItem('ASSET_CURRENT')))
            }
            this.props.setNavigation(this.props.location.pathname)
            this.checkPath(this.props.location.pathname)
        })
    }

    getGuidelineName = () => {
        if(this.props.guideline){
            if(this.props.guideline.libelle){
                return this.props.guideline.libelle;
            }
            return null;
        }
        else{
            return null;
        }
    };

    getLinkBrand = () => {
        if(this.props.guideline){
            for(let guidelineData of this.props.guideline.guidelineData.edges){
                if(guidelineData.node.locale.code === this.state.currentLang && guidelineData.node.attribute.identifier === "guideline_url"){
                    return(guidelineData.node.value)
                }
            }
            return null;
        }
        else{
            return null;
        }
    };

    getGuidelineImg = () => {
        if (this.props.guideline){
            for (let guidelineData of this.props.guideline.guidelineData.edges){
                if (guidelineData.node.locale.code === this.state.currentLang && guidelineData.node.attribute.identifier === "guideline_logo"){
                    return process.env.REACT_APP_API_ROOT+'/medias/'+guidelineData.node.media?.filePath;
                }
            }
            return null;
        }
        else{
            return null;
        }
    };

    render(){
        const { props } = this;
        const { expanded, menuItems, expandedSub } = this.state;
        const { classes,t } = this.props;
        const isCRM = process.env.REACT_APP_MODE_CRM === 'true';
        if(props.windowWidth <= 1000){
            return(
                <SwipeableDrawerCustom
                    className={clsx(classes.drawer, {
                        [classes.drawerOpen]: props.opened,
                        [classes.drawerClose]: !props.opened,
                    })}
                    open={props.opened}
                    style={{zIndex: 100}}
                    onClose={() => {return null}}
                    onOpen={() => {return null}}
                >
                    <Grid container alignItems='center' justifyContent="center" style={{backgroundColor: 'white', zIndex: 1, minHeight: 200}}>
                    {/* {
                        isCRM ?
                            null
                        :
                            <EncartTopLeft className={props.opened ? 'open' : 'close'} imagefound={(this.getGuidelineImg() !== null && isCRM === false) ? "true" : "false"} drawerwidth={props.opened ? props.drawerWidth : drawerWidth} onClick={() => this.goTo(ROUTE_HOME)} style={{cursor: 'pointer'}}>
                                {
                                    this.getGuidelineImg() !== null && isCRM === false ? (
                                        <img src={this.getGuidelineImg()} alt={this.getGuidelineName()} />
                                    ) : this.getGuidelineName() !== null && isCRM === false ? (
                                            <Typography variant="h2">{this.getGuidelineName()}</Typography>
                                        ) : null
                                }
                            </EncartTopLeft>
                    } */}
                    </Grid>
                    { menuItems ? 
                        menuItems.map((item, index) => {
                            if (!item.childrens.every(_.isNull)){
                                if (!item.notAccordion){
                                    return(
                                        <AccordionCustom
                                            expanded={expanded === item.route}
                                            onChange={this.handleChange(item.route)} 
                                            TransitionProps={{ unmountOnExit: true }}
                                            key={`MenuItem${index}`}
                                        >
                                            <AccordionSummaryCustom
                                                expandIcon={expanded === item.route ? <RemoveIcon /> : <AddIcon />}
                                                className={ item.allRoutes.indexOf(props.location.pathname) > -1 || (!item.isHome && props.location.pathname.includes(item.route)) ? 'activePanel' : ''}
                                            >
                                                <TypographyCustom variant="body2">{item.name}</TypographyCustom>
                                                {/* <TypographyCustom variant="body2">{t(item.name,item.nameArgs)}</TypographyCustom> */}
                                            </AccordionSummaryCustom>
                                            <AccordionDetailsCustom>
                                                <ListCustom>
                                                {
                                                    item.childrens.map((children,index) =>{
                                                        if (children !== null){
                                                            if (children.hasSubmenu || this.state.detailActive){
                                                                return(
                                                                    <>
                                                                        <BootstrapTooltip 
                                                                        title={children.name} 
                                                                        placement="right" 
                                                                        disableHoverListener={props.opened ? true : false} 
                                                                        className={clsx(classes.tooltip)} 
                                                                        key={`ChildItem${index}`}
                                                                        >
                                                                            <ListItemCustom button onClick={() => {this.goTo(children.route, children.customState); this.setState({subMenuItems: children.childrensStructure})}} className={
                                                                                clsx(classes.drawer, {
                                                                                    [classes.itemOpen]: props.opened,
                                                                                    [classes.itemClose]: !props.opened,
                                                                                },
                                                                                props.location.pathname === children.route || (children.childrens?this.isPathChildren(props.location.pathname,children.childrens):false)? 'activeNav' : '')
                                                                            }>
                                                                                <ListItemIconCustom>
                                                                                    <IcomoonReact iconSet={iconSet} size={28} icon={children.icone} />
                                                                                </ListItemIconCustom>
                                                                                <ListItemTextCustom primary={children.name} />
                                                                                {/* <ListItemTextCustom primary={t(children.name,children.nameArgs)} /> */}
                                                                            </ListItemCustom>
                                                                        </BootstrapTooltip>
                                                                        {
                                                                            this.state.detailActive && this.state.detailActive.route === children.route && this.state.detailActive.childrensStructure ?
                                                                            <>
                                                                                <BootstrapTooltip 
                                                                                title={this.state.assetName} 
                                                                                placement="right" 
                                                                                disableHoverListener={props.opened ? true : false} 
                                                                                className={clsx(classes.tooltip)} 
                                                                                // key={`ChildItemSub${indexSub}`}
                                                                                >
                                                                                    <ListItemSubTitleCustom className={
                                                                                        clsx(classes.drawer, {
                                                                                            [classes.itemOpen]: props.opened,
                                                                                            [classes.itemClose]: !props.opened,
                                                                                        },
                                                                                        this.state.detailActive.route === children.route ? 'activeNav' : '')
                                                                                    }>
                                                                                        {/* <ListItemIconCustom>
                                                                                            <IcomoonReact iconSet={iconSet} size={28} icon={'ico_retour_fonce'} />
                                                                                        </ListItemIconCustom> */}
                                                                                        <ListItemTextCustom primary={this.state.assetName} />
                                                                                    </ListItemSubTitleCustom>
                                                                                </BootstrapTooltip>
                                                                                {
                                                                                    this.state.assetName !== '...' ? 
                                                                                        this.state.detailActive.childrensStructure?.map((item, indexAsset) => {
                                                                                            var numbAssetId = props.location.pathname.match(/\d+/)?.[0]
                                                                                            return(
                                                                                                <BootstrapTooltip 
                                                                                                title={item.name} 
                                                                                                // title={t(item.name,item.nameArgs)} 
                                                                                                placement="right" 
                                                                                                disableHoverListener={props.opened ? true : false} 
                                                                                                className={clsx(classes.tooltip)} 
                                                                                                key={`ChildItemSubAsset${indexAsset}`}
                                                                                                >
                                                                                                    <ListItemSubCustom button onClick={() => {this.goTo(item.route.replace(':id', numbAssetId || this.state.asset.id.replace('/api/assets/', '')).replace(':typeAsset', this.state.asset.assetType.identifier), item.customState);}} className={
                                                                                                        clsx(classes.drawer, {
                                                                                                            [classes.itemOpen]: props.opened,
                                                                                                            [classes.itemClose]: !props.opened,
                                                                                                        },
                                                                                                        props.location.pathname === item.route.replace(':id', numbAssetId || this.state.asset.id.replace('/api/assets/', '')).replace(':typeAsset', this.state.asset.assetType.identifier) || (item.childrens? this.isPathChildren(props.location.pathname,item.childrens):false) ? 'activeNav' : '')
                                                                                                    }>
                                                                                                        <ListItemIconCustom>
                                                                                                            <IcomoonReact iconSet={iconSet} size={28} icon={'ico_retour_fonce'} />
                                                                                                        </ListItemIconCustom>
                                                                                                        <ListItemTextCustom primary={item.name}/>
                                                                                                        {/* <ListItemTextCustom primary={t(item.name,item.nameArgs)}/> */}
                                                                                                    </ListItemSubCustom>
                                                                                                </BootstrapTooltip>
                                                                                            )
                                                                                        })
                                                                                    : null
                                                                                }
                                                                            </>
                                                                            : null
                                                                        }
                                                                        {
                                                                            this.state.childActive && this.state.childActive.hasSubmenu && (this.state.childActive.parent === children.route || this.state.childActive.route === children.route) ?
                                                                            this.state.childActive.childrensStructure.map((childActive, indexSub) => {
                                                                                return (
                                                                                    <BootstrapTooltip 
                                                                                    title={childActive.name} 
                                                                                    // title={t(childActive.name,childActive.nameArgs)} 
                                                                                    placement="right" 
                                                                                    disableHoverListener={props.opened ? true : false} 
                                                                                    className={clsx(classes.tooltip)} 
                                                                                    key={`ChildItemSub${indexSub}`}
                                                                                    >
                                                                                        <ListItemSubCustom button onClick={() => {this.goTo(childActive.route, childActive.customState);}} className={
                                                                                            clsx(classes.drawer, {
                                                                                                [classes.itemOpen]: props.opened,
                                                                                                [classes.itemClose]: !props.opened,
                                                                                            },
                                                                                            props.location.pathname === childActive.route || (childActive.childrens?this.isPathChildren(props.location.pathname,childActive.childrens):false)? 'activeNav' : '')
                                                                                        }>
                                                                                            <ListItemIconCustom>
                                                                                                <IcomoonReact iconSet={iconSet} size={28} icon={'ico_retour_fonce'} />
                                                                                            </ListItemIconCustom>
                                                                                            <ListItemTextCustom primary={childActive.name} />
                                                                                            {/* <ListItemTextCustom primary={t(childActive.name,childActive.nameArgs)} /> */}
                                                                                        </ListItemSubCustom>
                                                                                    </BootstrapTooltip>
                                                                                )
                                                                            })
                                                                        : null
                                                                        }
                                                                    </>
                                                                )
                                                            }else{
                                                                return(
                                                                    <BootstrapTooltip 
                                                                    title={children.name} 
                                                                    // title={t(children.name,children.nameArgs)} 
                                                                    placement="right" 
                                                                    disableHoverListener={props.opened ? true : false} 
                                                                    className={clsx(classes.tooltip)} 
                                                                    key={`ChildItem${index}`}
                                                                    >
                                                                        <ListItemCustom button onClick={() => {this.goTo(children.route, children.customState); this.setState({subMenuItems: null})}} className={
                                                                            clsx(classes.drawer, {
                                                                                [classes.itemOpen]: props.opened,
                                                                                [classes.itemClose]: !props.opened,
                                                                            },
                                                                            props.location.pathname === children.route || (children.childrens?this.isPathChildren(props.location.pathname,children.childrens):false)? 'activeNav' : '')
                                                                        }>
                                                                            <ListItemIconCustom>
                                                                                <IcomoonReact iconSet={iconSet} size={28} icon={children.icone} />
                                                                            </ListItemIconCustom>
                                                                            <ListItemTextCustom primary={children.name} />
                                                                            {/* <ListItemTextCustom primary={t(children.name,children.nameArgs)} /> */}
                                                                        </ListItemCustom>
                                                                    </BootstrapTooltip>
                                                                )
                                                            }

                                                        }else{
                                                            return null
                                                        }
                                                    })
                                                }
                                                </ListCustom>
                                            </AccordionDetailsCustom>
                                        </AccordionCustom>
                                        // Version
                                                )
                                }else{
                                    return(
                                        <AccordionCustom
                                            TransitionProps={{ unmountOnExit: true }}
                                            key={`MenuItem${index}`}
                                            onClick={() => {this.goTo(item.route)}}
                                        >
                                            <AccordionSummaryCustom
                                                className={ item.allRoutes.indexOf(props.location.pathname) > -1 || (!item.isHome && props.location.pathname.includes(item.route)) ? 'activePanel' : ''}
                                            >
                                                <TypographyCustom variant="body2">{item.name}</TypographyCustom>
                                                {/* <TypographyCustom variant="body2">{t(item.name,item.nameArgs)}</TypographyCustom> */}
                                            </AccordionSummaryCustom>
                                        </AccordionCustom>
                                    )
                                }
                            }else{
                                return null
                            }
                        })
                    : null } 
                </SwipeableDrawerCustom>
            )
        }
        else{
            return(
                <DrawerCustom
                    className={clsx(classes.drawer, {
                        [classes.drawerOpen]: props.opened,
                        [classes.drawerClose]: !props.opened,
                    })}
                    id={!props.opened ? 'closed': ''}
                    variant={'permanent'}
                    drawerwidth={props.drawerWidth}
                    classes={{
                        paper: clsx({
                            [classes.drawerOpen]: props.opened,
                            [classes.drawerClose]: !props.opened,
                        }),
                    }}
                    style={{width: props.drawerWidth}}
                >
                    <Grid container alignItems='center' justifyContent="center" style={{backgroundColor: 'white', zIndex: 1, minHeight: 193}}>
                    {
                        isCRM ?
                            null
                        :
                            <EncartTopLeft className={props.opened ? 'open' : 'close'} imagefound={(this.getGuidelineImg() !== null && isCRM === false) ? "true" : "false"} drawerwidth={props.opened ? props.drawerWidth : drawerWidth} onClick={() => this.goTo(ROUTE_HOME)} style={{cursor: 'pointer'}}>
                                {
                                    this.getGuidelineImg() !== null && isCRM === false ? (
                                        <img src={this.getGuidelineImg()} alt={this.getGuidelineName()} />
                                    ) : this.getGuidelineName() !== null && isCRM === false ? (
                                            <Typography variant="h2">{this.getGuidelineName()}</Typography>
                                        ) : null
                                }
                            </EncartTopLeft>
                    }
                    </Grid>
                    { menuItems ? 
                        menuItems.map((item, index) => {
                            if (!item.childrens.every(_.isNull)){
                                if (!item.notAccordion){
                                    return(
                                        <AccordionCustom
                                            expanded={expanded === item.route}
                                            onChange={this.handleChange(item.route)} 
                                            TransitionProps={{ unmountOnExit: true }}
                                            key={`MenuItem${index}`}
                                        >
                                            <AccordionSummaryCustom
                                                expandIcon={expanded === item.route ? <RemoveIcon /> : <AddIcon />}
                                                className={ item.allRoutes.indexOf(props.location.pathname) > -1 || (!item.isHome && props.location.pathname.includes(item.route)) ? 'activePanel' : ''}
                                            >
                                                <TypographyCustom variant="body2">{item.name}</TypographyCustom>
                                                {/* <TypographyCustom variant="body2">{t(item.name,item.nameArgs)}</TypographyCustom> */}
                                            </AccordionSummaryCustom>
                                            <AccordionDetailsCustom>
                                                <ListCustom>
                                                {
                                                    item.childrens.map((children,index) =>{
                                                        if (children){
                                                            if (children.hasSubmenu || this.state.detailActive || children.staticItem){
                                                                return(
                                                                    <>
                                                                        <BootstrapTooltip 
                                                                        title={children.name} 
                                                                        // title={t(children.name,children.nameArgs)} 
                                                                        placement="right" 
                                                                        disableHoverListener={props.opened ? true : false} 
                                                                        className={clsx(classes.tooltip)} 
                                                                        key={`ChildItem${index}`}
                                                                        >
                                                                            <ListItemCustom button onClick={() => {this.goTo(children.route, children.customState); this.setState({subMenuItems: children.childrensStructure})}} className={
                                                                                clsx(classes.drawer, {
                                                                                    [classes.itemOpen]: props.opened,
                                                                                    [classes.itemClose]: !props.opened,
                                                                                },
                                                                                props.location.pathname === children.route || (children.childrens?this.isPathChildren(props.location.pathname,children.childrens):false)? 'activeNav' : '')
                                                                            }>
                                                                                <ListItemIconCustom>
                                                                                    <IcomoonReact iconSet={iconSet} size={28} icon={children.icone} />
                                                                                </ListItemIconCustom>
                                                                                <ListItemTextCustom primary={children.name} />
                                                                                {/* <ListItemTextCustom primary={t(children.name,children.nameArgs)} /> */}
                                                                            </ListItemCustom>
                                                                        </BootstrapTooltip>
                                                                        {
                                                                            children.staticItem && this.state.childActive?.route === children.route ? 
                                                                                <BootstrapTooltip 
                                                                                    title={children.staticItem.title} 
                                                                                    // title={t(children.staticItem.title)} 
                                                                                    placement="right" 
                                                                                    disableHoverListener={props.opened ? true : false} 
                                                                                    className={clsx(classes.tooltip)} 
                                                                                    // key={`ChildItemSub${indexSub}`}
                                                                                >
                                                                                    <ListItemSubTitleCustom className={
                                                                                        clsx(classes.drawer, {
                                                                                            [classes.itemOpen]: props.opened,
                                                                                            [classes.itemClose]: !props.opened,
                                                                                        },
                                                                                        this.state.childActive?.route === children.route ? 'activeNav' : '')
                                                                                    }>
                                                                                        {/* <ListItemIconCustom>
                                                                                            <IcomoonReact iconSet={iconSet} size={28} icon={'ico_retour_fonce'} />
                                                                                        </ListItemIconCustom> */}
                                                                                        <ListItemTextCustom primary={children.staticItem.title} />
                                                                                        {/* <ListItemTextCustom primary={t(children.staticItem.title)} /> */}
                                                                                    </ListItemSubTitleCustom>
                                                                                </BootstrapTooltip>
                                                                            : null
                                                                        }
                                                                        {
                                                                            this.state.detailActive && this.state.detailActive.route === children.route && this.state.detailActive.childrensStructure ?
                                                                            <>
                                                                                <BootstrapTooltip 
                                                                                title={this.state.assetName} 
                                                                                placement="right" 
                                                                                disableHoverListener={props.opened ? true : false} 
                                                                                className={clsx(classes.tooltip)} 
                                                                                // key={`ChildItemSub${indexSub}`}
                                                                                >
                                                                                    <ListItemSubTitleCustom className={
                                                                                        clsx(classes.drawer, {
                                                                                            [classes.itemOpen]: props.opened,
                                                                                            [classes.itemClose]: !props.opened,
                                                                                        },
                                                                                        this.state.detailActive.route === children.route ? 'activeNav' : '')
                                                                                    }>
                                                                                        {/* <ListItemIconCustom>
                                                                                            <IcomoonReact iconSet={iconSet} size={28} icon={'ico_retour_fonce'} />
                                                                                        </ListItemIconCustom> */}
                                                                                        <ListItemTextCustom primary={this.state.assetName} />
                                                                                    </ListItemSubTitleCustom>
                                                                                </BootstrapTooltip>
                                                                                {
                                                                                    this.state.assetName !== '...' ? 
                                                                                        this.state.detailActive.childrensStructure?.map((item, indexAsset) => {
                                                                                            var numbAssetId = props.location.pathname.match(/\d+/)?.[0]
                                                                                            if (item.noRoute){
                                                                                                return(
                                                                                                    <AccordionCustomSub
                                                                                                        expanded={expandedSub === item.route}
                                                                                                        onChange={this.handleChangeSub(item.route)} 
                                                                                                        TransitionProps={{ unmountOnExit: true }}
                                                                                                        key={`MenuItem${index}`}
                                                                                                    >
                                                                                                        <AccordionSummaryCustomSub
                                                                                                            className={ expandedSub === item.route ? 'activeNav' : ''}
                                                                                                        >
                                                                                                            <ListItemIconCustom>
                                                                                                                {
                                                                                                                    expandedSub === item.route ? <RemoveIcon style={{width: 28, height: 28}}/> : <AddIcon style={{width: 28, height: 28}}/>
                                                                                                                }
                                                                                                            </ListItemIconCustom>
                                                                                                            <TypographyCustom variant="body2">{item.name}</TypographyCustom>
                                                                                                            {/* <TypographyCustom variant="body2">{t(item.name,item.nameArgs)}</TypographyCustom> */}
                                                                                                        </AccordionSummaryCustomSub>
                                                                                                        <AccordionDetailsSubCustom>
                                                                                                            <ListCustom>
                                                                                                                {
                                                                                                                    item.childrensStructure?.map((item2, indexAsset) => {
                                                                                                                        var numbAssetId = props.location.pathname.match(/\d+/)?.[0]
                                                                                                                        return(
                                                                                                                            <BootstrapTooltip 
                                                                                                                            title={item2.name} 
                                                                                                                            // title={t(item2.name,item2.nameArgs)} 
                                                                                                                            placement="right" 
                                                                                                                            disableHoverListener={props.opened ? true : false} 
                                                                                                                            className={clsx(classes.tooltip)} 
                                                                                                                            key={`ChildItemSubSubAsset${indexAsset}`}
                                                                                                                            >
                                                                                                                                <ListItemSubSubCustom button onClick={() => {this.goTo(item2.route.replace(':id', numbAssetId || this.state.asset.id.replace('/api/assets/', '')).replace(':typeAsset', this.state.asset.assetType.identifier), item2.customState);}} className={
                                                                                                                                    clsx(classes.drawer, {
                                                                                                                                        [classes.itemOpen]: props.opened,
                                                                                                                                        [classes.itemClose]: !props.opened,
                                                                                                                                    },
                                                                                                                                    props.location.pathname === item2.route.replace(':id', numbAssetId || this.state.asset.id.replace('/api/assets/', '')).replace(':typeAsset', this.state.asset.assetType.identifier) || (item2.childrens? this.isPathChildren(props.location.pathname,item2.childrens) : false)? 'activeNav' : '')
                                                                                                                                }>
                                                                                                                                    <ListItemSubTextCustom primary={item2.name} />
                                                                                                                                    {/* <ListItemSubTextCustom primary={t(item2.name,item2.nameArgs)} /> */}
                                                                                                                                </ListItemSubSubCustom>
                                                                                                                            </BootstrapTooltip>
                                                                                                                        )
                                                                                                                    })
                                                                                                                }
                                                                                                            </ListCustom>
                                                                                                        </AccordionDetailsSubCustom>
                                                                                                    </AccordionCustomSub>
                                                                                                )
                                                                                            }else{
                                                                                                return(
                                                                                                    <BootstrapTooltip 
                                                                                                    title={item.name} 
                                                                                                    // title={t(item.name,item.nameArgs)} 
                                                                                                    placement="right" 
                                                                                                    disableHoverListener={props.opened ? true : false} 
                                                                                                    className={clsx(classes.tooltip)} 
                                                                                                    key={`ChildItemSubAsset${indexAsset}`}
                                                                                                    >
                                                                                                        <ListItemSubCustom button onClick={() => {this.goTo(item.route.replace(':id', numbAssetId || this.state.asset.id.replace('/api/assets/', '')).replace(':typeAsset', this.state.asset.assetType.identifier), item.customState);}} className={
                                                                                                            clsx(classes.drawer, {
                                                                                                                [classes.itemOpen]: props.opened,
                                                                                                                [classes.itemClose]: !props.opened,
                                                                                                            },
                                                                                                            props.location.pathname === item.route.replace(':id', numbAssetId || this.state.asset.id.replace('/api/assets/', '')).replace(':typeAsset', this.state.asset.assetType.identifier) || (item.childrens? this.isPathChildren(props.location.pathname,item.childrens) : false)? 'activeNav' : '')
                                                                                                        }>
                                                                                                            <ListItemIconCustom>
                                                                                                                <IcomoonReact className='ico_force_return' iconSet={iconSet} size={28} icon={'ico_retour_fonce'} />
                                                                                                            </ListItemIconCustom>
                                                                                                            <ListItemTextCustom primary={item.name} />
                                                                                                            {/* <ListItemTextCustom primary={t(item.name,item.nameArgs)} /> */}
                                                                                                        </ListItemSubCustom>
                                                                                                    </BootstrapTooltip>
                                                                                                )
                                                                                            }
                                                                                        })
                                                                                    : null
                                                                                }
                                                                            </>
                                                                            : null
                                                                        }
                                                                        {
                                                                            this.state.childActive && this.state.childActive.hasSubmenu && (this.state.childActive.parent === children.route || this.state.childActive.route === children.route) ?
                                                                            this.state.childActive.childrensStructure.map((childActive, indexSub) => {
                                                                                return (
                                                                                    <BootstrapTooltip 
                                                                                    title={childActive.name} 
                                                                                    // title={t(childActive.name,childActive.nameArgs)} 
                                                                                    placement="right" 
                                                                                    disableHoverListener={props.opened ? true : false} 
                                                                                    className={clsx(classes.tooltip)} 
                                                                                    key={`ChildItemSub${indexSub}`}
                                                                                    >
                                                                                        <ListItemSubCustom button onClick={() => {this.goTo(childActive.route, childActive.customState);}} className={
                                                                                            clsx(classes.drawer, {
                                                                                                [classes.itemOpen]: props.opened,
                                                                                                [classes.itemClose]: !props.opened,
                                                                                            },
                                                                                            props.location.pathname === childActive.route || (childActive.childrens?this.isPathChildren(props.location.pathname,childActive.childrens):false)? 'activeNav' : '')
                                                                                        }>
                                                                                            <ListItemIconCustom>
                                                                                                <IcomoonReact iconSet={iconSet} size={28} icon={'ico_retour_fonce'} />
                                                                                            </ListItemIconCustom>
                                                                                            <ListItemTextCustom primary={childActive.name} />
                                                                                            {/* <ListItemTextCustom primary={t(childActive.name,childActive.nameArgs)} /> */}
                                                                                        </ListItemSubCustom>
                                                                                    </BootstrapTooltip>
                                                                                )
                                                                            })
                                                                        : null
                                                                        }
                                                                    </>
                                                                )
                                                            }else{
                                                                return(
                                                                    <BootstrapTooltip 
                                                                    title={children.name} 
                                                                    // title={t(children.name,children.nameArgs)} 
                                                                    placement="right" 
                                                                    disableHoverListener={props.opened ? true : false} 
                                                                    className={clsx(classes.tooltip)} 
                                                                    key={`ChildItem${index}`}
                                                                    >
                                                                        <ListItemCustom button onClick={() => {this.goTo(children.route, children.customState); this.setState({subMenuItems: null})}} className={
                                                                            clsx(classes.drawer, {
                                                                                [classes.itemOpen]: props.opened,
                                                                                [classes.itemClose]: !props.opened,
                                                                            },
                                                                            props.location.pathname === children.route || (children.childrens?this.isPathChildren(props.location.pathname,children.childrens):false)? 'activeNav' : '')
                                                                        }>
                                                                            <ListItemIconCustom>
                                                                                <IcomoonReact iconSet={iconSet} size={28} icon={children.icone} />
                                                                            </ListItemIconCustom>
                                                                            <ListItemTextCustom primary={children.name} />
                                                                            {/* <ListItemTextCustom primary={t(children.name,children.nameArgs)} /> */}
                                                                        </ListItemCustom>
                                                                    </BootstrapTooltip>
                                                                )
                                                            }

                                                        }else{
                                                            return null
                                                        }
                                                    })
                                                }
                                                </ListCustom>
                                            </AccordionDetailsCustom>
                                        </AccordionCustom>
                                    )
                                }else{
                                    return(
                                        <AccordionCustom
                                            TransitionProps={{ unmountOnExit: true }}
                                            key={`MenuItem${index}`}
                                            onClick={() => {this.goTo(item.route)}}
                                        >
                                            <AccordionSummaryCustom
                                                className={ item.allRoutes.indexOf(props.location.pathname) > -1 || (!item.isHome && props.location.pathname.includes(item.route)) ? 'activePanel' : ''}
                                            >
                                                <TypographyCustom variant="body2">{item.name}</TypographyCustom>
                                                {/* <TypographyCustom variant="body2">{t(item.name,item.nameArgs)}</TypographyCustom> */}
                                            </AccordionSummaryCustom>
                                        </AccordionCustom>
                                    )
                                }
                            }else{
                                return null
                            }
                        })
                    : null } 
                </DrawerCustom>
            );
        }
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setNavigation: (navigation) => dispatch({ type: SET_NAVIGATION, payload: { navigation }}),
    }
}

const mapStateToProps = state => {
    return {
        navigation: state.navigation,
        attributes: state.attributes,
        guideline: state.guideline,
        locales: state.locales,
    };
};

export default (withRouter(withStyles(styles)(connect(mapStateToProps,mapDispatchToProps)(DrawerLeft))));