import request from "../utils/fetch"

export const getGlobalStats = async (screen, date, url = `${process.env.REACT_APP_API}/statistic/global`) => {
    date.setDate(date.getDate());

    var data = {
        startDate: date.toISOString().split('T')[0]
    }

    const response = await request(url, 'post', data);
    var statsData = [];

    switch (screen) {
        case 'dashboard':
            if (response.hasOwnProperty("salesAmount")) {
                let value = response.totalOrder ?? 0;
                let evolution = response.oldTotalOrder ?? 0;
                statsData.push({
                    title: 'Ventes',
                    value: value,
                    evolution: evolution,
                    percent: true
                });
            }
        
            if (response.hasOwnProperty("stockValue")) {
                let value = response.stockValue ?? 0;
                statsData.push({
                    title: 'Valeur du stock (HT)',
                    value: value,
                    evolution: null,
                    percent: false,
                    suffix: '€',
                    hidePercent: true,
                    style: {
                        fontSize: value.toString().length >= 6 ? 25 : null
                    }
                });
            }
    
            if (response.hasOwnProperty("totalOrder")) {
                let value = response.totalOrder ?? 0;
                let evolution = response.oldTotalOrder ?? 0;
                statsData.push({
                    title: 'Commandes',
                    value: value,
                    evolution: evolution,
                    percent: true
                });
            }
    
            if (response.hasOwnProperty("totalTreated")) {
                let value = response.totalTreated ?? 0;
                let evolution = response.oldTotalTreated ?? 0;
                statsData.push({
                    title: 'Traitées',
                    value: value,
                    evolution: evolution,
                    percent: (value - evolution) / evolution * 100
                });
            }
            break;
        case 'orders':
            if (response.hasOwnProperty("totalOrder")) {
                let value = response.totalOrder ?? 0;
                let evolution = response.oldTotalOrder ?? 0;
                statsData.push({
                    title: 'Commandes',
                    value: value,
                    evolution: evolution,
                    percent: true
                });
            }
        
            if (response.hasOwnProperty("totalItems")) {
                let value = response.totalItems ?? 0;
                let evolution = response.oldTotalItems ?? 0;
                statsData.push({
                    title: 'Articles commandés',
                    value: value,
                    evolution: evolution,
                    percent: (value - evolution) / evolution * 100
                });
            }

            if (response.hasOwnProperty("totalTreated")) {
                let value = response.totalTreated ?? 0;
                let evolution = response.oldTotalTreated ?? 0;
                statsData.push({
                    title: 'Commandes traitées',
                    value: value,
                    evolution: evolution,
                    percent: (value - evolution) / evolution * 100
                });
            }

            if (response.hasOwnProperty("delay")) {
                let value = response.delay ?? 0;
                let evolution = response.oldDelay ?? 0;
                statsData.push({
                    title: 'Délais de traitement',
                    value: value,
                    evolution: evolution,
                    suffix: 'min',
                    percent: (value - evolution) / evolution * 100,
                    style: {
                        fontSize: 25
                    }
                });
            }

            if (response.hasOwnProperty("stockValue")) {
                let value = response.stockValue ?? 0;
                statsData.push({
                    title: 'Valeur du stock (HT)',
                    value: value,
                    evolution: null,
                    percent: false,
                    hidePercent: true,
                    suffix: '€',
                    style: {
                        fontSize: value.toString().length >= 6 ? 25 : null
                    }
                });
            }
            break;
        case 'returns':
    
                if (response.hasOwnProperty("totalReturned")) {
                    let value = response.totalReturned ?? 0;
                    let evolution = response.oldTotalReturned ?? 0;
                    statsData.push({
                        title: 'Articles retournés',
                        value: value,
                        evolution: evolution,
                        percent: (value - evolution) / evolution * 100
                    });
                }
            
                if (response.hasOwnProperty("delay")) {
                    let value = response.delay ?? 0;
                    let evolution = response.oldDelay ?? 0;
                    statsData.push({
                        title: 'Délais de traitement',
                        value: value,
                        evolution: evolution,
                        suffix: 'min',
                        percent: (value - evolution) / evolution * 100,
                        style: {
                            fontSize: 25
                        }
                    });
                }
    
                if (response.hasOwnProperty("stockValue")) {
                    let value = response.stockValue ?? 0;
                    statsData.push({
                        title: 'Valeur du stock (HT)',
                        value: value,
                        evolution: null,
                        percent: false,
                        hidePercent: true,
                        suffix: '€',
                        style: {
                            fontSize: value.toString().length >= 6 ? 25 : null
                        }
                    });
                }
            break;

        }


    // if (response.hasOwnProperty("salesAmount")) {
    //     let value = response.totalOrder ?? 0;
    //     let evolution = response.oldTotalOrder ?? 0;
    //     statsData.push({
    //         title: 'Vente',
    //         value: value,
    //         evolution: evolution,
    //         percent: true
    //     });
    // }

    // if (response.hasOwnProperty("stockValue")) {
    //     let value = response.stockValue ?? 0;
    //     statsData.push({
    //         title: 'Valeur du stock',
    //         value: value + "€",
    //         evolution: null,
    //         percent: false,
    //         hidePercent: true
    //     });
    // }
    
    // if (response.hasOwnProperty("totalOrder")) {
    //     let value = response.totalOrder ?? 0;
    //     let evolution = response.oldTotalOrder ?? 0;
    //     statsData.push({
    //         title: 'Commandes',
    //         value: value,
    //         evolution: evolution,
    //         percent: true
    //     });
    // }

    // if (response.hasOwnProperty("totalItems")) {
    //     let value = response.totalItems ?? 0;
    //     let evolution = response.oldTotalItems ?? 0;
    //     statsData.push({
    //         title: 'Articles commandés',
    //         value: value,
    //         evolution: evolution,
    //         percent: (value - evolution) / evolution * 100
    //     });
    // }

    // if (response.hasOwnProperty("totalReturned")) {
    //     let value = response.totalReturned ?? 0;
    //     let evolution = response.oldTotalReturned ?? 0;
    //     statsData.push({
    //         title: 'Articles retournés',
    //         value: value,
    //         evolution: evolution,
    //         percent: (value - evolution) / evolution * 100
    //     });
    // }

    // if (response.hasOwnProperty("totalTreated")) {
    //     let value = response.totalTreated ?? 0;
    //     let evolution = response.oldTotalTreated ?? 0;
    //     statsData.push({
    //         title: 'Commandes traitées',
    //         value: value,
    //         evolution: evolution,
    //         percent: (value - evolution) / evolution * 100
    //     });
    // }

    // if (response.hasOwnProperty("delay")) {
    //     let value = response.delay ?? 0;
    //     let evolution = response.oldDelay ?? 0;
    //     statsData.push({
    //         title: 'Délais de traitement',
    //         value: value + " min",
    //         evolution: evolution,
    //         percent: (value - evolution) / evolution * 100,
    //     });
    // }

    return statsData;
}