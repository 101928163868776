import React from 'react';

import { withRouter } from 'react-router';
import { connect } from "react-redux";
import ReactEcharts from 'echarts-for-react';
import colors from '../../../config/theme/colors';

class LineCharts extends React.Component {

    constructor(props){
        super(props)
        this.state = {
            xAxisData: this.props.xAxisData,
            yAxisData: this.props.yAxisData,
        };
    }


    
    getOption(){
        var options = {
            grid: {
                left: '0',
                bottom: '0',
                top:'15%',
                right:'20px',
                containLabel: true,
            },
            tooltip: {
                trigger: 'axis',
                position: 'top',
            },
            legend: {
                align: 'left',
                icon: 'circle',
                right: '0',
                show: !this.props.noLegend,
                itemGap: 90,
                textStyle: {
                    fontSize: 16,
                    fontFamily: 'Soleil'
                }
            },
            xAxis: {
                type: 'category',
                boundaryGap: false,
                data: this.state.xAxisData,
                splitLine: {
                    show: true,
                },
            },
            yAxis: {
                type: 'value',
                splitLine: {
                    show: true,
                },
            },
            series: this.state.yAxisData.map(serie => {
                return {
                    name: serie.title,
                    type: 'line',
                    smooth: false,
                    sampling: 'average',
                    itemStyle: {
                        color: serie.color
                    },
                    lineStyle: {
                        width : 1,
                    },
                    // areaStyle: {
                    //     origin: 'start',
                    //     color: {
                    //         type: 'linear',
                    //         x: 0,
                    //         y: 0,
                    //         x2: 0,
                    //         y2: 1,
                    //         colorStops: [{
                    //             offset: 0.15, color: serie.color // color at 0% position
                    //         }, {
                    //             offset: 1, color: '#FFFFFF' // color at 100% position
                    //         }],
                    //         global: false // false by default
                    //     }
                    // },
                    data: serie.data
                }
            })
        };

        return options

    };

    render() {
        return (
            <div>
                <ReactEcharts option={this.getOption()} />
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        loading: state.loading,
    };
};

export default withRouter(connect(mapStateToProps, null)(LineCharts));
