import { Tooltip } from '@material-ui/core';
import TableCell from '@material-ui/core/TableCell';

function CellText(props) {
    return (
        <TableCell
            style={{
                width: props.width ? props.width : 'auto',
                fontWeight: props.fontWeight ? props.fontWeight : 'normal',
                fontSize: props.fontSize ? props.fontSize : 'unset',
                padding: 10,
                paddingLeft: props.index === 0 ? 30 : null,
            }}
        >
            <Tooltip title={props.text ?? props.value}>
                <span>{props.value}</span>
            </Tooltip>
        </TableCell>
    )
}
export default CellText;