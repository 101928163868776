import React from 'react';

import { Avatar, Box, Grid } from '@material-ui/core';
import Typography from '../../../ui/typography/Typography';
import styled from 'styled-components'

import JohnDoe from '../../../../assets/images/john-doe.png';
import Stats from '../../Stats/Stats';
import { connect } from 'react-redux';
import { START_LOADING, STOP_LOADING } from '../../../../js/constants/action-types';
import colors from '../../../../config/theme/colors';
import { PowerSettingsNew } from '@material-ui/icons';

const ContainerImage = styled(Grid)`
    max-width: 60px;
    max-height: 60px;
    width: 60px;
    height: 60px;
    position: relative;
    overflow: hidden;
    border-radius: 50%;
    flex-basis:auto;
    img{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: inline-block;
        width: 100%;
        max-height: 60px;
        max-width: 60px;
    }
`;
const DisconnectText = styled(Typography)`
    color: #BEBEBF;
    font-weight: lighter;
    cursor: pointer;
    display: inline-block;
    align-items: center;
    &:hover{
        color:${colors.grey.lighter.hue600};
    };
    svg{
        width: 16px;
        height: 16px;
    }
    span{
        margin-left: 4px;
    }
`;

function CardHomepageStats(props) {
    const stats = props.statsHomepage;

    return (
        <Box>
            <Grid container justifyContent={'space-between'} wrap={props.windowWidth > 1100 ? "nowrap" : "wrap"}>
                <Grid item style={{ flexGrow: 1 }}>
                    <Grid container justifyContent={"flex-start"} alignItems="center" style={{
                        paddingBottom: "28px",
                        paddingTop: "23px",
                        paddingLeft: "43px"
                    }}>
                        <ContainerImage item xs={4} style={{
                            background: !props.user.image && colors.grey.lighter.hue980,
                            marginRight: '19px'
                        }}>                            
                            {props.user.image ? (
                                <img src={props.user.image ? `${process.env.REACT_APP_API_ROOT}/${props.user.image}` : JohnDoe} style={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)'
                                }} />
                            ) : (
                                <div style={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)',
                                    color: colors.grey.lighter.hue800,
                                    fontWeight: 'bold',
                                    fontSize: '34px'
                                }}>{props.user.firstname.charAt(0).toUpperCase()}{props.user.lastname.charAt(0).toUpperCase()}</div>
                            )}
                        </ContainerImage>
                        <Grid item xs={8}>
                            {/* <Typography variant={"body2"} style={{fontWeight: "bold"}}>Bienvenue</Typography> */}
                            <Box>
                                <Typography variant={"h2"}>{props.user.firstname} {props.user.lastname}</Typography>
                            </Box>
                            <Box>
                                <Box onClick={() => {
                                    props.startLoading();

                                    setTimeout(() => {
                                        localStorage.removeItem('AUTH_USER');
                                        localStorage.removeItem('AUTH_TOKEN');
                                        localStorage.removeItem('AUTH_TOKEN_EXPIRY');
                                        // localStorage.removeItem('ATTRIBUTES');
                                        // localStorage.removeItem('ATTRIBUTE_GROUPS');
                                        localStorage.removeItem('LOCALES');
                                        localStorage.removeItem('GUIDELINE');
                                        localStorage.removeItem('ATTRIBUTES_LOADED');
                                    }, 1000);
                                }}>
                                    <DisconnectText style={{
                                        textDecoration: 'underline',
                                    }}>{'Se déconnecter'}</DisconnectText>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Box >
    );
}

const mapDispatchToProps = dispatch => {
    return {
        startLoading: () => dispatch({ type: START_LOADING }),
        stopLoading: () => dispatch({ type: STOP_LOADING }),
    }
}

const mapStateToProps = state => {
    return {
        loading: state.loading
    };
};

export default (connect(mapStateToProps, mapDispatchToProps)(CardHomepageStats));