import React, { useEffect, useState } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import {Box, Grid} from '@material-ui/core'
import LaunchIcon from '@material-ui/icons/Launch';
import styled from 'styled-components';
import colors from '../../../../../config/theme/colors';
import request from '../../../../../js/utils/fetch';
import LoadingOverlay from '../../../../ui/loading-overlay/LoadingOverlay';
import OurButton from '../../../../ui/button/Button';
import { ALERT_ERROR, ALERT_SUCCESS } from '../../../../../js/constants/alert-types';
import downloadTempMedia from '../../../../../js/utils/downloadTempMedia';

const CustomTableHead = styled(TableHead)`
    background : transparent;
    th{
        color: ${colors.grey.regular};
        font-style: italic;
        /* text-align: center; */
    }
`

const CustomBodyHead = styled(TableBody)`
    tr:nth-child(2n){
        background-color: ${colors.grey.lighter.hue900};
    }
`

export default function PdfTracking(props){

    const [trackings, setTrackings] = useState(false)
    const [isLoading, setIsLoading] = useState(null)

    useEffect(() => {
        request(`${process.env.REACT_APP_API}/tracking/order/${props.id}`).then((data) => {
            if(data.trackings && data.trackings.length > 0) setTrackings(data.trackings)

            console.log(data.trackings);
            setIsLoading(false)
        })
    },[])

    const downloadBl = (id) => {
        return new Promise(async (resolve, reject) => {
            try {
                downloadTempMedia(`${process.env.REACT_APP_API}/tracking/pdf/${parseInt(id)}`)
                    .then(() => props.snack(ALERT_SUCCESS, `Bon de livraison généré`))
                    .catch((error) => {props.snack(ALERT_ERROR, `Une erreur est survenue`)})
            } catch (e) {
                props.snack(ALERT_ERROR, `Une erreur est survenue`);
            }

            resolve();
        });
    }

    const downloadColissimo = (id, isPdf = true) => {
        return new Promise(async (resolve, reject) => {
            var format = isPdf ? 'pdf' : 'zpl';
            try {
                var data = await request(`${process.env.REACT_APP_API}/colissimo/etiquette/${format}/${parseInt(id)}`, 'get', false, undefined, true)

                if (data.success) {
                    window.open(`${process.env.REACT_APP_MEDIAS}/${data.path}`, '_blank');
                    props.snack(ALERT_SUCCESS, `Étiquette Colissimo générée`);
                } else {
                    props.snack(ALERT_ERROR, `Une erreur est survenue`);
                }

            } catch (e) {
                props.snack(ALERT_ERROR, `Une erreur est survenue`);
            }

            resolve();
        });
    }

    return(
        <Box style={{
            maxHeight: 200,
            overflow: 'auto'
        }}>
            <Table size="small" stickyHeader>
                {
                    isLoading ?  
                    <LoadingOverlay/>:
                    trackings ? 
                    <>
                        <CustomTableHead style={{paddingBottom:20}}>
                            <TableRow>
                                <TableCell style={{ background: 'white' }}>Tracking</TableCell>
                                <TableCell style={{ background: 'white' }}>PDF</TableCell>
                                <TableCell style={{ background: 'white' }}>Étiquette Colissimo</TableCell>
                            </TableRow>
                        </CustomTableHead>
                        <CustomBodyHead>
                            {
                                trackings.map((tracking, index) => {
                                    return (
                                    <TableRow key={`tracking-number-${index}`}>
                                        <TableCell style={{fontWeight: 700}}>
                                            <a href={tracking.trackingLink} target="_blank" style={{ fontSize: 12, color: colors.black.regular, fontWeight: 700 }}>
                                                {tracking.trackingLink}
                                                <LaunchIcon style={{ fontSize: 20, marginLeft: 10, verticalAlign: 'middle' }} />
                                            </a> 
                                        </TableCell>
                                        <TableCell style={{fontWeight: 700}}>
                                            <span style={{textDecoration: 'underline', cursor: 'pointer'}} onClick={() => downloadBl(tracking.id)}>
                                                voir le BL
                                                <LaunchIcon style={{ fontSize: 20, marginLeft: 10, verticalAlign: 'middle' }} />
                                            </span>    
                                        </TableCell>
                                        <TableCell style={{fontWeight: 700}}>
                                            <span style={{textDecoration: 'underline', cursor: 'pointer'}} onClick={() => downloadColissimo(tracking.id, true)}>
                                                PDF
                                                <LaunchIcon style={{ fontSize: 20, marginLeft: 10, verticalAlign: 'middle' }} />
                                            </span> 
                                            <span style={{textDecoration: 'underline', cursor: 'pointer', marginLeft: 15}} onClick={() => downloadColissimo(tracking.id, false)}>
                                                ZPL
                                                <LaunchIcon style={{ fontSize: 20, marginLeft: 10, verticalAlign: 'middle' }} />
                                            </span>    
                                        </TableCell>
                                    </TableRow>
                                    )
                                })
                            }
                        </CustomBodyHead>
                    </>
                    :
                    <h4 style={{paddingLeft: 12}}>Pas de tracking</h4>
                }
                
            </Table>
        </Box>
    )
}