import React from 'react';
import styled from 'styled-components';
import colors from '../../../../../config/theme/colors'

import {Link} from 'react-router-dom'
import { ROUTE_LOCATION_DETAIL } from '../../../../../js/constants/route-names';

import Typography from '../../../../ui/typography/Typography'
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';

const StocksInfoContainer =styled.div`
    display : grid;
    gap : 5px;
    padding-top:5px;
`
const StocksInfoItem = styled.div`
    display :grid;
    grid-template-columns :auto auto 1fr;
    align-items:center;
    gap:5px;
    color : ${colors.blue.regular};

    a{
        text-decoration:none;
        &:hover{
            text-decoration : underline;
            color:${colors.blue.lighter.hue300};
        }
    }
`

const StockStatus = styled.div`
 
        
        width: 14px;
        height: 14px;
        background: ${props => props.status === 'active' ? colors.green.regular : colors.red.regular};
        border-radius: 50%;
        line-height:14px;
        text-align:center;
     

    
`
export default function StocksInfo(props){
    return(
        <StocksInfoContainer>
            {props.stocks.map(stock => {
                let isActive;
                if(stock.node.location != null){
                    isActive = stock.node.quantity > 0 && stock.node.location.isActive
                } else {
                    isActive = false
                }
                return(
                    <StocksInfoItem>
                        <StockStatus status={ isActive? 'active' : 'disabled'}>
                            {isActive ? 
                                <CheckIcon style={{color:'white',fontSize:12}}/>
                            : 
                                <CloseIcon style={{color:'white',fontSize:12}}/>
                                
                            }
                        </StockStatus>
                        { (stock.node.location === null) ? '' :
                            props.noLink===true ? <Typography variant="body2" colortext={colors.blue.regular}>{stock.node.location.code} : </Typography>
                            :
                            <Link to={{pathname : ROUTE_LOCATION_DETAIL.replace(':id',stock.node.location._id)}}>
                                <Typography variant="body2" colortext={colors.blue.regular} colortexthover={colors.blue.lighter.hue300}>{stock.node.location.code} : </Typography>
                            
                            </Link>
                        }
                        
                        <Typography variant="body2">{stock.node.quantity}</Typography>
                        

                    </StocksInfoItem>
                )

            })}
        </StocksInfoContainer>
    )
}