import { gql } from 'apollo-boost';

//----- Récupération des commandes

export const GET_ORDERS_CURSOR = gql`
{
  orders (first: 10000){
    edges{
      cursor
    }
  }
}
`;

export const GET_ORDERS_PAGINATION = gql`
query getSalesOrderSearch($state: String, $origin: String, $marketPlaceReference: String, $marketPlaceName: String, $magentoId: String, $nbperpage: Int, $cursor: String, $cursorLast: String, $hubIdentifier : String, $order : [SalesOrderFilter_order],$marketPlaceName_list:[String],$billToFirstname: String,$billToLastname: String,$billToEmail: String, $importedAt : String,$shoppingFeedId : String, $productSkus: [String], $discountCodes: [String]){
  salesOrders(order:$order,state: $state, status: "active", origin: $origin, marketPlaceReference:$marketPlaceReference, marketPlaceName: $marketPlaceName, magentoId: $magentoId,first: $nbperpage, after: $cursor, before: $cursorLast,hubIdentifier : $hubIdentifier,shoppingFeedId : $shoppingFeedId,marketPlaceName_list : $marketPlaceName_list,billToFirstname : $billToFirstname, billToLastname : $billToLastname, billToEmail : $billToEmail,importedAt:$importedAt, salesOrderItems_product_sku_list: $productSkus, discountCode_list: $discountCodes){
    totalCount
    pageInfo {
      startCursor
      endCursor
    }
    edges{
      node{
        _id
        id
        state
        createdAt
        updatedAt
        importedAt    
        creationDate
        shoppingFeedId
        marketPlaceName
        marketPlaceReference
        hubIdentifier 
        origin
        magentoId
        currencyCode
        shipmentMethodCode
        shipToFirstname
        shipToLastname
        billToFirstname
        billToLastname
        grandTotal
        subtotal
        shippingWithoutTax
        shippingTaxRate
        customerTINCode
        storeID
        shippingAgentCode
        magentoEntityId
        pushAcceptationMagento
        pushShipmentMagento
        pushInvoiceMagento
        salesOrderItems{
          edges{
            node{
              shipments{
                edges{
                  node{
                    shipmentQuantity
                    shippedQuantity
                    createdAt
                    stockVersion
                    hasBeenValidated
                    hasBeenPushToPlatform
                    location{
                      id
                      code
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
`;

/*
{
  "hasCodeRaisonRetour": true
}
 */
export const GET_RETURNED_SALES_ORDERS= gql`
query getReturnedSalesOrders($hasCodeRaisonRetour: Boolean!,$nbperpage: Int, $cursor: String, $cursorLast: String,$hubIdentifier : String,$returnIdentifier:String,$importedAt : String, $productSkus: [String]){
  salesOrders(order:{importedAt: "DESC"},exists:{ salesOrderItems_requests: $hasCodeRaisonRetour},first: $nbperpage, after: $cursor, before: $cursorLast,hubIdentifier:$hubIdentifier,salesOrderItems_requests_hubIdentifier:$returnIdentifier,importedAt : $importedAt, salesOrderItems_product_sku_list: $productSkus){
        totalCount
    pageInfo {
      startCursor
      endCursor
    }
    edges{
      node{
        id
        _id
        state
        hubIdentifier
        createdAt
        updatedAt
        importedAt
        creationDate
        shoppingFeedId
        marketPlaceName
        marketPlaceReference
        origin
        magentoId
        currencyCode
        shipmentMethodCode
        billToFirstname
        billToLastname
        grandTotal
        salesOrderItems{
          edges{
            node{
              requests{
                edges{
                  node{
                    id
                    hubIdentifier
                    requestedReturnedQuantity
                    returnedQuantity
                    createdAt
                    codeRaisonRetour
                    comment
                    hasBeenNotifiedToLCS
                  }
                }
              }
              
              
              shipments{
                edges{
                  node{
                    shippedQuantity
                    shipmentQuantity
                    createdAt
                    stockVersion
                    hasBeenValidated
                    hasBeenPushToPlatform
                    location{
                      id
                      code
                      warehouse{
                        id
                        code
                      }
                    }
                    tracking{
                      id
                      carrierName
                      trackingLink
                    }
                  }
                }
              }
              product{
                id
                sku
                itemNo
                variantCode
                collectionName
                stocks{
                  edges{
                    node{
                      id
                      quantity
                      stockVersion
                      createdAt
                      updatedAt
                      location{
                        id
                        code
                      }
                    }
                  }
                }
              }
            }
          }
        }
        salesOrderData{
          edges{
            node{
              id
              value
              attribute{
                id
                identifier
              }
              attributeOption{
                id
                identifier
              }
              locale{
                id
                code
                libelle
              }
              media{
                id
                filePath
              }
            }
          }
        }
      }
    }
  }
}
`;

export const GET_ORDERS = gql`
{
  orders{
    edges{
      node{
        _id
        id
        number
        billingName
        billingFirstname
        billingCompany
        billingStreet
        billingZipcode
        billingCity
        billingCountry
        billingEmail
        billingPhone
        shippingName
        shippingFirstname
        shippingCompany
        shippingStreet
        shippingZipcode
        shippingCity
        shippingCountry
        shippingEmail
        shippingPhone
        status
        paymentMethod
        shippingMethod
        shippingAmount
        discountAmount
        totalAmount
        source
        sourceNumber
        orderTrackings{
          edges{
            node{
              url
            }
          }
        }
        orderItems{
          edges{
            node{
              id
              product{
                id
                sku
              }
              qty
              qtyShipped
              qtyInvoiced
            }
          }
        }
      }
    }
  } 
}
`;


/*
{
  "id": "/api/sales-orders/4"
}
 */
export const GET_SALES_ORDER = gql`
query getSalesOrder($id: ID!) {
  salesOrder(id: $id) {
    _id
    id
    state
    createdAt
    updatedAt
    importedAt
    creationDate
    shoppingFeedId
    marketPlaceName
    marketPlaceReference
    origin
    hubIdentifier
    magentoId
    currencyCode
    shipmentMethodCode
    billToFirstname
    billToLastname
    billToAddress
    billToAddress2
    billToCity
    billToPostcode
    billToRegion
    billToCountry
    billToPhone
    billToEmail
    shipToFirstname
    shipToLastname
    shipToAddress
    shipToAddress2
    shipToCity
    shipToPostcode
    shipToRegion
    shipToCountry
    shipToPhone
    shipToEmail
    grandTotal
    subtotal
    shippingWithoutTax
    shippingInclTax
    shippingTaxRate
    customerTINCode
    storeID
    shippingAgentCode
    magentoEntityId
    pushAcceptationMagento
    pushShipmentMagento
    pushInvoiceMagento
    shoppingFeedSpecialField 
    datePayment
    instruction
    colisage
    discountCode
    isPaid
    paidAt
    infos
    payments{
      edges{
        node{
          storeId
          batchNumber
          tenderID
          currencyCode
          currencyAmount
          commercialDiscount

        }
      }
    }
    
    salesOrderItems{
      edges{
        node{
          id
          orderedQuantity
          reservedQuantity
          invoicedQuantity
          createdAt
          updatedAt
          unitFullPrice
          unitFullPriceWithDiscount
          discountRate
          taxRate
          unitHTPriceWithDiscount
          discountId
          discountAmount
          codeRaisonOperation
          magentoOrderItemId
          productType
          taxAmount
          parent{
            id
          }
          requests{
            edges{
              node{
                id
                requestedReturnedQuantity
                returnedQuantity
                createdAt
                codeRaisonRetour
                comment
                hasBeenNotifiedToLCS
                hubIdentifier 

              }
            }
          }
          shipments{
            edges{
              node{
                shippedQuantity

                shipmentQuantity
                createdAt
                stockVersion
                hasBeenValidated
                hasBeenPushToPlatform
                location{
                  id
                  code
                  warehouse{
                    id
                    code
                  }
                }
                tracking{
                  id
                  carrierName
                  trackingLink
                  trackingNumber
                }
              }
            }
          }
          invoices{
            edges{
              node{
                id
                pdf{
                  id
                  filePath
                }
                NAVInvoiceNo
              }
            }
          }
          product{
            id
            sku
            productName
            createdAt
            updatedAt
            itemNo
            variantCode
            collectionName
            imageUrl
            media{
              filePath
            }
            stocks{
              edges{
                node{
                  id
                  quantity
                  stockVersion
                  createdAt
                  updatedAt
                }
              }
            }
          }
          
        }
      }
    }
    salesOrderData{
      edges{
        node{
          id
          value
          attribute{
            id
            identifier
          }
          attributeOption{
            id
            identifier
          }
          locale{
            id
            code
            libelle
          }
          media{
            id
            filePath
          }
        }
      }
    }
    customer{
      id
      lastname
      firstname
      suffix
      email
      magentoCustomerId
    }
    logs{
      edges{
        node{
          id
          hubStatus
          createdAt
          message
        }
      }
    }
  }
}
`;

export const SEARCH_ORDERS = gql`
query orders ($nbperpage: Int, $cursor: String, $number: String, $source_number: String, $shipping_name: String, $shipping_firstname: String, $billing_name: String, $billing_firstname: String, $status: String, $source: String){
  orders (first: $nbperpage, after: $cursor, number: $number, source_number: $source_number, shipping_name:$shipping_name, shipping_firstname:$shipping_firstname, billing_name:$billing_name, billing_firstname:$billing_firstname, status:$status, source:$source){
    edges{
      node{
        id
        number
        billingName
        billingFirstname
        billingCompany
        billingStreet
        billingZipcode
        billingCity
        billingCountry
        billingEmail
        billingPhone
        shippingName
        shippingFirstname
        shippingCompany
        shippingStreet
        shippingZipcode
        shippingCity
        shippingCountry
        shippingEmail
        shippingPhone
        status
        paymentMethod
        shippingMethod
        shippingAmount
        discountAmount
        totalAmount
        source
        sourceNumber
        orderTrackings{
          edges{
            node{
              url
            }
          }
        }
        orderItems{
          edges{
            node{
              id
              product{
                id
                sku
              }
              qty
              qtyShipped
              qtyInvoiced
            }
          }
        }
      }
    }
  } 
}
`;

export const GET_MARKETPLACES = gql`
{
  getMarketPlaceNameSalesOrders(origin: "MP") {edges{node{marketPlaceName}}}
}
`