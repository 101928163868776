import { Box, FormControl, Grid, MenuItem, Select } from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";
import moment from 'moment';
import React, { useEffect, useState } from 'react';
// import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { withRouter } from 'react-router';
import styled from 'styled-components';
import colors from '../../../../config/theme/colors';
import { SET_GUIDELINE, SET_USER, SNACK, START_LOADING, STOP_LOADING } from '../../../../js/constants/action-types';
import { getGlobalStats } from '../../../../js/helpers/stats';
// import { checkRouting } from '../../../../js/utils/checkRouting';
import request from '../../../../js/utils/fetch';
import AccordionCustom from '../../../layouts/Accordion/AccordionCustom';
import BarCharts from '../../../layouts/Stats/BarCharts';
import LineCharts from '../../../layouts/Stats/LineCharts';
import StatsBar from '../../../layouts/StatsBar/StatsBar';
import PageLoader from '../../../ui/loadings/page-loader/PageLoader';
import Typography from '../../../ui/typography/Typography';

import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { KeyboardDatePicker} from '@material-ui/pickers';
import OurButton from '../../../ui/button/Button';

const TitleGlobals = styled(Typography)`
    line-height: 23px; 
    /* white-space: nowrap; */
    color:${colors.black.regular};
    @media screen and (max-width: 1249px){
        max-width: 100%;
    }
`;
const WrapperGlobalsLeft = styled(Grid)`
    width: 100% ;    
    padding-right:20px;
    @media screen and (max-width: 1249px){
        max-width: 100%
    }
`;

const WrapperGlobalsRight = styled(Grid)`
    width: 100%;    
    @media screen and (max-width: 1249px){
        margin-top: 24px;
        max-width: 100%
    }
`;

const inputStyles = makeStyles({
  input: {
    border: `0.5px solid ${colors.grey.lighter.hue700}`,
    minWidth: '200px',
  }
})

const CustomLegend = styled(Box)`
    height:4px;
    width:36px;
    background-color: ${props => props.backgroundColor};
`;

const BoxCustom = styled(Box)`
    color: ${props => props.statuscolor};
    svg circle{
        stroke: ${props => props.statuscolor};
    }
    div:nth-child(2) svg circle{
        stroke: #EDEEEE;
    }
    p{
        color: ${props => props.statuscolor};
    }
`;

export const DatePickerCustom = styled(KeyboardDatePicker)`
    &.MuiTextField-root{  
        max-width:150px;
      .MuiInput-root{
          border: 0.5px solid ${colors.grey.lighter.hue700};
          height:100%;
          &::before{
              border-bottom:none;
          }
          .MuiInputAdornment-root{
            button span svg {
                fill:#000;
            }
          }
        }
        .MuiFormHelperText-root{
          position:absolute;
        }
    }
    input{        
        height:100%;
        padding:0 10px;
        caret-color:transparent;
        font-weight:bold;
    }    
`;



const HistoryDashboard = (props) => {
  const [mainStats, setMainStats] = useState([])
  const [isLoadingMainStats, setIsLoadingMainStats] = useState(false)

  const [periode, setPeriode] = useState('week')
  // const [city, setCity] = useState('all')
  const [transport, setTransport] = useState('all')
  const [channel, setChannel] = useState('all')
  const [date, setDate] = useState({
    startDate: moment().startOf('week').format('yyyy-MM-DD'),
    endDate: moment().endOf('week').format('yyyy-MM-DD')
  })
  const [inputDateLine, setInputDateLine] = useState({
    startDate: moment().startOf('week').format('yyyy-MM-DD'),
    endDate: moment().endOf('week').format('yyyy-MM-DD')
  })
  const [isInputValuesLine, setIsInputValuesLine] = useState(false)

  const [periodeBar, setPeriodeBar] = useState('week')
  const [dateBar, setDateBar] = useState(null)

  const [stats, setStats] = useState([])
  const [formattedStats, setFormattedStats] = useState([])
  const [statsAreLoading, setStatsAreLoading] = useState(false)
  const [formattedDates, setFormattedDates] = useState([])
  const [totalImported, setTotalImported] = useState(null)

  const [isLineChartsLoaded, setIsLineChartsLoaded] = useState(false)
  const [isBarChartsLoaded, setIsBarChartsLoaded] = useState(false)
  const [error, setError] = useState(null)

  const [lastDatas, setLastDatas] = useState([])

  const [delays, setDelays] = useState([])
  const [delayChannels, setDelayChannels] = useState([])

  const [expeditionDelays, setExpeditionDelays] = useState([])
  const [deliveryDelays, setDeliveryDelays] = useState([])
  const [expeditionDelayTransport, setExpeditionDelayTransport] = useState([])

  const inputClass = inputStyles()

  // Get Chart stats
  const getChartsStats = (statsDate) => {
    setIsLineChartsLoaded(false)
    request(`${process.env.REACT_APP_API}/statistic/sales/detail`, 'POST', {
      ...date,
      "delayFormat": "HOUR"
    })
      .then(
        (data) => {
          if(data?.success === false) {
            setDelays(null)
            return
          }
          const filteredDataByChannel = data?.delay[channel === 'all' ? 'ALL' : channel]
          setDelayChannels(data?.origins)
          setDelays(filteredDataByChannel)
          setFormattedDates(Object.keys(filteredDataByChannel).map((key) => moment(key).format('[  ]ddd [\n] D.MM')))
          setIsLineChartsLoaded(true)
        }
      )
      .catch((err) => {
        console.log('error', err);
        // setIsBarChartsLoaded(true)
        // setError(err)
      })
  }

  // Get bar graphs stats
  const getBarChartStats = (statsDate) => {
    setIsBarChartsLoaded(false)
    request(`${process.env.REACT_APP_API}/statistic/sales/expe`, 'POST', {
      "startDate": date.startDate,
      // "endDate": statsDate.endDate,
      "delayFormat": "DAY"
    })
      .then(
        (data) => {
          console.log(data.transporteurs, '123');
          setExpeditionDelayTransport(data?.transporteurs)
          setExpeditionDelays(data?.delay[transport === 'all' ? 'ALL' : transport])
          setIsBarChartsLoaded(true)
        }
      )
      .catch((err) => {
        console.log('error', err);
        // setIsBarChartsLoaded(true)
        // setError(err)
      })
  }

  useEffect(() => {
    getChartsStats(date)
    getBarChartStats(date)
  }, [date, channel])

  useEffect(() => {
    handleInputDate()
  }, [inputDateLine.endDate])


  useEffect(() => {
    getBarChartStats(dateBar)
  }, [transport])
  // }, [city, transport])

  // Format top stats
  useEffect(() => {
      // TODO: Put date from state
      setIsLoadingMainStats(true)
      getGlobalStats('dashboard', new Date(), `${process.env.REACT_APP_API}/statistic/sales`).then(value => {
        const filteredStats = value?.filter((item) => item?.title !== 'Délais de traitement')
        setFormattedStats(filteredStats)
        setIsLoadingMainStats(false);
      })

  }, [])

  // Handle Transport
  const handleChangeTransport = (e) => {
    setTransport(e.target.value)
  }

  // Handle City
  // const handleChangeCity = (e) => {
  //   setCity(e.target.value)
  // }

  // Handle Channel
  const handleChangeChannel = (e) => {
    setChannel(e.target.value)
  }

  // Handle Date
  const handleChangeLineChartsPeriode = (e) => {
    setIsInputValuesLine(false)
    let startDate, endDate;

    switch (e.target.value) {
      case "week":
        startDate = moment().startOf('week').format('yyyy-MM-DD');
        endDate = moment(endDate).endOf(e.target.value).format('yyyy-MM-DD')
        break;

      case "month":
        startDate = moment().startOf('months').format('yyyy-MM-DD');
        endDate = moment(endDate).endOf(e.target.value).format('yyyy-MM-DD')
        break;

      case "year":
        startDate = moment().startOf('years').format('yyyy-MM-DD');
        endDate = moment(endDate).endOf(e.target.value).format('yyyy-MM-DD')
        break;

      default:
        startDate = moment().startOf('week').format('yyyy-MM-DD');
        endDate = moment().format('yyyy-MM-DD')
        break;
    }

    setDate({
      startDate,
      endDate
    })
    setInputDateLine({
      startDate,
      endDate: moment(endDate).endOf(e.target.value).format('yyyy-MM-DD')
    })
    setPeriode(e.target.value)
  }

  const checkDateOnInput = (date, value, ref) => {
    if (ref === 'start') {
        setInputDateLine({
            startDate: value,
            endDate: inputDateLine.endDate
        })
    } else if (ref === 'end') {
        setInputDateLine({
            startDate: inputDateLine.startDate,
            endDate: value
        })
    }
  }  

  const handleInputDate = () => {
    setIsInputValuesLine(true)
    setDate({
        startDate: inputDateLine.startDate,
        endDate: inputDateLine.endDate
    })
  }

  return (
    <div style={{ width: "100%", paddingTop: 32 }}>
      <Grid container>
        {/* <StatsRealTime /> */}
        <StatsBar
          xs={3}
          spacing={3}
          data={formattedStats}
          windowWidth={props.windowWidth}
          statsIsLoading={isLoadingMainStats}
          customPadding={'33px 30px'}
        />

        <AccordionCustom defaultExpanded={true} square={true} title={"Statistiques délais"}>
          <Grid container direction="column" justifyContent="center" spacing={0}>
            <Grid container direction="row" alignItems='end' spacing={5} justifyContent='space-between'>
              {
                statsAreLoading ?
                  <PageLoader />
                  :
                  <>
                    <WrapperGlobalsLeft item xs={12} md={8}>
                      <Grid container alignItems={'center'} justifyContent={"space-between"} style={{ display: 'flex', gap: 30, rowGap: 0 }}>
                        <Grid item xs={12}>
                          <TitleGlobals style={{ maxWidth: "none", marginBottom: 16 }} variant={"h2"}>Délais de traitement (heures)</TitleGlobals>
                        </Grid>
                        <Grid item xs={12} pt={3} pb={3} style={{ display: 'flex', gap: '10px' }}>
                          <FormControl style={{
                            display:"flex",
                            justifyContent:"space-between",
                            gap:10,
                            flexDirection:"row"
                          }}>

                          <MuiPickersUtilsProvider utils={MomentUtils} style={{ display: 'flex', flexDirection: 'row', gap: '10px', alignItems: "center" }}>
                                <DatePickerCustom
                                    disableToolbar
                                    autoOk
                                    invalidDateMessage={''}
                                    maxDateMessage={''}
                                    variant="inline"
                                    labelFunc={(date, invalidLabel) => moment(date).format("DD/MM/YYYY")}
                                    format="yyyy-MM-DD"
                                    value={inputDateLine.startDate}
                                    maxDate={inputDateLine.endDate}
                                    onChange={(date, value) => checkDateOnInput(date, value, 'start')}
                                    onKeyDown={(e) => e.preventDefault()}
                                    InputProps={{
                                        disableUnderline: true
                                    }}
                                />
                                <DatePickerCustom
                                    disableToolbar
                                    autoOk
                                    invalidDateMessage={''}
                                    maxDateMessage={''}
                                    variant="inline"
                                    labelFunc={(date, invalidLabel) => moment(date).format("DD/MM/YYYY")}
                                    format="yyyy-MM-DD"
                                    value={inputDateLine.endDate}
                                    maxDate={moment().format('yyyy-MM-DD')}
                                    minDate={inputDateLine.startDate}
                                    onChange={(date, value) => checkDateOnInput(date, value, 'end')}
                                    onKeyDown={(e) => e.preventDefault()}
                                    InputProps={{
                                        disableUnderline: true
                                    }}
                                />
                                {/* <OurButton
                                    onClick={handleInputDate}
                                    style={{ marginTop: 0, marginBottom: 0 }}
                                >
                                    Valider
                                </OurButton> */}
                            </MuiPickersUtilsProvider>
                            {/* <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={periode}
                              className={inputClass.input}
                              style={{
                                width: "100%",
                                padding: "5px 10px",
                                fontWeight: 'bold'
                              }}
                              disableUnderline
                              onChange={handleChangeLineChartsPeriode}
                            >
                              <MenuItem value={"week"}>Semaine</MenuItem>
                              <MenuItem value={"month"}>Mois</MenuItem>
                              <MenuItem value={"year"}>Année</MenuItem>
                            </Select> */}
                          </FormControl>
                          <FormControl>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={channel}
                              className={inputClass.input}
                              style={{
                                width: "100%",
                                padding: "5px 10px",
                                fontWeight: 'bold'
                              }}
                              disableUnderline
                              onChange={handleChangeChannel}
                            >
                              {delayChannels?.toReversed().map((item, index) => (
                                <MenuItem value={item === 'ALL' ? 'all' : item}>{item === 'ALL' ? 'Tous les canaux' : item}</MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                      </Grid>
                      {isLineChartsLoaded ? (
                        <Grid container direction="row" justifyContent='space-between'>
                          <Grid item xs={12}>
                            {Object.values(delays).findIndex(i => i) !== -1 ? (
                              <Box>
                                <LineCharts xAxisData={formattedDates} yAxisData={[
                                  {
                                    title: '',
                                    color: colors.green.regular,
                                    data: Object.values(delays),
                                  }
                                ]} noLegend={true} fullHeight={true} showArea={false} noExport={true} fullWidth={true} />
                              </Box>
                            ) : (
                              <p>Pas de commandes</p>
                            )}
                          </Grid>
                        </Grid>
                      ) : <PageLoader />}
                    </WrapperGlobalsLeft>

                    <WrapperGlobalsRight item xs={12} md={4}>
                      <Box display={'flex'} style={{ gap: 20, flexDirection: 'column' }}>
                        <Grid item xs={12}>
                          <TitleGlobals variant={"h2"}>Délais d’expéditions (jrs)</TitleGlobals>
                        </Grid>
                        <Grid item xs={12}>
                          <FormControl style={{
                            marginRight: 12
                          }}>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={transport}
                              className={inputClass.input}
                              style={{
                                width: "100%",
                                padding: "5px 10px",
                                fontWeight: 'bold',
                              }}
                              disableUnderline
                              onChange={handleChangeTransport}
                            >
                              {expeditionDelayTransport?.map(item => (
                                <MenuItem value={item === 'ALL' ? 'all' : item}>{item === 'ALL' ? 'Tous les transporteurs' : item}</MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                          {/* <FormControl>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={city}
                              className={inputClass.input}
                              style={{
                                width: "100%",
                                padding: "5px 10px",
                                fontWeight: 'bold'
                              }}
                              disableUnderline
                              onChange={handleChangeCity}
                            >
                              <MenuItem value={"all"}>Toutes les villes</MenuItem>
                              <MenuItem value={"month"}>Mois en cours</MenuItem>
                              <MenuItem value={"year"}>Année en cours</MenuItem>
                            </Select>
                          </FormControl> */}
                        </Grid>
                      </Box>
                      {
                        // true ?
                        isBarChartsLoaded ?
                          expeditionDelays ? (
                            <Box style={{ marginTop: 18 }}>
                              <BarCharts xAxisData={["Expédition", "Livraison"]} yAxisData={[{
                                max: 5,
                                title: '',
                                color: 'green',
                                data: [expeditionDelays, expeditionDelays+2]
                              }]} fullHeight={true} fullWidth={true} />
                            </Box>
                          ) : (
                            <p>Pas de commandes</p>
                          )
                          : <PageLoader />
                      }
                    </WrapperGlobalsRight>
                  </>
              }
            </Grid>
          </Grid>
        </AccordionCustom>
      </Grid>

    </div>
  );
}


const mapDispatchToProps = dispatch => {
  return {
    startLoading: () => dispatch({ type: START_LOADING }),
    stopLoading: () => dispatch({ type: STOP_LOADING }),
    setUser: user => dispatch({ type: SET_USER, payload: { user } }),
    setGuideline: guideline => dispatch({ type: SET_GUIDELINE, payload: { guideline } }),
    snack: (type, message) => dispatch({ type: SNACK, payload: { type, message } }),

  }
}

export default (withRouter(connect(null, mapDispatchToProps)(HistoryDashboard)));