import React from 'react';
import {CursorPagination} from '../../../js/utils/pagination'
import styled from 'styled-components';

import PageLoader from '../../ui/loadings/page-loader/PageLoader'
import { Grid} from '@material-ui/core';
import GridView from './components/GridView/GridView';
import TableView from './components/TableView/TableView'
import ChangeView from './components/ChangeView';
import {itemMapper} from '../../../js/mappers/mapper';
import * as listingHelper from '../../../js/utils/listing';
import {getElements} from '../../../js/utils/functions';
import NoResult from './components/NoResult';
import Search from './components/Search/Search';

const ListingContainer = styled.div`
    width:100%;
`

const capitalize = (s) => {
    if (typeof s !== 'string') return ''
    return s.charAt(0).toUpperCase() + s.slice(1)
}

class Listing extends React.Component{
    constructor(props) {
        super(props)
        this.state = {
            mappingReady : false,
            items : null,
            pagination : this.props.pagination != false ? 
                {
                    page : 0,
                    perPage : this.props.perPageOptions[this.props.viewsOptions.current][0]
                }
                : null,
            views: this.props.viewsOptions,
            orderBy:props.orderBy,
            noResult: false,
            searchValue:{},
            searchQueryVar : {}
        }
    }


    mapItems = async(items) =>{
        this.setState({mappingReady : false})
        let result = await itemMapper(this.props.mappers,items,this.props.currentLang);
        this.setState({
            items : result
        })
    }

    setLocaleStorage(value) {
        if(value){
            localStorage.setItem('filterValues',JSON.stringify(value));
        } else {
            localStorage.removeItem('filterValues');
        }
    }

    getLocaleStorage() {
        const filterValues = localStorage.getItem('filterValues');
        return JSON.parse(filterValues);
    }


    handleGetItems() {
        return new Promise(async(resolve, reject) => {
            this.setState({ready:false})

            let localStorageFilters;

            if(Object.keys(this.state.searchQueryVar).length===0){

                localStorageFilters = this.getLocaleStorage();
            } 
            let variables;
            if(localStorageFilters){
                variables = {
                    ...this.props.queryVariables,
                    ...localStorageFilters
                }
            } else {
                variables = {
                    ...this.props.queryVariables,
                    ...this.state.searchQueryVar
                }
            }

            if(Object.keys(this.state.searchQueryVar).length>0) this.setLocaleStorage(this.state.searchQueryVar);

            if(this.state.orderBy){
                let sortVar = {};
                for(let sort of this.state.orderBy){
                    sortVar[sort.key] = sort.order
                }
                variables = {...variables,order : {sortVar}}
            }
            variables = await listingHelper.initQuery(this.state,variables,'queryData');

            if (variables?.productSkus) {
                variables.productSkus = variables.productSkus.replace(/\s/, '').split(',')
            }
            if (variables?.stock_productSkus) {
                variables.stock_productSkus = variables.stock_productSkus
//                variables.stock_productSkus = variables.stock_productSkus.replace(/\s/, '').split(',')
            }

            if (variables?.discountCodes) {
                variables.discountCodes = variables.discountCodes.replace(/\s/, '').split(',')
            }

            if(variables?.state && variables?.state=='all'){
                variables.state = null;
            }
            if(variables?.origin && variables?.origin=='all'){
                variables.origin = null;
            }
            
            let queryStates = await listingHelper.initQuery(this.state,variables,'states');
            this.setState({...queryStates});
            let result = await getElements(this.props.identifier,variables);
            let handleResults = await listingHelper.handleResult(result.data[this.props.resultIdentifier??this.props.identifier],'listItems',this.state);
            await this.mapItems(handleResults.listItems);
            this.setState({
                ...handleResults,
            })
            if(this.props.listingCallback)
                this.props.listingCallback(handleResults.listItems,handleResults?.pagination?.count)
            resolve();
        
        });
    }


    changeViews = async(mode) =>{
        let views = await listingHelper.changeViews(this.state,mode);
        this.setState({
            views
        })
        if(this.props.perPageOptions?.[mode]?.[0]!=this.state.pagination.perPage){
            this.changePerPage(this.props.perPageOptions?.[mode]?.[0])
        }
    }

    changePage = async(newPage) =>{
        let pagination = await listingHelper.pagination.changePage(this.state.pagination,newPage);
        this.setState({
            pagination
        },()=>this.handleGetItems())
    }
    
    changePerPage = async(perPage) => {
        let reload = this.state.pagination.perPage != perPage;
        if(reload){
            let pagination = await listingHelper.pagination.updatePerPage(this.state.pagination,perPage);
            this.setState({
                pagination
            },()=>{this.handleGetItems()})            
        }

    }

    handleSort = async(orderBy) => {
        let newOrderBy = await listingHelper.sortBy.handleSort(this.state.orderBy,orderBy);
        let pagination = await listingHelper.pagination.resetPagination(this.state.pagination.perPage);
        this.setState({
            orderBy : newOrderBy,
            pagination
        },()=>this.handleGetItems())
    }

    resetPagination = async() => {
        let pagination = await listingHelper.pagination.resetPagination(this.state.pagination.perPage);
        this.setState({pagination, noResult: false},() => this.handleGetItems())
        this.handleGetItems();
    }

    handleSearchInput = (value,input) =>{
        let searchValue = this.state.searchValue;
        
        if(input.type === 'text' && value.length ===0)
            value = null;

        if(input.mapper)
            value = input.mapper(value)


        if(input.queryField)
            searchValue[input.queryField] = value;

        this.setState({searchValue},()=>{
            if(input.callback){
                input.callback(value,input)
            }
            if(this.props.inputCallback){
                this.props.inputCallback(value,input)
            }
        })
    }



    handleSearch = () =>{
    
        // Permet de créer un deuxième champs sur la même propriété d'une entity
        // requis pour le hub GD qui recherche par N° de facture 
        // Sauf qu'il y a aussi une recherche sur le N° CE
        // et en base N° facture c'est le même champs que N° CE
        let searchValue = {};
        
        for (const key in this.state.searchValue) {
            if (this.state.searchValue[key] && typeof this.state.searchValue[key] === "string" && key.includes("::")) {
                let formatedKey = key.substring(key.indexOf("::") + 2);
                searchValue[formatedKey] = this.state.searchValue[key]
            } else {
                searchValue[key] = this.state.searchValue[key]
            }
        }

        let newValue 
        if(searchValue.isActive){
            switch(searchValue.isActive){
                case 'active':
                    newValue = true;
                    break;
                case 'inactive':
                    newValue = false;
                    break;
                default:
                    newValue = null;
                    break;
            }
            searchValue.isActive = newValue;
        }

        this.setState({
            searchQueryVar : searchValue
        },()=>{
            this.resetPagination();
            if(this.props.searchCallback){
                this.props.searchCallback(searchValue)
            }
            
        })
    }

    clearSearch = () =>{
        let oldQueryVar = this.state.searchQueryVar;
        this.setState({
            searchValue : {},
            searchQueryVar : {}
        })
        this.setLocaleStorage(null);
        
        if(Object.keys(oldQueryVar).length >0 ){
            this.resetPagination();

        }
    }

    componentDidMount(){
        //this.handleGetItems();
        console.log('componentDidMount');

        let localStorageFilters = this.getLocaleStorage();
        if(localStorageFilters){
            console.log('componentDidMount localStorageFilters',localStorageFilters);
            this.setState({
                searchQueryVar: localStorageFilters,
                searchValue: localStorageFilters
            }, () => {
                console.log('componentDidMount this.state',this.state)
                this.handleGetItems();
            });
        } else {
            console.log(' componentDidMount !localStorageFilters');
            this.handleGetItems();
        }

    }

    componentDidUpdate(prevProps,prevState){
        if(JSON.stringify(this.props.queryVariables) != JSON.stringify(prevProps.queryVariables)){
            this.resetPagination();
        }
        if(this.props.reload != prevProps.reload && this.props.reload == true){
            this.resetPagination();
        }
        if(this.props.currentLang != prevProps.currentLang){
            this.mapItems(this.state.listItems)
        }
        if(this.props.identifier != prevProps.identifier){
            this.clearSearch();

        }
    }

    

    render(){
        let {settings,label,perPageOptions} = this.props; 
        let pagination = this.state.pagination;
        let currentLang = this.props.currentLang;
        let noResult = this.state.noResult;
        let views = this.state.views;
        let searchSettings = this.props.searchSettings;


        return(
            <ListingContainer>

                {
                    searchSettings ? 
                    <Search settings={searchSettings} handleSearchInput={this.handleSearchInput} searchValue={this.state.searchValue} handleSearch={this.handleSearch} clearSearch={this.clearSearch}/>
                    : null
                }

                {views.settings?.length > 1 ? 
                    <Grid container justify="flex-end" alignItems="center" style={{
                        marginBottom : '20px'
                    }}>
                        <ChangeView views={views} handleViews={this.changeViews} />
                    </Grid>
                : null}

                {
                    !noResult ?
                        <>
                            {
                                views.current == 'card' ?
                                        this.state.ready ?
                                            <GridView settings={settings?.grid} items={this.state.items[views.current]} cardProps={this.props.cardProps} label={label} currentLang={currentLang} cardContainerProps = {this.props.cardContainerProps}/>
                                        : <PageLoader/>
                                    :  
                                        this.state.ready ?
                                            <TableView settings={settings?.table} tableProps={this.props.tableProps} items={this.state.items[views.current]} label={label} ready={this.state.ready} sortCallback={this.handleSort} orderBy={this.state.orderBy} rowAction={this.props.rowAction} />
                                        : <PageLoader/>
                            }    
                                        
                            {
                                this.state.ready && pagination  ? 
                                    <CursorPagination
                                        rowLabel = {`${capitalize(label)} par page`}
                                        pagination = {pagination}
                                        type = "table"
                                        changePageCallback = {this.changePage}
                                        changePerPageCallback = {this.changePerPage}
                                        showPerPage = {perPageOptions[views.current]?.length > 0}
                                        perPageOptions = {perPageOptions[views.current]}
                                    />
                                : null
                                
                            }                    
                        </>

                    : <NoResult component={this.props.noResultComponent}/>
                }


            </ListingContainer>
        )        
    }


}

export default Listing;