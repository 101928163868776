import { Grid,Tooltip} from '@material-ui/core';
import ViewListIcon from '@material-ui/icons/ViewList';
import ViewModuleIcon from '@material-ui/icons/ViewModule';
import styled from 'styled-components';
import colors from '../../../../config/theme/colors'
import { makeStyles } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import OurTypography from '../../../ui/typography/Typography';

const ViewModuleIconCustom = styled(ViewModuleIcon)`
    fill: ${colors.grey.regular};
    cursor: pointer;
    background:none;
    &.active{
        fill: ${colors.black.regular};
        cursor: inherit;
    }
`;
const ViewListIconCustom = styled(ViewListIcon)`
    fill: ${colors.grey.regular};
    cursor: pointer;
    background:none;

    &.active{
        fill: ${colors.black.regular};
        cursor: inherit;
    }
`;

export default function ChangeView(props){
    let {views} = props;
    const useStylesBootstrap = makeStyles(theme => ({
        arrow: {
            color: colors.black.regular,
        },
        tooltip: {
            backgroundColor: colors.black.regular,
            fontSize: 14,
        },
    }));

    const classes = useStylesBootstrap();
    
    const BootstrapTooltip = (props) =>{
        return <Tooltip arrow classes={classes} {...props} />;
    }

    return(
        <Grid container justify="flex-start" alignItems="center">
            <OurTypography colortext={colors.black.regular} style={{marginRight: 5}}>Aperçu de la page :</OurTypography>
            <BootstrapTooltip title="Voir en cartes" className={clsx(classes.tooltip)}>
                <ViewModuleIconCustom 
                style={{ fontSize: 32 }} 
                className={views.current === "card" ? 'active': null}
                onClick={views.current === "card" ? null : () => props.handleViews('card')}
                />
            </BootstrapTooltip>
            <BootstrapTooltip title="Voir en liste" className={clsx(classes.tooltip)}>
                <ViewListIconCustom 
                style={{ fontSize: 30, marginLeft: 0 }} 
                className={views.current === "table" ? 'active': null}
                onClick={views.current === "table" ? null : () => props.handleViews('table')}
                />
            </BootstrapTooltip>
        </Grid>
    )
}