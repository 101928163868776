import { gql } from 'apollo-boost';


/*
{
  "id": "/api/look_books/1"
}
 */
export const GET_LOOKBOOK = gql`
query getLookBook($id: ID!){
  lookBook(id: $id) {
    id
    libelle
    status
    media{
      id
      filePath
    }
    productLookBooks(order:{position:"ASC"}){
      edges{
        node{
          id
          position
          product{
            id
            sku
          }
        }
      }
    }
  }
}
`;

/*
{
  "libelle": "test"
}
 */
export const GET_LOOKBOOKS_BY_LIBELLE = gql`
query getLookBooksByLibelle($libelle: String!){
  lookBooks(libelle: $libelle) {
    edges{
      node{
        id
        libelle
        status
        media{
          id
          filePath
        }
        productLookBooks(order:{position:"ASC"}){
          edges{
            node{
              id
              position
              product{
                id
                sku
              }
            }
          }
        }   
      }
    }  
  }
}
`;


export const GET_LOOKBOOKS_PAGINATION = gql`
query products ($asset: String, $nbperpage: Int, $cursor: String, $cursorLast: String){
  lookBooks(first: $nbperpage, after: $cursor, before: $cursorLast, asset: $asset){
    pageInfo{
      startCursor
      endCursor
    }
    totalCount
    edges{
      node{
        id
        status
        libelle
        media{
          id
          filePath
        }
        productLookBooks(order:{position:"ASC"}){
          edges{
            node{
              id
              position
              product{
                id
                sku
              }
            }
          }
        }
      }
    }
  }
}
`;

/*
{
  "libelle": "test",
  "status": true,
  "media": "api/medias/1"
}
 */
export const CREATE_LOOKBOOK = gql`
mutation createLookBook($libelle: String!, $status: Boolean, $media: String, $asset: String){
  createLookBook(input:{libelle: $libelle, status: $status, media: $media, asset: $asset}){
    lookBook{
      id
    }
  }
}
`;

/*
{
  "id": "/api/look_books/1",
  "libelle": "test",
  "status": true,
  "media": "api/medias/1"
}
 */
export const UPDATE_LOOKBOOK = gql`
mutation updateLookBook($id: ID!, $libelle: String!, $status: Boolean, $media: String, $asset: String){
    updateLookBook(input:{id: $id, libelle: $libelle, status: $status, media: $media, asset: $asset}){
        lookBook{
            id
        }
    }
}
`;

/*
{
  "id": "/api/look_books/1",
}
 */
export const DELETE_LOOKBOOK = gql`
mutation deleteLookBook($id: ID!){
    deleteLookBook(input:{id: $id}){
        lookBook{
            id
        }
    }
}
`;

// ---------- LOOK BOOK PRODUCT


/*
{
  "product": "/api/products/1",
  "lookBook": "/api/look_books/1",
  "position": 1
}
 */
export const CREATE_LOOKBOOK_PRODUCT = gql`
mutation createLookBookProduct($product: String!, $lookBook: String!, $position: Int){
  createProductLookBook(input:{product: $product, lookBook: $lookBook, position: $position}){
    productLookBook{
      id
    }
  }
}
`;

/*
{
  "id": "/api/look_book_products/1",
  "position": 1
}
 */
export const UPDATE_LOOKBOOK_PRODUCT_POSITION = gql`
mutation updateLookBookProductPosition($id: ID!, $position: Int!){
  updateProductLookBook(input:{id: $id, position: $position}){
    productLookBook{
      id
    }
  }
}
`;

/*
{
  "id": "/api/look_book_products/1"
}
 */
export const DELETE_LOOKBOOK_PRODUCT_POSITION = gql`
mutation deleteLookBookProductPosition($id: ID!){
  deleteProductLookBook(input:{id: $id}){
    productLookBook{
      id
    }
  }
}
`;
