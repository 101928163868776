import React from 'react';

import { withRouter } from 'react-router';
import { connect } from "react-redux";
import request from '../../../../js/utils/fetch';
import { ROUTE_HOME, ROUTE_ORDERS_DETAIL } from '../../../../js/constants/route-names';
import colors from '../../../../config/theme/colors';

import { START_LOADING, STOP_LOADING, SNACK } from '../../../../js/constants/action-types';
import { ALERT_ERROR, ALERT_SUCCESS } from '../../../../js/constants/alert-types'

import PageContainer from '../../../layouts/Container/PageContainer';
import ContentContainer from '../../../layouts/Container/ContentContainer';
import { getElements } from '../../../../js/utils/functions';
import { Grid, Box, Chip } from '@material-ui/core';
import styled from 'styled-components';
import Typography from '../../../ui/typography/Typography';
import moment from 'moment';
import OrderItems from './components/OrderItems';
import History from './components/History';
import Tracking from './components/Tracking';
import { hasReturn, splitItemsByReturn } from '../../../../js/utils/hub';
import DialogModal from '../../../ui/dialog/DialogModal';
import OrderItemsRepayment from './components/OrderItemsRepayment';
import DeleteSharpIcon from '@material-ui/icons/DeleteSharp';

import _ from 'lodash';
import AccordionCustom from '../../../layouts/Accordion/AccordionCustom';
import { spaceOnNumbers } from '../../../../js/utils/spaceOnNumbers';
import OurButton from '../../../ui/button/Button';
import StatusInfo from '../../../ui/status-info/StatusInfo';
import GeneralDialogModal from '../../../ui/dialog/GeneralDialogModal';
import LayoutFormBuilder from '../../../ui/form/LayoutFormBuilder';
import { ordersEditFormConfig } from './config/ordersListing.config';
import { eventService } from '../../../../js/services/event.service';
import { EditSharp } from '@material-ui/icons';

import PdfTracking from './components/PdfTracking';
import { GET_PRODUCTS_SEARCH_PAGINATION } from '../../../../queries/products';
import { withApollo } from 'react-apollo';
import downloadTempMedia from '../../../../js/utils/downloadTempMedia';


const Info = styled(Box)`
    background:white;
    padding:20px;
    height:100%;
    box-shadow:rgb(22 34 66 / 10%) 0px 0px 5px;
    border-radius:5px;
    position :relative;
`;

const InfosContainer = styled.div`
    display:grid;
    gap:24px;
    width:100%;
`;

const RowContainer = styled.div`
    display: grid;
    padding: 5px 0;
    grid-template-columns: 150px 1fr;
    gap: 10px;
    min-height: 30px;

    &:last-of-type{
        padding-bottom:0;
    }
`;

const GrandTotal = styled.div`
    padding:20px;
    text-align:right;
`;


const infoRow = (key, value, link) => {

    return (
        <RowContainer>
            <Typography variant="body1" style={{ fontSize: 13, color: colors.black.regular }}>
                {key} :
            </Typography>
            {
                (typeof value !== 'string' && typeof value !== 'number') ? value :
            
                link ?
                <a style={{color: colors.black.regular, fontWeight: 'bold'}} href={link.path}>
                    {value}
                </a>

                :
                <Typography variant="body1" style={{ fontSize: 13, fontWeight: 'bold', color: colors.black.regular }}>
                    {value}
                </Typography>
            }

        </RowContainer>
    )
}

const addressRow = (address) => {
    return (
        <RowContainer>
            <Typography variant="body1" style={{ fontSize: 13, color: colors.black.regular }}>
                Adresse
            </Typography>
            <Grid container style={{ fontSize: 15, fontWeight: 'bold' }}>
                <Grid item xs={12}>
                    {address.address_1}
                </Grid>
                {address.address_2 ?
                    <Grid item xs={12}>
                        {address.address_2}
                    </Grid>
                    : null}

                <Grid item xs={12}>

                    {`${address.zip} - ${address.city} (${address.country})`}
                </Grid>
            </Grid>
        </RowContainer>
    )
}

class OrderDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            id: this.props.match.params.id,
            buttonAvailable: false,
            popupRepayment: false,
            selectedProductsRepayment: [],
            qtyProductsRepayment: [],
            refundShippingFees: false,
            adjustment: 0,
            typeAction: 'refund',
            popupTitle: '',
            trackingLink: '',
            trackingNumber: '',
            trackingName: '',
            trackingSendEmail: false,
            trackingEmailContent: false,
            errorShippingTrackingName: false,
            errorShippingTrackingLink: false,
            errorShippingTrackingNumber: false,
            errors: {},
            openForm: false
        }
        this.errors = {}
    }


    componentDidMount() {
        if (process.env.REACT_APP_MODE_SPREAD !== "hub") {
            this.props.snack(ALERT_ERROR, `Vous n'avez pas les droits suffisants pour accéder à cette page`);
            this.goTo(ROUTE_HOME);
        }
        this.initProduct()

    }

    initProduct = async () => {
        this.props.startLoading()
        let order = await getElements('salesOrder', { id: `/api/sales-orders/${this.state.id}` }, { enableLoading: true });
        // console.log(order)
        // if (order?.data?.salesOrder?.state === 'Pre-order') {
        //     let productData = await this.props.client.query({
        //         query: GET_PRODUCTS_SEARCH_PAGINATION,
        //         variables: {
        //             'page': 1,
        //             'itemsPerPage':1,
        //             // 'sku': order?.data?.salesOrder?.salesOrderItems?.edges[0]?.node?.product?.sku,
        //         }
        //     })
        //     console.log(productData)
        // }
        if (order?.data?.salesOrder) {
            this.setState({
                //BILLING INFO
                billFirstname: order?.data?.salesOrder?.billToFirstname,
                billLastname: order?.data?.salesOrder?.billToLastname,
                billAddress1: order?.data?.salesOrder?.billToAddress,
                billAddress2: order?.data?.salesOrder?.billToAddress2,
                billCity: order?.data?.salesOrder?.billToCity,
                billPostalCode: order?.data?.salesOrder?.billToPostcode,
                billMail: order?.data?.salesOrder?.billToEmail,
                billTelephone: order?.data?.salesOrder?.billToPhone,

                //SHIPPING INFO
                shipFirstname: order?.data?.salesOrder?.shipToFirstname,
                shipLastname: order?.data?.salesOrder?.shipToLastname,
                shipAddress1: order?.data?.salesOrder?.shipToAddress,
                shipAddress2: order?.data?.salesOrder?.shipToAddress2,
                shipCity: order?.data?.salesOrder?.shipToCity,
                shipPostalCode: order?.data?.salesOrder?.shipToPostcode,
                shipMail: order?.data?.salesOrder?.shipToEmail,
                shipTelephone: order?.data?.salesOrder?.shipToPhone,

                //ADDITIONAL INFOS
                infos: order?.data?.salesOrder?.infos,
                customerTincode: order?.data?.salesOrder?.customerTINCode,
                marketPlaceName: order?.data?.salesOrder?.marketPlaceName
            })
        }
        this.setState({
            order: order?.data?.salesOrder,
            hasReturn: hasReturn(order.data.salesOrder),
            buttonAvailable: true,
        }, () => {
            let a = [];
            for (let element of this.state.order.salesOrderItems.edges) {
                a.push(0);
            }
            this.setState({ qtyProductsRepayment: a });
        })
        this.props.stopLoading();
    }

    handlePopupRepayment = () => {
        return new Promise(async (resolve, reject) => {
            this.props.startLoading();

            let requestMethod = 'post';
            let nameApiPoint = '';
            if (this.state.typeAction === 'refund') {
                nameApiPoint = '/hub/order/refund';
            } else if (this.state.typeAction === 'accept') {
                nameApiPoint = '/hub/orders/accept/' + parseInt(this.state.id);
                requestMethod = 'get';
            } else if (this.state.typeAction === 'refuse') {
                nameApiPoint = '/hub/orders/refuse/' + parseInt(this.state.id);
                requestMethod = 'get';
            } else if (this.state.typeAction === 'cancel') {
                nameApiPoint = '/hub/orders/cancel/' + parseInt(this.state.id);
                requestMethod = 'get';
            } else if (this.state.typeAction === 'ship') {
                nameApiPoint = '/hub/orders/ship/' + parseInt(this.state.id);

            } else {
                nameApiPoint = '/hub/declare/avis-souffrance';
            }


            let object = {};
            object.salesOrderId = parseInt(this.state.id);

            if (this.state.typeAction === 'refund') {
                object.refundShippingFees = this.state.refundShippingFees;
                object.adjustment = parseFloat(this.state.adjustment);
            }

            let i = 0;
            let arrayItems = [];

            if (this.state.typeAction === 'refund' || this.state.typeAction === 'suffering') {
                for (let element of this.state.order.salesOrderItems.edges) {
                    let index = _.findIndex(this.state.order.salesOrderItems.edges, element);
                    let item = {};
                    item.id = parseInt(element.node.id.replace("/api/sales-order-items/", ""));

                    if (this.state.typeAction === 'refund') {
                        item.refundQuantity = this.state.qtyProductsRepayment[index]
                    } else {
                        item.qty = this.state.qtyProductsRepayment[index]
                    }

                    arrayItems.push(item);
                }

                object.items = arrayItems;
            } else if (this.state.typeAction === 'ship') {
                object.link = this.state.trackingLink;
                object.number = this.state.trackingNumber;
                object.name = this.state.trackingName;
                object.sendEmail = this.state.trackingSendEmail;
                object.emailContent = this.state.trackingEmailContent;

                if (object.link == '' || object.number == '' || object.name == '') {
                    this.setState({
                        errorShippingTrackingName: (object.name == ''),
                        errorShippingTrackingLink: (object.number == ''),
                        errorShippingTrackingNumber: (object.link == '')
                    })
                    this.props.snack(ALERT_ERROR, `Merci de remplir tous les champs`);
                    this.props.stopLoading();
                    console.log(this.state);
                    return
                }

                for (let element of this.state.order.salesOrderItems.edges) {
                    let index = _.findIndex(this.state.order.salesOrderItems.edges, element);
                    let item = {};
                    item.id = parseInt(element.node.id.replace("/api/sales-order-items/", ""));

                    item.qty = this.state.qtyProductsRepayment[index]

                    if(item.qty>0) arrayItems.push(item);
                }

                object.items = arrayItems;

                if(object.items.length === 0) {
                    this.props.snack(ALERT_ERROR, `Merci de choisir des produits à expédier`);
                    this.props.stopLoading();
                    return
                }

            } else {
                object = false
            }

            try {
                await request(`${process.env.REACT_APP_API}${nameApiPoint}`, requestMethod, object, undefined, true);
                switch (this.state.typeAction) {
                    case 'refund':
                        this.props.snack(ALERT_SUCCESS, `Le remboursement a été créé`);
                        break;

                    case 'suffering':
                        this.props.snack(ALERT_SUCCESS, `L'avis de souffrance a été créé`);
                        break;

                    case 'accept':
                        this.props.snack(ALERT_SUCCESS, `La commande a été acceptée`);
                        break;

                    case 'refuse':
                        this.props.snack(ALERT_SUCCESS, `La commande a été refusée`);
                        break;

                    case 'cancel':
                        this.props.snack(ALERT_SUCCESS, `La commande a été annulée`);
                        break;

                    case 'ship':
                        this.props.snack(ALERT_SUCCESS, `La commande a été expédiée`);
                        break;

                    default:
                        this.props.snack(ALERT_SUCCESS, `Action effectué avec succès`);
                        break;
                }

                this.setState({ popupRepayment: false, typeAction: 'refund' })
                this.props.stopLoading();
            } catch (e) {
                switch (this.state.typeAction) {
                    case 'refund':
                        this.props.snack(ALERT_ERROR, `La création du remboursement a échoué !`);
                        break;

                    case 'suffering':
                        this.props.snack(ALERT_ERROR, `La création de l'avis de souffrance a échoué !`);
                        break;

                    case 'accept':
                        this.props.snack(ALERT_ERROR, `L'acceptation a échoué !`);
                        break;

                    case 'refuse':
                        this.props.snack(ALERT_ERROR, `Le refus a échoué !`);
                        break;

                    case 'cancel':
                        this.props.snack(ALERT_ERROR, `L'annulation a échoué !`);
                        break;

                    case 'ship':
                        this.props.snack(ALERT_ERROR, `L'expédition a échoué !`);
                        break;

                    default:
                        this.props.snack(ALERT_ERROR, `L'action a échoué`);
                        break;
                }

                this.setState({ popupRepayment: false, typeAction: 'refund' })
                this.props.stopLoading();
            }
            this.initProduct();
            resolve();
        });
    };

    handleToggleDialog = (stateName) => {
        this.setState({ [stateName]: false });
        switch (this.state.typeAction) {
            case 'refund':
                var title = `Création d'un remboursement pour la commande ${this.state.order ? this.state.order.origin === 'MP' ? this.state.order.shoppingFeedId : this.state.order.hubIdentifier : ''}`
                break;

            case 'suffering':
                var title = `Création d'un avis de souffrance pour la commande ${this.state.order ? this.state.order.origin === 'MP' ? this.state.order.shoppingFeedId : this.state.order.hubIdentifier : ''}`
                break;

            case 'accept':
                var title = `Acceptation de la commande ${this.state.order ? this.state.order.origin === 'MP' ? this.state.order.shoppingFeedId : this.state.order.hubIdentifier : ''}`
                break;

            case 'refuse':
                var title = `Refus de la commande ${this.state.order ? this.state.order.origin === 'MP' ? this.state.order.shoppingFeedId : this.state.order.hubIdentifier : ''}`
                break;

            case 'cancel':
                var title = `Annulation de la commande ${this.state.order ? this.state.order.origin === 'MP' ? this.state.order.shoppingFeedId : this.state.order.hubIdentifier : ''}`
                break;

            case 'ship':
                var title = `Expédition de la commande ${this.state.order ? this.state.order.origin === 'MP' ? this.state.order.shoppingFeedId : this.state.order.hubIdentifier : ''}`
                break;

            default:
                var title = `Confirmer cette action ?`
                break;
        }

        this.setState({ [stateName]: !this.state[stateName], popupTitle: title });
    };

    handleDownload(type) {
        return new Promise(async (resolve, reject) => {
            try {
                if (type === 'facture') {
                    downloadTempMedia(`${process.env.REACT_APP_API}/sales-order-shipments/${parseInt(this.state.id)}/generate-purchase-order-pdf`)
                        .then(() => this.props.snack(ALERT_SUCCESS, `Facture générée`))
                        .catch(error => {
                            this.props.snack(ALERT_ERROR, error?.message ? error.message :`Une erreur est survenue`);
                        })
                } else if (type === 'livraison') {
                    downloadTempMedia(`${process.env.REACT_APP_API}/sales-orders/${parseInt(this.state.id)}/generate-invoice-pdf`)
                        .then(() => this.props.snack(ALERT_SUCCESS, `Bon de livraison généré`))
                        .catch(error => {
                            this.props.snack(ALERT_ERROR, error?.message ? error.message :`Une erreur est survenue`);
                        })
                }
            } catch (e) {
                this.props.snack(ALERT_ERROR, `Une erreur est survenue`);
            }

            resolve();
        });
    }

    getButtons = (orderState) => {
        switch(orderState) {
            case 'Shipped':
                return {
                    // main: {
                    //     text: 'Création de remboursement',
                    //     action: async () => { await this.setState({ typeAction: 'refund' }); this.handleToggleDialog('popupRepayment'); },
                    // },
                    // secondary: {
                    //     text: 'Avis de souffrance',
                    //     action: async() => {await this.setState({typeAction: 'suffering'}); this.handleToggleDialog('popupRepayment'); }
                    // }
                }
            break;
            case "Pre-order":
            case 'Waiting for exportation':
            case 'Pending for validation':
            case "Manual action required":
                return {
                    main: {
                        text: 'Accepter',
                        action: async() => { await this.setState({typeAction: 'accept'}); this.handleToggleDialog('popupRepayment');},
                    },
                    cancel: {
                        text: 'Annuler',
                        action: async() => { await this.setState({typeAction: 'refuse'}); this.handleToggleDialog('popupRepayment');},
                    }
                }
            break;
            case 'Accepted':
                return {
                    main: {
                        text: 'Expédier',
                        action: async() => { await this.setState({typeAction: 'ship'}); this.handleToggleDialog('popupRepayment');},
                    },
                    cancel: {
                        text: 'Annuler',
                        action: async() => { await this.setState({typeAction: 'cancel'}); this.handleToggleDialog('popupRepayment');}
                    }
                }
            break;
            case 'Avoir':
            case "Pending for validation":
            case "Closed":
            case "Partially Shipped":
                return {
                    main: {
                        text: 'Expédier',
                        action: async() => { await this.setState({typeAction: 'ship'}); this.handleToggleDialog('popupRepayment');},
                    }
                }
            case "Partially Accepted":
            case "MP Partially Accepted":
            case "MP Partially Shipped":
            case "Declined":
            case "Paid":
            case "To Ship":
                return {
                    main: {
                        text: null,
                        action: null,
                    },
                    cancel: {
                        text: null,
                        action: null
                    }
                }
            break;
        }
    }

    stateCallback = (stateName, evt) => {
        const value = evt.target.value;

        this.setState({
            [stateName]: value
        });
    }

    handleButtonGroupChange = (stateName, value) => {
        this.setState({
            [stateName]: value
        });
    };
    
    handleToggleDrawer = (stateName) => {
        this.setState({[stateName]: !this.state[stateName]});
    }

    handleFormError = (stateName, error) => {
        let errors = this.state.errors;

        errors[stateName] = error;

        this.setState({ errors });
    };

    hasErrors = () => {
        if (this.state.errors) {
            for (let error in this.state.errors) {
                if (this.state.errors[error])
                    return true;
            }
        }

        return false;
    };

    handlerMutation = () => {
        const postData = {
            billToFirstname: this.state.billFirstname,
            billToLastname: this.state.billLastname,
            billToAddress: this.state.billAddress1,
            billToAddress2: this.state.billAddress2,
            billToCity: this.state.billCity,
            billToPostcode: this.state.billPostalCode,
            billToPhone: this.state.billTelephone,
            billToEmail: this.state.billMail,

            shipToFirstname: this.state.shipFirstname,
            shipToLastname: this.state.shipLastname,
            shipToAddress: this.state.shipAddress1,
            shipToAddress2: this.state.shipAddress2,
            shipToCity: this.state.shipCity,
            shipToPostcode: this.state.shipPostalCode,
            shipToPhone: this.state.shipTelephone,
            shipToEmail: this.state.shipMail,

            infos: this.state.infos,
            customerTINCode: this.state.customerTincode,
            marketPlaceName: this.state.marketPlaceName,
        }


        request(`${process.env.REACT_APP_API}/sales-orders/${this.state.id}`, 'put', postData).then(
            (data) => {
                if (data.id) {
                    this.props.snack(ALERT_SUCCESS, `Modification réussie`);

                    this.initProduct()
                    this.setState({openForm: false, loading: true})
                } else {
                    this.props.snack(ALERT_ERROR, `Modification impossible`);
                }
            }
        );
    }

    checkEditForm = () => {
        if (this.hasErrors()) {
            this.props.snack(ALERT_ERROR, 'Veuillez vérifier les champs invalides');
            this.setState({ seeErrors: true });
            eventService.fire();
            return false;
        }

        return true;
    }

    render() {
        let order = this.state.order;


        const PAY_METHOD = [
            { tenderID: 2, libelle: 'Carte Bleue' },
            { tenderID: 6, libelle: 'Paypal' },
            { tenderID: 7, libelle: 'American Express' },
            { tenderID: 8, libelle: 'Amazon Pay' },
            { tenderID: 15, libelle: 'ShoppingFeed' }
        ]
        let shippingAddress = {
            address_1: order?.shipToAddress,
            address_2: order?.shipToAddress2,
            city: order?.shipToCity,
            zip: order?.shipToPostcode,
            country: order?.shipToCountry,
        }
        let billingAddress = {
            address_1: order?.billToAddress,
            address_2: order?.billToAddress2,
            city: order?.billToCity,
            zip: order?.billToPostcode,
            country: order?.billToCountry,
        }

        const getOrderStatus = (status) => {
            switch (status) {
                case 'Pending for validation':
                    return {
                        label: 'En attente',
                        status: 'warning'
                    }
                case 'Shipped':
                    return {
                        label: 'Expédiée',
                        status: 'success'
                    }
                case 'Closed':
                    return {
                        label: 'Fermée',
                        status: 'success'
                    }
                case 'Waiting for exportation':
                    return {
                        label: 'En attente',
                        status: 'warning'
                    }
                case 'Partially Shipped':
                    return {
                        label: process.env.REACT_APP_DISABLE_PARTALLY_SHIPPED === "true" ? "Expédiée" : 'Expédiée partiellement',
                        status: 'success'
                    }
                case 'Partially Accepted':
                    return {
                        label: 'Acceptée',
                        status: 'success'
                    }
                case 'MP Partially Shipped':
                    return {
                        label: process.env.REACT_APP_DISABLE_PARTALLY_SHIPPED === "true" ? "Expédiée" : 'Expédiée partiellement',
                        status: 'success'
                    }
                case 'MP Partially Accepted':
                    return {
                        label: 'Acceptée',
                        status: 'success'
                    }
                case 'Declined':
                    return {
                        label: 'Refusée',
                        status: 'error'
                    }
                case 'Accepted':
                    return {
                        label: 'Acceptée',
                        status: 'success'
                    }
                case 'Manual action required':
                    return {
                        label: 'Action requise',
                        status: 'error'
                    }
                case 'Avoir':
                    return {
                        label: 'Avoir',
                        status: 'success'
                    }
                case 'Paid':
                    return {
                        label: 'Payée',
                        status: 'success'
                    }
                case 'To Ship':
                    return {
                        label: 'À livrer',
                        status: 'warning'
                    }
                case 'Pre-order':
                    return {
                        label: 'Précommande',
                        status: 'warning'
                    }
            }
        }

        return (
            <PageContainer openForm={false} drawerWidth={this.props.drawerWidth}>
                <ContentContainer
                    headerSettings={{
                        icomoon: "picto-produit",
                        title: `Détails de la commande ${order
                            ? order.origin === 'MP'
                                ? order.shoppingFeedId
                                : order.hubIdentifier
                            : ''}`,
                        subtitle: 'Retrouvez ici le détail de votre commande',
                        windowWidth: this.props.windowWidth,
                        buttonAvailable: this.state.buttonAvailable,

                        textAdd:  order?.state && this.getButtons(order?.state)?.main?.text, 
                        handlerAdd: order?.state && this.getButtons(order?.state).main?.action,
                        // textDelete:  order?.state && this.getButtons(order?.state).cancel?.text, 
                        // handlerDelete: order?.state && this.getButtons(order?.state).cancel?.action,
                        secondaryButton:  {
                            ...order?.state && this.getButtons(order?.state)?.secondary,
                            icon: null
                        },
                        menu: [
                            {
                                label: 'Modifier',
                                color: colors.black.regular,
                                icon: <EditSharp style={{ fill: colors.red.regular }} />,
                                action:  () => {this.handleToggleDrawer('openForm')},
                                isDisabled: (order?.state && (order.state === 'Pending for validation' || order.state === 'Accepted' || order.state === 'Waiting for exportation' || order.state === 'Avoir')) ? false : true
                            },
                            (order?.state && this.getButtons(order?.state).cancel?.text) ?
                            {
                                label: order?.state && this.getButtons(order?.state).cancel?.text ? this.getButtons(order?.state).cancel?.text : null,
                                color: (order?.state && this.getButtons(order?.state).cancel?.text) ? colors.red.regular : null,
                                icon: (order?.state && this.getButtons(order?.state).cancel?.text) ? <DeleteSharpIcon style={{ fill: colors.red.regular }} /> : null,
                                action: order?.state && this.getButtons(order?.state).cancel?.action ? this.getButtons(order?.state).cancel?.action : () => {}
                            }: null
                        ]
                    }}
                >
                    {order ?
                        <Box>
                            <AccordionCustom title='Général' forcedExpanded={true} style={{ marginBottom: '32px' }}>
                                <Grid container>
                                    <Grid item xs={4}>
                                        <Box>
                                            <Typography variant="h3" style={{ paddingBottom: 10, fontSize: 25 }}>Informations Commande</Typography>
                                            <Box p={'50px'}>
                                                {/* // TODO: Change colors */}
                                                {infoRow('Statut', <StatusInfo status={getOrderStatus(order.state).status} statusText={getOrderStatus(order.state).label} />)}
                                                {infoRow('Reference', order
                                                    ? order.origin === 'MP'
                                                        ? order.shoppingFeedId
                                                        : order.hubIdentifier
                                                    : '')}
                                                {infoRow('Sous total', (order.grandTotal - order.shippingInclTax).toFixed(2) + '€')}
                                                {infoRow('Frais de port', order.shippingInclTax + '€')}
                                                {
                                                    order.origin === 'MP' && order.marketPlaceName === "Colizey"
                                                        ? infoRow('N° Colizey', order.shoppingFeedSpecialField)
                                                        : null
                                                }
                                                {infoRow('Date', moment((order.creationDate) ? order.creationDate : order.createdAt).format('DD/MM/YYYY à HH:mm'))}
                                                {infoRow('Origine', order.origin === 'MANUAL' ? 'HUB DXP' : order.origin)}
                                                {
                                                    order.origin === 'MP'
                                                        ? infoRow('Marketplace', order.marketPlaceName)
                                                        : null
                                                }
                                                {infoRow('N° de source', order.origin === 'B2C' ? order.magentoId : order.marketPlaceReference)}
                                                {infoRow('Code promo', order.discountCode ? order.discountCode : '/')}
                                                {infoRow('Marqué comme payée',(order.isPaid && order.paidAt) ? `Oui (le ${moment(order.paidAt).format('DD/MM/YYYY')})` : 'non')}

                                            </Box>
                                        </Box>
                                    </Grid>

                                    <Grid item xs={8}>
                                        <Box>
                                            <OrderItems origin={order.origin} items={order.salesOrderItems.edges} />
                                            <Grid container justify='space-between' alignItems='center' style={{ marginTop: '10px' }}>
                                                <GrandTotal>
                                                    <strong>Total </strong>
                                                    (+ frais de port) : <strong>{order.grandTotal}€</strong>
                                                </GrandTotal>
                                            </Grid>
                                            {this.state.hasReturn ?
                                                <div style={{ paddingTop: 20 }}>
                                                    <OrderItems items={splitItemsByReturn(order.salesOrderItems.edges, 'returns')} isReturn={true} />
                                                    <Grid container justify='space-between' alignItems='center' style={{ marginTop: '10px' }}>
                                                        <GrandTotal>
                                                            <strong>Total </strong>
                                                            (+ frais de port) : <strong>{order.grandTotal?.toFixed(2)}€</strong>
                                                        </GrandTotal>
                                                    </Grid>
                                                </div>
                                                : null}
                                        </Box>
                                    </Grid>
                                </Grid>
                            </AccordionCustom>

                            <div style={{ flexGrow: 1 }}>
                                <Grid container spacing={4}>
                                    <Grid item xs={6}>
                                        <AccordionCustom title='Informations' detailsStyles={{
                                            // height: 300
                                        }} forcedExpanded={true}>
                                            <Grid container>
                                                <Grid item xs={8}>
                                                    <Typography variant="h3" style={{ paddingBottom: 25, fontSize: 25 }}>Facturation commande</Typography>
                                                    {infoRow('Nom', `${order.billToFirstname} ${order.billToLastname}`)}
                                                    {infoRow('Téléphone', spaceOnNumbers(order.billToPhone))}
                                                    {infoRow('Entreprise', '-')}
                                                    {infoRow('Mail', order.billToEmail, { path: `mailto:${order.billToEmail}` })}
                                                    {addressRow(billingAddress)}
                                                    {infoRow('Paiement', PAY_METHOD.find(e => e.tenderID == order.payments?.edges[0]?.node?.tenderID)?.libelle ?? '-')}
                                                    {order.datePayment ? infoRow('Info date paiement', order.datePayment) : null}
                                                </Grid>
                                                <Grid item xs={4} style={{display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end'}}>
                                                    <OurButton
                                                        text={'Télécharger'}
                                                        icon={null}
                                                        color={colors.green.darker}
                                                        bgcolorhover={colors.green.lighterv2Hover}
                                                        bgcolor={colors.green.lighterv2}
                                                        border={`1px solid ${colors.green.darker}`}
                                                        stopPropagation={true}
                                                        onClick={async () => { this.handleDownload('facture') }}
                                                        disabled={false}
                                                        style={{
                                                            margin: 0, padding: "14px 32px", boxSizing: 'border-box'
                                                        }}
                                                    /> 
                                                </Grid>
                                            </Grid>
                                        </AccordionCustom>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <AccordionCustom title='Informations' detailsStyles={{
                                            // height: 300
                                        }} forcedExpanded={true}>
                                            <Grid container>
                                                <Grid item xs={(order?.state === "Accepted" || order?.state === "Shipped") ? 8 : 12}>
                                                    <Typography variant="h3" style={{ paddingBottom: 25, fontSize: 25 }}>Informations de livraison</Typography>
                                                    {infoRow('Nom', `${order.shipToFirstname} ${order.shipToLastname}`)}
                                                    {infoRow('Téléphone', spaceOnNumbers(order.shipToPhone))}
                                                    {infoRow('Entreprise', '-')}
                                                    {infoRow('Mail', order.shipToEmail, { path: `mailto:${order.shipToEmail}` })}
                                                    {addressRow(shippingAddress)}

                                                    {infoRow('Mode', order.shipmentMethodCode)}
                                                </Grid>
                                                {(order?.state === "Accepted" || order?.state === "Shipped") ? (
                                                    <Grid item xs={4} style={{display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end'}}>
                                                        <OurButton
                                                            text={'Télécharger'}
                                                            icon={null}
                                                            color={colors.green.darker}
                                                            bgcolorhover={colors.green.lighterv2Hover}
                                                            bgcolor={colors.green.lighterv2}
                                                            border={`1px solid ${colors.green.darker}`}
                                                            stopPropagation={true}
                                                            onClick={async () => { this.handleDownload('livraison')}}
                                                            disabled={false}
                                                            style={{
                                                                margin: 0, padding: "14px 32px", boxSizing: 'border-box'
                                                            }}
                                                        /> 
                                                    </Grid>
                                                ) : null}
                                            </Grid>
                                        </AccordionCustom>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <AccordionCustom title='Historique' detailsStyles={{
                                            height: 300
                                        }} forcedExpanded={true} custompadding={'0px'}>
                                            <Box width={'100%'}>
                                                <Typography variant="h3" style={{padding: '22px 0px 25px 22px'}}>Historique</Typography>
                                                <History items={order.logs.edges}/>
                                            </Box>
                                        </AccordionCustom>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <AccordionCustom title='Tracking' detailsStyles={{
                                            height: 300,
                                            overflow:'scroll'
                                        }} forcedExpanded={true} custompadding={'0px'}>
                                            <Box width={'100%'}>
                                                <Typography variant="h3" style={{padding: '22px 0px 25px 22px'}}>Tracking</Typography>
                                                <Tracking items={order.salesOrderItems.edges}/>

                                                <Typography variant="h3" style={{padding: '22px 0px 25px 22px'}}>PDF de tracking</Typography>
                                                <PdfTracking id={this.state.id} snack={this.props.snack} />
                                            </Box> 
                                        </AccordionCustom>
                                    </Grid>
                                </Grid>
                            </div>
                        </Box>

                        // <InfosContainer>
                        //     <Grid container spacing={3}>
                        //         <Grid item xs={6}>
                        //             <Info>
                        //                 <Typography variant="h3" style={{paddingBottom:10}}>Informations Commande {order.origin==='MP' ? order.shoppingFeedId : order.hubIdentifier}</Typography>
                        //                 {infoRow('Status',order.state)}
                        //                 {infoRow('Origine de la commande', order.origin==='MANUAL' ? 'HUB DXP' :order.origin)}
                        //                 {infoRow('N° de source',order.origin==='B2C' ? order.magentoId :  order.marketPlaceReference)}
                        //                 {
                        //                     order.origin === 'MP'
                        //                         ? infoRow('Nom du marketplace',order.marketPlaceName)
                        //                         : null
                        //                 }
                        //                 {
                        //                     order.origin === 'MP' && order.marketPlaceName === "Colizey" 
                        //                         ? infoRow('N° Colizey',order.shoppingFeedSpecialField)
                        //                         : null
                        //                 }
                        //                 {infoRow('Date de commande',moment((order.creationDate) ? order.creationDate : order.createdAt).format('DD/MM/YYYY à HH:mm'))}

                        //                 {this.state.hasReturn ? 
                        //                     <div style={{position:'absolute',top:20,right:20}}>
                        //                         <Chip variant="outlined" size="small" label="RETOUR" style={{color : colors.red.regular,borderColor : colors.red.regular}}/>
                        //                     </div>
                        //                 : null}
                        //             </Info>
                        //         </Grid>
                        //         <Grid item xs={6}>
                        //             <Info>
                        //                 <Typography variant="h3" style={{paddingBottom:10}}>Méthode de livraison</Typography>
                        //                 {infoRow('Mode de livraison',order.shipmentMethodCode)}
                        //                 {infoRow('Frais de port',`${(order.shippingInclTax) ? order.shippingInclTax : '0'} €`)}

                        //             </Info>
                        //         </Grid>
                        //     </Grid>

                        //     <Grid container spacing={3}>
                        //         <Grid item xs={6}>
                        //             <Info>
                        //                 <Typography variant="h3" style={{paddingBottom:10}}>Informations de facturation</Typography>
                        //                 <Typography variant="h4">{`${order.billToFirstname} ${order.billToLastname}`}</Typography>
                        //                 {infoRow('Téléphone',order.billToPhone)}
                        //                 {infoRow('Email',order.billToEmail,{path : `mailto:${order.billToEmail}`})}
                        //                 {addressRow(billingAddress)}
                        //                 {infoRow('Moyen de paiement',PAY_METHOD.find(e => e.tenderID == order.payments?.edges[0]?.node?.tenderID)?.libelle ?? '-')}
                        //                 {order.datePayment ? infoRow('Info date paiement',order.datePayment) : null}


                        //             </Info>
                        //         </Grid>

                        //         <Grid item xs={6}>
                        //             <Info>
                        //                 <Typography variant="h3" style={{paddingBottom:10}}>Informations de livraison</Typography>
                        //                 <Typography variant="h4">{`${order.shipToFirstname} ${order.shipToLastname}`}</Typography>
                        //                 {infoRow('Téléphone',order.shipToPhone)}
                        //                 {infoRow('Email',order.shipToEmail,{path : `mailto:${order.shipToEmail}`})}
                        //                 {addressRow(shippingAddress)}

                        //                 {infoRow('Instruction',order.instruction)}
                        //                 {infoRow('Colisage',order.colisage)}

                        //             </Info>
                        //         </Grid>


                        //     </Grid>

                        //     <Info>
                        //         <OrderItems items={order.salesOrderItems.edges} />
                        //         <GrandTotal>
                        //             <strong>Total (+ frais de port) : </strong>
                        //             {order.grandTotal}€
                        //         </GrandTotal>
                        //         {this.state.hasReturn ?
                        //             <div style={{paddingTop:20}}>
                        //                 <OrderItems items={splitItemsByReturn(order.salesOrderItems.edges,'returns')} isReturn={true}/> 
                        //             </div>
                        //         : null}


                        //     </Info>

                        //     <Grid container spacing={3}>
                        //         <Grid item xs={6}>
                        //             <Info>
                        //                 <Typography variant="h3" style={{paddingBottom:10}}>Historique</Typography>
                        //                 <History items={order.logs.edges}/>
                        //             </Info>
                        //         </Grid>

                        //         <Grid item xs={6}>
                        //             <Info>
                        //                 <Typography variant="h3" style={{paddingBottom:10}}>Tracking</Typography>
                        //                 <Tracking items={order.salesOrderItems.edges}/>
                        //             </Info>
                        //         </Grid>

                        //     </Grid>

                        // </InfosContainer>                        
                        : null}

                </ContentContainer>
                {
                    this.state.popupRepayment && 
                        this.state.typeAction !== 'cancel' && this.state.typeAction !== 'suffering' &&
                        this.state.typeAction !== 'accept' && this.state.typeAction !== 'refuse' ? (
                        <DialogModal
                            open={this.state.popupRepayment}
                            title={this.state.popupTitle} maxWidth={1200}
                            customStyle={{
                                borderRadius: 0,
                                boxShadow: 'none',

                            }}
                            titleStyle={{
                                padding: '24px 24px',
                            }}
                            primaryAction={() => this.handlePopupRepayment()} primarybgcolor={colors.green.regular} primarybgcolorhover={colors.green.darker} primaryText="Confirmer"
                            secondaryAction={() => { this.handleToggleDialog('popupRepayment') }} secondarycolor={colors.grey.regular} secondarybgcolor={colors.white} secondarybgcolorhover={colors.grey.lighter.hue900} secondaryborder={`1px solid ${colors.grey.regular}`}
                        >
                            {
                                (this.state.typeAction === 'refund' || this.state.typeAction === 'ship') ? (
                                    <OrderItemsRepayment
                                        items={order.salesOrderItems.edges}
                                        selectedProductsRepayment={this.state.selectedProductsRepayment}
                                        qtyProductsRepayment={this.state.qtyProductsRepayment}
                                        refundShippingFees={this.state.refundShippingFees}
                                        adjustment={this.state.adjustment}
                                        typeAction={this.state.typeAction}
                                        setState={(value, state) => this.setState({ [state]: value })}
                                        trackingName={this.state.trackingName}
                                        trackingNumber={this.state.trackingNumber}
                                        trackingLink={this.state.trackingLink}
                                        trackingSendEmail={this.state.trackingSendEmail}
                                        trackingEmailContent={this.state.trackingEmailContent}
                                        errors={{trackingName: this.state.errorShippingTrackingName, trackingNumber: this.state.errorShippingTrackingNumber, trackingLink: this.state.errorShippingTrackingLink}}
                                    />
                                ) : null
                            }

                        </DialogModal>
                    ) : this.state.popupRepayment && 
                        (this.state.typeAction === 'cancel' || this.state.typeAction === 'suffering' || this.state.typeAction === 'accept' || this.state.typeAction === 'refuse')
                        ? (
                            <GeneralDialogModal
                                icon={true}
                                type={this.state.typeAction === 'cancel' || this.state.typeAction === 'refuse' 
                                    ? 'cancel' 
                                    : this.state.typeAction === 'suffering'
                                        ? 'add'
                                        : this.state.typeAction === 'accept'
                                            && 'confirm'
                                }
                                open={this.state.popupRepayment}
                                title={
                                    this.state.typeAction === 'cancel'
                                        ? 'Êtes-vous sûr de vouloir annuler cette commande ?'
                                        : this.state.typeAction === 'suffernig'
                                            ? 'Êtes-vous sûr de vouloir créer un avis de souffrance ?'
                                            : this.state.typeAction === 'accept'
                                                ? 'Êtes-vous sûr de vouloir accepter cette commande ?'
                                                : this.state.typeAction === 'refuse'
                                                    && 'Êtes-vous sûr de vouloir refuser cette commande ?'
                                }
                                primaryAction={() => this.handlePopupRepayment()}
                                secondaryAction={() => { this.handleToggleDialog('popupRepayment') }}
                                windowWidth={this.props.windowWidth}
                            >
                                {this.state.typeAction === 'cancel' ? (
                                    <Typography variant="body2">Êtes-vous sûr de vouloir annuler cette commande ? <strong>Cette action est irréversible</strong></Typography>
                                ) : this.state.typeAction === 'suffering' ? (
                                    <Typography variant="body2">Êtes-vous sûr de vouloir créer un avis de souffrance ?</Typography>
                                ) : this.state.typeAction === 'accept' ? (
                                    <Typography variant="body2">Êtes-vous sûr de vouloir accepter cette commande ?</Typography>
                                ) : this.state.typeAction === 'refuse' && (
                                    <Typography variant="body2">Êtes-vous sûr de vouloir refuser cette commande ?</Typography>
                                )}
                            </GeneralDialogModal>
                        )
                        : null
                }

                <LayoutFormBuilder
                    opened={this.state.openForm}//use state
                    icomoon={ null }
                    forClose={() => {this.handleToggleDrawer('openForm')}} 
                    dataLayout={ordersEditFormConfig()} 
                    handleButtonGroupChange={this.handleButtonGroupChange}
                    drawerWidth={this.props.drawerWidth}
                    // handlerSetup={this.handlerSetup} 
                    stateCallback={this.stateCallback}
                    errorCallback={this.handleFormError}
                    stepperSkip={3}
                    validateButton={true}
                    // deleteText={this.state.editForm === 'edit' ? 'Supprimer' : null}
                    handlerMutation={() => {this.handlerMutation()}}
                    allState={this.state} 
                    // currentLang={this.state.currentLang}
                    // handleLang={this.handleLang}
                    // hideInput={this.state.inputHidden}
                    windowWidth={this.props.windowWidth}
                    stepperButtonAction={[
                        () => {
                            return this.checkEditForm();
                        }, 
                        () => {
                            return this.checkEditForm();
                        }, 
                        () => {
                            return this.checkEditForm();
                        }, 
                    ]}
                    isSublayout={false}
                />
            </PageContainer>
        )
    }
}

const mapStateToProps = state => {
    return {
        loading: state.loading,
        locales: state.locales,
        attributes: state.attributes
    };
};

const mapDispatchToProps = dispatch => {
    return {
        startLoading: () => dispatch({ type: START_LOADING }),
        stopLoading: () => dispatch({ type: STOP_LOADING }),
        snack: (type, message) => dispatch({ type: SNACK, payload: { type, message } })
    }
};

export default withRouter(withApollo(connect(mapStateToProps, mapDispatchToProps)(OrderDetails)));
