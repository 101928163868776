import { FORM } from "../../../../../js/constants/form-types";
import OurButton from "../../../../ui/button/Button";

export default function newLocationConfig(warehouses) {
    var obj = {
        titleForm: 'Créer une zone',
        subTitleForm: `Veuillez compléter les champs ci-dessous pour créer votre zone`,
        langSelect: false,
        drawerType: 'drawer',
        noForm: true,
        component: OurButton,
        textButtonValidate: 'Créer',
        formConfig: {
            type: FORM,
            children: [
                {
                    labelName: 'Principal',
                    isOptionnal: false,
                    optionsInputs: [
                        {
                            type: 'oneImage',
                            label: "Image de la zone",
                            translated: false,
                            helper: {
                                text: "Choisir une image pour la zone",
                                link: false,
                            },
                            required: false,
                            stateName: 'formImage'
                        },
                        {
                            type: 'text',
                            label: 'Code',
                            helper: {
                                text: "Code",
                                link: false,
                            },
                            required: true,
                            disabled: false,
                            stateName: 'locationCode',
                        },
                        {
                            type: 'text',
                            label: 'Description',
                            helper: {
                                text: "Description",
                                link: false,
                            },
                            required: false,
                            stateName: 'locationDescription',
                        },
                        {
                            type: 'select',
                            label: 'Lier à un entrepôt',
                            translated: false,
                            helper: {
                                text: `Lier à un entrepôt`,
                                link: false,
                            },
                            required: true,
                            disabled: false,
                            stateName: 'locationWarehouseToLink',
                            value: warehouses?.map(warehouse => ({
                                value: warehouse.node.id,
                                label: warehouse.node.code,
                                disabled: false,
                            })),
                        },
                        {
                            type: 'switch',
                            label: 'Actif',
                            helper: {
                                text: "Voulez-vous activer la zone ?",
                                link: false,
                            },
                            required: true,
                            stateName: 'locationIsActive',
                        },
                    ]
                },
            ]
        }
    }
    return obj;
}