import noImageCompany from '../../../../../assets/images/emptyCompany.jpg';
import CardProductHub from '../../../../layouts/Card/cardContent/CardProductHub';
import CardSchema from '../../../../layouts/Card/cardContent/CardSchema';

export default function stocksListingConfig(datas = {}) {
  let config = {
    listSettings: {
      grid: {
        components: CardSchema,
        // components: CardProductHub,
        columns: {
          xs: 1,
          sm: 2,
          laptop: 3,
          xl: 3
        }
      },
      table: {
        style: {
          type: 'orders',
          cursor: 'default',
        },
        columns: [
          {
            label: 'Reference',
            field: 'sku',
            cellType: 'text',
            cellProps: {
              fontSize: 15,
            },
            prefix: 'Ref. '
          },
          {
            label: 'Nom du produit',
            field: 'productName',
            cellType: 'text',
            cellProps: {
              fontSize: 15,
            }
          },
          {
            label: "N° d'entrepôts",
            field: 'warehousesCount',
            cellType: 'text',
            cellProps: {
              fontSize: 15,
            }
          },
          {
            label: 'Stock',
            field: 'totalStock',
            cellType: 'text',
            cellProps: {
              fontSize: 15,
            }
          },
        ]
      }
    },

    listMappers: [
      {
        view: 'card',
        mapper: 'productsGridMapper'
      },
      {
        view: 'table',
        mapper: 'productsGridMapper'
      },

    ],

    perPageOptions: {
      'card': ['24','48'],
      'table': ['10', '20', '30', '40', '50']
    },


    searchSettings: {
      inputs: [
        {
          placeholder: 'Rechercher par SKU',
          queryField: 'sku',
          type: 'text',
          mapper: null,
          callback: null,
          label: 'SKU'
        },
        {
          placeholder: 'Rechercher par Nom ',
          queryField: 'productName',
          type: 'text',
          mapper: null,
          callback: null,
          label: 'Nom'
        },
      ]
    }
  }

  return config;

}
