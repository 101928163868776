import React from 'react';
import { withRouter } from 'react-router';
import { connect } from "react-redux";
import TopPanel from '../../../layouts/TopPanel/TopPanel';
import colors from '../../../../config/theme/colors';

class SettingsAccess extends React.Component {
    constructor(props){
        super(props)
        this.state = {
        };
    }

    render() {
        return (
            <div>
                <TopPanel 
                    icomoon="verified" 
                    colorIcomoon={colors.blue.lighter.hue300} 
                    title="Gestion des accès" 
                    subtitle="Gestion de vos accès (création / modification / suppression)" 
                    handlerAdd={this.handleToggleDrawer} 
                    /* textAdd={hasRights(SETTINGS, SETTINGS_USERS, CREATE) ? "Ajouter un utilisateur" : null} */
                    searchHandler={this.handleChange}  
                    gradientColor1={colors.menu.regular} 
                    gradientColor2={colors.menu.darker}
                    windowWidth={this.props.windowWidth}
                    buttonAvailable={this.state.users ? true : false}
                    openForm={this.state.openForm}
                    hasBorder={true}
                />
            </div>
        );
    }
    goTo = route => {
        this.props.history.push(route);
    };
}

const mapStateToProps = state => {
    return {
        loading: state.loading,
        products: state.products,
    };
};

export default withRouter(connect(mapStateToProps, null)(SettingsAccess));
