import React from 'react';
import PageContainer from '../../../layouts/Container/PageContainer';
import ContentContainer from '../../../layouts/Container/ContentContainer';
import Listing from '../../../layouts/Listing/Listing';
import ordersListingConfig, { ordersFormConfig } from './config/ordersListing.config'
import {connect} from 'react-redux';
import { withRouter } from 'react-router';
import {ROUTE_ORDERS_DETAIL} from '../../../../js/constants/route-names'
import { setValue } from '../../../../js/helpers/form';
import { getElements } from '../../../../js/utils/functions';
import colors from '../../../../config/theme/colors';
import DialogModal from '../../../ui/dialog/DialogModal';
import { Box, Button, FormControl, FormControlLabel, Grid, MenuItem, Select } from '@material-ui/core';
import {ALERT_ERROR, ALERT_SUCCESS} from '../../../../js/constants/alert-types'
import request from '../../../../js/utils/fetch';
import { SNACK, START_LOADING, STOP_LOADING } from '../../../../js/constants/action-types';
import Switch from '@material-ui/core/Switch';
import StatsBar from '../../../layouts/StatsBar/StatsBar';
import { getGlobalStats } from '../../../../js/helpers/stats';
import LayoutFormBuilder from '../../../ui/form/LayoutFormBuilder';
import { withApollo } from 'react-apollo';
import InputBuilder from '../../../ui/form/InputBuilder';
import OurTypography from '../../../ui/typography/Typography';
import moment from 'moment';
import { eventService } from '../../../../js/services/event.service';
import { exportComptaConfig } from './config/exportComptaConfig.config';
import downloadTempMedia from '../../../../js/utils/downloadTempMedia';

class ListOrders extends React.Component{
    constructor(props){
        super(props);
        this.state={
            currentLang: props.locales[0].node.code,
            openExportCompta: false,
            type : props.noStatus,
            startDate: '',
            endDate: '',
            popup: false,
            yearlyExport: false,
            yearExport: '',
            possibleYear: [],
            file: null,
            fileName: null,
            statusExport:"all",
            statsIsLoading: true,
            stats: [],
            products: [],
            selectedProducts: [],
            errors: {},
            seeErrors: false,
            origin:"all",
            marketplaces: [],
            marketPlaceName:null,
            typeAction: 'export',
            marketplaces: [],
            infos: null,
            statusArray: {
                all:null,
                shipped: ["Shipped","Partially Shipped","MP Partially Shipped"],
                accepted: ["Partially Accepted","MP Partially Accepted","Accepted"],
                waiting: ["Pending for validation","Waiting for exportation"],
                avoir:["Avoir"],
                actionRequired:["Manual Action Required"],
                refused: ["Declined"],
                closed: ["Closed"],
            }
        }
    }
    
    goTo = (id) =>{
        id = id.replace('/api/sales-orders/','')
        let path = ROUTE_ORDERS_DETAIL.replace(':id',id);
        this.props.history.push({pathname : path})
    }

    getIdentifierByType = (type) =>{
        switch(type){
            case 'returned' :
                return 'salesOrderReturned'
            default :
                return 'salesOrders'
        }
    }

    getQueryVariables = (identifier) =>{
        let queryvariables = {};
        switch(identifier){
            case 'salesOrderReturned':
                queryvariables.hasCodeRaisonRetour = true;
                break;
        }

        return queryvariables;
    }

    handleInput = (value,input) =>{
        let values = setValue(this.state,input.stateName ?? input.queryField,value)
        this.setState({...this.state,...values})
    }

    resetStates = () =>{
        this.setState({
            origin : null
        })
    }

    componentDidUpdate(prevProps,prevState){
        if(prevProps.noStatus != this.props.noStatus){
            this.resetStates()
            this.setState({type:this.props.noStatus})
        }
        if(prevState.pickedDate != this.state.pickedDate){
            this.setState({
                statsIsLoading: true,
            })
        }
        if (prevState.type != this.state.type ) {
            this.setState({
                statsIsLoading: true
            })

            if (this.state.type == 'returned' ) {
                getGlobalStats('returns', new Date()).then(value => {
                    this.setState({
                        statsIsLoading: false,
                        stats: value
                    })
                })

            } else {
                getGlobalStats('orders', new Date()).then(value => {
                    this.setState({
                        statsIsLoading: false,
                        stats: value
                    })
                })

            }
        }
    }

    initMarketplaces = async() =>{
        let marketplaces = await getElements('marketplaces')
        this.setState({
            marketplaces : marketplaces.data.getMarketPlaceNameSalesOrders.edges,
            marketplaceschoices : marketplaces.data.getMarketPlaceNameSalesOrders.edges.map(marketplace =>(
                {value : marketplace.node.marketPlaceName,label : marketplace.node.marketPlaceName}
            ))
        })
    }

    componentDidMount(){
        this.initMarketplaces();
        var date = new Date();
        var month = (date.getMonth()+1).toString()
        var day = date.getDate().toString()
        var month = (month.length === 1) ? '0'+month : month;
        var day = (day.length === 1) ? '0'+day : day;
        var startDate = date.getFullYear() + '-' + month + '-01';
        var endDate = date.getFullYear() + '-' + month + '-' + day;
        var yearExport = date.getFullYear();

        var possibleYear = [(yearExport - 2), (yearExport - 1), yearExport]

        this.setState({
            startDate: startDate,
            endDate: endDate,
            yearExport: yearExport,
            possibleYear: possibleYear
        })
        
        if (this.state.type == 'returned' ) {
            getGlobalStats('returns', new Date()).then(value => {
                this.setState({
                    statsIsLoading: false,
                    stats: value
                })
            })

        } else {
            getGlobalStats('orders', new Date()).then(value => {
                this.setState({
                    statsIsLoading: false,
                    stats: value
                })
            })

        }
    }

    handleExport(canExport) {
        return new Promise(async (resolve, reject) => {
            this.props.startLoading();

            if (!canExport) {
                this.setState({openExportCompta: false, popup: false})
                this.props.stopLoading();
                resolve();
                return
            }

            if (this.state.typeAction === "import-orders" || this.state.typeAction === 'import-orders-is-paid') {
                this.handleSubmit();
                this.setState({popup: false})
                this.props.stopLoading();
                resolve();
                return;
            }

            var object = {
                startDate: this.state.startDate,
                endDate: this.state.endDate,
            }
            
            switch (this.state.typeAction) {
                case 'export':
                    var object = {
                        startDate: this.state.startDate,
                        endDate: this.state.endDate,
                        state:this.state.statusArray[this.state.statusExport],
                        origin:this.state.origin === "all" ? null : this.state.origin === "MP2" ? "MP" : this.state.origin,
                        filename:this.state.fileName,
                        name:this.state.marketPlaceName
                    }
                    var url = `${process.env.REACT_APP_API}/sales-orders/export`;
                    break;

                    case 'exportDetails':
                        var url = `${process.env.REACT_APP_API}/sales-orders/export/detailed`;
                        var object = {
                            filename: this.state.exportsName,
                            startDate: this.state.startDate,
                            endDate: this.state.endDate,
                            origin: this.state.exportOrigin,
                            mp_name: this.state.exportMarketName
                        }
                        break;                        

                case 'export-compta':
                    var object = {
                        startDate: this.state.startDate,
                        endDate: this.state.endDate,
                        state:this.state.statusArray[this.state.statusExport],
                        origin:this.state.origin === "all" ? null : this.state.origin === "MP2" ? "MP" : this.state.origin,
                        filename:this.state.fileName
                    }
                    if(this.state.yearlyExport) {
                        object = {
                            yearly: true,
                            year: this.state.yearExport
                        }
                    }
                    var url = `${process.env.REACT_APP_API}/sales-orders/export/compta`;
                    break;

                case 'export-compta-gd':
                    var object = {
                        startDate: this.state.startDate,
                        endDate: this.state.endDate,
                    }

                    var url = `${process.env.REACT_APP_API}/gd/export-compta`;
                    break;

                case 'export-facture':
                    var object = {
                        startDate: this.state.startDate,
                        endDate: this.state.endDate,
                    }
                    var url = `${process.env.REACT_APP_API}/sales-orders/export/facture`;
                    break;
    
                default:
                    var object = {
                        startDate: this.state.startDate,
                        endDate: this.state.endDate,
                        state:this.state.statusArray[this.state.statusExport],
                        origin:this.state.origin === "all" ? null : this.state.origin === "MP2" ? "MP" : this.state.origin,
                        filename:this.state.fileName,
                        name:this.state.marketPlaceName
                    }
                    var url = `${process.env.REACT_APP_API}/sales-orders/export`;
                    break;
            }

            try {
                if(this.state.isAnnualExport) {
                    object = {
                        startDate: moment().startOf('year').format('YYYY-MM-DD'),
                        endDate: moment().endOf('year').format('YYYY-MM-DD'),
                    }
                }
                 
                downloadTempMedia(url, 'post', object)
                    .then(() => this.props.snack(ALERT_SUCCESS, `Export réussi`))
                    .catch((error) => {
                        this.props.snack(ALERT_ERROR, `${error?.message ?? 'Une erreur est survenue'}`);
                    });
            } catch(e) {
                this.props.snack(ALERT_ERROR, `Une erreur est survenue`);
            }
        

            this.setState({openExportCompta: false, popup: false})
            this.props.stopLoading();
            resolve();
        });
    }
    
    handleToggleDialog = (stateName) => {
        this.setState({[stateName]: false});
        switch (this.state.typeAction) {
            case 'export':
                var title = `Export des commandes`
                break;

            case 'export-compta':
                var title = `Export compta`
                break;

            case 'export-compta-gd':
                var title = `CADOR`
                break;

            case 'export-facture':
                var title = `Export facture`
                break;

            case 'import-orders':
                var title = `Import commandes`
                break;

            case 'import-orders-is-paid':
                var title = `Import statut paiement des commandes`
                break;

            default:
                var title = `Confirmer cette action ?`
                break;
        }
        
        this.setState({[stateName]: !this.state[stateName], popupTitle: title});
    };

    handleSubmit = async () => {

        if (this.state.file === null) {
            this.props.snack(ALERT_ERROR, `Merci de choisir un fichier`);
            return;
        }

        const formData = new FormData();
        formData.append('file', this.state.file);
        const config = {
          headers: {
            'content-type': 'multipart/form-data',
          },
        };
        
        if (this.state.typeAction === "import-orders") {
            var url = `${process.env.REACT_APP_API}/sales-orders/import/csv`;
        } else if(this.state.typeAction === 'import-orders-is-paid') {
            var url = `${process.env.REACT_APP_API}/gd/import/is-paid`;
        }

        try {
            var data = await request(url, 'post', formData, null, true);
            
            if (data.success) {
                this.props.snack(ALERT_SUCCESS, `Import réussi`);
            } else {
                this.props.snack(ALERT_ERROR, `Une erreur est survenue`);
            }

        } catch(e) {
            this.props.snack(ALERT_ERROR, `Une erreur est survenue`);
        }
    }

    goTo = (url, state) => {
        this.props.history.push({
            pathname: url,
            state: state,
        })
    }

    handleToggleDrawer = (stateName) => {
        this.setState({[stateName]: !this.state[stateName]});
    }

    stateCallback = (stateName, evt) => {
        const value = evt.target.value;

        this.setState({
            [stateName]: value
        });
    }

    handleButtonGroupChange = (stateName, value) => {
        this.setState({
            [stateName]: value
        });
    };

    hasErrors = () => {
        if(!this.state.isActive){
            const errorsCopy = {...this.state.errors};
            const filteredErrors = Object.keys(errorsCopy).filter(key => !key.includes('ship'));
            const newArr = filteredErrors.map(key => errorsCopy[key]);

            if (newArr) {
                for (let error in newArr) {
                    if (newArr[error])
                        return true;
                }
            }
        } else {
            if (this.state.errors) {
                for (let error in this.state.errors) {
                    if (this.state.errors[error])
                        return true;
                }
            }
        }
        return false;
    };

    handlerMutation = () => {
        const postData = {
            billToFirstname: this.state.billFirstname,
            billToLastname: this.state.billLastname,
            billToAddress: this.state.billAddress1 + ' ' + this.state.billAddress2,
            billToCity: this.state.billCity,
            billToPostcode: this.state.billPostalCode,
            billToPhone: this.state.billTelephone,
            billToEmail: this.state.billMail,

            shipToFirstname: this.state.isActive && this.state.shipFirstname,
            shipToLastname: this.state.isActive && this.state.shipLastname,
            shipToAddress: this.state.isActive && this.state.shipAddress1 + ' ' + this.state.shipAddress2,
            shipToCity: this.state.isActive && this.state.shipCity,
            shipToPostcode: this.state.isActive && this.state.shipPostalCode,
            shipToPhone: this.state.isActive && this.state.shipTelephone,
            shipToEmail: this.state.isActive && this.state.shipMail,

            datePayment: null,
            products: this.state.selectedProducts,

            infos: this.state.infos,
            marketPlaceName: this.state.marketPlaceName,
        }

        if (!this.state.isActive) {
            postData.shipToFirstname = this.state.billFirstname;
            postData.shipToLastname = this.state.billLastname;
            postData.shipToAddress = this.state.billAddress1 + ' ' + this.state.billAddress2;
            postData.shipToCity = this.state.billCity;
            postData.shipToPostcode = this.state.billPostalCode;
            postData.shipToPhone = this.state.billTelephone;
            postData.shipToEmail = this.state.billMail;
        }

        const url = this.state.typeForm === 'avoir' ? 'avoir' : 'new'

        request(`${process.env.REACT_APP_API}/sales-orders/${url}`, 'post', postData).then(
            (data) => {
                if (data.success) {
                    this.props.snack(ALERT_SUCCESS, `Création réussie`);

                    this.goTo(ROUTE_ORDERS_DETAIL.replace(':id', data.salesOrder))
                } else {
                    this.props.snack(ALERT_ERROR, `Création impossible`);
                }
            }
        );
    }

    handleButtonGroupChange = (stateName, value) => {
        this.setState({
            [stateName]: value
        });
    };

    handleAddProduct = (product) => {
        if(this.state.selectedProducts.find(p => p.sku === product.sku)) {
            this.setState({ selectedProducts: this.state.selectedProducts.map(p => {
                if(p.sku === product.sku) {
                    p.quantity += 1
                    p.totalPrice = p.quantity * p.unitPrice
                }
                return p
            }) })
        } else {
            product.quantity = 1
            product.unitPrice = 0
            product.unitTVA = 0
            product.totalPrice = 0
            product.id = product._id
            this.setState({ selectedProducts: [...this.state.selectedProducts, product] })
        }
    };

    handleProductName = (e, productIndex) => {
        const selectedProducts = [...this.state.selectedProducts];

        selectedProducts[productIndex].name = e.target.value

        this.setState({ selectedProducts: selectedProducts })
    }

    handleUnitPrice = (e, productIndex) => {
        const selectedProducts = [...this.state.selectedProducts];

        selectedProducts[productIndex].unitPrice = parseFloat(e.target.value)

        this.setState({ selectedProducts: selectedProducts })
        this.calcPrice(productIndex)
    }

    handleUnitTVA = (e, productIndex) => {
        const selectedProducts = [...this.state.selectedProducts];

        selectedProducts[productIndex].unitTVA = parseFloat(e.target.value)

        this.setState({ selectedProducts: selectedProducts })
        this.calcPrice(productIndex)
    }

    calcPrice = (productIndex) => {
        const selectedProducts = [...this.state.selectedProducts];

        selectedProducts[productIndex].totalPrice = (selectedProducts[productIndex].unitPrice + selectedProducts[productIndex].unitTVA) * selectedProducts[productIndex].quantity

        this.setState({ selectedProducts: selectedProducts })
    }

    addCustomProduct = () => {
        const timestamp = Date.now();
        const selectedProduct = {};
        selectedProduct.quantity = 1
        selectedProduct.unitPrice = null
        selectedProduct.unitTVA = null
        selectedProduct.totalPrice = 0
        selectedProduct.name = ""
        selectedProduct.id = "custom_".concat(timestamp)
        selectedProduct.sku = "custom_".concat(timestamp)
        selectedProduct.custom = true
        this.setState({ selectedProducts: [...this.state.selectedProducts, selectedProduct] })
    };

    handleQty = (e, type, productIndex) => {
        const selectedProducts = [...this.state.selectedProducts];

        if (type === "+") {
            selectedProducts[productIndex].quantity = selectedProducts[productIndex].quantity + 1
            this.calcPrice(productIndex)
        }

        if (type === "-") {
            selectedProducts[productIndex].quantity = selectedProducts[productIndex].quantity - 1
            if (selectedProducts[productIndex].quantity < 1) {
                selectedProducts.splice(productIndex, 1);
            } else {
                this.calcPrice(productIndex)
            }
        }

        this.setState({ selectedProducts: selectedProducts })
    }

    handleFormError = (stateName, error) => {
        let errors = this.state.errors;

        errors[stateName] = error;

        this.setState({ errors });
    };

    getTopPanelData = () => {
        switch (this.props.noStatus) {
            case 'returned':
                return {
                    title: 'Liste des retours',
                    subtitle: 'Vue d’ensemble pour gérer vos retours',
                }
            // case 'avoir':
            //     return {
            //         title: 'Avoirs',
            //         subtitle: 'Avoirs',
            //     }
            default:
                return {
                    title: 'Liste des commandes',
                    subtitle: 'Vue d’ensemble pour gérer vos commandes',
                }
        }
    }

    render(){
        let identifier = this.getIdentifierByType(this.state.type);
        let queryvariables = this.getQueryVariables(identifier);
        let {listSettings,perPageOptions,listMappers,searchSettings,orderBy} = ordersListingConfig(identifier,this.state,{dateMapper : this.dateMapper})

        let marketName = [
            {
                value: 'all',
                label: 'Toutes les marketplaces'
            }
        ];

        this.state.marketplaces.forEach(marketplace => {
            marketName.push(
                {
                    value: marketplace.node.marketPlaceName,
                    label: marketplace.node.marketPlaceName
                }
            )
        })

        const formInputs = {
            name: {
                xs: 12,
                type: 'text',
                label:'Nom',
                helper:'Nom',
                required: true,
                stateName: 'exportsName',
                disabled: false,
                value: ''
            },
            startDate: {
                type: 'date',
                label: 'Date de début',
                helper: {
                    text: 'Date de debut',
                    link: false,
                },
                required: true,
                stateName: 'exportsStartAt',
            },
            endDate: {
                type: 'date',
                label: 'Date de fin',
                helper: {
                    text: 'Date de fin',
                    link: false,
                },
                required: true,
                stateName: 'exportsEndAt',
            },
            origin: {
                type: 'select',
                label: 'Origine de la commande',
                helper: {
                    text: '',
                    link: false,
                },
                required: true,
                stateName: 'exportOrigin',
                value: [
                    {
                        value: 'all',
                        label: 'Toutes'
                    },
                    {
                        value: 'B2C',
                        label: 'Site officiel'
                    },
                    {
                        value: 'MP',
                        label: 'Marketplace'
                    }
                ]
            },
            mp_name: {
                type: 'select',
                label: 'Marketplace',
                helper: {
                    text: '',
                    link: false,
                },
                required: true,
                stateName: 'exportMarketName',
                value: marketName
            },
            annualExport: {
                type: 'switch',
                label: 'Actif',
                helper: {
                    text: '',
                    link: false,
                },
                required: true,
                stateName: 'isAnnualExport',
                value: [
                    {
                        value: true,
                        label: 'Actif',
                    },
                    {
                        value: false,
                        label: 'Inactif',
                    },
                ]
            }
        };

        return(
            <PageContainer openForm={false} drawerWidth={this.props.drawerWidth}>
                <ContentContainer
                    headerSettings={{
                        icomoon:"picto-produit",
                        title:this.getTopPanelData().title,
                        subtitle:this.getTopPanelData().subtitle,
                        windowWidth:this.props.windowWidth,
                        buttonAvailable:true,
                        textAdd: '+ Nouvelle commande', 
                        handlerAdd: () => {
                            this.setState({
                                typeForm: 'order',
                            }, () => {
                                this.handleToggleDrawer('openForm')
                            })
                        },

                        secondaryButton: {
                            text: '+ Nouvel avoir',
                            icon: null,
                            action: () => {
                                this.setState({
                                    typeForm: 'avoir',
                                }, () => {
                                    this.handleToggleDrawer('openForm')
                                })
                            }
                        },
                    }}
                >
                    <StatsBar 
                        data={this.state.stats} 
                        windowWidth={this.props.windowWidth}
                        callbackAction={
                            () => {this.setState({
                                openExportCompta: !this.state.openExportCompta,
                                popupTitle: 'Export comptabilité',
                                popupContent: 'Veuillez compléter les champs ci-dessous pour votre exports compta',
                                typeAction: 'export' 
                            })}
                        }
                        

                        callbackActionImportLabel={(process.env.REACT_APP_IMPORT_IS_PAID) ? 'Import paiement' : 'Import commandes'}
                        callbackActionImport={
                            (process.env.REACT_APP_IMPORT_ORDERS !== 'true' && process.env.REACT_APP_IMPORT_IS_PAID !== 'true') ? null :
                            () => {this.setState({
                                popup: !this.state.popup,
                                popupTitle: (process.env.REACT_APP_IMPORT_IS_PAID) ? 'Import paiement' : 'Import commandes',
                                popupContent: 'Veuillez compléter les champs ci-dessous pour votre import',
                                typeAction: (process.env.REACT_APP_IMPORT_IS_PAID) ? 'import-orders-is-paid' : 'import-orders'
                            })}
                        }
                        callbackActionDetails={
                            (process.env.REACT_APP_EXPORT_COMMANDES === 'true') ? 
                            () => {this.setState({
                                popup: !this.state.popup,
                                popupTitle: 'Export commandes',
                                popupContent: 'Veuillez compléter les champs ci-dessous pour votre export',
                                typeAction: 'exportDetails'
                            })} : null
                        }

                        callbackActionFacture={
                            (process.env.REACT_APP_EXPORT_FACTURE === 'true') ? 
                            () => {this.setState({
                                popup: !this.state.popup,
                                popupTitle: 'Export facture',
                                popupContent: 'Veuillez compléter les champs ci-dessous pour votre export',
                                typeAction: 'export-facture'
                            })} : null
                        }

                        callbackActionIsPaid={
                            (process.env.REACT_APP_IMPORT_IS_PAID === 'true') ? 
                            () => {this.setState({
                                popup: !this.state.popup,
                                popupTitle: 'Import paiement',
                                popupContent: 'Veuillez compléter les champs ci-dessous pour votre import',
                                typeAction: 'import-orders-is-paid'
                            })} : null
                        }

                        callbackActionCompta={
                            () => {this.setState({
                                popup: !this.state.popup,
                                popupTitle: 'Export comptable',
                                popupContent: 'Veuillez compléter les champs ci-dessous pour votre import',
                                typeAction: 'export-compta'
                            })}
                        }

                        callbackActionCador={
                            () => {this.setState({
                                popup: !this.state.popup,
                                popupTitle: 'CADOR',
                                popupContent: 'Veuillez compléter les champs ci-dessous pour votre import',
                                typeAction: 'export-compta-gd'
                            })}
                        }

                        
                        statsIsLoading={this.state.statsIsLoading}
                        customPadding={'0px'}
                        spacing={0}
                    />

                    <Listing
                        label = 'commandes'
                        settings = {listSettings}
                        cardProps = {{}}
                        perPageOptions = {perPageOptions}
                        mappers = {listMappers}
                        currentLang = {this.state.currentLang}
                        identifier = {identifier}
                        viewsOptions = {{
                            current : 'table',
                            settings : ['table'] //Si il n'y a qu'un seul item il n'y aura pas de changement de vues
                        }}
                        tableProps = {{
                            textButton: 'Commande',
                            action: this.goTo,
                            url: ROUTE_ORDERS_DETAIL
                        }}
                        queryVariables={queryvariables}
                        rowAction={this.goTo}
                        resultIdentifier="salesOrders"
                        searchSettings = {searchSettings}
                        orderBy = {orderBy}
                        inputCallback={this.handleInput}
                    >

                    </Listing>


                    <LayoutFormBuilder
                        opened={this.state.openForm}
                        icomoon={ null }
                        forClose={() => {this.handleToggleDrawer('openForm')}} 
                        dataLayout={ordersFormConfig(
                            this.state.typeForm,
                            this.state.isActive,
                            this.handleAddProduct, 
                            this.state.selectedProducts,
                            this.handleProductName,
                            this.handleUnitPrice,
                            this.handleUnitTVA,
                            this.calcPrice,
                            this.handleQty,
                            this.addCustomProduct
                        )} 
                        drawerWidth={this.props.drawerWidth}
                        // handlerSetup={this.handlerSetup} 
                        stateCallback={this.stateCallback}
                        handleButtonGroupChange={this.handleButtonGroupChange}
                        stepperButtonAction={[
                            () => {
                                if (this.hasErrors()) {
                                    this.props.snack(ALERT_ERROR, 'Veuillez vérifier les champs invalides');
                                    this.setState({ seeErrors: true });
                                    eventService.fire();
                                    return false;
                                }

                                return true;
                            }, 
                            () => {
                                if (this.state.selectedProducts.length === 0) {
                                    this.props.snack(ALERT_ERROR, 'Veuillez vérifier les champs invalides');
                                    this.setState({ seeErrors: true });
                                    eventService.fire();
                                    return false;
                                }

                                return true;
                            }, 
                            () => {
                                return true;
                            },
                        ]}
                        errorCallback={this.handleFormError}
                        stepperSkip={3}
                        validateButton={true}
                        // deleteMutation={hasRights(this.state.mainRight, this.state.secondaryRight, DELETE) && this.state.editForm === 'edit' ? this.handleToggleDialog : null}
                        stepperButtonDisabled={[null, () => {
                            if (this.state.selectedProducts.length === 0) {
                                return true;
                            } else {
                                let hasEmpty = false;
                                this.state.selectedProducts.forEach(product => {
                                    if (
                                        product.name === '' || product.name === null
                                        || product.quantity === '' || isNaN(product.quantity)
                                        || product.unitPrice === ''  || isNaN(product.unitPrice)
                                        || product.unitTVA === '' || isNaN(product.unitTVA)) {
                                        hasEmpty = true;
                                    }
                                });
                                return hasEmpty;
                            }
                        }, null]}
                        // deleteText={this.state.editForm === 'edit' ? 'Supprimer' : null}
                        handlerMutation={() => {this.handlerMutation()}}
                        allState={this.state} 
                        // currentLang={this.state.currentLang}
                        // handleLang={this.handleLang}
                        // hideInput={this.state.inputHidden}
                        windowWidth={this.props.windowWidth}
                        isSublayout={false}
                    />
                    <LayoutFormBuilder
                        opened={this.state.openExportCompta}
                        icomoon={null}
                        forClose={() => {this.handleToggleDrawer('openExportCompta')}} 
                        dataLayout={exportComptaConfig(this.state)} 
                        drawerWidth={"40vw"}
                        stateCallback={this.stateCallback}
                        handleButtonGroupChange={this.handleButtonGroupChange}
                        errorCallback={this.handleFormError}
                        validateButton={true}
                        handlerMutation={() => this.handleExport(true)}
                        allState={this.state} 
                        windowWidth={this.props.windowWidth}
                        gap={"0 0"}
                    />

                    {
                        this.state.popup ? (
                            <DialogModal 
                                open={this.state.popup} 
                                title={this.state.popupTitle} maxWidth={1200} 
                                description={this.state.popupContent}
                                customStyle={{
                                    width: '444px'
                                }}
                                primaryAction={() => this.handleExport(true)} primarybgcolor={colors.green.regular} primarybgcolorhover={colors.green.darker} primaryText="Confirmer"
                                secondaryAction={() => {this.handleExport(false)}} secondarycolor={colors.grey.regular} secondarybgcolor={colors.white} secondarybgcolorhover={colors.grey.lighter.hue900} secondaryborder={`1px solid ${colors.grey.regular}`}
                            >
                                <div style={{display: 'flex', flexDirection: 'column', marginTop: 8}}>
                                    {
                                        this.state.typeAction == 'export-compta' ? 
                                            <FormControlLabel control={
                                                <Switch checked={this.state.yearlyExport}
                                                    onChange={() => {this.setState({yearlyExport: !this.state.yearlyExport}) }}
                                                    name="checkedA"
                                                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                                                    color="primary"
                                                /> 
                                            } label="Export annuel ?" />
                                            : ''
                                    }
                                    {
                                        (this.state.typeAction == 'export-compta' || this.state.typeAction == 'export-compta-gd') ? 
                                            (this.state.yearlyExport) ? (
                                                <>
                                                
                                                <FormControl>
                                                <span style={{width: '100%', color: '#808F98', fontSize: 14, fontFamily: 'soleil, sans-serif', fontWeight: 'normal', lineHeight: '19px', marginTop: '12px'}}>Année de l'export</span>
                                                    
                                                    <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={this.state.yearExport}
                                                    onChange={(e) => {this.setState({yearExport: e.target.value})}}
                                                    >
                                                    {
                                                        this.state.possibleYear.map((year, i) => (
                                                            <MenuItem key={i} value={year}>
                                                                {year}
                                                            </MenuItem>
                                                        ))
                                                    }
                                                    </Select>
                                                </FormControl>
                                                </>
                                            ) : (
                                                <>
                                                    <Grid item>
                                                        <OurTypography>{'Date de début'}</OurTypography>
                                                    </Grid>
                                                    <InputBuilder value={this.state.startDate} input={formInputs.startDate} stateCallback={(e) => this.setState({startDate: e.target.value})} /> 
                                                    
                                                    <Grid item>
                                                        <OurTypography>{'Date de fin'}</OurTypography>
                                                    </Grid>
                                                    <InputBuilder value={this.state.endDate} input={formInputs.endDate} stateCallback={(e) => this.setState({endDate: e.target.value})} /> 
                                                </>
                                            )
                                            : (
                                                (this.state.typeAction !== "import-orders" && this.state.typeAction !== "import-orders-is-paid") ?
                                                <Box style={{
                                                    backgroundColor: colors.grey.lighter.hue980,
                                                    padding: 24,
                                                    border: `1px solid ${colors.grey.border}`,
                                                }}>
                                                    {/* // TODO: Ask why name */}
                                                    {
                                                        (this.state.typeAction !== 'export'  && this.state.typeAction !== 'exportDetails' &&  this.state.typeAction !== 'export-facture') ?
                                                        <Box>
                                                            <Grid item>
                                                                <OurTypography>{'Nom'}</OurTypography>
                                                            </Grid>
                                                            <InputBuilder value={this.state.exportsName} input={formInputs.name} stateCallback={(e) => this.setState({exportsName: e.target.value})} /> 
                                                        </Box>
                                                        : null
                                                    }
                                                    
                                                    <Grid item>
                                                        <OurTypography>{'Date de début'}</OurTypography>
                                                    </Grid>
                                                    <InputBuilder value={this.state.startDate} input={formInputs.startDate} stateCallback={(e) => this.setState({startDate: e.target.value})} /> 
                                                    
                                                    <Grid item>
                                                        <OurTypography>{'Date de fin'}</OurTypography>
                                                    </Grid>
                                                    <InputBuilder value={this.state.endDate} input={formInputs.endDate} stateCallback={(e) => this.setState({endDate: e.target.value})} /> 
                                                    
                                                    {
                                                        (this.state.typeAction !== 'exportDetails' &&  this.state.typeAction !== 'export-facture') ?
                                                        <>
                                                        <Grid item>
                                                            <OurTypography>{formInputs.origin.label}</OurTypography>
                                                        </Grid>
                                                        <InputBuilder value={this.state.exportOrigin} input={formInputs.origin} stateCallback={(e) => this.setState({exportOrigin: e.target.value})} /> 

                                                        {
                                                            (this.state.exportOrigin === 'MP') ?
                                                            <Box>
                                                                <Grid item>
                                                                    <OurTypography>{formInputs.mp_name.label}</OurTypography>
                                                                </Grid>
                                                                <InputBuilder value={this.state.exportMarketName} input={formInputs.mp_name} stateCallback={(e) => this.setState({exportMarketName: e.target.value})} /> 
                                                            </Box>
                                                            : null
                                                        }
                                                       
                                                        </>
                                                        : null
                                                    }
                                                    {
                                                        (this.state.typeAction !== 'export' && this.state.typeAction !== 'exportDetails' &&  this.state.typeAction !== 'export-facture') ?
                                                        <Box>
                                                            <Grid item>
                                                                <OurTypography>{'Export annuel'}</OurTypography>
                                                            </Grid>
                                                            <InputBuilder value={this.state.isAnnualExport} input={formInputs.annualExport} handleButtonGroupChange={this.handleButtonGroupChange} /> 
                                                        </Box>
                                                        : null
                                                    }
                                                </Box>
                                                : 
                                                
                                                <Box style={{
                                                    backgroundColor: colors.grey.lighter.hue980,
                                                    padding: 24,
                                                    border: `1px solid ${colors.grey.border}`,
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    alignItems: 'center',
                                                    justifyContent: 'center'
                                                }}>
                                                    <input
                                                        style={{display: 'none'}}
                                                        accept="text/csv"
                                                        id="button-file"
                                                        type="file"
                                                        onChange={(e) => this.setState({file: e.target.files[0], fileName: e.target.files[0].name})}
                                                    />
                                                    <label htmlFor="button-file">
                                                    <Button component="span" variant='primary' style={{background: colors.green.regular, color: 'white'}}>
                                                        Ajouter un fichier
                                                    </Button>
                                                    </label> 
                                                    <p>Fichier choisis: 
                                                    {
                                                        this.state.fileName ? 
                                                        this.state.fileName
                                                        : ' aucun'
                                                    }
                                                    </p>
                                                </Box>
                                            )
                                    }
                                </div>
                                
                            </DialogModal>
                        ) : null
                    }
                </ContentContainer>
            </PageContainer>       
        )

    }
}
const mapStateToProps = state => {
    return {
        loading: state.loading,
        products: state.products,
        locales: state.locales,
        attributes: state.attributes
    };
};

const mapDispatchToProps = dispatch => {
    return {
        snack: (type, message) => dispatch({ type: SNACK, payload: { type, message }}),
        startLoading: () => dispatch({ type: START_LOADING }),
        stopLoading: () => dispatch({ type: STOP_LOADING })
    }
};

export default withRouter(withApollo(connect(mapStateToProps, mapDispatchToProps)(ListOrders)));

