import React from 'react';
import colors from '../../../config/theme/colors';
import Button from "../../ui/button/Button";
import styled from 'styled-components';
import {Grid, Typography, Box} from '@material-ui/core';

const GridCustom = styled(Grid)`
    display: flex;
    flex-wrap: wrap;
`;
const Title = styled(Typography)`
    color: ${colors.blue.regular};
    @media screen and (max-width: 1480px){
        font-size: 18px;
    }
    @media screen and (max-width: 1250px){
        font-size: 17px;
    }
    @media screen and (max-width: 1000px){
        font-size: 16px;
    }
`;
const Subtitle = styled(Typography)`
    color: ${colors.grey.regular};
    margin-top: 8px;
    @media screen and (max-width: 1480px){
        font-size: 14px;
        margin-top: 0;
    }
    @media screen and (max-width: 1250px){
        font-size: 13px;
    }
    @media screen and (max-width: 1000px){
        font-size: 12px;
    }
`;

const EmptyCard = (props) => {
    const { title, subtitle, textButton, picto, onClick, openForm, mtImg } = props;
    return (
        <GridCustom item xs={12}>
            <Grid container alignItems={"center"} justify={"center"}>
                <Grid item xs={props.xsImg ? props.xsImg : 2} style={{transition: 'all 250ms cubic-bezier(0, 0, 0.2, 1) 0ms'}}>
                    <Box textAlign={"center"} mt={mtImg ? mtImg : 8}>
                        <img src={picto} width={"100%"}/>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Box textAlign={"center"} mt={4}>
                        <Title variant={"h2"}>{title}</Title>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Box textAlign={"center"}>
                        <Subtitle variant={"h4"}>{subtitle}</Subtitle>
                    </Box>
                </Grid>
                {textButton ?
                    <Box textAlign={"center"} mt={3}>
                        <Button bgcolor={colors.green.regular.hue300} shadowcolor={colors.green.darker} text={textButton ? `${textButton}` : null} onClick={onClick} className="button" disabled={openForm} />
                    </Box>
                : null
                }
            </Grid>
        </GridCustom>
    );
};

export default EmptyCard;