import React from "react";
import PropTypes from "prop-types";
import { Box, Grid, IconButton, Menu, MenuItem, Typography, Fade } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import OurButton from "../../../ui/button/Button";
import EditIcon from '@material-ui/icons/Edit';
import colors from "../../../../config/theme/colors";
import { useCallback } from "react";
import StatusInfo from "../../../ui/status-info/StatusInfo";
// import DefaultImageCard from "../../../../assets/images/image-fake.png";
import styled from "styled-components";
import FileCopySharpIcon from '@material-ui/icons/FileCopySharp';
import DeleteSharpIcon from '@material-ui/icons/DeleteSharp';
import OurTypography from "../../../ui/typography/Typography";
// import OurMenu from "../../../ui/menu/Menu";
import UpdateSharpIcon from '@material-ui/icons/UpdateSharp';
import { useHistory } from "react-router";
import { ROUTE_PRODUCTS_LIST_DETAIL } from "../../../../js/constants/route-names";

const ImageBox = styled(Box)`
  background-image: ${props => props.src ? `url(${props.src})` : `url(${null})`};
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  transform: ${props => props.src ? null : 'scale(2)'};
  overflow: hidden;
  width: 100%;
  height: 100%;
`

const MenuItemCustom = styled(MenuItem)`
    color: ${colors.black.regular};
    svg{
        fill: ${colors.black.regular};
    }
`

const MenuCustom = styled(Menu)`
    .MuiMenu-paper{
        box-shadow: 0 0 10px #1622421A;
    }
`

const GridAbsolute = styled(Grid)`
    position: absolute;
    left: 0;
    top: 0;
    background-color: ${colors.green.regular};
    color: ${colors.white};
    z-index: 99;
    display: flex;
    gap: 3px;
    padding: 2px 8px;
    min-width: 50px;
`

const Wrapper = styled(Grid)`
    padding: 16px;
    position: relative;
    min-height: 265px;
    cursor: pointer;
`

const useStyles = makeStyles((theme) => ({
    editIcon: {
        position: 'absolute',
        color: colors.black.regular.regular,
        // display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: 25,
        top: -10,
        right: 10,
        transition: 'all .5s',
        display: 'none',
    },
    menu: {
        position: 'absolute',
        top: 0,
        right: 0,
    },
    paper: {
        border: '1px solid #d3d4d5',
    }
}));

const CardSummary = ({
    summary,
    modifyButtonProps,
    data,
    ...props
}) => {
    const history = useHistory();

    const classes = useStyles();

    const getMediaOrigin = () => {
        if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
            // DEV
            return process.env.REACT_APP_MEDIAS;
        } else {
            // PROD
            return process.env.REACT_APP_SPREAD_CDN ? `${process.env.REACT_APP_SPREAD_CDN}/medias` : process.env.REACT_APP_MEDIAS;
        }
    };

    const goTo = (url,variables) =>{
        history.push({
            pathname : url,
            states : variables
        })
    }

    return (
        <Wrapper container onClick={() => {goTo(ROUTE_PRODUCTS_LIST_DETAIL.replace(':id',data?._id))}}>
            <GridAbsolute>
                <UpdateSharpIcon style={{fontSize: '20px'}}/>
                {data.totalStock}
            </GridAbsolute>
            <Grid item xs={6} style={{
                overflow: 'hidden',
                position: 'relative',
            }}>
                {/* Picture */}
                {/* <Image src={data.node.media ?? DefaultImageCard} alt={data.node.libelle} responsive /> */}
                <ImageBox origin={getMediaOrigin()} src={data.imageUrl} /> {/* TODO: Ask for path */}
            </Grid>
            <Grid container xs={6} direction='column' justifyContent='space-between' style={{
                position: 'relative',
                paddingLeft: '21px',
            }}>

                <Grid container justify="space-between">
                    <OurTypography colortext={colors.grey.lighter.hue800} style={{fontSize: 14}}>Ref. {data.sku}</OurTypography>
                    <StatusInfo status={data.status && 'success'} width={'auto'}/>
                    {/* <OurMenu menuOptions={editMenuOptions} handleClick={(e, item) => item.action(e)}/> */}
                </Grid>

                <Grid item style={{
                    marginTop: '20px',
                }}>
                    {/* Title */}
                    <Typography variant='h3' style={{ marginBottom: '12px', fontSize: '18px' }}>{data.productName}</Typography>
                    {/* Summary */}
                    <Grid container>
                        <Grid item xs={8}>
                            <Typography variant='body1' style={{ color: colors.black.regular, fontSize: '13px' }}>Entrepot :</Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography variant='body1' style={{ color: colors.black.regular, fontWeight: 'bold' }}>{data.warehousesCount}</Typography>
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={8}>
                            <Typography variant='body1' style={{ color: colors.black.regular, fontSize: '13px' }}>Dispo en reel :</Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography variant='body1' style={{ color: colors.black.regular, fontWeight: 'bold' }}>{'-'}</Typography>
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={8}>
                            <Typography variant='body1' style={{ color: colors.black.regular, fontSize: '13px' }}>En stock :</Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography variant='body1' style={{ color: colors.black.regular, fontWeight: 'bold' }}>{data.totalStock}</Typography>
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={8}>
                            <Typography variant='body1' style={{ color: colors.black.regular, fontSize: '13px' }}>Reservé :</Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography variant='body1' style={{ color: colors.black.regular, fontWeight: 'bold' }}>{'-'}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Wrapper>
    );
}

CardSummary.propTypes = {
    title: PropTypes.string.isRequired,
    summary: PropTypes.array.isRequired,
    modifyButtonProps: PropTypes.objectOf({
        text: PropTypes.string.isRequired,
        onClick: PropTypes.func.isRequired
    }),
}

CardSummary.defaultProps = {
    title: 'Test',
    summary: [
        {
            label: 'Test',
            value: 'Test'
        },
        {
            label: 'Test',
            value: 'Test'
        },
    ],
    modifyButtonProps: {
        text: 'Test',
        onClick: () => { },
    },
}

export default React.memo(CardSummary);