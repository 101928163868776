import axios from 'axios';

// FOR NOW, JUST FOR FILE

const Axios = (url, method = 'GET', data = null, auth = true,contentType='multipart/form-data') => {
    const token = localStorage.getItem('AUTH_TOKEN');

    let headers = {
        'Content-Type': contentType
    };

    if (auth && token)
        headers['Authorization'] = `Bearer ${token}`;

    let init = {
        url,
        method,
        headers,
        data,
        mode: 'cors',
        onUploadProgress: (event) => {
            console.log(event);
        }
    };

    return new Promise((resolve, reject) => {
        axios(init)
            .then(response => {
                resolve(response.data);
            })
            .catch(e => {
                reject(e);
            });
    });
};

export default Axios;