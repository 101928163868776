import React from 'react';
import { Box, Grid, Typography, Tooltip } from '@material-ui/core';
import Button from '../../../ui/button/Button';
import StatusInfo from '../../../ui/status-info/StatusInfo';
import colors from '../../../../config/theme/colors';
import styled from 'styled-components';
import NoImage from '../../../../assets/images/not-found.png';
import PhotoLibraryOutlinedIcon from '@material-ui/icons/PhotoLibraryOutlined';
import CircularProgress from '@material-ui/core/CircularProgress';
import { getTraductionAttributs } from '../../../../js/utils/functions';
import { makeStyles } from '@material-ui/core/styles';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import StoreIcon from '@material-ui/icons/Store';
import _ from 'lodash';
import UpdateIcon from '@material-ui/icons/Update';
import PlayForWorkIcon from '@material-ui/icons/PlayForWork';
import * as moment from 'moment';
import StyleIcon from '@material-ui/icons/Style';

const InfoStock = styled(Grid)`
    position: absolute;
    top: -32px;
    right: -32px;
    width: 70px;
    height: 70px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    color: white;
    background: ${colors.blue.regular};
    opacity: 0.8;
    font-weight: bold;
    line-height: normal;
`
const ContainerCard = styled(Box)`
    display: block;
    height: 100%;
    width: 100%;
    padding-top: 8px;
`
const ButtonCustom = styled(Button)`
    margin: 0;
    text-transform: unset;
    font-weight: bold;
`
const Title = styled(Typography)`
    font-weight: bold;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: ${props => props.nbline ? props.nbline : '2'};
    -webkit-box-orient: vertical;
`
const Sku = styled(Typography)``;
const ProductAttr = styled.div``;
const SpanColor = styled.span`
    color: ${colors.blue.lighter.hue300};
    font-weight: bold;
    font-size: 13px;
`
const ContainerButton = styled(Box)`
    display: flex;
    justify-content: flex-end;

`
const CircularProgressCustom = styled(CircularProgress)`
    color: ${props => props.value > 30 
            ? props.value > 65 
                ? colors.green.regular : "#f6d607"
            : colors.red.regular};
    margin-left: ${props => props.marginleft};
`

export default function CardCustom(props){

    const useStylesBootstrap = makeStyles(theme => ({
        arrow: {
            color: colors.blue.regular,
        },
        tooltip: {
            backgroundColor: colors.blue.regular,
            fontSize: 14,
            padding: 10,
            textAlign: 'left',
        },
    }));

    function BootstrapTooltip(props) {
        const classes = useStylesBootstrap();
        return <Tooltip arrow classes={classes} {...props} />;
    }

    const product = props.product;
    const attributes = product.productDatas.edges;
    const smallCard = props.smallCard ? props.smallCard : false;
    let name = '';
    let image= '';
    let imageSelected = [];
    let getImageByTranslation = '';
    let statsCompletion = product.stats?.infoStats?.percentByLangs ? 
                            product.stats?.infoStats?.percentByLangs?.[props.currentLang] 
                                ? product.stats.infoStats.percentByLangs[props.currentLang] 
                                : product.stats?.infoStats.percentByLangs[0] 
                                    ? product.stats.infoStats.percentByLangs[0] 
                                    : 0
                            : 0
    
    for(let attribute of attributes){
        if(attribute.node.attribute.isSystem){
            imageSelected = attributes.filter(e => e.node.media !== null)
            getImageByTranslation = imageSelected.filter(e => e.node.locale.code === props.currentLang)

            name = getTraductionAttributs('product_name', attributes, props.currentLang);

            if(attribute.node.attribute.identifier === 'product_image' && image === ''){
                image = getTraductionAttributs('product_image', attributes, props.currentLang, 'image');
            }
        }
    }
    let catalogList = []
    /*
    for (let category of props.product.productCategories.edges){
        if (category.node.category.catalog){
            if (catalogList.indexOf(category.node.category.catalog.libelle) === -1){
                catalogList.push(category.node.category.catalog.libelle)
            }
        }
    }*/

    const goTo = props.routeProduct;

    let stock = 0;
    let cart = 0;
    let reappro = 0;
    let realTime = 0;
    if(product.stocks?.allInfoStocks){
        cart = product.stocks.allInfoStocks.cart.value;
        for(let item of product.stocks.allInfoStocks.stock){
            stock += item.value;
        }
        for(let item of product.stocks.allInfoStocks.reappro){
            reappro += item.value;
        }
        realTime = stock - cart;
    }

    let date = moment(product?.stocks?.allInfoStocks?.reappro[0].date);
    let diff = date.diff(moment(), 'days') + 1;
    return(
        <ContainerCard>
            <Grid container style={{position: 'relative', height: '100%', width: '100%'}}>
                {
                    product.stocks?.allInfoStocks ?
                        <InfoStock item>
                            <span>Dispo</span>
                            <span style={{fontSize: 24, display:'flex', flexDirection:'row', alignItems:'center'}}><UpdateIcon /> <span style={{marginLeft: 8}}>{realTime}</span></span>
                        </InfoStock>
                    : null
                }
                
                <Grid item xs={props.type === "dashboard" ? 4 : 5}>
                    <Box pr={props.type === "dashboard" ? 2 : 4}>
                        <Grid container alignItems="center" justify="center" style={{minHeight: 100}}>
                            {
                                image !== '' && image!== null && image.isOptimized ?
                                <img src={image !== '' && image!== null ? `${process.env.REACT_APP_MEDIAS}/images/optimized/${image.filePath}` : NoImage} style={{maxHeight: 180, maxWidth: '100%'}} alt={name} />
                                :
                                <img src={image !== '' && image!== null ? `${process.env.REACT_APP_MEDIAS}/${image.filePath}` : NoImage} style={{maxHeight: 180, maxWidth: '100%'}} alt={name} />
                            }
                        </Grid>
                    </Box>
                    {
                        process.env.REACT_APP_MODE_SPREAD !== "hub" && !props.minimalSetup ?
                            <Box pr={2} mt={2} display="flex" style={{alignItems: "center", justifyContent: "space-around", flexDirection: props.windowWidth > 900 ? "row" : "column"}}>
                                {
                                    statsCompletion === 0 || statsCompletion ?
                                    (
                                        <Box position="relative" display="inline-flex" mb={props.windowWidth > 900 ? 0 : 2}>
                                            <CircularProgressCustom variant="determinate" thickness={3} value={statsCompletion} size={40}/>
                                            <Box
                                                top={0}
                                                left={0}
                                                bottom={0}
                                                right={0}
                                                position="absolute"
                                                display="flex"
                                                alignItems="center"
                                                justifyContent="center"
                                            >
                                                <Typography variant="h5" component="div" color="textSecondary">
                                                    {statsCompletion > 0 ? `${Math.round(statsCompletion)}%` : `${statsCompletion}%`}
                                                </Typography>
                                            </Box>
                                        </Box>
                                    ) : null
                                }
                                {
                                    imageSelected ? 
                                    (
                                        <Grid container alignItems="center" style={{width:"auto"}}>
                                            <PhotoLibraryOutlinedIcon  style={{color: colors.grey.regular}}/>
                                            <Typography variant="body1" style={{marginLeft: '8px'}}><SpanColor>{getImageByTranslation.length}</SpanColor>/{props.nbImageTotal}</Typography>
                                        </Grid>
                                    ) : null
                                }
                            </Box>
                        : null
                    }
                </Grid>
                <Grid item xs={props.type === "dashboard" ? 8 : 7} style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
                    <Box>
                        <Box style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                            <Sku variant="body2">{product.sku}</Sku>
                            <Box style={{width: 'auto'}}>
                                <StatusInfo status={product.status}></StatusInfo>
                            </Box>
                        </Box>
                        <Box pt={1}>
                            <Title variant="h4" nbline={props.type === 'dashboard' ? '1' : '2' }>{name || 'N/A'}</Title>
                        </Box>
                        {}
                        <Box pt={1}>
                            <ProductAttr variant="body1">
                                {
                                    !props.minimalSetup ? 
                                    <>
                                        <Typography>Présent dans : <br/></Typography>
                                        <Box style={{display: 'inline-block'}}>
                                            {/*
                                                product.productCategories.edges.length > 0 ? (
                                                    <BootstrapTooltip 
                                                        title={ product.productCategories.edges.filter(e => e.node.category.catalog === null).map((categorie, index) => {
                                                            if(categorie.node.category.catalog === null){
                                                                if (categorie.node.category.categoryDatas.edges.length > 0 && categorie.node.category.categoryDatas.edges.find(e => e.node.attribute.identifier === "category_name")){
                                                                    let getCategorieByLang = getTraductionAttributs('category_name', categorie.node.category.categoryDatas.edges, props.currentLang)
                                                                    return(<span key={`CategorieName${index}`} style={{display: 'block'}}>{index + 1} - {getCategorieByLang}</span>);
                                                                } else {
                                                                    return(<span key={`CategorieName${index}`} style={{display: 'block'}}>{index + 1} - {categorie.node.category.libelle}</span>);
                                                                }
                                                            }else{
                                                                return null
                                                            }                            
                                                        })}
                                                        arrow
                                                    >
                                                        <SpanColor> {product.productCategories.edges.filter(e => e.node.category.catalog === null).length} {`catégorie${product.productCategories.edges.filter(e => e.node.category.catalog === null).length > 1 ? 's' : ''}`}</SpanColor>
                                                    </BootstrapTooltip>
                                                ) : ( <Typography variant="body1"><SpanColor>Aucune catégorie</SpanColor></Typography> )
                                                    */   
                                                }
                                                {
                                                    product.productCategories.totalCount> 0 ? (
                                                        <BootstrapTooltip 
                                                            title={product.productCategories.totalCount}
                                                            arrow
                                                        >
                                                            <SpanColor> {product.productCategories.totalCount} {`catégorie${product.productCategories.totalCount > 1 ? 's' : ''}`}</SpanColor>
                                                        </BootstrapTooltip>
                                                    ) : ( <Typography variant="body1"><SpanColor>Aucune catégorie</SpanColor></Typography> ) 
                                                }
                                        </Box>
                                        <Box style={{display: 'inline-block', padding: '0 5px'}}> / </Box>
                                        <Box style={{display: 'inline-block'}}>
                                            {
                                                product.productCatalogs.totalCount> 0 ? (
                                                    <BootstrapTooltip 
                                                        title={product.productCatalogs.totalCount}
                                                        arrow
                                                    >
                                                        <SpanColor> {product.productCatalogs.totalCount} {`catalogue${product.productCatalogs.totalCount > 1 ? 's' : ''}`}</SpanColor>
                                                    </BootstrapTooltip>
                                                ) : ( <Typography variant="body1"><SpanColor>Aucun catalogue</SpanColor></Typography> ) 
                                            }
                                        </Box>
                                    </>
                                    : null
                                }
                                {
                                    product.children.totalCount > 0 ? 
                                        <Grid container alignItems={"center"} style={{marginTop: 8}}>
                                            <StyleIcon  style={{color: colors.grey.regular}}/>
                                            <Typography variant="body1" style={{marginLeft: 8}}> 
                                                <SpanColor> {product.children.totalCount} {`variante${product.children.totalCount> 1 ? 's' : ''}`}</SpanColor>
                                            </Typography>
                                        </Grid>
                                    : null
                                }
                            </ProductAttr>
                        </Box>
                    </Box>
                    {
                        <ContainerButton pt={1} style={{justifyContent: process.env.REACT_APP_MODE_SPREAD !== "hub" ? "flex-end" : "flex-end"}}>
                            {!smallCard ? 
                                <ButtonCustom stopPropagation={props.inForm} disableElevation={true} text="Voir le produit" arrow="right" bgColor={colors.green.regular} onClick={goTo} fontsize={ 12 } style={props.type === "dashboard" ? {height: '100%', padding: 6} : null} />
                            :null}
                        </ContainerButton>
                    }
                </Grid>
                {
                    product.stocks?.infoStocks ?
                    
                        <Grid container style={{paddingTop: 16}}>
                            {
                                product.stocks.infoStocks.length > 0 ?
                                    product.stocks.infoStocks.map((stock) => {
                                        if (stock.value > 0){
                                            return(
                                                <Grid container alignItems="center">
                                                    {stock.type === 'store' ?
                                                        <StoreIcon style={{color: colors.grey.regular}}/>
                                                        : 
                                                        <EqualizerIcon style={{color: colors.grey.regular}}/>
                                                    }
                                                    <Typography variant="body1" style={{marginLeft: 4}}>{stock.libelle}: <b><SpanColor>{stock.value}</SpanColor></b></Typography>
                                                </Grid>
                                            )
                                        }else{
                                            return null
                                        }
                                    })
                                : null
                            }
                        </Grid>
                    : null
                }
                {
                    product.stocks?.allInfoStocks ?
                        <Grid container style={{marginTop: 8}} alignItems="center">
                            <PlayForWorkIcon style={{color: colors.grey.regular}}/>
                            <Typography variant="body1" style={{marginLeft: 4}}>Réassort: <b><SpanColor>{reappro > 0 ? reappro : 'Aucune'}</SpanColor></b> le {moment(product.stocks.allInfoStocks.reappro[0].date).format('DD/MM/YYYY')} { diff > 0 ? `(J+${diff})` : null }</Typography>
                        </Grid>
                    : null
                }
                
            </Grid>
            
            {smallCard ? 
                <ButtonCustom disableElevation={true} stopPropagation={props.inForm} text="Voir le produit" arrow="right" bgColor={colors.green.regular}  onClick={goTo} style={{marginTop:10}} />
            :null}
        </ContainerCard>
    );
}