import React from 'react';
import { withRouter } from 'react-router'
import { getElements } from '../../../../js/utils/functions';
import ContentContainer from '../../../layouts/Container/ContentContainer';
import PageContainer from '../../../layouts/Container/PageContainer';
import { connect } from 'react-redux'
import Typography from '../../../ui/typography/Typography'
import CardCustom from '../../../layouts/Card/CardCustom';
import { Grid, Box, Divider } from '@material-ui/core'
import Listing from '../../../layouts/Listing/Listing';
import productListingConfig from './config/productListing.config';
import colors from '../../../../config/theme/colors'
import styled from 'styled-components';
import Button from '../../../ui/button/Button'
import { ROUTE_LOCATION_DETAIL, ROUTE_PRODUCTS_LIST_DETAIL } from '../../../../js/constants/route-names';
import StatsBar from '../../../layouts/StatsBar/StatsBar';
import PageLoader from '../../../ui/loadings/page-loader/PageLoader';
import { getGlobalStats } from '../../../../js/helpers/stats';
import AccordionCustom from '../../../layouts/Accordion/AccordionCustom';
import StatusInfo from '../../../ui/status-info/StatusInfo';
import IcomoonReact from 'icomoon-react';
import iconSet from "../../../../assets/selection.json";
import AutorenewSharpIcon from '@material-ui/icons/AutorenewSharp';
import LayoutFormBuilder from '../../../ui/form/LayoutFormBuilder';
import editWarehouseConfig from './config/editWarehouse.config';
import { UPDATE_MEDIA } from '../../../../queries/medias';
import { withApollo } from 'react-apollo';
import { DELETE_WAREHOUSE, UPDATE_WAREHOUSE } from '../../../../queries/warehouses';
import GeneralDialogModal from '../../../ui/dialog/GeneralDialogModal';
import ImgNotFound from '../../../../assets/images/socials/image-fake.png';
import './styles.scss'
import { getDeviceZoom } from '../../../../js/utils/getDeviceZoom';
import Fetch from '../../../../js/utils/fetch';
import axios from 'axios';
import { getFileConfig } from '../../../../js/helpers/files';
import EditSharpIcon from '@material-ui/icons/EditSharp';
import DeleteSharpIcon from '@material-ui/icons/DeleteSharp';

const CardSite = styled(Box)`
    /* background:white; */
    padding:12px;
    display:flex;
    align-items:center;
    background-color: ${props => props.index % 2 === 0 ? colors.grey.lighter.hue900 : 'none'};
    width:100%;
    justify-content:space-between;
`

const RowContainer = styled.div`
    display: grid;
    padding: 5px 0;
    grid-template-columns: 150px 1fr;
    gap: 10px;
    min-height: 30px;

    &:last-of-type{
        padding-bottom:0;
    }
`;

const ContainerImage = styled(Box)`
    overflow: hidden;
    background: url(${props => props.backgroundimage});
    background-size: cover;
    background-position: center;
    height: 100%;
    min-height: 200px;
    width: 100%;
`;

const infoRow = (key, value, link) => {
    return (
        <RowContainer>
            <Typography variant="body1" style={{ fontSize: 13, color: colors.black.regular }}>
                {key}
            </Typography>
            {link ?
                <a style={{ color: colors.black.regular, fontWeight: 'bold' }} href={link.path}>
                    {value}
                </a>

                :
                <Typography variant="body1" style={{ fontSize: 13, fontWeight: 'bold', color: colors.black.regular }}>
                    {value}
                </Typography>
            }

        </RowContainer>
    )
}

class WarehouseDetails extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            currentLang: props.locales[0].node.code,
            stats: [],
            openForm: false,
            // pickedDate: new Date('1995-12-17T03:24:00'), // Put the date of the "pickedDropdownOption" Dropdown Option
            // pickedDropdownOption: 1,
            statsIsLoading: true,
            warehouseImage: null,
            warehouseCode: null,
            warehouseDescription: null,
            warehouseIsActive: null,
        }
    }

    componentDidMount() {
        this.initWarehouse()

        getGlobalStats('orders', new Date()).then(value => {
            this.setState({
                statsIsLoading: false,
                stats: value
            })
        })
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.formImage != this.state.formImage) {

            // getGlobalStats(this.state.pickedDate).then(value => {
            //     this.setState({
            //         statsIsLoading: false,
            //         stats: value
            //     })
            // })
        }
        if (prevState.pickedDate != this.state.pickedDate) {
            this.setState({
                statsIsLoading: true,
            })
            // getGlobalStats(this.state.pickedDate).then(value => {
            //     this.setState({
            //         statsIsLoading: false,
            //         stats: value
            //     })
            // })
        }
    }

    initWarehouse = async () => {
        this.props.startLoading();
        let warehouse = await getElements('warehouse', { id: `/api/warehouses/${this.props.match.params.id}` })
        this.setState({
            warehouse: warehouse.data?.warehouse,
            formImage:  {
                changed: false,
                data: warehouse.data?.warehouse.media?.filePath ? "medias/" + warehouse.data?.warehouse.media?.filePath : null,
                file: warehouse.data?.warehouse.media
            },
            warehouseCode: warehouse.data?.warehouse.code,
            warehouseDescription: warehouse?.data.warehouse.description,
            warehouseIsActive: warehouse.data?.warehouse.isActive,
            ready: true
        })
        this.props.stopLoading();
    }

    handleListingCallback = (items, count) => {
        this.setState({
            productsTotalCount: count,
        })
    }

    goTo = (url, state) => {
        this.props.history.push({
            pathname: url,
            state: state,
        })
    }

    toggleForm = () => {
        this.setState({
            openForm: !this.state.openForm
        })
    }

    handleButtonGroupChange = (stateName, value) => {
        this.setState({
            [stateName]: value
        });
    };

    stateCallback = (state, evt) => {
        const value = evt?.target?.value ?? evt;

        this.setState({
            [state]: value
        })
    }

    saveMedia = async () => {

        let resultMedia;
        let formData = new FormData();


        let media = this.state.formImage;
        formData.append('file', media.file);
        formData.append('type', media.file.type);
        formData.append('name', this.state.fileName ? this.state.fileName : media.file.name.replaceAll(/ /g, "_").toLowerCase());
        formData.append('alt', this.state.fileAlt);
        resultMedia = await axios.post(`${process.env.REACT_APP_API}/media-objects`,
            formData,
            {
                headers: {
                    "Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN"),
                }
            },
        );
        return resultMedia.data;

    }

    handlerMutation = async () => {
        this.props.startLoading();
        try {
            const media = await this.saveMedia();
            // const media = await this.saveMedia(this.state?.warehouse?.media?.id ?? this.state.formImage);
            const mediaId = `/api/media-objects/${media.id}`;
            await this.props.client.mutate({
                mutation: UPDATE_WAREHOUSE,
                variables: {
                    id: this.state?.warehouse?.id,
                    code: this.state?.warehouseCode,
                    description: this.state?.warehouseDescription,
                    isActive: this.state?.warehouseIsActive,
                    media: mediaId,
                }
            })
            this.props.snack('success', 'Entrepôt modifié avec succès');
            this.initWarehouse();
            this.toggleForm();
        } catch (error) {
            console.log(error);
            this.props.snack('error', 'Une erreur est survenue');
        }

        this.props.stopLoading();
    }

    toggleDialog = (name) => {
        this.setState({
            [name]: !this.state[name]
        })
    }

    handleDialogPrimaryAction = () => {
        this.props.startLoading();

        Fetch(`${process.env.REACT_APP_API_ROOT}api/warehouses/${this.props.match.params.id}`, 'delete').then(({ data }) => {
            this.props.snack('success', 'Entrepôt supprimé avec succès');
            this.props.stopLoading();
            this.props.history.push('/warehouses');
        }).catch(error => {
            this.props.snack('error', 'Une erreur est survenue');
            this.props.stopLoading();
        })
    }

    render() {
        let { listSettings, listMappers, perPageOptions, searchSettings } = productListingConfig({
            locations: this.state.warehouse?.location
        });

        // TODO: Put the good dates
        // const dropdownOptions = [
        //     { value: 1, label: 'Uno', date: new Date('1995-12-17T03:24:00') },
        //     { value: 2, label: 'Dos', date: new Date() },
        //     { value: 3, label: 'Tres', date: new Date() },
        // ]

        return (
            <PageContainer>
                {this.state.ready ?
                    <ContentContainer
                        headerSettings={{
                            icomoon: "icon-business",
                            title: `Détails de l'entrepôt`,
                            subtitle: 'Visualiser les sites, les produits',
                            windowWidth: this.props.windowWidth,
                            modifyButton: {
                                action: () => this.toggleForm(),
                                text: <div style={{display: 'flex', alignItems: 'center'}}><EditSharpIcon style={{fontSize: 18, marginRight: 5}} /> Modifier</div>,
                                icon: 'icon-edit',
                            },
                            // textDelete: 'Supprimer',
                            // handlerDelete: () => {
                            //     this.setState({
                            //         typeDialog: 'deleteDialog',
                            //     }, () => {
                            //         this.toggleDialog('deleteDialog')
                            //     })
                            // },
                            menu: [
                                {
                                    label: 'Supprimer',
                                    color: colors.red.regular,
                                    icon: <DeleteSharpIcon style={{ fill: colors.red.regular }} />,
                                    action: () => {
                                        this.setState({
                                            typeDialog: 'deleteDialog',
                                        }, () => {
                                            this.toggleDialog('deleteDialog')
                                        })
                                    }
                                }
                            ]
                        }}
                    >
                        <StatsBar
                            data={this.state.stats}
                            windowWidth={this.props.windowWidth}
                            statsIsLoading={this.state.statsIsLoading}
                            customPadding={'0px'}
                            spacing={0}
                        // dropdownOptions={dropdownOptions}
                        // selectedOption={this.state.pickedDropdownOption}
                        // stateCallback={(e) => {
                        //     this.setState({
                        //         pickedDate: dropdownOptions.find(option => option.value === e.target.value).date,
                        //         pickedDropdownOption: e.target.value
                        //     })
                        // }}
                        />

                        <Grid container>
                            <Grid item xs={12}>
                                <div style={{ display: 'grid', gap: 15 }}>
                                    {this.state.warehouse.location ?
                                        <AccordionCustom title={'Information lié'} custompadding={'20px'} >
                                            <Grid container justify='space-between'>
                                                <Grid container xs={12} md={8}>
                                                    <Grid item xs={6}>
                                                        <ContainerImage width={'100%'} backgroundimage={this.state.warehouse?.media?.filePath ? `${process.env.REACT_APP_MEDIAS}/${this.state.warehouse?.media?.filePath}` : ImgNotFound} />
                                                    </Grid>
                                                    <Grid item xs={6} className={'details-warehouse'} style={{
                                                        paddingTop: 20,
                                                        paddingLeft: 40
                                                    }}>
                                                        <Typography variant="h2" style={{ fontSize: 25, marginBottom: 8 }}>Site {this.state.warehouse.code}</Typography>
                                                        <Typography variant="h2" style={{ fontSize: 16, color: colors.grey.regular, fontWeight: 'normal' }}>{this.state.warehouse.description}</Typography>
                                                        {/* <Divider style={{ marginBottom: 20, marginTop: 20 }} /> */}
                                                        
                                                        
                                                        {/* {infoRow('Synchronisation :', <StatusInfo icon={
                                                            <AutorenewSharpIcon style={{ color: colors.green.regular, marginLeft: '-5px', marginRight: '-5px', fontSize: 22 }} />
                                                        } status={'success'} statusText={'Automatique'} />)} */}
                                                        {infoRow('Total de produits :', this.state.productsTotalCount)}
                                                        {infoRow('Statut :', <StatusInfo status={this.state.warehouse.isActive ? 'success' : 'error'} statusText={this.state.warehouse.isActive ? 'Actif' : 'Inactif'} />)}
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={12} md={4} style={{
                                                    paddingLeft: 20,
                                                    paddingTop: 20
                                                }}>
                                                    <Typography variant="h4" style={{
                                                        marginBottom: 10,
                                                        fontSize: 25,
                                                        fontWeight: 'bold',
                                                    }}>
                                                        {`Liste des zones (${this.state.warehouse.location.totalCount})`}
                                                    </Typography>
                                                    <Grid container style={{
                                                        maxHeight: 160,
                                                        overflowY: 'auto',
                                                        overflowX: 'hidden',
                                                    }}>
                                                        {this.state.warehouse.location.edges.map((location, index) => (
                                                            <Grid item xs={12}>
                                                            {/* <Grid item xs={getDeviceZoom() >= 125 || this.props.windowWidth < 1600 ? 12 : 6}> */}
                                                                <CardSite index={index}>
                                                                    <Typography variant="h3" style={{fontSize: 16}}>
                                                                        {location.node.code}
                                                                    </Typography>
                                                                    <Typography variant="body2" style={{
                                                                        textDecoration: 'underline',
                                                                        color: colors.black.regular,
                                                                        cursor: 'pointer',
                                                                        fontSize: 12
                                                                    }} onClick={() => this.goTo(ROUTE_LOCATION_DETAIL.replace(':id', location.node._id))}>
                                                                        Voir la zone
                                                                    </Typography>
                                                                    {/* <Button onClick={() => this.goTo(ROUTE_LOCATION_DETAIL.replace(':id', location.node._id))} style={{
                                                                        background: colors.black.regular,
                                                                    }}>
                                                                        Voir la zone
                                                                    </Button> */}
                                                                </CardSite>
                                                            </Grid>
                                                        ))}
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </AccordionCustom>
                                        : null}

                                    <div style={{ background: colors.grey.lighter.hue980 }}>
                                        <Listing
                                            label='produits'
                                            settings={listSettings}
                                            cardContainerProps={{
                                                style: { height: '100%' },
                                                spacing: 3
                                            }}
                                            perPageOptions={perPageOptions}
                                            mappers={listMappers}
                                            currentLang={this.state.currentLang}
                                            identifier='productLites'
                                            rowAction={() => { }}
                                            searchSettings={searchSettings}
                                            viewsOptions={{
                                                current: 'card',
                                                settings: ['card', 'table'] //Si il n'y a qu'un seul item il n'y aura pas de changement de vues
                                            }}
                                            pagination={true}
                                            queryVariables={{
                                                warehouse: this.state.warehouse.id
                                            }}
                                            tableProps={{
                                                textButton: 'Voir le produit',
                                                action: this.goTo,
                                                url: ROUTE_PRODUCTS_LIST_DETAIL,
                                            }}
                                            cardProps={{
                                                actionData: this.goTo,
                                                paths: {
                                                    id: '_id',
                                                    title: 'productName',
                                                    image: 'imageUrl',
                                                    ref: 'sku',
                                                    status: 'status',
                                                    mainInfo: 'totalStock',
                                                    summary: [
                                                        {
                                                            label: 'Entrepôt :',
                                                            path: 'warehousesCount'
                                                        },
                                                        {
                                                            label: 'Dispo en réel :',
                                                            path: null
                                                        },
                                                        {
                                                            label: 'En stock :',
                                                            path: 'totalStock'
                                                        },
                                                        {
                                                            label: 'Reservé :',
                                                            path: null
                                                        },
                                                    ]
                                                }
                                            }}
                                            listingCallback={this.handleListingCallback}

                                        />
                                    </div>
                                </div>



                            </Grid>


                        </Grid>

                        <LayoutFormBuilder
                            opened={this.state.openForm}
                            icomoon={null}
                            forClose={() => { this.toggleForm() }}
                            dataLayout={editWarehouseConfig(this.state.warehouse)}
                            drawerWidth={this.props.drawerWidth}
                            stateCallback={this.stateCallback}
                            handleButtonGroupChange={this.handleButtonGroupChange}
                            errorCallback={this.handleFormError}
                            validateButton={true}
                            handlerMutation={() => { this.handlerMutation() }}
                            allState={this.state}
                            windowWidth={this.props.windowWidth}
                            isSublayout={false}
                        />

                        <GeneralDialogModal
                            icon={true}
                            type={this.state.typeDialog === 'deleteDialog' ? 'delete' : 'info'}
                            open={this.state[this.state.typeDialog]}
                            title={
                                this.state.typeDialog === 'deleteDialog'
                                    ? 'Êtes-vous sûr de vouloir supprimer cet entrepôt ?'
                                    : 'Information'
                            }
                            primaryAction={() => this.handleDialogPrimaryAction()}
                            secondaryAction={() => { this.toggleDialog(this.state.typeDialog) }}
                            windowWidth={this.props.windowWidth}
                        >
                            {this.state.typeDialog === 'deleteDialog' ? (
                                <Typography variant="body2">Êtes-vous sûr de vouloir supprimer cet entrepôt ? <strong>Cette action est irréversible</strong></Typography>
                            ) : (
                                <Typography variant="body2">Cette action est irréversible</Typography>
                            )}
                        </GeneralDialogModal>
                    </ContentContainer>
                    : null}

            </PageContainer>

        )
    }
}
const mapStateToProps = state => {
    return {
        loading: state.loading,
        locales: state.locales,
        attributes: state.attributes
    };
};

const mapDispatchToProps = dispatch => {
    return {
        startLoading: () => dispatch({ type: 'START_LOADING' }),
        stopLoading: () => dispatch({ type: 'STOP_LOADING' }),
        snack: (type, message) => dispatch({ type: 'SNACK', payload: { type, message } })
    }
};

export default withApollo(withRouter(connect(mapStateToProps, mapDispatchToProps)(WarehouseDetails)));