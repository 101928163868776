import React from 'react';
import {Grid} from '@material-ui/core';
import Typography from '../../../ui/typography/Typography';
import CardCustom from '../../../layouts/Card/CardCustom';
import CardLanguage from '../../../layouts/Card/cardContent/CardLanguage';
import ValidateLanguages from './validate/ValidateLanguages';
import TopPanel from '../../../layouts/TopPanel/TopPanel';
import colors from '../../../../config/theme/colors';
import PageLoader from "../../../ui/loadings/page-loader/PageLoader";

import { withApollo } from 'react-apollo';
import { GET_LOCALES } from '../../../../queries/locales';

import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { hasRights } from '../../../../js/utils/rights';
import { SETTINGS, SETTINGS_LANGUAGES, VIEW, CREATE, UPDATE, DELETE} from '../../../../js/constants/constant-rights';
import { ROUTE_HOME } from '../../../../js/constants/route-names';
import { SNACK } from '../../../../js/constants/action-types';
import { ALERT_ERROR } from '../../../../js/constants/alert-types';
import { connect } from "react-redux";
import { withRouter } from "react-router";

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}


class SettingsLanguages extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            openForm: false,
            NbLang: 0,
            NbLangTotal: 0,
            NbLangInitial: 0,
            changes: [],
            languages: [],
            existentLanguages: [],
            validate: false,
            noLang: true,
            loading: true,
            textSnack: 'La suppression de la langue s\'est déroulée avec succès',
            typeSnack: 'success',
            openSnack: false,
        }
    }

    handleInputChange = (stateName, evt, id) => {
        const value = evt.target.value;
        this.setState({
            [`${stateName}-${id}`]: value
        });
    };

    handleGetLanguages(){
        this.props.client.query({
            query: GET_LOCALES,
            fetchPolicy: 'no-cache'
        }).then(result =>{
            this.setState({
                languages: []
            })
            if(result.data.locales.edges.length > 0){
                this.setState({
                    existentLanguages: result.data.locales.edges,
                    NbLangInitial: result.data.locales.edges.length,
                    NbLangTotal: result.data.locales.edges.length,
                    noLang: false
                }, () => {
                    this.setState({loading: false}, () => {
                        this.forceUpdate();
                    });
                });
            }
            else{
                this.setState({loading: false}, () => {
                    this.forceUpdate();
                });
            }
        });
    }


    handleAddLanguage = () => {
        this.setState({
            NbLangTotal: this.state.NbLangTotal + 1,
        }, () => {
            this.state.languages.push({
                "id": this.state.NbLangTotal,
                "libelle": '',
                "code" : '',
            });
            this.setState({validate: false});
            this.forceUpdate();
        });
    }

    handleRemoveExistentLanguage = () => {
        if(this.state.NbLangInitial === 1){
            this.setState({
                NbLangInitial: 0,
                noLang: true,
            });
        } else {
            this.setState({
                NbLangInitial: this.state.NbLangInitial - 1,
            });
        }
        this.setState({openSnack: true})
        this.handleGetLanguages();
        this.forceUpdate();
    }


    handleRemoveLanguage = (index) => {
        this.setState({
            NbLangTotal: this.state.NbLangTotal - 1,
        });

        let i = 0;
        for (let language of this.state.languages) {
            if(i>index){
                language.id = language.id - 1;
            }
            i++;
        }
        this.state.languages.splice(index,1);
        this.forceUpdate();
    }

    handleResetData = () => {
        this.handleGetLanguages();
        this.setState({changes: []});
    }

    handleCheckValidate = () => {
        let changes = this.state.changes;
        let languages = this.state.languages;
        let success = true;
        for (let item of changes) {
            if (success) {
                if(item.data.libelle === '' || item.data.code === ''){
                    success = false;
                }
            }
            
        }
        for (let language of languages) {
            if (success) {
                if(language.libelle === '' || language.code === ''){
                    success = false;
                }
            }
        }
        if(success){
            this.setState({validate: true});
        }
        else{
            this.setState({validate: false});
        }
    }

    componentDidMount = () => {
        const getRights = hasRights(SETTINGS, SETTINGS_LANGUAGES, VIEW)
        if (!getRights){
            this.props.snack(ALERT_ERROR, `Vous n'avez pas les droits suffisants pour accéder à cette page`);
            this.goTo(ROUTE_HOME);
        }
        this.handleGetLanguages();
    }

    handleCloseSnack = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        this.setState({openSnack: false});
    };

    render() {
        return (
            <div>
                <TopPanel 
                    icomoon="picto-parametre" 
                    colorIcomoon={colors.blue.lighter.hue300} 
                    title="Gérer les langues" 
                    subtitle="Gestion de vos langues (création / modification / suppression)"  
                    handlerAdd={!this.state.loading ? this.handleAddLanguage : null} 
                    textAdd={hasRights(SETTINGS, SETTINGS_LANGUAGES, CREATE) ? "Ajouter une langue" : null}
                    gradientColor1={colors.menu.regular} 
                    gradientColor2={colors.menu.darker} 
                    buttonAvailable={true}
                    hasBorder={true}
                    windowWidth={this.props.windowWidth}
                />
                <Grid container direction="column" justify="center" spacing={0} style={{marginTop: 16}}>
                    <Grid container direction="row" spacing={4}>
                        {
                            !this.state.loading ?
                                (
                                    this.state.noLang ? 
                                        this.state.languages.length === 0 ? 
                                            (<p style={{marginLeft: 15}}>Aucune langue existante pour l'instant, vous pouvez en créer une dès maintenant.</p>) 
                                        :
                                            null
                                    : 
                                        (
                                            this.state.existentLanguages.map((language, i) => {
                                                return(
                                                    <React.Fragment key={`ContainerLangueQuery${i}`}>
                                                        <Grid container style={{paddingLeft: 16, paddingTop: 16}}>
                                                            <Typography>Langue n°{i+1}</Typography>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <CardCustom paddingbottom="8px" paddingtop="32px">
                                                                <CardLanguage
                                                                    windowWidth={this.props.windowWidth}
                                                                    type="existing"
                                                                    handleInputChange = {
                                                                        (data) => {
                                                                        let changes = this.state.changes;
                                                                        let found = false;
                                                                        for (let item of changes) {
                                                                            if (item.id === data.id) {
                                                                                item.data = data.data;
                                                                                this.handleCheckValidate();
                                                                                found = true;
                                                                            }
                                                                        }
                                                                        
                                                                        if (!found)
                                                                            changes.push(data);
                                                                        
                                                                        this.setState({ changes: [].concat(changes) });
                                                                    }} 
                                                                    handleRemove={hasRights(SETTINGS, SETTINGS_LANGUAGES, DELETE) ? this.handleRemoveExistentLanguage : null} 
                                                                    language={language.node} 
                                                                    disabled={hasRights(SETTINGS, SETTINGS_LANGUAGES, UPDATE) ? null : 'disabled'}
                                                                />
                                                            </CardCustom>
                                                        </Grid>
                                                    </React.Fragment>
                                                )
                                            })
                                        )
                                ) 
                                : 
                                (<PageLoader />)
                        }


                        {this.state.languages.map((language, index) =>
                            <React.Fragment key={`ContainerLangue${index}`}>
                                <Grid container style={{paddingLeft: 16, paddingTop: 16}}>
                                    <Typography>Langue n°{language.id}</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <CardCustom paddingbottom="8px" paddingtop="32px">
                                        <CardLanguage
                                            windowWidth={this.props.windowWidth}
                                            type="new"
                                            handleInputChange={(data) => {
                                                for (let language of this.state.languages) {
                                                    if (language.id === data.id) {
                                                        language.libelle = data.data.libelle;
                                                        language.code = data.data.code;
                                                        this.handleCheckValidate();
                                                    }
                                                }
                                            }} 
                                            handleRemove={() => this.handleRemoveLanguage(index)}
                                            language={language} 
                                        />
                                    </CardCustom>
                                </Grid>
                            </React.Fragment>
                        )}
                    </Grid>
                </Grid>

                {
                    this.state.existentLanguages.length > 0 || this.state.languages.length > 0 ?
                    (
                        <ValidateLanguages newLanguages={this.state.languages} changesLanguages={this.state.changes} validate={this.state.validate} handleResetData={this.handleResetData} />
                    ) : null
                }

                <Snackbar open={this.state.openSnack} autoHideDuration={10000} onClose={this.handleCloseSnack}>
                    <Alert onClose={this.handleCloseSnack} severity={this.state.typeSnack}>
                        {this.state.textSnack}
                    </Alert>
                </Snackbar>
            </div>
        );
    }
    goTo = route => this.props.history.push(route);
}
const mapDispatchToProps = dispatch => {
    return {
        snack: (type, message) => dispatch({ type: SNACK, payload: { type, message }})
    }
}

export default withRouter(withApollo(connect(null, mapDispatchToProps)(SettingsLanguages)));
