const colors = ({
    blue:{
        regular : "#000000",
        regularv2 : "#78CBEE",
        darker : {
            hue300 : "#0273A5",
        },        
        lighter:{
            hue150: "#53839D",
            hue200: "#5BC0EB",
            hue300 : "#6EAED1",
            hue600 : "#D2EDF9",
            hue900 : "#F0F9FD",
        },
        turquoise : '#5E829A'
    },
    grey:{
        regular: "#808F98",
        darker: "#727779",
        lighter:{
            hue600 : "#AAB4BA",
            hue700 : "rgba(201,203,204,0.5)",
            hue800 : "#D4D9DC",
            hue900 : "#F5F5F5",
            hue980 : "#FAFBFB",
        },
        border: "#C9CBCC",
        disabled: '#ababab',
    },
    green:{
        lighterv2 : '#82D3931A',
        lighterv2Hover : '#82D3934D',
        regular: "#4CAA5F",
        lighter: "#ebffef",
        darker: "#388648",
        regularv2 : '#79C579'
    },
    red:{
        regular: "#E01B22",
        lighter: "#F7777B",
        lighterv2: "#FFEBEC",
        lighterv2Hover : '#ffd6d8',
        darker: "#BB161B"
    },
    orange:{
        regular: "#F67817",
        lighter: "#fff8eb",
        darker: "#D16614"
    },
    yellow:{
        regular: "#FFE53B",
    },
    purple:{
        regular: "#BC8DF3",
    },
    pink:{
        regular: "#E21C55",
        lighter: "#CD2683",
    },
    cyan:{
        regular: "#58AFFD",
        lighter: "#37DBE0",
    },
    sinfinNewMenu:{
        normalAccordion: "#98999A",
        normal : "#4D4E50",
        active: "#36738D",
        backgroundHover: "#F0F9FD",
        backgroundSubHover: "#D2EDF9",
        selected : "#78CBEE",
    },
    menu:{
        regular: "#000000",
        darker: "#000000"
    },
    white: "#FFFFFF",
    black: {
        regular: "#000000",
        lighter: "#000000",
    },
    crmNavBackground : '#000000'
})

export default colors;