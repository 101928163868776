import React from 'react';
import {Grid} from '@material-ui/core';
import Typography from '../../../ui/typography/Typography';
import CardCustom from '../../../layouts/Card/CardCustom';
import CardCurrencies from '../../../layouts/Card/cardContent/CardCurrencies';
import ValidateTaxes from './validate/ValidateTaxes';
import TopPanel from '../../../layouts/TopPanel/TopPanel';
import colors from '../../../../config/theme/colors';
import PageLoader from "../../../ui/loadings/page-loader/PageLoader";
import { withApollo } from 'react-apollo';

import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

import { hasRights } from '../../../../js/utils/rights';
import { SETTINGS, SETTINGS_CURRENCIES, VIEW, CREATE, UPDATE, DELETE} from '../../../../js/constants/constant-rights';
import { ROUTE_HOME } from '../../../../js/constants/route-names';
import { SNACK } from '../../../../js/constants/action-types';
import { ALERT_ERROR } from '../../../../js/constants/alert-types';
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { GET_TAXRATES } from '../../../../queries/tax_rates';
import { GET_CURRENCIES } from '../../../../queries/currencies';
import { GET_RATE_GROUPS_PAGINATION } from '../../../../queries/rate_group';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}


class SettingsTaxRates extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            NbTaxes: 0,
            NbTaxesTotal: 0,
            NbTaxesInitial: 0,
            changes: [],
            taxes: [],
            existentTaxes: [],
            validate: false,
            noTax: true,
            loading: true,
            textSnack: "La suppression de la taxe s'est déroulée avec succès",
            typeSnack: 'success',
            openSnack: false,
        }
    }
    // handleInputChange = (stateName, evt, id) => {
    //     const value = evt.target.value;
    //     this.setState({
    //         [`${stateName}-${id}`]: value
    //     });
    // };

    handleGetTaxes(){
        this.props.client.query({
            query: GET_TAXRATES,
            fetchPolicy: 'no-cache'
        }).then(result =>{
            this.setState({
                taxes: []
            })
            this.props.client.query({
                query: GET_RATE_GROUPS_PAGINATION,
                fetchPolicy: 'no-cache'
            }).then(resultGroupRates => {
                this.setState({
                    allGroupRates: resultGroupRates.data.rateGroups.edges
                })
                if(result.data.taxRates.edges.length > 0){
                    this.setState({
                        existentTaxes: result.data.taxRates.edges,
                        NbTaxesInitial: result.data.taxRates.edges.length,
                        NbTaxesTotal: result.data.taxRates.edges.length,
                        noTax: false
                    }, () => {
                        this.setState({loading: false}, () => {
                            this.forceUpdate();
                        });
                    });
                }
                else{
                    this.setState({loading: false}, () => {
                        this.forceUpdate();
                    });
                }
            })
        });
    }

    handleAddTax = () => {
        this.setState({
            NbTaxesTotal: this.state.NbTaxesTotal + 1,
        }, () => {
            this.state.taxes.push({
                "code": '',
                "rate" : '',
                "countryCode" : '',
                "rateGroup" : {id: ''},
                "id": this.state.NbTaxesTotal,
            });
    
            this.setState({validate: false});
            this.forceUpdate();
        });
    }

    handleRemoveExistentTax = () => {
        if(this.state.NbTaxesInitial === 1){
            this.setState({
                NbTaxesInitial: 0,
                noTax: true,
            });
        } else {
            this.setState({
                NbTaxesInitial: this.state.NbTaxesInitial - 1,
            });
        }
        this.setState({openSnack: true})
        this.handleGetTaxes();
        this.forceUpdate();
    }

    handleRemoveTax = (index) => {
        this.setState({
            NbTaxesTotal: this.state.NbTaxesTotal - 1,
        });

        let i = 0;
        for (let currency of this.state.taxes) {
            if(i>index){
                currency.id = currency.id - 1;
            }
            i++;
        }
        this.state.taxes.splice(index,1);
        this.forceUpdate();
    }

    handleResetData = () => {
        this.handleGetTaxes();
        this.setState({changes: []})
    }

    handleCheckValidate = () => {
        let changes = this.state.changes;
        let taxes = this.state.taxes;
        let success = true;
        for (let item of changes) {
            if (success) {
                if(item.data.code === '' || item.data.rate === '' || item.data.countryCode === ''){
                    success = false;
                }
            }
            
        }
        for (let tax of taxes) {
            if (success) {
                if(tax.code === '' || tax.rate === '' || !tax.countryCode === ''){
                    success = false;
                }
            }
        }
        if(success){
            this.setState({validate: true});
        }
        else{
            this.setState({validate: false});
        }
    }

    componentDidMount = () => {
        const getRights = hasRights(SETTINGS, SETTINGS_CURRENCIES, VIEW)
        if (!getRights){
            this.props.snack(ALERT_ERROR, `Vous n'avez pas les droits suffisants pour accéder à cette page`);
            this.goTo(ROUTE_HOME);
        }
        this.handleGetTaxes();
    }

    handleCloseSnack = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        this.setState({openSnack: false});
    };


    render() {
        return (
            <div>
                <TopPanel 
                icomoon="picto-prix" 
                colorIcomoon={colors.blue.lighter.hue300} 
                title="Gérer les taxes" 
                subtitle="Gestion de vos taxes (création / modification / suppression)"  
                handlerAdd={!this.state.loading ? this.handleAddTax : null} 
                textAdd={hasRights(SETTINGS, SETTINGS_CURRENCIES, CREATE) ? "Ajouter une taxe" : null}
                gradientColor1={colors.menu.regular} 
                gradientColor2={colors.menu.darker} 
                buttonAvailable={true}
                hasBorder={true}
                windowWidth={this.props.windowWidth}
                />
                <Grid container direction="column" justify="center" spacing={0} style={{marginTop: 16}}>
                    <Grid container direction="row" spacing={4}>
                        {
                            !this.state.loading ?
                                (
                                    this.state.noTax ? 
                                        this.state.taxes.length === 0 ? 
                                            (<p style={{marginLeft: 15}}>Aucune taxe existante pour l'instant, vous pouvez en créer une dès maintenant.</p>) 
                                        :
                                            null
                                    : 
                                        (
                                            this.state.existentTaxes.map((tax, i) => {
                                                return(
                                                    <React.Fragment key={`ContainerTax${i}`}>
                                                        <Grid container style={{paddingLeft: 16, paddingTop: 16}}>
                                                            <Typography>Taxe n°{i+1}</Typography>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <CardCustom paddingbottom="8px" paddingtop="32px">
                                                                <CardCurrencies
                                                                    windowWidth={this.props.windowWidth}
                                                                    type="existing"
                                                                    handleInputChange = {(data) => {
                                                                        let changes = this.state.changes;
                                                                        let found = false;
                                                                        for (let item of changes) {
                                                                            if (item.id === data.id) {
                                                                                item.data = data.data;
                                                                                found = true;
                                                                            }
                                                                        }
                                                                        this.handleCheckValidate();
                                                                        if (!found)
                                                                            changes.push(data);
                                                                        
                                                                        this.setState({ changes: [].concat(changes) });
                                                                    }} 
                                                                    handleRemove={hasRights(SETTINGS, SETTINGS_CURRENCIES, DELETE) ? this.handleRemoveExistentTax : null} 
                                                                    currency={tax.node}
                                                                    disabled={hasRights(SETTINGS, SETTINGS_CURRENCIES, UPDATE) ? null : 'disabled'}
                                                                    typeTax={true}
                                                                    allGroupRates={this.state.allGroupRates}
                                                                />
                                                            </CardCustom>
                                                        </Grid>
                                                    </React.Fragment>
                                                )
                                            })
                                        )
                                ) 
                                : 
                                (<PageLoader />)
                        }

                        {
                            this.state.taxes.map((tax, index) =>
                                <React.Fragment key={`ContainerTax${index}`}>
                                    <Grid container style={{paddingLeft: 16, paddingTop: 16}}>
                                        <Typography>Taxe n°{tax.id}</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <CardCustom paddingbottom="8px" paddingtop="32px">
                                            <CardCurrencies
                                                windowWidth={this.props.windowWidth}
                                                type="new"
                                                handleInputChange={(data) => {
                                                    for (let tax of this.state.taxes) {
                                                        if (tax.id === data.id) {
                                                            tax.code = data.data.code;
                                                            tax.rate = data.data.rate;
                                                            tax.countryCode = data.data.countryCode;
                                                            tax.rateGroup = data.data.rateGroup;
                                                            this.handleCheckValidate();
                                                        }
                                                    }
                                                }} 
                                                handleRemove={() => this.handleRemoveTax(index)} 
                                                currency={tax}
                                                typeTax={true}
                                                allGroupRates={this.state.allGroupRates}
                                            />
                                        </CardCustom>
                                    </Grid>
                                </React.Fragment>
                            )
                        }
                    </Grid>
                </Grid>
                {
                    this.state.existentTaxes.length > 0 || this.state.taxes.length > 0 ? (
                        <ValidateTaxes newTaxes={this.state.taxes} changesTaxes={this.state.changes} validate={this.state.validate} handleResetData={this.handleResetData} />
                    ) : null
                }

                
                <Snackbar open={this.state.openSnack} autoHideDuration={10000} onClose={this.handleCloseSnack}>
                    <Alert onClose={this.handleCloseSnack} severity={this.state.typeSnack}>
                        {this.state.textSnack}
                    </Alert>
                </Snackbar>
            </div>
        );
    }
    goTo = route => this.props.history.push(route);
}
const mapDispatchToProps = dispatch => {
    return {
        snack: (type, message) => dispatch({ type: SNACK, payload: { type, message }})
    }
}

export default withRouter(withApollo(connect(null, mapDispatchToProps)(SettingsTaxRates)));