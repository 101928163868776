import React, { Component } from 'react';
import './App.css';
import { withRouter, Redirect } from "react-router";
import {
  Switch,
  Route
} from "react-router-dom";
import { connect } from "react-redux";
import * as moment from "moment";
import 'moment/locale/fr';
import { withApollo } from 'react-apollo';
import { START_LOADING, STOP_LOADING, STOP_SNACK, SET_USER } from './js/constants/action-types';
import { GET_USER_BY_ID } from './queries/users';
import LoadingOverlay from './components/ui/loading-overlay/LoadingOverlay';
import Snack from './components/ui/snack/Snack';
import Dashboard from './components/screens/dashboard/Dashboard';
import Login from './components/screens/login/Login';
import ForgetPassword from './components/screens/login/ForgetPassword';
import ResetPassword from './components/screens/login/ResetPassword';
import Demo from './components/screens/demo/Demo';
import { MuiThemeProvider} from '@material-ui/core/styles';
import THEME_SPREAD from '../src/config/theme/theming';
import 'react-sortable-tree/style.css'; // This only needs to be imported once in your app
import './scss/main.scss'; // This only needs to be imported once in your app

import request from './js/utils/fetch';
import { default as ForgotPasswordTemplate } from './email/forgetPassword';


class App extends Component {
  
  constructor(props) {
    super(props);
    this.state = { width: 0, height: 0 };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    moment.locale('fr');
  }

  componentDidMount() {
    // This allows us to check the token and auto reload to login if needed
    if (this.props.user) {
      this.props.client.query({
        query: GET_USER_BY_ID,
        variables: { id: this.props.user.id }
      }).then(result =>{
          let getUser = result.data.user
          let idUserRole = result.data.user.userRole.id.replace('/api/user-roles/', '')

          request(`${process.env.REACT_APP_API}/users/acl/${idUserRole}`, 'get', false).then(
            (data) => {
              if(data.success){
                getUser.rights = data.acl
              }
              localStorage.setItem('AUTH_USER', JSON.stringify(getUser));
              this.props.setUser(getUser);
            }
          )
      });
    }

    //Get window width
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  render() {
    const { snackDuration, snackMsg, snackType, snacking, loading, user } = this.props;
    const { width, height } = this.state;
    
    return (
      <MuiThemeProvider theme={THEME_SPREAD}>
        <div className="App">
          <Switch>
            
            <Route path="/login">
              { user ? <Redirect to="/" /> : <Login /> }
            </Route>
            <Route path="/email">
              <h1 style={{ textDecoration: 'underline', margin: 10 }}>Oubli de mot de passe : </h1>
              <div dangerouslySetInnerHTML={{ __html: ForgotPasswordTemplate('https://google.com', `${process.env.REACT_APP_PUBLIC}/img/logo.png`) }}></div>
            </Route>
            <Route path="/password/forget">
              { user ? <Redirect to="/" /> : <ForgetPassword /> }
            </Route>
            <Route path="/password/reset/:token">
              { user ? <Redirect to="/" /> : <ResetPassword /> }
            </Route>
            <Route path="/demo">
              { process.env.REACT_APP_ENV === 'dev' ? <Demo /> : <Redirect to="/" /> }
            </Route>
    
            <Route path="/">
              { 
                user ? 
                  <Dashboard windowWidth={width} /> 
                : <Redirect to="/login" /> 
              }
            </Route>
          </Switch>

          {
            loading 
              ? <LoadingOverlay /> 
              : null 
          }

          <Snack
            message={snackMsg} 
            type={snackType} 
            duration={snackDuration}
            open={snacking} 
            onClose={() => { this.props.stopSnack() }} 
          /> 
        </div>
      </MuiThemeProvider>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    startLoading: () => dispatch({ type: START_LOADING }),
    stopLoading: () => dispatch({ type: STOP_LOADING }),
    stopSnack: () => dispatch({ type: STOP_SNACK }),
    setUser: (user) => dispatch({ type: SET_USER, payload: { user }})
  }
}

const mapStateToProps = state => {
  return {
    loading: state.loading,
    snacking: state.snacking,
    snackMsg: state.snackMsg,
    snackType: state.snackType,
    snackDuration: state.snackDuration,
    user: state.user
  };
};

export default withRouter(withApollo(connect(mapStateToProps, mapDispatchToProps)(App)));
