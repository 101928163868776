import { Box, CircularProgress, Grid } from "@material-ui/core"
import { useEffect } from "react";
import { getRealTime } from "../../../js/utils/stats";
import InputBuilder from "../../ui/form/InputBuilder";
import OurTypography from "../../ui/typography/Typography";
import AccordionCustom from "../Accordion/AccordionCustom"
import CardOrdersHub from "../Card/cardContent/CardOrdersHub"
import PageLoader from "../../ui/loadings/page-loader/PageLoader";
import colors from "../../../config/theme/colors";
import GetAppSharpIcon from '@material-ui/icons/GetAppSharp';

const StatsBar = (props) => {

    // const selectLang = {
    //     type: 'dropdown',
    //     label: 'Langue',
    //     helper: 'Langue',
    //     required: false,
    //     stateName: 'stats',
    //     value: props.dropdownOptions
    // };

    return (
        <AccordionCustom style={{
            marginBottom: 20,
        }} custompadding={props.customPadding ?? '10px'} forcedExpanded={true} title={
            <Grid container alignItems="center" justify="space-between">
                <div>
                    <span style={{marginRight: '5px'}}>Dashboard: </span>
                    {/* <InputBuilder value={props.selectedOption} input={selectLang} stateCallback={props.stateCallback} customStyle={{
                        flexBasis: 'unset',
                        marginBottom: 0,
                        maxHeight: 40,
                        fontWeight: 700,
                    }} /> */}
                    <strong>{getRealTime()}</strong>
                </div>
                <div style={{display:'flex', alignItems:'center',gap:20}}>
                    {props.callbackAction && (
                        <OurTypography onClick={props.callbackAction} style={{
                            cursor: 'pointer',
                            color: colors.green.regular,
                            fontWeight: 700,
                            fontSize: 14,
                            display: 'flex',
                            alignItems: 'center',
                            // textDecoration: 'underline',
                        }}>
                            <GetAppSharpIcon style={{marginRight: 5, fontSize: 18}} />
                            Export
                        </OurTypography>
                    )}
                    {props.callbackActionDetails && (
                        <OurTypography onClick={props.callbackActionDetails} style={{
                            cursor: 'pointer',
                            color: colors.green.regular,
                            fontWeight: 700,
                            fontSize: 14,
                            display: 'flex',
                            alignItems: 'center',
                            // textDecoration: 'underline',
                        }}>
                            <GetAppSharpIcon style={{marginRight: 5, fontSize: 18}} />
                            Exports commandes
                        </OurTypography>
                    )}
                    {props.callbackActionImport && (
                        <OurTypography onClick={props.callbackActionImport} style={{
                            cursor: 'pointer',
                            color: colors.green.regular,
                            fontWeight: 700,
                            fontSize: 14,
                            display: 'flex',
                            alignItems: 'center',
                            // textDecoration: 'underline',
                        }}>
                            <GetAppSharpIcon style={{marginRight: 5, fontSize: 18}} />
                            {props.callbackActionImportLabel ? props.callbackActionImportLabel : 'Import'}
                        </OurTypography>
                    )}

                    {props.callbackActionFacture && (
                        <OurTypography onClick={props.callbackActionFacture} style={{
                            cursor: 'pointer',
                            color: colors.green.regular,
                            fontWeight: 700,
                            fontSize: 14,
                            display: 'flex',
                            alignItems: 'center',
                            // textDecoration: 'underline',
                        }}>
                            <GetAppSharpIcon style={{marginRight: 5, fontSize: 18}} />
                            {props.callbackActionFactureLabel ? props.callbackActionFactureLabel : 'Export facture'}
                        </OurTypography>
                    )}

                    {props.callbackActionCompta && (
                        <OurTypography onClick={props.callbackActionCompta} style={{
                            cursor: 'pointer',
                            color: colors.green.regular,
                            fontWeight: 700,
                            fontSize: 14,
                            display: 'flex',
                            alignItems: 'center',
                            // textDecoration: 'underline',
                        }}>
                            <GetAppSharpIcon style={{marginRight: 5, fontSize: 18}} />
                            {props.callbackActionComptaLabel ? props.callbackActionComptaLabel : 'Export Comptable'}
                        </OurTypography>
                    )}

                    {props.callbackActionCador && process.env.REACT_APP_EXPORT_COMPTA_GD == 'true' && (
                        <OurTypography onClick={props.callbackActionCador} style={{
                            cursor: 'pointer',
                            color: colors.green.regular,
                            fontWeight: 700,
                            fontSize: 14,
                            display: 'flex',
                            alignItems: 'center',
                            // textDecoration: 'underline',
                        }}>
                            <GetAppSharpIcon style={{marginRight: 5, fontSize: 18}} />
                            CADOR
                        </OurTypography>
                    )}



                </div>
                
            </Grid>
        } defaultExpanded={true} >
            {props.statsIsLoading ? <div style={{height: 100, width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}><CircularProgress/></div> : 
            <Grid container>
                <div style={{flexGrow: 1}}>
                    <Grid container spacing={props?.spacing ?? 2}>
                        {props.data?.map((item, index) => (
                            <Grid item xs={props.xs|| 2}>
                                <CardOrdersHub windowWidth={props.windowWidth} data={item} key={index} index={index} isLast={props.data?.length === index + 1} spacing={props.spacing} />
                            </Grid>
                        ))}
                    </Grid>
                </div>
            </Grid>
            }
        </AccordionCustom>
    )
}

export default StatsBar