import React from 'react';
import {Box} from '@material-ui/core';
import styled from 'styled-components';

const Container = styled(Box)`
    background:white;
    box-shadow: ${props => props.collapse ? 'none' : '0px 0px 5px #1622421A'};
    border-radius: 5px;
    width:100%;
    padding:16px;
`

export default function ItemContainer (props){
    return(
        <Container {...props}>
            {props.children}
        </Container>
    )
}