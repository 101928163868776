import React from 'react';
import { withRouter } from 'react-router';
import { connect } from "react-redux";
import { withApollo } from 'react-apollo';

import TopPanel from '../../../layouts/TopPanel/TopPanel';

import LayoutBuilder from '../../../ui/form/LayoutFormBuilder';
import groupRateEdit from './config/groupRateEdit.config';
import { START_LOADING, STOP_LOADING, SNACK } from '../../../../js/constants/action-types';
import { ALERT_ERROR, ALERT_SUCCESS } from '../../../../js/constants/alert-types';
import colors from '../../../../config/theme/colors';
import { eventService } from '../../../../js/services/event.service';
import request from '../../../../js/utils/fetch';

import { ROUTE_HOME } from '../../../../js/constants/route-names';
import { hasRights } from '../../../../js/utils/rights';
import { SETTINGS, SETTINGS_CURRENCIES, VIEW, CREATE, IMPORT} from '../../../../js/constants/constant-rights';

import {prepareAttributeValues,saveElement,updateElement,getElements} from '../../../../js/utils/functions';
import Listing from '../../../layouts/Listing/Listing';
import {listSettings,listMappers,perPageOptions} from './config/listGroupRates.config';
import * as formHelper from '../../../../js/helpers/form'
import {makeUnique,searchItem} from '../../../../js/helpers/search'
import {Grid} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '../../../ui/button/Button';
import { DELETE_RATE_GROUP } from '../../../../queries/rate_group';
import { GET_CURRENCIES } from '../../../../queries/currencies';


class SettingsGroupRates extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            openForm:false,
            editForm: 'add',
            currentLang: props.locales[0].node.code,
            errors: {},
            seeErrors: false,
            ready: false,
            groupRateIdentifier: null,
            groupRateCurrency: null,
        };
    }

    handleLang = (event) => {
        this.setState({ 
            currentLang: event.target.value,
        }, () => {
            eventService.fire();
        });
    };

    handleToggleDrawer = (stateDrawer,reset=false) => {
        this.setState({ 
            [stateDrawer]: !this.state[stateDrawer]
        });
        if (reset){
            this.resetState()
        }
    };
    

    handleFormError = (stateName, error) => {
        let errors = this.state.errors;
        errors[stateName] = error;
        this.setState({ errors });
    };

    handleNextStep = () =>{
        let result = formHelper.handleStep('next',this.state.errors);
        this.setState({
            seeErrors : !result
        })
        return result;
    }

    handleBackStep = () => {
        this.setState({ errors: {} });
    }


    handleMediaPicker=(selected,stateName,translated)=>{
        this.handleInput(stateName,selected,null,translated);  
    }

    handleInput = (stateName,evt,custom,translated) =>{
        let value = formHelper.setValue(this.state,stateName, evt, custom, translated);
        this.setState({...value})
    }

    resetState = () =>{
        this.setState({
            groupRateIdentifier: null,
            groupRateCurrency: null,
        })
    }

    handlerGroupRateMutation= async()=>{
        this.props.startLoading();
        let variables = null;
        switch(this.state.editForm){
            case 'add':
                
                variables={
                    code : this.state.groupRateIdentifier,
                    currency : this.state.groupRateCurrency,
                }
                await saveElement('rateGroup',variables,{enableLoad:false})
                this.props.snack(ALERT_SUCCESS, 'Groupe de taxes ajoutée avec succès');
                this.handleToggleDrawer('openForm', true);
                this.reloadGroupRates();
                this.props.stopLoading();
                break;

            case 'edit':
                
                variables={
                    id : this.state.currentGroupRateId,
                    code : this.state.groupRateIdentifier,
                    currency : this.state.groupRateCurrency,
                }
                let getResult = await updateElement(this.state,'rateGroup',variables,null,{enableLoad:false})
                if (getResult){
                    this.props.snack(ALERT_SUCCESS, 'Groupe de taxes modifiée avec succès');
                    this.handleToggleDrawer('openForm', true);
                    this.props.stopLoading();
                    this.reloadGroupRates();
                }
               
                break;
            default:
                return null;
        }
    }

    handleToggleDialog = () => {
        this.setState({ 
            openDialog: !this.state.openDialog
        });
    };

    deleteMutation = () => {
        this.props.startLoading();
        this.props.client.mutate({
            mutation: DELETE_RATE_GROUP,
            variables: { id: this.state.currentGroupRateId }
        }).then(result => {
            this.props.stopLoading();
            this.props.snack(ALERT_SUCCESS, 'Groupe de taxes supprimé avec succès');
            this.reloadGroupRates();
            this.handleToggleDialog();
            this.handleToggleDrawer('openForm', true);
        }).catch(error => {
            this.props.stopLoading();
            this.props.snack(ALERT_ERROR, `Impossible de supprimer le groupe de taxe`);
            this.reloadGroupRates();
            this.handleToggleDialog();
            this.handleToggleDrawer('openForm', true);
        });
    };

    stateCallback = (stateName, value, custom, translated, callback) => {
        this.setState({
            [stateName]: value?.target?.value ?? value
        }, callback);
    };

    initForm=()=>{
        this.setState({
            editForm : 'add'
        })
        this.handleToggleDrawer('openForm');
    }

    editHandler=(groupRate)=>{
        this.setState({
            editForm : 'edit',
            currentGroupRateId: groupRate.id,
            groupRateIdentifier: groupRate.code,
            groupRateCurrency: groupRate.currency.id,
        })
        this.handleToggleDrawer('openForm');
    }

    getGroupRatesCallback = () =>{
        this.setState({
            reloadGroupRates:false
        })
    }
    reloadGroupRates = () =>{
        this.setState({ reloadGroupRates : true})
    }

    handleGetCurrencies = () => {
        this.props.client.query({
            query: GET_CURRENCIES,
            fetchPolicy: 'no-cache'
        }).then(result => {
            this.setState({
                listCurrencies: result.data.currencies.edges,
            })
        });
    }

    componentDidMount() {
        const getRights = hasRights(SETTINGS, SETTINGS_CURRENCIES, VIEW);
        if (!getRights){
            this.props.snack(ALERT_ERROR, `Vous n'avez pas les droits suffisants pour accéder à cette page`);
            this.goTo(ROUTE_HOME);
        }else{
            
            this.handleGetCurrencies();
            this.setState({
                isReady : true,
            });
            if(this.props.location.state?.hasMessage){
                this.props.snack(ALERT_SUCCESS,this.props.location.state.message)
            }
        }
    };

    render() {
        return (
            <div style={{width: "100%", transition: 'all 250ms cubic-bezier(0, 0, 0.2, 1) 0ms'}}>
                <TopPanel 
                    icomoon="picto-prix" 
                    colorIcomoon={colors.blue.lighter.hue300}
                    title="Groupes de taxes" 
                    subtitle="Gestion de vos groupes de taxes (création / modification / suppression)" 
                    handlerAdd={()=>this.initForm()} 
                    textAdd={hasRights(SETTINGS, SETTINGS_CURRENCIES, CREATE) ? "Ajouter un groupe" : null}
                    gradientColor1={colors.menu.regular} 
                    gradientColor2={colors.menu.darker} 
                    openForm={this.state.openForm}
                    buttonAvailable={!this.state.openForm}
                    hasBorder={true}
                />
                <Grid container style={{marginTop: 16}}>
                    <Listing
                        label = 'groupes'
                        settings = {listSettings}
                        cardProps = {{
                            openForm : this.state.openForm,
                            currentLang : this.state.currentLang,
                            textButton:'Modifier',
                            handlerButton: this.editHandler,
                            windowWidth : this.props.windowWidth,
                        }}
                        perPageOptions = {perPageOptions}
                        mappers = {listMappers}
                        currentLang = {this.state.currentLang}
                        identifier = 'rateGroups'
                        viewsOptions = {{
                            current : 'card',
                            settings : ['card'] //Si il n'y a qu'un seul item il n'y aura pas de changement de vues
                        }}
                        reload={this.state.reloadGroupRates}
                        listingCallback = {this.getGroupRatesCallback}
                    />
                </Grid>
            
                {this.state.isReady ? (
                    <div>
                            <LayoutBuilder
                                icomoon="picto-prix"
                                opened={this.state.openForm} 
                                forClose={()=>this.handleToggleDrawer('openForm', true)} 
                                handlerSetup={()=>{}} 
                                dataLayout={groupRateEdit(this.state.editForm, this.state.listCurrencies)} 
                                drawerWidth={this.props.drawerWidth}     
                                allState={this.state}    
                                stateCallback={this.handleInput} 
                                errorCallback={this.handleFormError}    
                                stepperButtonAction={[
                                    this.handleNextStep,
                                    this.handleNextStep,
                                    this.handleNextStep,
                                ]}
                                validateButton={true}
                                handlerMutation={this.handlerGroupRateMutation}
                                currentLang={this.state.currentLang}
                                handleLang={this.handleLang}
                                deleteMutation={this.state.editForm === "edit" ? () => {this.handleToggleDialog()} :  null}
                                deleteText={'Supprimer le groupe de taxes'}
                            />
                        </div>
                    )
                :null}
                <Dialog
                    open={this.state.openDialog}
                    onClose={this.handleToggleDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        Êtes-vous sûr de vouloir supprimer ce groupe de taxes ?
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Si vous supprimez ce groupe, celui-ci ne sera plus accessible. Si vous ne souhaitez pas le supprimer, annulez la suppression en cliquant sur annuler.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleToggleDialog} color={colors.grey.regular} bgcolor={colors.white} bgcolorhover={colors.grey.lighter.hue900} border={`1px solid ${colors.grey.regular}`}>
                            Annuler
                        </Button>
                        <Button onClick={this.deleteMutation} bgcolor={colors.red.regular} bgcolorhover={colors.red.darker} autoFocus>
                            Supprimer
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }

    // be careful, only breaks references at objects level
    copyArrayOfObjects = array => array.map(a => a.node ? ({...a, node: {...a.node}}) : ({...a})); 

    goTo = route => {
        this.props.history.push(route);
    };
}

const mapStateToProps = state => {
    return {
        loading: state.loading,
        products: state.products,
        attributes: state.attributes, 
        locales: state.locales
    };
};

const mapDispatchToProps = dispatch => {
    return {
        startLoading: () => dispatch({ type: START_LOADING }),
        stopLoading: () => dispatch({ type: STOP_LOADING }),
        snack: (type, message) => dispatch({ type: SNACK, payload: { type, message }})
    };
};

export default withApollo(withRouter((connect(mapStateToProps, mapDispatchToProps)(SettingsGroupRates))));