export const handleSort = (sortBy,newSort) =>{
     return new Promise((resolve,reject)=>{

        let newSortBy = sortBy.map(sortItem =>{
            if(sortItem.key === newSort.key){
                return newSort
            } else{
                return sortItem
            }
        })
        resolve(newSortBy)
    })
}