import React, { useState, useEffect } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import Divider from '@material-ui/core/Divider';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Pagination from '@material-ui/lab/Pagination';
import styled from 'styled-components';
import Badge from '@material-ui/core/Badge';
import Typography from '@material-ui/core/Typography';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { green } from '@material-ui/core/colors';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import { Query, withApollo } from 'react-apollo';
import { GET_MEDIA_CATEGORIES_COMPLETE } from '../../../../queries/mediaCategories';
import { GET_MEDIAS, GET_MEDIAS_IMAGES,GET_MEDIAS_CURSOR } from '../../../../queries/medias';
import PageLoader from "../../../../components/ui/loadings/page-loader/PageLoader";
import SearchBar from '../../../../components/ui/search/SearchBar';
import axios from '../../../../js/utils/axios';
import ItemCard from './ItemCard';
import ChipMedias from '../../../../components/screens/dashboard/medias/components/ChipMedias';
import { ALLOWED, DOCUMENTS, VIDEOS, IMAGES } from '../../../../js/constants/medias-types';
import { SNACK } from "../../../../js/constants/action-types";
import { ALERT_SUCCESS, ALERT_ERROR } from "../../../../js/constants/alert-types";
import { connect } from "react-redux";
import colors from "../../../../config/theme/colors";

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        marginBottom: 15
    },
    top: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: `0 ${theme.spacing(1)}px`
    },
    title: {
        margin: theme.spacing(1)
    },
    margin: {
        margin: `4px ${theme.spacing(1)}px`,
        width: 'calc(100% - 16px)'
    },
    chip: {
        margin: `${theme.spacing(2)}px ${theme.spacing(1)}px`
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        width: 120,
        height: 120,
        margin: '0 auto',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        cursor: 'pointer',
        position: 'relative',
        display: 'flex', 
        justifyContent: 'center',
        alignItems: 'center'
    },
    allMedias: {
        padding: 30
    },
    none: {
        display: 'block'
    },
    categories: {
        paddingBottom: theme.spacing(1)
    },
    list: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        flexWrap: 'wrap',
        '& > *': {
            margin: theme.spacing(0.5)
        },
        padding: 4
    },
    subtitle: {
        padding: '0 10px'
    },
    item: {
        marginBottom: theme.spacing(1)
    },
    mediasTop: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    validateButton: {
        marginRight: theme.spacing(2)
    },
    labelFab: {
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer'
    }
}));
const PaginationCustom = styled(Pagination)`
    ul{
        justify-content: center; 
        margin-top: 24px;
    }
`;
const CustomFab = withStyles((theme) => ({
    label: {
        height: '100%'
    }
}))(Fab);

const ColorButton = withStyles((theme) => ({
    root: {
      color: '#fff',
      backgroundColor: green[500],
      '&:hover': {
        backgroundColor: green[700]
      }
    }
}))(Button);

const supportedTypes = IMAGES;
let timer = null;

function MediaSelector ({ onSelect,client,xs, stateName,handleOpenMediaPicker,translated, isBuilder, allowedTypes, snack}) {
    const classes = useStyles();
    const allowedTypesInitial                       = allowedTypes;
    const [ currentFolder, setCurrentFolder ]       = useState(null);
    const [ previousFolders, setPreviousFolders ]   = useState([]);
    const [ selected, setSelected ]                 = useState(null);
    const [ search, setSearch ]                     = useState('');
    const [ searchedItems, setSearchedItems ]       = useState(null);
    const [ searching, setSearching ]               = useState(false);
    const [page,setPage]                            = useState(1);
    const [countPage,setCountPage]                  = useState(0);
    const [cursor,setCursor]                        = useState(null);
    const [cursorsList,setCursorsList]              = useState([]);
    const [filterByType, setFilterByType]           = useState(allowedTypesInitial?.length > 0 ? allowedTypesInitial : supportedTypes);
    const [totalElements, setTotalElements]         = useState(0);
    const nbperpage                                 = 19;
    const filterTypes = allowedTypesInitial?.length > 0 ? allowedTypesInitial : supportedTypes;

    useEffect(() => {
        client.query({
            query : GET_MEDIAS_CURSOR,
            variables:{types: filterByType},
            fetchPolicy : 'no-cache'
        }).then(result=>{
            setCursorsList(result.data.mediaObjects.edges);
        })
    }, []);

    function getCursors(filter) {
        client.query({
            query : GET_MEDIAS_CURSOR,
            variables:{types: filter ? filter : filterByType},
            fetchPolicy : 'no-cache'
        }).then(result=>{
            setCursorsList(result.data.mediaObjects.edges);
        })
    }
    function useWindowSize() {
        // Initialize state with undefined width/height so server and client renders match
        // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
        const [windowSize, setWindowSize] = useState({
          width: undefined,
          height: undefined,
        });
      
        useEffect(() => {
          // Handler to call on window resize
            function handleResize() {
                // Set window width/height to state
                setWindowSize({
                    width: window.innerWidth,
                    height: window.innerHeight,
                });
            }
          
          // Add event listener
            window.addEventListener("resize", handleResize);
          
          // Call handler right away so state gets updated with initial window size
            handleResize();
          
          // Remove event listener on cleanup
            return () => window.removeEventListener("resize", handleResize);
        }, []); // Empty array ensures that effect is only run on mount
        return windowSize;
    }

    const sizeWindow = useWindowSize();

    const changePage = (page)=>{
        let index = 0;
        if(page > 1){
            index = ( page * nbperpage ) - nbperpage - 1
        }
        setPage(page);
        setCursor(cursorsList[index].cursor)
    }

    const mediaMap = (e, i) => {
        return <ItemCard 
            key={i} 
            item={e} 
            selected={selected} 
            setSelected={setSelected} 
            xs={sizeWindow.width < 1300 ? 6 : sizeWindow.width < 1500 ? 4 : xs?xs:6}
            allowedTypes={filterTypes}
        />;
    };
    const typeFilter= 
        [
            {
                libelle:'All',
                type:'all',
            },
            filterTypes.some(array=> IMAGES.indexOf(array) >= 0) ?
            {
                libelle:'Images',
                type:IMAGES,
            } : null,
            filterTypes.some(array=> VIDEOS.indexOf(array) >= 0) ?
            {
                libelle:'Vidéos',
                type:VIDEOS,
            } : null,
            filterTypes.some(array=> DOCUMENTS.indexOf(array) >= 0) ?
            {
                libelle:'Documents',
                type:DOCUMENTS,
            } : null,
        ]

    const filterByTypeFunction=(cat)=>{
        let filter;
        if(cat==='all'){
            filter = filterTypes;
        }
        else{
            filter = cat;     
        }
        setFilterByType(filter);
        getCursors(filter);
    }

    return (
        <>
            <SearchBar style={{
                margin: '0 auto 8px auto',
                width: 'calc(100% - 16px)'
            }} onChange={(e) => {
                let value = e.target.value.trim();

                clearTimeout(timer);
                setSearch(value);

                timer = setTimeout(() => {
                    setSearching(true);
    
                    client.query({
                        query: GET_MEDIAS_IMAGES,
                        variables: { types: filterTypes, filePath: value },
                        fetchPolicy: 'no-cache'
                    }).then(result => {
                        if (value === ''){
                            setSearchedItems(null);
                        }else{
                            setSearchedItems(result.data.mediaObjects.edges);
                        }
                        setPage(1)
                        setCursor(null)
                        setSearching(false);
                        setCountPage(Math.ceil(result.data.mediaObjects.totalCount / nbperpage))
                    }).catch((e) => {
                        setSearching(false);
                    }); 
                }, 250);
            }} />

            <div className={classes.top} style={searchedItems ? { display: 'none'} : null}>
                {
                    currentFolder !== null ? <Button variant="outlined" color="primary" size="small" onClick={() => {
                        let previous = previousFolders.pop();
                        setCurrentFolder(previous);
                        setPreviousFolders(previousFolders);
                    }}>
                        Revenir en arrière
                    </Button> : <span />
                }

                <Typography variant="h3" className={classes.title}>
                    { currentFolder ? ` ${currentFolder.node.libelle}` : '/' }
                </Typography>
            </div>

            <div className={classes.categories}>
                <Query 
                    query={GET_MEDIA_CATEGORIES_COMPLETE} 
                    variables={{
                        types: filterByType
                    }} 
                    fetchPolicy="no-cache"
                >
                    {({ loading, error, data }) => {
                        if (error) return `Erreur ! ${error.message}`;

                        let categories  = [];
                        let root        = null;

                        if (!loading) {
                            root = data.mediaObjectCategories.edges.find(e => e.node.parent === null);

                            categories = data.mediaObjectCategories.edges.filter(e => {
                                return currentFolder 
                                    ? e.node.parent !== null && e.node.parent.id === currentFolder.node.id 
                                    : e.node.parent?.id === root.node.id ?? false;
                            });
                        }

                        return (
                            <>
                                <Badge badgeContent={categories?.length ?? 0} color="error" className={classes.chip} style={searchedItems ? { display: 'none'} : null} showZero>
                                    <Typography variant="h4" className={classes.subtitle}>
                                        Catégories
                                    </Typography>
                                </Badge>

                                {
                                    // loading ? (
                                    //     <PageLoader />
                                    // ) : (
                                        <div className={classes.list} style={searchedItems ? { display: 'none'} : null}>
                                            { 
                                                categories.map((e, i) => {
                                                    return (
                                                        <Chip 
                                                            label={ e.node.libelle } 
                                                            clickable 
                                                            onClick={() => {
                                                                previousFolders.push(currentFolder);
                                                                setCurrentFolder(e);
                                                                setPreviousFolders(previousFolders);
                                                            }} 
                                                            className={classes.category} 
                                                            key={`cat-${e.node.id}`}
                                                        />
                                                    );
                                                })
                                            }
                                        </div>
                                    // )
                                }
                                <Divider style={searchedItems ? { display: 'none'} : null} />
                                <div className={classes.mediasTop}>
                                    <Badge badgeContent={totalElements} color="error" className={classes.chip} showZero>
                                        <Typography variant="h4" className={classes.subtitle}>
                                            Médias
                                        </Typography>
                                    </Badge>

                                    {
                                        selected && <ColorButton 
                                                        variant     = "contained" 
                                                        color       = "primary" 
                                                        className   = {classes.validateButton} 
                                                        startIcon   = {<CheckCircleIcon />}
                                                        onClick     = {
                                                            () => {
                                                                onSelect(selected,stateName,translated);
                                                                if(handleOpenMediaPicker){
                                                                    handleOpenMediaPicker();
                                                                }
                                                            }}
                                                    >
                                                        Valider
                                                    </ColorButton>
                                    }
                                </div>
                                <Grid container direction="row" style={{marginLeft: 24}}>
                                    <ChipMedias 
                                        categories={typeFilter} 
                                        handleChangeTab={filterByTypeFunction} 
                                        // defaultTab={"all"}
                                    />
                                </Grid>
                                {
                                    loading ? null : (
                                        <>
                                            <div className={classes.medias}>
                                                <Query 
                                                    query={GET_MEDIAS_IMAGES} 
                                                    variables={{ 
                                                        types: filterByType, 
                                                        categories: [currentFolder ? currentFolder.node.id : root.node.id],
                                                        nbperpage:nbperpage,cursor : cursor ? cursor : null 
                                                    }} 
                                                    fetchPolicy="no-cache"
                                                    onCompleted={(data)=>{setCountPage(Math.ceil(data.mediaObjects.totalCount / nbperpage))}}
                                                >
                                                    {({ loading, error, data, refetch }) => {
                                                        if (error) return `Erreur ! ${error.message}`;

                                                        let medias = [];
                                                        let countPages = 0;

                                                        if (!loading) {
                                                            medias = data.mediaObjects.edges;
                                                            setTotalElements(data.mediaObjects.totalCount)
                                                        }

                                                        return (
                                                            <>
                                                                
                                                                {
                                                                    searchedItems ? null :
                                                                        loading ? (
                                                                            <PageLoader />
                                                                        ) : (
                                                                            <Grid 
                                                                                container 
                                                                                direction="row"
                                                                                justify="flex-start"
                                                                                alignItems="center"
                                                                                spacing={0}
                                                                                className={classes.allMedias}
                                                                                style={{paddingBottom: isBuilder ? 40 : 30}}
                                                                            >
                                                                                <Grid 
                                                                                    item 
                                                                                    xs={sizeWindow.width < 1300 ? 6 : sizeWindow.width < 1500 ? 4 : xs?xs:6}
                                                                                    className={classes.item}
                                                                                    key={`media-create`}
                                                                                >
                                                                                    <Paper 
                                                                                        className={classes.paper}
                                                                                        style={{ cursor: 'default' }}
                                                                                    >
                                                                                        <input
                                                                                            id={'add-media'}
                                                                                            type="file"
                                                                                            variant="outlined"
                                                                                            style={{ display: 'none' }}
                                                                                            accept={filterTypes?.length > 0 ? filterTypes : "image/*"}
                                                                                            hidden={true}
                                                                                            onChange={event => {
                                                                                            

                                                                                                event.preventDefault();
                                                                                                

                                                                                                let file    = event.target.files[0];
                                                                                                let reader  = new FileReader();
                                                                                                let newName=file['name'].replaceAll(' ','_');
                                                                                                newName=newName.replaceAll("'","");
                                                                                                newName=newName.replaceAll('"','');
                                                                                                reader.readAsDataURL(file);

                                                                                                event.target.value = null;
                                                                                                
                                                                                                reader.onloadend = async () => {
                                                                                                    let fileTypes = filterTypes;
                                                                                                    
                                                                                                    if (fileTypes.includes(file.type)) {
                                                                                                        if (file.size < (process.env.REACT_APP_FILE_UPLOAD_MAX ? process.env.REACT_APP_FILE_UPLOAD_MAX : 30000000)){
                                                                                                            let formData = new FormData();

                                                                                                            formData.append('file', file,newName);
                                                                                                            formData.append('type', file.type);
                                                                                                            formData.append('name', newName);
                                                                                                            formData.append('alt',newName)

                                                                                                            let id = currentFolder ? currentFolder.node.id : root.node.id;

                                                                                                            formData.append('categories[]', id.replace('/api/media-object-categories/', ''));
                                                                                        
                                                                                                            await axios(`${process.env.REACT_APP_API}/media-objects`, 'post', formData);
                                                                                                            refetch();
                                                                                                        }else{
                                                                                                            snack(ALERT_ERROR, 'Fichier trop volumineux (30 Mo max)')
                                                                                                        }
                                                                                                    }
                                                                                                };
                                                                                            }}
                                                                                        />
                                                                                        
                                                                                        <CustomFab color="primary" aria-label="add">
                                                                                            <label className={classes.labelFab} htmlFor={'add-media'}>
                                                                                                <AddIcon />
                                                                                            </label>
                                                                                        </CustomFab>
                                                                                    </Paper>
                                                                                    <div style={{height: 30,margin: 'auto', marginTop: 8, width: 120, height: 30, overflow: 'hidden',textAlign: 'center'}}>
                                                                                        
                                                                                        <Typography variant="h5" component="div" style={{color: colors.blue.regular}}>
                                                                                            Fichier de 30 Mo maximum
                                                                                        </Typography>
                                                                                    </div>
                                                                                </Grid>

                                                                                { medias.map(mediaMap) }
                                                                                {countPage > 1 ? 
                                                                                    <Grid item xs={12}>
                                                                                        <Grid container justify="center">
                                                                                            <PaginationCustom count={countPage} page={page} onChange={(event,page)=>{changePage(page)}}/>
                                                                                        </Grid>
                                                                                    </Grid>                                                                                
                                                                                :null}


                                                                            </Grid>
                                                                        )
                                                                }

                                                                {
                                                                    searchedItems === null ? null : searching ? (
                                                                        <PageLoader />
                                                                    ) : (
                                                                        <Grid 
                                                                            container 
                                                                            direction="row"
                                                                            justify="flex-start"
                                                                            alignItems="center"
                                                                            spacing={0}
                                                                            className={classes.allMedias}
                                                                        >
                                                                            { searchedItems.map(mediaMap) }
                                                                            { searchedItems.length > 0 ? 
                                                                                countPage > 1 ?
                                                                                    <Grid item xs={12}>
                                                                                        <Grid container justify="center">
                                                                                            <PaginationCustom count={countPage} page={page} onChange={(event,page)=>{changePage(page)}}/>
                                                                                        </Grid>
                                                                                    </Grid>  
                                                                                : null                                                                              
                                                                            : 'Aucun résultat'}

                                                                        </Grid>
                                                                    )
                                                                }
                                                            </>
                                                        );
                                                    }}
                                                </Query>
                                            </div>
                                        </>
                                    )
                                }
                            </>
                        );
                    }}
                </Query>
            </div>
        </>
    );
};

const mapDispatchToProps = dispatch => {
    return {
        snack: (type, message) => dispatch({ type: SNACK, payload: { type, message }})
    }
};

export default withApollo(connect(null, mapDispatchToProps)(MediaSelector));