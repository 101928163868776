import { gql } from 'apollo-boost';

export const UPDATE_PRODUCT_STOCK = gql`
mutation UpdateStock($id: ID!, $quantity: Int,$location:String){
    updateStock(input: {id:$id, quantity:$quantity,location:$location}){
        stock{
            id
            quantity
        }
    }
}
`

export const CREATE_PRODUCT_STOCK = gql`
mutation CreateStock($product:String, $quantity: Int!,$createdAt:String!,$updatedAt:String!, $location:String!){
    createStock(input: {product:$product,quantity:$quantity,createdAt:$createdAt,updatedAt:$updatedAt,location:$location}){
        stock{
            id
            quantity
        }
    }
}
`