import React, { useEffect } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import styled from 'styled-components';
import colors from '../../../../../config/theme/colors';
import Typography from '../../../../ui/typography/Typography';
import Chip from '@material-ui/core/Chip';
import DefaultImg from '../../../../../assets/images/socials/image-fake.png';
import { Box, Modal } from '@material-ui/core';
import OurButton from '../../../../ui/button/Button';

const CustomTableHead = styled(TableHead)`
    background : white !important;
    th{
        color: ${colors.grey.regular};
        font-style: italic;
    }
`

const CustomTableBody = styled(TableBody)`
    max-height: 230px;
    // border: 1px solid ${colors.grey.lighter.hue800};
    overflow-y: auto;
    /* max-height: 230px; */
    tr:nth-child(even){
        background-color: ${colors.grey.lighter.hue900};
    }
`

export default function OrderItems(props){
    const [openModal, setOpenModal] = React.useState(false);
    const [currentBundle, setCurrentBundle] = React.useState(null);

    const getShippedQuantity = (item) =>{
        let shippedQuantity = 0;
        let orderedQuantity = item.node.orderedQuantity;
        if(item.node.shipments?.edges?.length >0){
            for(let shipment of item.node.shipments.edges){
                shippedQuantity = shippedQuantity + shipment.node.shippedQuantity;
                // if(shipment.node.hasBeenValidated){
                //     shippedQuantity = shippedQuantity + shipment.node.shippedQuantity;
                // }
            }
        } 
        return `${shippedQuantity} / ${orderedQuantity}`
    }

    const handleBundleModal = (bundle) =>{
        if (bundle) {
            setCurrentBundle(bundle);
        }else{
            setCurrentBundle(null);
        }
        setOpenModal(!openModal);
    }

    return(
        props.items?.length >0 ? 
            <>
                <Typography variant="h3" style={{paddingBottom:30, fontSize: 25}}>{`Produits ${props.isReturn ? 'retournés':'commandés'}`}</Typography>

                <Box style={{
                    height: 250,
                    overflowY: 'auto',
                    border: `1px solid ${colors.grey.lighter.hue800}`
                }}>
                    <Table stickyHeader>
                        <CustomTableHead>
                            <TableRow>
                                <TableCell style={{textAlign: 'center', background: 'white'}}></TableCell>
                                <TableCell style={{textAlign: 'center', background: 'white'}}>SKU</TableCell>
                                <TableCell style={{background: 'white'}}>Nom du produit</TableCell>
                                {props.isReturn ?
                                    <TableCell style={{textAlign: 'center', background: 'white'}}>Numéros de retour</TableCell>
                                : null }
                                <TableCell style={{textAlign: 'center', background: 'white'}}>Qté</TableCell>
                                <TableCell style={{textAlign: 'center', background: 'white'}}>{`${props.isReturn ? 'Retournée' : 'Expédiée'}`}</TableCell>
                                
                                {/* <TableCell>Pourcentage de remise</TableCell> */}
                                <TableCell style={{textAlign: 'center', background: 'white'}}>Remise</TableCell>
                                <TableCell style={{textAlign: 'center', background: 'white'}}>PU* (HT)</TableCell>
                                {/* <TableCell>Prix unitaire (avec réduction)</TableCell> */}
                                <TableCell style={{textAlign: 'center', background: 'white'}}>Total (HT)</TableCell>                    
                            </TableRow>
                        </CustomTableHead>
                        <CustomTableBody>
                            {props.items.map((item,index)=>{
                                if (!item.node.unitFullPrice) {
                                    return;
                                }
                                return <TableRow key={`order-${index}`}>
                                    <TableCell style={{padding: 10, fontWeight: 'bold', textAlign: 'center',borderBottom:index+ 1 === props.items.length ?'none':"auto"}}>
                                        <img src={item.node.product?.imageUrl ?? DefaultImg} style={{width: 60, height: 60, objectFit: 'cover'}}/>
                                    </TableCell>
                                    <TableCell style={{padding: 10, fontWeight: 'bold', textAlign: 'center',borderBottom:index+ 1 === props.items.length ?'none':"auto"}}>{item.node.product.sku}</TableCell>
                                    <TableCell style={{padding: 10, fontWeight: 'bold',borderBottom:index+ 1 === props.items.length ?'none':"auto"}}>
                                        {item.node.product.productName ?? '-'}
                                        {
                                            item.node.productType === "bundle" &&
                                                <p 
                                                    style={{
                                                        cursor:"pointer",
                                                    }} 
                                                    onClick={()=>handleBundleModal(item.node.product.id)}
                                                >
                                                    voir le détail
                                                </p>
                                        }
                                    </TableCell>
                                    {props.isReturn ?
                                        <TableCell style={{padding: 10, fontWeight: 'bold', textAlign: 'center',borderBottom:index+ 1 === props.items.length ?'none':"auto"}}>
                                            {item.node.requests.edges.map(request =>(
                                                <Chip label={request.node.hubIdentifier} style={{marginRight:5,fontSize:12}} size="small" />
                                            ))}
                                        </TableCell>
                                    : null }
                                    <TableCell style={{padding: 10, fontWeight: 'bold', textAlign: 'center',borderBottom:index+ 1 === props.items.length ?'none':"auto"}}>{item.node.orderedQuantity}</TableCell>
                                    <TableCell style={{padding: 10, fontWeight: 'bold', textAlign: 'center',borderBottom:index+ 1 === props.items.length ?'none':"auto"}}>{getShippedQuantity(item)}</TableCell>
                                    {/* <TableCell>{item.node.discountRate ? `${item.node.discountRate} %` : '-'}</TableCell> */}
                                    <TableCell style={{padding: 10, fontWeight: 'bold', textAlign: 'center',borderBottom:index+ 1 === props.items.length ?'none':"auto"}}>{item.node.discountAmount ? `${item.node.discountAmount} €` : '-'}</TableCell>
                                    {/* <TableCell style={{fontWeight: 'bold'}}>{item.node.unitFullPrice}€</TableCell> */}
                                    <TableCell style={{padding: 10, fontWeight: 'bold', textAlign: 'center',borderBottom:index+ 1 === props.items.length ?'none':"auto"}}>
                                        {(props.origin != 'MANUAL' && props.origin != 'AVOIR') ?
                                            ((item.node.unitFullPrice - item.node.discountAmount - item.node.taxAmount)).toFixed(2)
                                            : ((item.node.unitFullPrice - item.node.discountAmount)).toFixed(2)
                                        }€
                                        {(item.node.discountAmount > 0) && <div style={{fontSize: 12, fontWeight: 'normal', marginTop: '2px'}}>({item.node.unitFullPrice - item.node.taxAmount}€ sans remise)</div>}
                                    </TableCell>
                                    <TableCell style={{ padding: 10, fontWeight: 'bold', textAlign: 'center',borderBottom:index+ 1 === props.items.length ?'none':"auto"}}>{
                                        (props.origin != 'MANUAL' && props.origin != 'AVOIR') ?
                                            ((item.node.unitFullPrice - item.node.discountAmount - item.node.taxAmount) * item.node.orderedQuantity).toFixed(2)
                                            : ((item.node.unitFullPrice - item.node.discountAmount) * item.node.orderedQuantity).toFixed(2)
                                        }€
                                    </TableCell>
                                </TableRow>
                            })}
                        </CustomTableBody>
                    </Table> 
                </Box>  
                <Modal
                    open={openModal}
                    onClose={handleBundleModal}
                    style={{display:'flex',alignItems:'center',justifyContent:'center'}}
                >
                    <div
                        style={{
                            backgroundColor: 'white',
                            outline:'none',
                            padding:20,
                            overflowY: 'auto',
                            border: `1px solid ${colors.grey.lighter.hue800}`
                        }}
                    >
                        {/* {
                            props.items.filter(e=>e.node.parent === currentBundle) &&
                            props.items.filter(e=>e.node.parent === currentBundle).map((item,i)=>{
                                return <div key={i}>here</div>
                            })
                        } */}
                        <Table stickyHeader>
                        <CustomTableHead>
                            <TableRow>
                                <TableCell style={{textAlign: 'center', background: 'white'}}></TableCell>
                                <TableCell style={{textAlign: 'center', background: 'white'}}>SKU</TableCell>
                                <TableCell style={{background: 'white'}}>Nom du produit</TableCell>
                                {props.isReturn ?
                                    <TableCell style={{textAlign: 'center', background: 'white'}}>Numéros de retour</TableCell>
                                : null }
                                <TableCell style={{textAlign: 'center', background: 'white'}}>Qté</TableCell>
                                <TableCell style={{textAlign: 'center', background: 'white'}}>{`${props.isReturn ? 'Retournée' : 'Expédiée'}`}</TableCell>
                                
                                {/* <TableCell>Pourcentage de remise</TableCell> */}
                                <TableCell style={{textAlign: 'center', background: 'white'}}>Remise</TableCell>
                                <TableCell style={{textAlign: 'center', background: 'white'}}>PU*</TableCell>
                                {/* <TableCell>Prix unitaire (avec réduction)</TableCell> */}
                                <TableCell style={{textAlign: 'center', background: 'white'}}>Total</TableCell>                    
                            </TableRow>
                        </CustomTableHead>
                        <CustomTableBody>
                            {props.items.filter(e=>e.node.parent?.id === currentBundle)&&
                            props.items.filter(e=>e.node.parent?.id === currentBundle).map((item,index)=>{
                                return <TableRow key={`order-${index}`}>
                                    <TableCell style={{padding: 10, fontWeight: 'bold', textAlign: 'center',borderBottom:index+ 1 === props.items.length ?'none':"auto"}}>
                                        <img src={item.node.product?.imageUrl ?? DefaultImg} style={{width: 60, height: 60, objectFit: 'cover'}}/>
                                    </TableCell>
                                    <TableCell style={{padding: 10, fontWeight: 'bold', textAlign: 'center',borderBottom:index+ 1 === props.items.length ?'none':"auto"}}>{item.node.product.sku}</TableCell>
                                    <TableCell style={{padding: 10, fontWeight: 'bold',borderBottom:index+ 1 === props.items.length ?'none':"auto"}}>
                                        {item.node.product.productName ?? '-'}
                                        {
                                            item.node.productType === "bundle" &&
                                                <p 
                                                    style={{
                                                        cursor:"pointer",
                                                    }} 
                                                    onClick={()=>handleBundleModal(item.node.id)}
                                                >
                                                    voir le détail
                                                </p>
                                        }
                                    </TableCell>
                                    {props.isReturn ?
                                        <TableCell style={{padding: 10, fontWeight: 'bold', textAlign: 'center',borderBottom:index+ 1 === props.items.length ?'none':"auto"}}>
                                            {item.node.requests.edges.map(request =>(
                                                <Chip label={request.node.hubIdentifier} style={{marginRight:5,fontSize:12}} size="small" />
                                            ))}
                                        </TableCell>
                                    : null }
                                    <TableCell style={{padding: 10, fontWeight: 'bold', textAlign: 'center',borderBottom:index+ 1 === props.items.length ?'none':"auto"}}>{item.node.orderedQuantity}</TableCell>
                                    <TableCell style={{padding: 10, fontWeight: 'bold', textAlign: 'center',borderBottom:index+ 1 === props.items.length ?'none':"auto"}}>{getShippedQuantity(item)}</TableCell>
                                    {/* <TableCell>{item.node.discountRate ? `${item.node.discountRate} %` : '-'}</TableCell> */}
                                    <TableCell style={{padding: 10, fontWeight: 'bold', textAlign: 'center',borderBottom:index+ 1 === props.items.length ?'none':"auto"}}>{item.node.discountAmount ? `${item.node.discountAmount} €` : '-'}</TableCell>
                                    {/* <TableCell style={{fontWeight: 'bold'}}>{item.node.unitFullPrice}€</TableCell> */}
                                    <TableCell style={{padding: 10, fontWeight: 'bold', textAlign: 'center',borderBottom:index+ 1 === props.items.length ?'none':"auto"}}>
                                    {(item.node.unitFullPrice - item.node.discountAmount).toFixed(2)}€
                                        {(item.node.discountAmount > 0) && <div style={{fontSize: 12, fontWeight: 'normal', marginTop: '2px'}}>({item.node.unitFullPrice}€ sans remise)</div>}
                                    </TableCell>
                                    <TableCell style={{ padding: 10, fontWeight: 'bold', textAlign: 'center',borderBottom:index+ 1 === props.items.length ?'none':"auto"}}>{
                                        (item.node.unitFullPriceWithDiscount !== null) 
                                            ? ((item.node.unitFullPriceWithDiscount - item.node.discountAmount) * item.node.orderedQuantity).toFixed(2)
                                            : (item.node.unitFullPrice * item.node.orderedQuantity).toFixed(2)}€
                                    </TableCell>
                                </TableRow>
                            })}
                        </CustomTableBody>
                        </Table> 
                        <div style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            marginTop: 20
                        }}>
                            <OurButton style={{margin:0}} onClick={handleBundleModal}>Fermer</OurButton>
                        </div>
                    </div>
                </Modal>
            </>

        : null
    )
}