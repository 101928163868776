import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import moment from 'moment';
import styled from 'styled-components';
import colors from '../../../../../config/theme/colors';
import { Box, Chip } from '@material-ui/core';

const CustomTableHead = styled(TableHead)`
    background : transparent;
    th{
        color: ${colors.grey.regular};
        font-style: italic;
        /* text-align: center; */
    }
`

const CustomBodyHead = styled(TableBody)`
    tr:nth-child(2n){
        background-color: ${colors.grey.lighter.hue900};
    }
`

export default function History(props){

    const getOrderStatus = (status) => {
        switch (status) {
            case 'Pending for validation':
                return {
                    label: 'En attente',
                }
            case 'Processing':
                return {
                    label: 'En Traitement',
                }
            case 'Shipped':
                return {
                    label: 'Expédiée',
                }
            case 'Closed':
                return {
                    label: 'Fermée',
                }
            case 'Waiting for exportation':
                return {
                    label: 'En attente',
                }
            case 'Partially Shipped':
                return {
                    label: process.env.REACT_APP_DISABLE_PARTALLY_SHIPPED === "true" ? "Expédiée" : 'Expédiée partiellement',
                }
            case 'Partially Accepted':
                return {
                    label: 'Acceptée',
                }
            case 'MP Partially Shipped':
                return {
                    label:process.env.REACT_APP_DISABLE_PARTALLY_SHIPPED === "true" ? "Expédiée" : 'Expédiée partiellement',
                }
            case 'MP Partially Accepted':
                return {
                    label: 'Acceptée',
                }
            case 'Declined':
                return {
                    label: 'Refusée',
                }
            case 'Accepted':
                return {
                    label: 'Acceptée',
                }
            case 'Manual action required':
                return {
                    label: 'Action requise',
                }
            case 'Avoir':
                return {
                    label: 'Avoir',
                }
            case 'processing':
                return {
                    label: 'Traitement'
                }
            default:
                return {
                    label: 'SINFIN - HUB'
                }
        }
    }

    return(
        <Box style={{
            maxHeight: 200,
            overflow: 'auto'
        }}>
            <Table size="small" style={{width: '100%'}} stickyHeader>
                <CustomTableHead>
                    <TableRow>
                        <TableCell style={{background: 'white'}}>Date</TableCell>
                        <TableCell style={{background: 'white'}}>Heure</TableCell>
                        <TableCell style={{background: 'white'}}>Statut</TableCell>
                        <TableCell style={{background: 'white'}}>Message</TableCell>
                    </TableRow>
                </CustomTableHead>
                <CustomBodyHead>
                    {props.items.map((item,index)=>(
                        <TableRow key={`log-${index}`}>
                            <TableCell style={{
                                height: 45,
                                display: 'webkit-box',
                                WebkitLineClamp: 2,
                                WebkitBoxOrient: 'vertical',
                                overflow: 'hidden',
                                fontWeight: 700
                            }}>{moment(item.node.createdAt).format('DD/MM/YYYY')}</TableCell>
                            <TableCell style={{
                                height: 45,
                                display: 'webkit-box',
                                WebkitLineClamp: 2,
                                WebkitBoxOrient: 'vertical',
                                overflow: 'hidden',
                                fontWeight: 700
                            }}>{moment(item.node.createdAt).format('HH:mm:ss')}</TableCell>
                            <TableCell style={{
                                height: 45,
                                display: 'webkit-box',
                                WebkitLineClamp: 2,
                                WebkitBoxOrient: 'vertical',
                                overflow: 'hidden',
                                fontWeight: 700
                            }}>
                                <Chip title={getOrderStatus(item.node.hubStatus).label} label={getOrderStatus(item.node.hubStatus).label} style={{ marginRight: 5, fontSize: 12, backgroundColor: colors.white, border: `1px solid ${colors.black.lighter}` }} size="small" />
                            </TableCell>
                            <TableCell style={{
                                height: 45,
                                display: 'webkit-box',
                                WebkitLineClamp: 2,
                                WebkitBoxOrient: 'vertical',
                                overflow: 'hidden',
                                fontWeight: 700
                            }}>{item.node.message}</TableCell>
                        </TableRow>
                    ))}
                </CustomBodyHead>
            </Table>
        </Box>
    )
}