import React from 'react';
import { withRouter } from 'react-router';
import { connect } from "react-redux";
import { START_LOADING } from '../../../../js/constants/action-types';

import { Box, Grid } from '@material-ui/core';
import Button from '../../../ui/button/Button';
import Typography from '../../../ui/typography/Typography';
import colors from '../../../../config/theme/colors';
import JohnDoe from '../../../../assets/images/john-doe.png';
import styled from 'styled-components';

const GridCustom = styled(Grid)`
    @media screen and (max-width: 1400px){
        .MuiTypography-body1{
            font-size: 13px;
        }
    }
`;

function CardUser(props){
    const user = props.userProperty;
    const listRoles = props.listRoles;
    return(
        <GridCustom container>
            <Grid container style={{margin: 0}} spacing={4}>
                <Grid item xs={window.innerWidth < 1150 && props.disabledAction ? 12 : 6} style={{
                    width: '100%',
                    position: 'relative',
                }}>
                    <img src={user.image && user.image.data ? user.image.data : user.image ? `${process.env.REACT_APP_API_ROOT}/${user.image}` : JohnDoe} style={{
                        width: '100%',
                        maxWidth: window.innerWidth < 1150 && props.disabledAction ? 150 : null,
                    }} />
                </Grid>
                <Grid item xs={window.innerWidth < 1150 && props.disabledAction ? 12 : 6}>
                    <Typography variant="h4" colortext={colors.blue.regular} component="div">
                        <Box pb={1} fontWeight="bold">
                            {user.firstname} {user.lastname}
                        </Box>
                    </Typography>
                    <Typography variant="body1" component="div">
                        {user.userRole ? 
                            (
                                <Box pb={1}>
                                    {listRoles?.map((role) => user.userRole === role.node.id ? `Rôle: ${role.node.libelle}` : null)}
                                </Box>
                            ) : null
                        }
                    </Typography>
                    <Typography variant="body2" component="div" style={{lineHeight: "32px", color: colors.blue.regular}}>Contact</Typography>
                    <Typography variant="body1" component="div" style={{lineHeight: "32px"}}>
                        { user.phone ? 
                            (
                                <Box pb={1}>
                                    Téléphone: {user.phone} 
                                </Box>
                            )
                            : null
                        }
                        { user.email ? 
                            (
                                <Box pb={1}>
                                    Email: {user.email} 
                                </Box>
                            )
                            : null
                        }
                    </Typography>
                </Grid>
            </Grid>
            {
                props.editUser ?
                    <Grid container style={{margin: 0}}>
                        <Grid item align="end" xs={12}>
                            <Button style={{marginBottom: 8, marginRight: 16}} onClick={props.editUser} disabled={props.disabledAction}>Modifier</Button>
                        </Grid>
                    </Grid>
                : null
            }
            
        </GridCustom>
    );
}

const mapDispatchToProps = dispatch => {
    return {
      startLoading: () => dispatch({ type: START_LOADING }),
    }
}

const mapStateToProps = state => {
    return {
        loading: state.loading,
        user: state.user,
    };
};
  
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CardUser));