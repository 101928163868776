import React from 'react';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import styled from 'styled-components';
import colors from '../../../../../config/theme/colors';
import Typography from '../../../../ui/typography/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import _ from 'lodash';
import { Box, Switch } from '@material-ui/core';
import ReactQuill from 'react-quill';
import { Grid } from 'react-virtualized';
import OurTypography from '../../../../ui/typography/Typography';
import InputBuilder from '../../../../ui/form/InputBuilder';
import { bgcolor } from '@material-ui/system';

const CustomTableHead = styled(TableHead)`
    background : ${colors.blue.regular};
    th{
        color:white;
    }
`;

export default function OrderItemsRepayment(props){
    const { items, selectedProductsRepayment, qtyProductsRepayment, refundShippingFees, adjustment, setState, typeAction, trackingLink, trackingNumber, trackingName, trackingSendEmail, trackingEmailContent } = props;

    const changeElementSelected = (item, index) => {
        if(selectedProductsRepayment.includes(item)){
            let i = 0;
            for(let element of selectedProductsRepayment){
                if(element === item){
                    console.log(item, i, selectedProductsRepayment[i], selectedProductsRepayment.splice(i,1));
                }
                i++;
            }
            setState(selectedProductsRepayment, 'selectedRepaymentProducts');
        }
        else{
            let array = selectedProductsRepayment.push(item);
            setState(selectedProductsRepayment, 'selectedRepaymentProducts');
            qtyProductsRepayment[index] = 1; 
            setState(qtyProductsRepayment, 'qtyProductsRepayment')
            
        }
    }

    var title = 'Détails de l\'action'
    switch (typeAction) {
        case 'refund':
            title = 'Paramétrer le remboursement';
            break;
    
        case 'refund':
            title = 'Paramétrer l\'avis de souffrance';
            break;

        case 'ship':
            title = 'Paramétrer l\'expédition';
            break;

        default:
            break;
    }

    const formInputs = {
        name: {
            type: 'text',
            label: 'Nom',
            helper: {
                text: "",
                link: false,
            },
            required: true,
            disabled: false,
            stateName: 'trackingName',
        },
        number: {
            type: 'text',
            label: 'Numéro',
            helper: {
                text: "",
                link: false,
            },
            required: true,
            disabled: false,
            stateName: 'trackingNumber',
        },
        link: {
            type: 'text',
            label: 'Lien',
            helper: {
                text: "",
                link: false,
            },
            required: true,
            disabled: false,
            stateName: 'trackingLink',
        },
        sendMail: {
            
        },
        adjustement: {
            type: 'number',
            label: 'Ajustement',
            helper: {
                text: "",
                link: false,
            },
            required: true,
            disabled: false,
            stateName: 'adjustement',
        },
    }

    return(
        props.items?.length >0 ? 
            <>
                <Typography variant="h3" style={{paddingBottom:10}}>{title}</Typography>

                <Table>
                    <CustomTableHead>
                        <TableRow>
                            {
                                typeAction !== 'ship' ? (
                                <>
                                    <TableCell padding="checkbox">
                                    </TableCell>
                                    <TableCell>
                                    </TableCell>
                                </>
                                ) : 
                                <>
                                <TableCell padding="checkbox">
                                </TableCell>
                                <TableCell>Quantité <br/>à expédier
                                </TableCell>
                                </>
                            }
                            <TableCell>Sku</TableCell>
                            <TableCell>Nom</TableCell>
                            <TableCell>Quantité <br/>expédiée</TableCell>
                            {/* <TableCell>Prix total</TableCell>                */}
                        </TableRow>
                    </CustomTableHead>
                    <TableBody>
                        {items.map((item,index)=>{
                            const labelId = `enhanced-table-checkbox-${index}`;
                            if (item.node.productType === 'bundle') {
                                return;
                            }
                            return(
                                <TableRow key={`order-${index}`}>
                                    {
                                        typeAction !== 'ship' ? (
                                        <>
                                            <TableCell padding="checkbox">
                                                <Checkbox
                                                    checked={selectedProductsRepayment.includes(item) ? true : false}
                                                    inputProps={{ 'aria-labelledby': labelId }}
                                                    onChange={() => changeElementSelected(item, index)}
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <TextField 
                                                    id="standard-secondary" 
                                                    color="secondary"
                                                    type="number"
                                                    InputProps={{ inputProps: { min: 1, max: item.node.orderedQuantity } }}
                                                    value={selectedProductsRepayment.includes(item) ? qtyProductsRepayment[index] : 0}
                                                    onChange={(e) => {qtyProductsRepayment[index] = e.target.value; setState(qtyProductsRepayment, 'qtyProductsRepayment')}}
                                                />
                                            </TableCell>
                                        </>
                                        ) : 
                                        <>
                                            <TableCell padding="checkbox">
                                            {item.node.invoicedQuantity != item.node.orderedQuantity ?
                                            (
                                                <>
                                                    <Checkbox
                                                        checked={selectedProductsRepayment.includes(item) ? true : false}
                                                        inputProps={{ 'aria-labelledby': labelId }}
                                                        onChange={() => changeElementSelected(item, index)}
                                                    />
                                                </>
                                            ) :
                                            (null)
                                            }
                                            </TableCell>
                                            <TableCell>
                                            {item.node.invoicedQuantity != item.node.orderedQuantity ?
                                            (
                                                <>
                                                    <TextField 
                                                        id="standard-secondary" 
                                                        color="secondary"
                                                        type="number"
                                                        InputProps={{ inputProps: { min: 1, max: item.node.orderedQuantity } }}
                                                        value={selectedProductsRepayment.includes(item) ? qtyProductsRepayment[index] : 0}
                                                        onChange={(e) => {qtyProductsRepayment[index] = e.target.value; setState(qtyProductsRepayment, 'qtyProductsRepayment')}}
                                                    />
                                                </>
                                            ) :
                                            (null)
                                            }

                                            </TableCell>
                                        </>
                                    }
                                    
                                    <TableCell>{item.node.product.sku}</TableCell>
                                    <TableCell>{item.node.product.productName ?? '-'}</TableCell>
                                    <TableCell>{item.node.invoicedQuantity?item.node.invoicedQuantity:0} / {item.node.orderedQuantity}</TableCell>
                                    {/* <TableCell>{(item.node.unitFullPriceWithDiscount !== null) ? (item.node.unitFullPriceWithDiscount * item.node.orderedQuantity) : (item.node.unitFullPrice * item.node.orderedQuantity)}€</TableCell> */}
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
                {
                    typeAction === 'refund' ? (
                        <Box style={{
                            background: colors.grey.lighter.hue980,
                            border: `1px solid ${colors.grey.border}`,
                            marginTop: 16,
                            padding: 24,
                        }}>
                            <div>
                                <FormControlLabel
                                    control={<Checkbox checked={refundShippingFees} onChange={(e) => setState(!refundShippingFees, 'refundShippingFees')} />}
                                    label="Remboursement des frais de port"
                                />
                            </div>
                            <div style={{display: 'flex', flexDirection: 'column', marginTop: 16}}>
                                <OurTypography style={{
                                    marginBottom: 8,
                                }}>{'Ajustement'}</OurTypography>
                                <InputBuilder value={adjustment} input={formInputs.adjustement} stateCallback={(e) => setState(e.target.value, 'adjustment')} /> 
                            </div>
                        </Box>
                    ) : null
                }

                {
                    typeAction === 'ship' ? (
                        <>
                            <h3>Suivi de l'expédition</h3>
                            <div style={{
                                display: 'flex', 
                                flexDirection: 'column', 
                                marginTop: 16, 
                                backgroundColor: colors.grey.lighter.hue980,
                                padding: 24,
                                border: `1px solid ${colors.grey.border}`,
                            }}>
                                <OurTypography>{'Nom'}</OurTypography>
                                <InputBuilder value={trackingName} input={formInputs.name} stateCallback={(e) => {
                                    setState(e.target.value, 'trackingName')
                                    setState(false, 'errorShippingTrackingName')
                                }} error={props.errors?.trackingName} /> 
                                
                                <OurTypography>{'Numéro'}</OurTypography>
                                <InputBuilder value={trackingNumber} input={formInputs.number} stateCallback={(e) => {
                                    setState(e.target.value, 'trackingNumber')
                                    setState(false, 'errorShippingTrackingNumber')

                                }} error={props.errors?.trackingNumber} /> 
                                
                                <OurTypography>{'Lien'}</OurTypography>
                                <InputBuilder value={trackingLink} input={formInputs.link} stateCallback={(e) => {
                                    setState(e.target.value, 'trackingLink')
                                    setState(false, 'errorShippingTrackingLink')
                                }} error={props.errors?.trackingLink} /> 

                                {/* <FormControlLabel
                                    control={
                                    <Switch
                                        checked={trackingSendEmail}
                                        value={trackingSendEmail}
                                        onChange={(e) => {setState(!trackingSendEmail, 'trackingSendEmail'); console.log(trackingSendEmail)}}
                                        color="primary"
                                    />
                                    }
                                    label="Envoyer un mail"
                                /> */}

                                {
                                    trackingSendEmail ?<> 
                                        <span style={{width: '100%', color: '#808F98', fontSize: 14, fontFamily: 'soleil, sans-serif', fontWeight: 'normal', lineHeight: '19px', marginTop: '12px', marginBottom: '12px'}}>Contenu du mail</span>
                                        <ReactQuill 
                                            value={trackingEmailContent}
                                            theme="snow"
                                            onChange={(content, delta, source, editor) => {
                                                setState(content, "trackingEmailContent")
                                            }}

                                            style={{maxWidth: '600px'}}
                                        />
                                    </>: null
                                }
                                
                            </div>
                            
                        </>
                    ) : null
                }
                
                {
                    typeAction === 'suffering' ? (
                        <>
                            <div style={{
                                display: 'flex', 
                                flexDirection: 'column', 
                                marginTop: 16, 
                                backgroundColor: colors.grey.lighter.hue980,
                                padding: 24,
                                border: `1px solid ${colors.grey.border}`,
                            }}>
                                <OurTypography>{'Nom'}</OurTypography>
                                
                            </div>
                            
                        </>
                    ) : null
                }
            </>
        : null
    )
}
