import React from 'react';
import { withRouter } from 'react-router';
import { connect } from "react-redux";
import {Grid, InputLabel, Box, Typography} from '@material-ui/core';

import DialogModal from '../../../ui/dialog/DialogModal';

import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

import CardCustom from '../../../layouts/Card/CardCustom';
import CardUserDetail from '../../../layouts/Card/cardContent/CardUserDetail';
import StatusInfo from '../../../ui/status-info/StatusInfo';
import LayoutBuilder from '../../../ui/form/LayoutFormBuilder';
import InputBuilder from '../../../ui/form/InputBuilder';
import { SNACK, START_LOADING, STOP_LOADING, SET_USER } from '../../../../js/constants/action-types';
import { ALERT_SUCCESS, ALERT_ERROR } from '../../../../js/constants/alert-types';
import { ROUTE_SETTINGS_USERS, ROUTE_HOME } from '../../../../js/constants/route-names';
import editUserForm from './config/editUser.config';
import * as moment from "moment";
import { withApollo } from 'react-apollo';

import { UPDATE_USER, DELETE_USER, GET_USER_BY_ID, GET_USERS_EMAIL, GET_ROLES, GET_ACL } from '../../../../queries/users';
import { eventService } from '../../../../js/services/event.service';
import styled from 'styled-components';
import colors from '../../../../config/theme/colors';
import * as generator from 'generate-password';
import request from '../../../../js/utils/fetch';
import { default as editedAccountTemplate } from '../../../../email/editedAccount';
import { hasRights } from '../../../../js/utils/rights';

import { SETTINGS, SETTINGS_USERS, VIEW, UPDATE, DELETE} from '../../../../js/constants/constant-rights';

const SpanColor = styled.span`
    color: ${colors.blue.lighter.hue300};
    font-weight: bold;
`;
const InputLabelCustom = styled(InputLabel)`
    color: ${colors.blue.regular};
    margin-top: ${props => props.margintop || 0};
    margin-bottom: 24px;
`;
const GridFlexCenter = styled(Grid)`
    display: flex;
    align-items: center;
`;
const BoxCustom = styled(Box)`
    width: auto;
`;
const ReturnLink = styled(Typography)`
    color: ${colors.blue.lighter.hue300};
    width: 70px;
    cursor: pointer;
    &:hover{
        text-decoration: underline;
    }
`;

const emailPopup = {
    type: 'text',
    label: 'Email',
    helper: 'Langue',
    disabled: 'disabled',
    stateName: 'email',
    value: ''
};
const passwordPopup = {
    type: 'password',
    label: 'Mot de passe',
    helper: 'Langue',
    disabled: 'disabled',
    stateName: 'password',
    value: ''
};

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class UserDetail extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            openSnack: false,
            openForm: false,
            editForm: 'edit',
            editPassword: false,
            openDialogRemove: false,
            openDialogPassword: false,
            page: 1,
            pageOfItems: [],
            user_id: `/api/users/${props.match.params.id}`,
            user_token_id: '',
            email: '',
            currentEmail: '',
            plainPassword: '',
            plainPasswordRepeat: '',
            roles: '', 
            firstname: '', 
            lastname: '', 
            phone: '', 
            image: '', 
            preferredLangcode: 'fr',
            notif: false,
            generate: false,
            showPassword: false,
            createdAt: moment().format(), 
            updatedAt: moment().format(),
            inputHidden: [],
            errors: {},
            currentLang: props.locales[0].node.id,
        }
    }

    handleInputChange = (stateName, evt) => {
        const value = evt?.target?.value ?? evt;
        this.setState({
            ...this.state,
            [stateName]: value
        });
        this.user[stateName] = value;
        this.forceUpdate();
    };

    handleButtonGroupChange = (stateName, value) => {
        this.setState({
            [stateName]: value
        });
    };

    handleToggleDialog = () => {
        this.setState({ 
            openDialogRemove : !this.state.openDialogRemove
        });
    };

    handleToggleDialogPassword = () => {
        this.setState({ 
            openDialogPassword : !this.state.openDialogPassword
        });
        this.handleToggleDrawer();
        this.handleGetUsers();
    };
    
    handleToggleDrawer = () => {
        this.setState({ 
            openForm : !this.state.openForm
        });
        if(this.state.openForm){
            this.handleGetUsers();
        }
    };
    
    
    deleteMutation = () => {
        this.props.startLoading();
        let query = null;
        let variables = null;
        
        query = DELETE_USER;
        variables = { "id": this.state.user_id }
        
        this.props.client.mutate({
            mutation: query,
            variables
        }).then(result =>{
            this.setState({ 
                openDialogRemove : false
            });
            this.props.stopLoading();
            this.goTo(ROUTE_SETTINGS_USERS)
        });
    };

    handlerMutation = () => {
        this.props.startLoading();
        let variables = {
            id: this.state.user_id,
            email: this.state.email,
            userRole: this.state.userRole,
            roles: [this.state.userRole],
            firstname: this.state.firstname,
            lastname: this.state.lastname,
            phone: this.state.phone,
            preferredLangcode: this.state.preferredLangcode,
            updatedAt: this.state.updatedAt
        }
        if(this.state.editPassword)
            variables.plainPassword = this.state.plainPassword;
        if(typeof(this.state.isActive) !== 'undefined')
            variables.isActive = this.state.isActive;
        if(typeof(this.state.isBlocked) !== 'undefined')
            variables.isBlocked = this.state.isBlocked;
        if(this.state.image.changed)
            variables.image = this.state.image.data;

        this.props.client.mutate({
            mutation: UPDATE_USER,
            variables
        }).then(result =>{
            if (this.state.editPassword) {
                this.setState({ openDialogPassword : true });
                request(`${process.env.REACT_APP_API}/sender-emails`, 'post', {
                    sender: "no-reply@sinfin.fr",
                    receiver: this.state.email,
                    subject: "Votre compte SpreadSuite a été modifié",
                    content: editedAccountTemplate(this.state.email, this.state.plainPassword, `${process.env.REACT_APP_PUBLIC}/login`, `${process.env.REACT_APP_PUBLIC}/img/logo.png`, this.state.notif)
                });
            } else if (!this.state.editPassword) {
                this.handleToggleDrawer();
            }
            if (this.state.user_id === this.props.user.id){
                this.props.client.query({
                    query: GET_USER_BY_ID,
                    variables: { id: this.props.user.id }
                  }).then(result =>{
                      let getUser = result.data.user
                      let idUserRole = result.data.user.userRole.id.replace('/api/user-roles/', '')
            
                      request(`${process.env.REACT_APP_API}/users/acl/${idUserRole}`, 'get', false).then(
                        (data) => {
                          if(data.success){
                            getUser.rights = data.acl
                          }
                          localStorage.setItem('AUTH_USER', JSON.stringify(getUser));
                          this.props.setUser(getUser);
                        }
                      )
                  });
            }
            this.props.snack(ALERT_SUCCESS, 'Le compte est modifié');
            this.handleGetUsers();
            this.props.stopLoading();
        })
    }

    resetState(){
        this.setState({
            email: '', 
            plainPassword: '', 
            roles: '', 
            firstname: '', 
            lastname: '', 
            phone: '', 
            image: {
                changed: false,
                data: null
            }, 
            preferredLangcode: 'fr', 
            isActive: true,
            notif: false,
            generate: false,
            isBlocked: false,  
            createdAt: moment().format(), 
            updatedAt: moment().format(),
            editPassword: true,
            hideInput: [],
        });
        this.handleGetRolesUsers();
        this.handleGetUsers();
    }

    editUser = (nodeInfo) => {
        this.setState({
            user_id: nodeInfo.id,
            openForm : !this.state.openForm,
            editForm: 'edit',
            email: nodeInfo.email, 
            plainPassword: '', 
            roles: nodeInfo.userRole?.id, 
            firstname: nodeInfo.firstname, 
            lastname: nodeInfo.lastname, 
            phone: nodeInfo.phone, 
            image: {
                changed: false,
                data: nodeInfo.image
            }, 
            preferredLangcode: 'fr', 
            isActive: nodeInfo.isActive, 
            isBlocked: nodeInfo.isBlocked,
            notif: false,
            generate: false,
            editPassword: false,
            updatedAt: moment().format(),
        }, () => {
            
            let selectRolesValues = [];
            for(let role of this.state.listRoles){
                selectRolesValues.push({value: role.node.id, label: role.node.libelle})
            }
            let i = 0;
            for(let item of editUserForm.formConfig.children[0].optionsInputs){
                if(item.stateName === 'roles'){
                    editUserForm.formConfig.children[0].optionsInputs[i].value = selectRolesValues;
                    this.forceUpdate();
                }
                i++;
            }
        });
        this.setState({
            inputHidden: ['generate', 'notif', 'plainPassword', 'plainPasswordRepeat']
        })
    }

    handleFormError = (stateName, error) => {
        let errors = this.state.errors;
        errors[stateName] = error;
        this.setState({ errors });
    };

    hasErrors = () => {
        if (this.state.errors) {
            for (let error in this.state.errors) {
                if (this.state.errors[error])
                    return true;
            }
        }

        return false;
    };

    
    handleGetRolesUsers(){
        this.props.client.query({
            query: GET_ROLES,
            fetchPolicy: 'no-cache',
        }).then(result => {
            this.setState({listRoles: result.data.userRoles.edges});
            let form = editUserForm;
            let i = 0;
            for(let item of form.formConfig.children[0].optionsInputs){
                if(item.stateName === 'userRole'){
                    editUserForm.formConfig.children[0].optionsInputs[i].value = [];
                    for(let role of result.data.userRoles.edges){
                        editUserForm.formConfig.children[0].optionsInputs[i].value.push({label: role.node.libelle, value: role.node.id});
                    }
                }
                i++;
            }
        });
    }

    handleGetUsers(){
        this.props.client.query({
            query: GET_USER_BY_ID,
            variables: {id: this.state.user_id},
            fetchPolicy: 'no-cache',
        }).then(result =>{
            let role = result.data.user.userRole?.id;
            this.user = result.data.user;

            this.user.userRole = role;
            this.user.roles = role;

            this.setState({
                isActive: result.data.user.isActive,
                isBlocked: result.data.user.isBlocked,
                currentEmail: result.data.user.email,
                roles: role,
                userRole: role,
            });

            if(this.props.user.id === this.state.user_id){
                let i=0;
                for(let input of editUserForm.formConfig.children[0].optionsInputs){
                    if(input.stateName === "isActive"){
                        editUserForm.formConfig.children[0].optionsInputs[i].disabled = true;
                    }
                    i++;
                }
            }
            else{
                let i=0;
                for(let input of editUserForm.formConfig.children[0].optionsInputs){
                    if(input.stateName === "isActive"){
                        editUserForm.formConfig.children[0].optionsInputs[i].disabled = false;
                    }
                    i++;
                }
            }
            this.forceUpdate();
        });
        this.props.client.query({
            query: GET_USERS_EMAIL,
            fetchPolicy: 'no-cache',
        }).then(result => {
            this.setState({
                listEmail: result.data.users.edges
            })
        })
    }

    componentDidMount() {
        const getRights = hasRights(SETTINGS, SETTINGS_USERS, VIEW)
        if (!getRights){
            this.props.snack(ALERT_ERROR, `Vous n'avez pas les droits suffisants pour accéder à cette page`);
            this.goTo(ROUTE_HOME);
        }
        this.handleGetRolesUsers();
        this.handleGetUsers();
    }

    componentDidUpdate(prevProps, prevState){
        if(this.props.match.params.id !== prevProps.match.params.id){
            this.setState({user_id: `/api/users/${this.props.match.params.id}`})
            this.handleGetUsers();
        }
        if(this.state.user_id !== prevState.user_id){
            this.handleGetUsers();
            if(this.state.user_id === this.props.user.id){
                let i = 0;
                for(let input of editUserForm.formConfig.children[0].optionsInputs){
                    if(input.stateName === "isActive"){
                        input.disabled = true;
                        editUserForm.formConfig.children[0].optionsInputs[i] = input;
                    }
                    i++;
                }   
            }
            else{
                for(let input of editUserForm.formConfig.children[0].optionsInputs){
                    if(input.stateName === "isActive"){
                        input.disabled = false;
                        editUserForm.formConfig.children[0].optionsInputs[i] = input;
                    }
                    i++;
                }
            }
        }
        if(this.state.isActive !== prevState.isActive){
            if(this.state.isActive){
                this.setState({
                    isBlocked: false,
                })
            }else if(!this.state.isActive){
                this.setState({
                    isBlocked: true,
                })
            }
        }
        if(this.state.generate !== prevState.generate){
            if(this.state.generate){
                var password = generator.generate({
                    length: 12,
                    numbers: true,
                    symbols: true,
                    lowercase: true,
                    uppercase: true,
                    excludeSimilarCharacters: true,
                    exclude: '"\'{}[]()=:;.,_+-?/<>',
                    strict: true
                });
                this.setState({
                    plainPassword: password,
                    plainPasswordRepeat: password,
                })
            }
            else{
                this.setState({
                    plainPassword: '',
                    plainPasswordRepeat: ''
                })
            }
            if(this.state.generate){
                this.state.inputHidden.push('plainPassword', 'plainPasswordRepeat')
            }else{
                for( var i = 0; i < this.state.inputHidden.length; i++){ 
                    if ( this.state.inputHidden[i] === 'plainPassword') { 
                        this.state.inputHidden.splice(i, 1);
                    }
                    if (this.state.inputHidden[i] === 'plainPasswordRepeat'){
                        this.state.inputHidden.splice(i, 1);
                    }
                }
            }
        }
        if(this.state.editPassword !== prevState.editPassword){
            if(this.state.editPassword){
                this.setState({
                    inputHidden: []
                })
            }
            else{
                this.setState({
                    plainPassword: '',
                    plainPasswordRepeat: '',
                    inputHidden: ['plainPassword', 'plainPasswordRepeat', 'generate', 'notif']
                });
            }
        }
        if(this.state.email !== prevState.email){
            for(let user of this.state.listEmail){
                if(this.state.email !== this.state.currentEmail){
                    if(user.node.email === this.state.email){
                        setTimeout(() => {
                            this.handleFormError('email', true);
                            eventService.fire({stateName: 'email', errorMessage: "Cet email est déjà utilisé est donc pas valide."});
                        }, 100);
                    }
                }
            }
        }       
    }

    render() {
        return (
            <>
                <div 
                    style={{
                        width: 
                            window.innerWidth > 1400 ? 
                                (this.state.openForm ? `calc(100% - ((50% - ${this.props.drawerWidth}px / 2) + (${this.props.drawerWidth}px / 2) + 32px))` : `50%`)
                            : (this.state.openForm ? `calc(100% - ((50% - ${this.props.drawerWidth}px / 2) + (${this.props.drawerWidth}px / 2)))` : `100%`)
                        ,
                        maxWidth: 800,
                        marginTop: 16, 
                        transition: 'all 250ms cubic-bezier(0, 0, 0.2, 1) 0ms'
                    }}
                >
                    {
                        this.state.user_id === this.props.user.id ? ( <Typography variant="h1" style={{paddingBottom: 16}}>Mon profil</Typography> ) : null
                    }
                    <Grid container direction="column" justify="center" spacing={0} style={{paddingBottom: 24}}>
                        <Grid container direction="row" spacing={4}>
                            { this.user ?
                                ( 
                                    <Grid item xs={12}>
                                        <CardCustom>
                                            <Grid container justify="space-between">
                                                <Grid item xs={10}>
                                                    <BoxCustom onClick={this.props.history.goBack} style={{marginBottom: 16}}>
                                                        <ReturnLink variant={'body2'}>&lt; Retour</ReturnLink>
                                                    </BoxCustom>
                                                </Grid>
                                                <Grid item xs={2}>
                                                    <StatusInfo justify="flex-end" status={this.state.isActive ? 'ACTIF' : null}/>
                                                </Grid>
                                            </Grid>
                                            <CardUserDetail userProperty={this.user} disabledAction={this.state.openForm} listRoles={this.state.listRoles} editUser={hasRights(SETTINGS, SETTINGS_USERS, UPDATE) ? () => {this.editUser(this.user)} : null} />
                                        </CardCustom>
                                    </Grid>
                                ) : null
                            }   
                        </Grid>
                    </Grid>

                    <LayoutBuilder 
                        icomoon={"ico-modifier-utilisateur"}
                        opened={this.state.openForm} 
                        forClose={this.handleToggleDrawer} 
                        dataLayout={editUserForm} 
                        allState={this.state} 
                        stateCallback={this.handleInputChange}
                        handleButtonGroupChange={this.handleButtonGroupChange}
                        handlerMutation={this.handlerMutation}
                        deleteMutation={hasRights(SETTINGS, SETTINGS_USERS, DELETE) 
                            ? this.props.user.id !== this.state.user_id 
                                ? this.handleToggleDialog 
                                : null 
                            : null}
                        deleteText={this.props.user.id !== this.state.user_id ? 'Supprimer l\'utilisateur' : null}
                        deleteButton={this.state.editForm}
                        hideInput={this.state.inputHidden}
                        validateButton={true}
                        drawerWidth={this.props.drawerWidth}
                    />

                    <DialogModal 
                        open={this.state.openDialogRemove} 
                        title={`Êtes-vous sûr de vouloir supprimer cet utilisateur ?`}
                        secondaryAction={this.handleToggleDialog} secondarycolor={colors.grey.regular} secondarybgcolor={colors.white} secondarybgcolorhover={colors.grey.lighter.hue900} secondaryborder={`1px solid ${colors.grey.regular}`}
                        primaryAction={this.deleteMutation} primarybgcolor={colors.red.regular} primarybgcolorhover={colors.red.darker} primaryText="Supprimer"
                    >
                        Si vous supprimez cet utilisateur celui-ci ne sera plus accessible. Si vous ne souhaitez pas le supprimer, annulez la suppression en cliquant sur annuler.
                    </DialogModal>
                    
                    <DialogModal 
                        open={this.state.openDialogPassword} 
                        title={`Compte modifié !`}  
                        primaryAction={this.handleToggleDialogPassword} 
                        primarybgcolor={colors.green.regular} 
                        primarybgcolorhover={colors.green.darker} 
                        primaryText="Valider"
                        notText={true}
                    >
                        <SpanColor>{this.state.firstname} {this.state.lastname}</SpanColor> a désormais des nouveaux identifiants SpreadSuite.
                        <Grid container style={{paddingTop: 10}}>
                            <GridFlexCenter item xs={4}>
                                <InputLabelCustom>Email</InputLabelCustom>
                            </GridFlexCenter>
                            <InputBuilder input={emailPopup} xs={8} value={this.state.email} />
                        </Grid>
                        <Grid container style={{marginBottom: 0}}>
                            <GridFlexCenter item xs={4}>
                                <InputLabelCustom>Mot de passe</InputLabelCustom>
                            </GridFlexCenter>
                            <InputBuilder input={passwordPopup} xs={8} value={this.state.plainPassword} />
                        </Grid>
                    </DialogModal>

                    <Snackbar open={this.state.openSnack} autoHideDuration={3000} onClose={this.handleClose}>
                        <Alert onClose={this.handleClose} severity={'success'}>
                            Le compte a bien été mis à jour !
                        </Alert>
                    </Snackbar>
                </div>
            </>
        );
    }
    goTo = (route) => {
        this.props.history.push({
            pathname : route,
        });
    };
}

const mapStateToProps = state => {
    return {
        loading: state.loading,
        users: state.users,
        locales: state.locales,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        startLoading: () => dispatch({ type: START_LOADING }),
        stopLoading: () => dispatch({ type: STOP_LOADING }),
        snack: (type, message) => dispatch({ type: SNACK, payload: { type, message }}),
        setUser: (user) => dispatch({ type: SET_USER, payload: { user }})
    }
}

export default withRouter(withApollo(connect(mapStateToProps, mapDispatchToProps)(UserDetail)));