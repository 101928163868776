import React from 'react';
import { withRouter } from 'react-router'
import { getElements } from '../../../../js/utils/functions';
import ContentContainer from '../../../layouts/Container/ContentContainer';
import PageContainer from '../../../layouts/Container/PageContainer';
import { connect } from 'react-redux'
import Typography from '../../../ui/typography/Typography'
import CardCustom from '../../../layouts/Card/CardCustom';
import { Grid, Box, Divider } from '@material-ui/core'
import Listing from '../../../layouts/Listing/Listing';
import productListingConfig from './config/productListing.config';
import colors from '../../../../config/theme/colors'
import styled from 'styled-components';
import Button from '../../../ui/button/Button'
import { ROUTE_LOCATION_DETAIL } from '../../../../js/constants/route-names';
import AccordionCustom from '../../../layouts/Accordion/AccordionCustom';
import StatusInfo from '../../../ui/status-info copy/StatusInfo';
import AutorenewSharpIcon from '@material-ui/icons/AutorenewSharp';
import LayoutFormBuilder from '../../../ui/form/LayoutFormBuilder';
import editLocationConfig from './config/editLocation.config';
import { UPDATE_MEDIA } from '../../../../queries/medias';
import { DELETE_LOCATION, UPDATE_LOCATION } from '../../../../queries/warehouses';
import { withApollo } from 'react-apollo';
import GeneralDialogModal from '../../../ui/dialog/GeneralDialogModal';
import ImgNotFound from '../../../../assets/images/socials/image-fake.png';
import DeleteSharpIcon from '@material-ui/icons/DeleteSharp';
import Fetch from '../../../../js/utils/fetch';
import EditSharpIcon from '@material-ui/icons/EditSharp';

const CardSite = styled(Box)`
    background:white;
    padding:16px;
    display:flex;
    align-items:center;
    box-shadow:0px 0px 5px #1622421A;
    border-radius: 5px;
    width:100%;
    justify-content:space-between;
`

const ContainerImage = styled(Box)`
    overflow: hidden;
    background: url(${props => props.backgroundimage});
    background-size: cover;
    background-position: center;
    height: 100%;
    min-height: 300px;
    width: 100%;
`;

const RowContainer = styled.div`
    display: grid;
    padding: 5px 0;
    grid-template-columns: 150px 1fr;
    gap: 10px;
    min-height: 30px;

    &:last-of-type{
        padding-bottom:0;
    }
`;

const infoRow = (key, value, link) => {
    return (
        <RowContainer>
            <Typography variant="body1" style={{ fontSize: 13, color: colors.black.regular }}>
                {key}
            </Typography>
            {link ?
                <a style={{ color: colors.black.regular, fontWeight: 'bold' }} href={link.path}>
                    {value}
                </a>

                :
                <Typography variant="body1" style={{ fontSize: 13, fontWeight: 'bold', color: colors.black.regular }}>
                    {value}
                </Typography>
            }

        </RowContainer>
    )
}

class WarehouseDetails extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            currentLang: props.locales[0].node.code,
        }
    }
    componentDidMount() {
        this.init()
    }

    init = () => {
        this.props.startLoading();
        this.initWarehouses();
        this.initLocation();
        this.props.stopLoading();
    }

    initWarehouses = async () => {
        let warehousesResponse = await getElements('warehouses')
        this.setState({
            warehouses: warehousesResponse.data.warehouses.edges,
        })
    }

    initLocation = async () => {
        let location = await getElements('location', { id: `/api/locations/${this.props.match.params.id}` })
        this.setState({
            location: location.data.location,
            locationImage: location.data.location.media,
            locationCode: location.data.location.code,
            locationDescription: location.data.location.description,
            locationIsActive: location.data.location.isActive,
            locationWarehouseToLink: location.data.location.warehouse.id,
            ready: true
        })
    }

    handleListingCallback = (items, count) => {
        this.setState({
            productsTotalCount: count,
        })
    }

    goTo = (url, state) => {
        this.props.history.push({
            pathname: url,
            state: state,
        })
    }

    componentDidUpdate(prevProps) {
        if (this.props.match.params.id != prevProps.match.params.id) {
            this.initLocation();
        }
    }

    toggleForm = () => {
        this.setState({
            openForm: !this.state.openForm
        })
    }

    handleButtonGroupChange = (stateName, value) => {
        this.setState({
            [stateName]: value
        });
    };

    stateCallback = (state, evt) => {
        const value = evt?.target?.value ?? evt;

        this.setState({
            [state]: value
        })
    }

    handlerMutation = () => {
        this.props.startLoading();
        if (this.state?.location?.media?.id) {
            this.props.client.mutate({
                mutation: UPDATE_MEDIA,
                variables: {
                    id: this.state?.location?.media?.id,
                    file: this.state?.locationImage?.data,
                    name: this.state?.locationImage?.file?.name,
                    filePath: `${process.env.REACT_APP_MEDIAS}/${this.state?.locationImage?.file?.name}`,
                    createdAt: new Date(),
                    type: this.state?.locationImage?.file?.type,
                    size: this.state?.locationImage?.file?.size,
                    isInternal: false,
                }
            }).then(({ data }) => {
                this.props.client.mutate({
                    mutation: UPDATE_LOCATION,
                    variables: {
                        id: this.state?.location?.id,
                        code: this.state?.locationCode,
                        description: this.state?.locationDescription,
                        isActive: this.state?.locationIsActive,
                        media: data.updateMediaObject.mediaObject.id,
                        warehouse: this.state?.locationWarehouseToLink,
                    }
                }).then(({ data }) => {
                    this.props.snack('success', 'Zone créée avec succès');
                    this.init();
                    this.toggleForm();
                }).catch(error => {
                    this.props.snack('error', 'Une erreur est survenue');
                })
            }).catch(error => {
                console.log(error);
            })
        } else {
            this.props.client.mutate({
                mutation: UPDATE_LOCATION,
                variables: {
                    id: this.state?.location?.id,
                    code: this.state?.locationCode,
                    description: this.state?.locationDescription,
                    isActive: this.state?.locationIsActive,
                    warehouse: this.state?.locationWarehouseToLink,
                }
            }).then(({ data }) => {
                this.props.snack('success', 'Zone créée avec succès');
                this.init();
                this.toggleForm();
            }).catch(error => {
                this.props.snack('error', 'Une erreur est survenue');
            })
        }
        this.props.stopLoading();
    }

    toggleDialog = (name) => {
        this.setState({
            [name]: !this.state[name]
        })
    }

    handleDialogPrimaryAction = () => {
        this.props.startLoading();

        Fetch(`${process.env.REACT_APP_API_ROOT}api/locations/${this.state?.location?.id.replace('/api/locations/', '')}`, 'delete').then(({ data }) => {
            this.props.snack('success', 'Zone supprimée avec succès');
            this.props.stopLoading();
            this.props.history.push('/warehouses/detail/' + this.state?.location?.warehouse?.id);
        }).catch(error => {
            this.props.snack('error', 'Une erreur est survenue');
            this.props.stopLoading();
        })
    }

    render() {
        let { listSettings, listMappers, perPageOptions, searchSettings } = productListingConfig();

        return (
            <PageContainer>
                {this.state.ready ?
                    <ContentContainer
                        headerSettings={{
                            icomoon: "icon-business",
                            title: `Détails de la zone`,
                            subtitle: 'Visualiser les produits',
                            windowWidth: this.props.windowWidth,
                            modifyButton: {
                                action: () => this.toggleForm(),
                                text: <div style={{display: 'flex', alignItems: 'center'}}><EditSharpIcon style={{fontSize: 18, marginRight: 5}} /> Modifier</div>,
                                icon: 'icon-edit',
                            },
                            // textDelete: 'Supprimer',
                            // handlerDelete: () => {
                            //     this.setState({
                            //         typeDialog: 'deleteDialog',
                            //     }, () => {
                            //         this.toggleDialog('deleteDialog')
                            //     })
                            // },
                            menu: [
                                {
                                    label: 'Supprimer',
                                    color: colors.red.regular,
                                    icon: <DeleteSharpIcon style={{ fill: colors.red.regular }} />,
                                    action: () => {
                                        this.setState({
                                            typeDialog: 'deleteDialog',
                                        }, () => {
                                            this.toggleDialog('deleteDialog')
                                        })
                                    }
                                }
                            ]
                        }}
                    >
                        <AccordionCustom title={'Information lié'} custompadding={'40px'} style={{ marginBottom: 32 }}>
                            <Grid container spacing={3}>
                                <Grid item xs={5}>
                                    <ContainerImage width={'100%'} backgroundimage={ImgNotFound} />
                                </Grid>
                                <Grid item xs={7}>
                                    <Typography variant="h2" style={{ fontSize: 25, marginBottom: 8 }}>Zone {this.state.location.code}</Typography>
                                    <Typography variant="h2" style={{ fontSize: 16, color: colors.grey.regular, fontWeight: 'normal' }}>{this.state.location.description}</Typography>
                                    <Divider style={{ marginBottom: 20, marginTop: 20 }} />
                                
                                    {/* {infoRow('Synchronisation :', <StatusInfo icon={
                                        <AutorenewSharpIcon style={{ color: colors.green.regular, marginLeft: '-5px', marginRight: '-5px', fontSize: 22 }} />
                                    } status={'success'} statusText={'Automatique'} />)} */}
                                    {infoRow('Total de produits :', this.state.productsTotalCount)}
                                    {infoRow('Statut :', <StatusInfo status={this.state.location.isActive ? 'success' : 'error'} statusText={this.state.location.isActive ? 'Actif' : 'Inactif'} />)}
                                </Grid>
                            </Grid>
                        </AccordionCustom>

                        <Listing
                            label='produits'
                            settings={listSettings}
                            cardContainerProps={{
                                style: { height: '100%' }
                            }}
                            perPageOptions={perPageOptions}
                            mappers={listMappers}
                            currentLang={this.state.currentLang}
                            identifier='productLites'
                            searchSettings={searchSettings}
                            rowAction={() => {}}
                            viewsOptions={{
                                current: 'card',
                                settings: ['card'] //Si il n'y a qu'un seul item il n'y aura pas de changement de vues
                            }}
                            queryVariables={{
                                location: this.state.location.id
                            }}
                            cardProps={{
                                paths: {
                                    id: '_id',
                                    title: 'productName',
                                    image: 'imageUrl',
                                    ref: 'sku',
                                    status: 'status',
                                    mainInfo: 'totalStock',
                                    summary: [
                                        {
                                            label: 'Entrepôt :',
                                            path: 'warehousesCount'
                                        },
                                        {
                                            label: 'Dispo en réel :',
                                            path: null
                                        },
                                        {
                                            label: 'En stock :',
                                            path: 'totalStock'
                                        },
                                        {
                                            label: 'Reservé :',
                                            path: null
                                        },
                                    ]
                                }
                            }}
                            listingCallback={this.handleListingCallback}

                        />

                        <LayoutFormBuilder
                            opened={this.state.openForm}
                            icomoon={null}
                            forClose={() => { this.toggleForm() }}
                            dataLayout={editLocationConfig(this.state.warehouses)}
                            drawerWidth={this.props.drawerWidth}
                            stateCallback={this.stateCallback}
                            handleButtonGroupChange={this.handleButtonGroupChange}
                            errorCallback={this.handleFormError}
                            validateButton={true}
                            handlerMutation={() => { this.handlerMutation() }}
                            allState={this.state}
                            windowWidth={this.props.windowWidth}
                            isSublayout={false}
                        />

                        <GeneralDialogModal
                            icon={true}
                            type={this.state.typeDialog === 'deleteDialog' ? 'delete' : 'info'}
                            open={this.state[this.state.typeDialog]}
                            title={
                                this.state.typeDialog === 'deleteDialog'
                                    ? 'Êtes-vous sûr de vouloir supprimer cette zone ?'
                                    : 'Information'
                            }
                            primaryAction={() => this.handleDialogPrimaryAction()}
                            secondaryAction={() => { this.toggleDialog(this.state.typeDialog) }}
                            windowWidth={this.props.windowWidth}
                        >
                            {this.state.typeDialog === 'deleteDialog' ? (
                                <Typography variant="body2">Êtes-vous sûr de vouloir supprimer cette zone ? <strong>Cette action est irréversible</strong></Typography>
                            ) : (
                                <Typography variant="body2">Cette action est irréversible</Typography>
                            )}
                        </GeneralDialogModal>

                    </ContentContainer>

                    : null}

            </PageContainer>

        )
    }
}
const mapStateToProps = state => {
    return {
        loading: state.loading,
        locales: state.locales,
        attributes: state.attributes
    };
};

const mapDispatchToProps = dispatch => {
    return {
        startLoading: () => dispatch({ type: 'START_LOADING' }),
        stopLoading: () => dispatch({ type: 'STOP_LOADING' }),
        snack: (type, message) => dispatch({ type: 'SNACK', payload: { type, message } })
    }
};

export default withApollo(withRouter(connect(mapStateToProps, mapDispatchToProps)(WarehouseDetails)));