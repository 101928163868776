import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import {Box, Grid} from '@material-ui/core'
import LaunchIcon from '@material-ui/icons/Launch';
import styled from 'styled-components';
import colors from '../../../../../config/theme/colors';

const CustomTableHead = styled(TableHead)`
    background : transparent;
    th{
        color: ${colors.grey.regular};
        font-style: italic;
        /* text-align: center; */
    }
`

const CustomBodyHead = styled(TableBody)`
    tr:nth-child(2n){
        background-color: ${colors.grey.lighter.hue900};
    }
`

export default function Tracking(props){
    return(
        <Box style={{
            maxHeight: 200,
            overflow: 'auto'
        }}>
            <Table size="small" stickyHeader>
                <CustomTableHead>
                    <TableRow>
                        <TableCell style={{background: 'white'}}>SKU</TableCell>
                        <TableCell style={{background: 'white'}}>Tracking</TableCell>
                        <TableCell style={{background: 'white'}}>N°suivi</TableCell>
                    </TableRow>
                </CustomTableHead>
                <CustomBodyHead>
                    {props.items.map((item,index)=>{
                        const hasTracking = item.node.shipments?.edges?.length>0 && item.node.shipments.edges.filter(e=>e.node.tracking != null)?.length>0;
                        return(
                            <TableRow key={`tracking-number-${index}`}>
                                <TableCell style={{fontWeight: 700}}>{item.node.product.sku}</TableCell>
                                <TableCell style={{fontWeight: 700}}>
                                    {
                                        hasTracking ? 
                                            <Grid container>
                                                {item.node.shipments.edges.map((shipment,index) =>(
                                                    shipment?.node?.tracking?.trackingLink ? 
                                                    <Grid item xs={12} key={`tracking-number-${index}`}>
                                                        <a href={shipment.node.tracking.trackingLink} target="_blank" style={{fontSize:12, color: colors.black.regular, fontWeight: 700}}>
                                                            {shipment.node.tracking.trackingLink}
                                                            <LaunchIcon style={{fontSize:20,marginLeft:10,verticalAlign:'middle'}}/>
                                                        </a>                                                
                                                    </Grid>
                                                : 'Pas de suivi disponible'
                                                ))}                                            
                                            </Grid>
            
                                        : 'Pas de suivi disponible'
                                    }
                            
                                </TableCell>
                                {
                                    hasTracking ?
                                        <TableCell style={{fontWeight: 700}}>{item.node.shipments.edges[0].node?.tracking?.trackingNumber}</TableCell>
                                    :<TableCell style={{fontWeight: 700}}>-</TableCell>
                                }
                            </TableRow>
                        )
            
                        })}
                
                </CustomBodyHead>
            </Table>
        </Box>
    )
}