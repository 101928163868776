import React from 'react';
import { Box, Grid, Typography, Tooltip, TextField, Checkbox, MenuItem } from '@material-ui/core';
import Button from '../../../ui/button/Button';
import StatusInfo from '../../../ui/status-info/StatusInfo';
import colors from '../../../../config/theme/colors';
import styled from 'styled-components';
import NoImage from '../../../../assets/images/not-found.png';
import CircularProgress from '@material-ui/core/CircularProgress';
import { getTraductionAttributs } from '../../../../js/utils/functions';
import { makeStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import DoneIcon from '@material-ui/icons/Done';

const TextFieldCustom = styled(TextField)`
    input, .MuiSelect-select{
        font-size: 13px;
        padding: 10px;
    }
    & > p{
        margin: 0;
    }
`;
const ButtonCustom = styled(Button)`
    margin: 0;
    text-transform: unset;
    font-weight: bold;
`;
const Title = styled(Typography)`
    font-weight: bold;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
`;
const Sku = styled(Typography)``;

const SpanColor = styled.span`
    color: ${colors.blue.lighter.hue300};
    font-weight: bold;
    display: block;
    width: 100%;
`;
const TableCellCustom = styled(TableCell)`
    border: none;
    // display: inline-block;
    // width: calc(100% / 9);
    padding: 3px 16px;
`;
const CircularProgressCustom = styled(CircularProgress)`
    color: ${props => props.value > 30 
            ? props.value > 65 
                ? colors.green.regular : "#f6d607"
            : colors.red.regular};
    margin-left: ${props => props.marginleft};
`;

const CheckboxCustom = styled(Checkbox)`
    background: white;
    border-radius: 5px;
    width: 35px;
    height: 27px;
    svg{
        fill: ${colors.blue.lighter.hue300}
    }
    &:hover{
        background: white!important;
        svg{
            fill: ${colors.blue.lighter.hue150}
        }
    }
    input{
        width: 40px;
    }
`;

const CheckboxCustomlist = styled(CheckboxCustom)`
    background: transparent;
    margin: 0;
`


export default function CardProductList(props){

    const useStylesBootstrap = makeStyles(theme => ({
        arrow: {
            color: colors.blue.regular,
        },
        tooltip: {
            backgroundColor: colors.blue.regular,
            fontSize: 14,
            padding: 10,
            textAlign: 'left',
        },
    }));

    function BootstrapTooltip(props) {
        const classes = useStylesBootstrap();
        return <Tooltip arrow classes={classes} {...props} />;
    }

    const product = props.product.node;
    const attributes = product.productDatas.edges;

    let name = '';
    let image= '';
    let imageSelected = [];
    let getImageByTranslation = '';

    let statsCompletion = product.stats?.infoStats.percentByLangs[props.currentLang] 
                            ? product.stats.infoStats.percentByLangs[props.currentLang] 
                            : product.stats?.infoStats.percentByLangs[0] 
                                ? product.stats.infoStats.percentByLangs[0] 
                                : 0;
    
    for(let attribute of attributes){
        if(attribute.node.attribute.isSystem){
            imageSelected = attributes.filter(e => e.node.media !== null)
            getImageByTranslation = imageSelected.filter(e => e.node.locale.code === props.currentLang)
            name = getTraductionAttributs('product_name', attributes, props.currentLang);
            if(attribute.node.attribute.identifier === 'product_image' && image === ''){
                image = getTraductionAttributs('product_image', attributes, props.currentLang, 'image');
            }
        }
    }

    let catalogList = []
    for (let category of product.productCategories.edges){
        if (category.node.category.catalog){
            if (catalogList.indexOf(category.node.category.catalog.libelle) === -1){
                catalogList.push(category.node.category.catalog.libelle)
            }
        }
    }

    const goTo = props.routeProduct;
    return(
        <TableRow key={'rowProduct'} onClick={props.selectedProducts ? () => props.handleToggle(props.product) : null} style={{cursor: props.selectedProducts ? 'pointer' : 'initial'}}>
            {
                props.selectedProducts ?
                <TableCellCustom component="th" scope="row" align="center">
                    <CheckboxCustomlist
                        edge="start"
                        checked={props.reverseCheck ? props.selectedProducts?.indexOf(product.id) !== -1 ? false : true : props.selectedProducts?.indexOf(product.id) === -1 ? false : true}
                    />
                </TableCellCustom>
                : null
            }
            <TableCellCustom component="th" scope="row" align="center">
                {
                    image && image !== '' ? 
                        (<img src={`${process.env.REACT_APP_MEDIAS}/${image.filePath}`} alt={name} style={{maxWidth: '100%', maxHeight: 40}}/>) 
                    : 
                        (<img src={NoImage} style={{maxWidth: '100%', maxHeight: 40}} alt={name}/>)
                }
            </TableCellCustom>
            <TableCellCustom >
                <Sku variant="body2">{product.sku}</Sku>
            </TableCellCustom>
            <TableCellCustom style={{minWidth: props.minimalForm ? null : 150}}>
                <Title variant="body1">{name || 'N/A'}</Title>
            </TableCellCustom>
            {
                !props.minimalForm ?
                <>
                    <TableCellCustom align="center" >
                        <BootstrapTooltip 
                            title={ product.productCategories.edges.filter(e => e.node.category.catalog === null).map((categorie, index) => {
                                if(categorie.node.category.catalog === null){
                                    if (categorie.node.category.categoryDatas.edges.length > 0 && categorie.node.category.categoryDatas.edges.find(e => e.node.attribute.identifier === "category_name")){
                                        let getCategorieByLang = getTraductionAttributs('category_name', categorie.node.category.categoryDatas.edges, props.currentLang)
                                        return(<span key={`CategorieName${index}`} style={{display: 'block'}}>{index + 1} - {getCategorieByLang}</span>);
                                    } else {
                                        return(<span key={`CategorieName${index}`} style={{display: 'block'}}>{index + 1} - {categorie.node.category.libelle}</span>);
                                    }
                                }else{
                                    return null
                                }                            
                            })}
                            arrow
                        >
                            <SpanColor> {product.productCategories.edges.filter(e => e.node.category.catalog === null).length}</SpanColor>
                        </BootstrapTooltip>
                    </TableCellCustom>
                    <TableCellCustom align="center">
                        <BootstrapTooltip 
                            title={catalogList.map((catalogName, index) =>{
                                return(<span key={`CatalogName${index}`} style={{display: 'block'}}>{index + 1} - {catalogName}</span>)
                            })} 
                            arrow
                        >
                            <SpanColor> {props.nbCatalog ? props.nbCatalog : 0}</SpanColor>
                        </BootstrapTooltip>
                    </TableCellCustom>
                </>
                : null
            }
            <TableCellCustom align="center" >
                <StatusInfo status={product.status} noText={true} justify={"center"}/>
            </TableCellCustom>
            {
                process.env.REACT_APP_MODE_SPREAD !== "hub" && !props.minimalForm ? 
                    <>
                    <TableCellCustom align="center">
                    {
                        statsCompletion === 0 || statsCompletion ?
                        (
                            <Box position="relative" display="inline-flex">
                                <CircularProgressCustom variant="determinate" thickness={4} value={statsCompletion} size={40}/>
                                <Box
                                    top={0}
                                    left={0}
                                    bottom={0}
                                    right={0}
                                    position="absolute"
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="center"
                                >
                                    <Typography variant="h5" component="div" color="textSecondary">
                                        {statsCompletion > 0 ? `${Math.round(statsCompletion)}%` : `${statsCompletion}%`}
                                    </Typography>
                                </Box>
                            </Box>
                        ) : null
                    }
                </TableCellCustom>
                <TableCellCustom align="center">
                    {
                        imageSelected ? 
                        (
                            <Grid container alignItems="center" justify="center">
                                {/* <PhotoLibraryOutlinedIcon  style={{color: colors.grey.regular}}/> */}
                                <Typography variant="body1"><SpanColor style={{width: "auto", display:"inline"}}>{getImageByTranslation.length}</SpanColor>/{props.nbImageTotal}</Typography>
                            </Grid>
                        ) : null
                    }
                </TableCellCustom>
                </>
                : null
            }
            {
                props.minimalForm ?
                <>
                    <TableCellCustom align="center" >
                        <TextFieldCustom
                            id={`Position-${props.index}`}
                            variant="outlined"
                            value={props.productCategory.node.position || ''}
                            fullWidth= {true}
                            onChange={evt => {
                                props.changePosition('position', evt, product.id);
                            }}
                            name={`Position-${props.index}`}
                            type={'text'}
                            placeholder={'Pos'}
                            style={{width: '100%'}}
                            onClick={(e) => e.stopPropagation()}
                        />
                    </TableCellCustom>
                    {
                        props.listCollections.length > 0 ?
                            <TableCellCustom align="left">
                                <TextFieldCustom
                                    id={`Selection-${props.index}`}
                                    name={`Selection-${props.index}`}
                                    variant="outlined"
                                    color="secondary"
                                    select
                                    fullWidth
                                    displayEmpty
                                    value={props.productCategory.node.selection !== null ? props.productCategory.node.selection.id : 'none'}
                                    onChange={evt => {
                                        props.changePosition('selection', evt, product.id);
                                    }}
                                    onClick={(e) => e.stopPropagation()}
                                    style={{minWidth: 150}}
                                    >
                                        <MenuItem
                                            value={'none'}
                                            disabled={props.productCategory.node.selection === '' ? true : false}
                                            >
                                            <em>Aucune collection</em>
                                        </MenuItem>
                                    {props.listCollections.map((option,index) => {
                                        return(
                                            <MenuItem key={`OptionSelect${index}`} value={option.node.id} disabled={props.productCategory.node.selection === option.node.id ? true : false}>
                                                {option.node.identifier}
                                            </MenuItem>
                                        )
                                    })}
                                </TextFieldCustom>
                            </TableCellCustom>
                        : null
                    }
                    <TableCellCustom align="right" >
                        <Button style={{minWidth: "100%"}} onClick={() => props.handleChangePositionMutation(props.productCategory.node, product.id)}>
                            <DoneIcon style={{fill: colors.white}}/>
                        </Button>
                    </TableCellCustom>
                </>
                : null
            }
            <TableCellCustom align="right">
                <ButtonCustom disableElevation={true} stopPropagation={props.inForm} text={props.minimalForm ? null :"Voir"} arrow="right" bgColor={colors.green.regular}  onClick={goTo} />
            </TableCellCustom>
        </TableRow>
    );
}