import React from "react";
import PageContainer from "../../../layouts/Container/PageContainer";
import ContentContainer from "../../../layouts/Container/ContentContainer";
import request from "../../../../js/utils/fetch";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { ROUTE_ORDERS_DETAIL } from "../../../../js/constants/route-names";
import { SNACK, START_LOADING, STOP_LOADING } from "../../../../js/constants/action-types";
import { withApollo } from "react-apollo";
import LoadingOverlay from "../../../ui/loading-overlay/LoadingOverlay";
import { Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import HeadingCell from "../../../layouts/Listing/components/TableView/cells/HeadingCell";
import styled from "styled-components";
import colors from "../../../../config/theme/colors";
import DialogModal from "../../../ui/dialog/DialogModal";
import OurButton from "../../../ui/button/Button";
import OurTypography from "../../../ui/typography/Typography";

const CustomTable = styled(Table)`
    background-color: ${colors.black.regular};
    border: 1px solid ${colors.grey.border};
`;

const HeadRow = styled(TableRow)`
    background: ${props => props.style?.headingBackground ? props.style.headingBackground : colors.black.regular};
    th {
        color: ${props => props.style?.headingColor ? props.style.headingColor : "white"};
        /* Ellipsis with one line */
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
`;

const CustomRow = styled(TableRow)`
    /* Ellipsis with one line */
    td {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 200px;
    }
    background: white;
    box-sizing: border-box;
    td {
        padding: 8px 16px;
    }
    cursor: pointer;
    &:hover {
        background: ${colors.green.lighter};
        & td {
            color: ${colors.green.regular};
        }
        & button {
            background-color: ${colors.green.regular} !important;
            color: ${colors.white} !important;
        }
        & .MuiChip-root {
            border: 1px solid ${colors.green.regular} !important;
            color: ${colors.green.regular} !important;
        }
    }
    td {
        padding: 15px 10px;
        font-size: 15px;
    }
    &:hover {
        background: #f2f2f2;
    }
`;

class ListAwaitingBankTransfer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            orders: null,
            order: null,
        };
    }

    goTo = (id) => {
        id = id.replace("/api/sales-orders/", "");
        let path = ROUTE_ORDERS_DETAIL.replace(":id", id);
        this.props.history.push({ pathname: path });
    };

    componentDidUpdate(prevProps, prevState) {

    }

    componentDidMount() {
        const getOrders = async () => {
            var data = await request(`${process.env.REACT_APP_API}/magento-bank-transfer`);

            this.setState({ orders: data });
        };

        getOrders();
    }

    markAsPaid(order_id) {
        const sendInvoice = async () => {
            try {
                await request(`${process.env.REACT_APP_API}/magento-bank-transfer/${order_id}`)
                window.location.reload();
            } catch (e) {
                alert('Impossible de valider la commande pour le moment')
            }
        }

        sendInvoice()
    }

    render() {
        const renderDate = (date) => {
            date = new Date(date);

            return date.toLocaleDateString();
        };

        return (
            <PageContainer openForm={false} drawerWidth={this.props.drawerWidth}>
                <ContentContainer
                    headerSettings={{
                        icomoon: "picto-produit",
                        title: "Virements en attente",
                        subtitle: "Valider les commandes en attente d'un virement",
                    }}
                >
                    {this.state.orders === null && <LoadingOverlay />}
                    {this.state.orders && this.state.orders.length > 0 && (

                        <CustomTable>
                            <TableHead>
                                <HeadRow>
                                    <HeadingCell label={"Date de commande"} />
                                    <HeadingCell label={"Ref commande"} />
                                    <HeadingCell label={"Client"} />
                                    <HeadingCell label={"Total"} />
                                    <HeadingCell label={""} />
                                </HeadRow>
                            </TableHead>
                            <TableBody>
                                {
                                    this.state.orders.map(order => (
                                        <CustomRow onClick={() => this.setState({ order: order })}>
                                            <TableCell>{renderDate(order.created_at)}</TableCell>
                                            <TableCell>{order.increment_id}</TableCell>
                                            <TableCell>{`${order.customer_firstname} ${order.customer_lastname} (${order.customer_email})`}</TableCell>
                                            <TableCell>{order.grand_total}€</TableCell>
                                            <TableCell><OurButton margin={'0px'} height={'35px'}  onClick={() => {}} text={'Vérifier'} style={{background: colors.black.regular}} /></TableCell>
                                        </CustomRow>
                                    ))
                                }
                            </TableBody>
                        </CustomTable>

                    )}
                    <DialogModal
                        open={this.state.order !== null}
                        title={"Gérer la commande"} maxWidth={1200}
                        description={"Marquer la commande comme payée pour l'accepter et la retrouver dans votre liste de commandes"}
                        primaryAction={() => {
                            this.markAsPaid(this.state.order?.entity_id);
                        }} primarybgcolor={colors.green.regular} primarybgcolorhover={colors.green.darker} primaryText="Marquer comme payée"
                        secondaryAction={() => {
                            this.setState({ order: null });
                        }} secondarycolor={colors.grey.regular} secondarybgcolor={colors.white} secondarybgcolorhover={colors.grey.lighter.hue900} secondaryborder={`1px solid ${colors.grey.regular}`}
                    >
                        <h2 style={{color: 'black'}}>Infos client</h2>
                        <ul>
                            <li style={{color: 'black'}}>Nom: {this.state.order?.customer_lastname}</li>
                            <li style={{color: 'black'}}>Prénom: {this.state.order?.customer_firstname}</li>
                            <li style={{color: 'black'}}>Email: {this.state.order?.customer_email}</li>
                            <li style={{color: 'black'}}>Adresse: {this.state.order?.billing_address?.street[0]}</li>
                            <li style={{color: 'black'}}>Ville: {this.state.order?.billing_address?.city}</li>
                            <li style={{color: 'black'}}>CP: {this.state.order?.billing_address?.postcode}</li>
                        </ul>

                        <hr/>

                        <h2 style={{color: 'black'}}>Produits commandés</h2>
                        <CustomTable>
                            <TableHead>
                                <HeadRow>
                                    <HeadingCell label={"SKU"} />
                                    <HeadingCell label={"Nom produit"} />
                                    <HeadingCell label={"Quantité"} />
                                    <HeadingCell label={"Total"} />
                                </HeadRow>
                            </TableHead>
                            <TableBody>
                                {
                                    this.state.order?.items.map(item => (
                                        <CustomRow>
                                            <TableCell>{item.sku}</TableCell>
                                            <TableCell>{item.name}</TableCell>
                                            <TableCell>{item.qty_ordered}</TableCell>
                                            <TableCell>{item.row_total_incl_tax}€</TableCell>
                                        </CustomRow>
                                    ))
                                }
                            </TableBody>
                        </CustomTable>
                    </DialogModal>
                </ContentContainer>
            </PageContainer>
        );
    }
}

const mapStateToProps = state => {
    return {
        loading: state.loading, products: state.products, locales: state.locales, attributes: state.attributes,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        snack: (type, message) => dispatch({ type: SNACK, payload: { type, message } }),
        startLoading: () => dispatch({ type: START_LOADING }),
        stopLoading: () => dispatch({ type: STOP_LOADING }),
    };
};

export default withRouter(withApollo(connect(mapStateToProps, mapDispatchToProps)(ListAwaitingBankTransfer)));

