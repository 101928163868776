import React from "react";
import PropTypes from "prop-types";
import { Box, Grid, IconButton, Menu, MenuItem, Typography, Fade, Tooltip } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import OurButton from "../../../ui/button/Button";
import EditIcon from '@material-ui/icons/Edit';
import colors from "../../../../config/theme/colors";
import { useCallback } from "react";
import StatusInfo from "../../../ui/status-info/StatusInfo";
// import DefaultImageCard from "../../../../assets/images/image-fake.png";
import styled from "styled-components";
import FileCopySharpIcon from '@material-ui/icons/FileCopySharp';
import DeleteSharpIcon from '@material-ui/icons/DeleteSharp';
import OurTypography from "../../../ui/typography/Typography";
// import OurMenu from "../../../ui/menu/Menu";
import UpdateSharpIcon from '@material-ui/icons/UpdateSharp';
import { useHistory } from "react-router";
import { ROUTE_PRODUCTS_LIST_DETAIL } from "../../../../js/constants/route-names";
// Import fakePng
import DefaultImageCard from "../../../../assets/images/image-fake.png";

const ImageBox = styled(Box)`
  background-image: ${props => props.src ? `url(${props.src})` : `url(${DefaultImageCard})`};
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  transform: ${props => props.src ? null : 'scale(2)'};
  overflow: hidden;
  width: 100%;
  height: 100%;
`

const MenuItemCustom = styled(MenuItem)`
    color: ${colors.black.regular};
    svg{
        fill: ${colors.black.regular};
    }
`

const MenuCustom = styled(Menu)`
    .MuiMenu-paper{
        box-shadow: 0 0 10px #1622421A;
    }
`

const GridAbsolute = styled(Grid)`
    position: absolute;
    left: 0;
    top: 0;
    background-color: ${colors.green.regular};
    color: ${colors.white};
    z-index: 999;
    display: flex;
    gap: 3px;
    padding: 2px 8px;
    min-width: 50px;
`

const Wrapper = styled(Grid)`
    padding: 16px;
    position: relative;
    min-height: 265px;
    cursor: ${props => props.isDisabled ? 'default' : props.hasClick ? 'pointer' : 'default'};
    ${props => props.isDisabled ?
        `
            &::before{
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: ${colors.grey.lighter.hue700};
                opacity: .5;
                z-index: 999;
            }
        `
        :
        ``
    }
    /* background-color: ${props => props.isDisabled ? colors.grey.regular : 'unset'}; */
`

const useStyles = makeStyles((theme) => ({
    editIcon: {
        position: 'absolute',
        color: colors.black.regular.regular,
        // display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: 25,
        top: -10,
        right: 10,
        transition: 'all .5s',
        display: 'none',
    },
    menu: {
        position: 'absolute',
        top: 0,
        right: 0,
    },
    paper: {
        border: '1px solid #d3d4d5',
    }
}));

const CardSchema = ({
    paths,
    data,
    ...props
}) => {
    const history = useHistory();

    const classes = useStyles();

    const getMediaOrigin = () => {
        if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
            // DEV
            return process.env.REACT_APP_MEDIAS;
        } else {
            // PROD
            return process.env.REACT_APP_SPREAD_CDN ? `${process.env.REACT_APP_SPREAD_CDN}/medias` : process.env.REACT_APP_MEDIAS;
        }
    };

    const getProp = (path) => {
        return path.split('.').reduce((prev, curr) => prev && prev[curr], data);
    }

    const goTo = (url,variables) =>{
        history.push({
            pathname : url,
            states : variables
        })
    }

    return (
        <Wrapper container hasClick={props?.action||props?.actionData ? true : false}  onClick={props?.action
            ? () => {
                if(getProp(paths.mainInfo) > 0){
                }
                props?.action(data)
            }
            // : () => {}
            : () => {goTo(ROUTE_PRODUCTS_LIST_DETAIL.replace(':id', getProp(paths.id)))}
        }>
        {/* <Wrapper container onClick={props?.action
            ? () => {props?.action(data)}
            : () => {goTo(ROUTE_PRODUCTS_LIST_DETAIL.replace(':id', getProp(paths.id)))}
        }> */}
            <GridAbsolute>
                <UpdateSharpIcon style={{fontSize: '20px'}}/>
                {getProp(paths.mainInfo)}
            </GridAbsolute>
            <Grid item xs={6} style={{
                overflow: 'hidden',
                position: 'relative',
            }}>
                {/* Picture */}
                {/* <Image src={data.node.media ?? DefaultImageCard} alt={data.node.libelle} responsive /> */}
                <ImageBox origin={getMediaOrigin()} src={getProp(paths.image)} />
            </Grid>
            <Grid container xs={6} direction='column' justifyContent='space-between' style={{
                position: 'relative',
                paddingLeft: '21px',
            }}>

                <Grid container justify="space-between" className={getProp(paths.mainInfo) > 0 && 'hasStock'}>
                    <Tooltip title={'Ref. ' + getProp(paths.ref)}>
                        <OurTypography colortext={colors.grey.lighter.hue800} style={{
                            fontSize: 14, 
                            maxWidth: 100, 
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                        }}>Ref. {getProp(paths.ref)}</OurTypography>
                    </Tooltip>
                    <StatusInfo status={getProp(paths.mainInfo) > 0 ? 'success' : false} width={'auto'}/>
                    {/* <OurMenu menuOptions={editMenuOptions} handleClick={(e, item) => item.action(e)}/> */}
                </Grid>

                <Grid item style={{
                    marginTop: '20px',
                }}>
                    <Typography variant='h3' style={{ 
                        marginBottom: '12px', 
                        fontSize: '18px',
                        display: '-webkit-box',
                        WebkitLineClamp: 2,
                        WebkitBoxOrient: 'vertical',
                        overflow: 'hidden',
                    }}>{getProp(paths.title)}</Typography>
                    {paths?.summary?.map((item, index) => (
                        <Grid container>
                            <Grid item xs={8}>
                                <Typography variant='body1' style={{ color: colors.black.regular, fontSize: '13px' }}>{item.label}</Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Typography variant='body1' style={{ color: colors.black.regular, fontWeight: 'bold' }}>{
                                    item.path ? getProp(item.path) : '-'
                                }</Typography>
                            </Grid>
                        </Grid>
                    ))}
                </Grid>
            </Grid>
        </Wrapper>
    );
}

CardSchema.propTypes = {
    title: PropTypes.string.isRequired,
    summary: PropTypes.array.isRequired,
    modifyButtonProps: PropTypes.objectOf({
        text: PropTypes.string.isRequired,
        onClick: PropTypes.func.isRequired
    }),
}

CardSchema.defaultProps = {
    title: 'Test',
    summary: [
        {
            label: 'Test',
            value: 'Test'
        },
        {
            label: 'Test',
            value: 'Test'
        },
    ],
    modifyButtonProps: {
        text: 'Test',
        onClick: () => { },
    },
}

export default React.memo(CardSchema);