import {
    ROUTE_ORDERS,
    ROUTE_ORDERS_LIST,
    ROUTE_ORDERS_TRANSFER,
    ROUTE_ORDERS_DETAIL,
    ALL_ROUTE_ORDERS,
    ROUTE_WAREHOUHSES,
    ALL_ROUTE_WAREHOUSES,
    ROUTE_WAREHOUSE_DETAIL,
    ROUTE_LOCATION_DETAIL,
    ROUTE_DASHBOARD_HUB,
    ROUTE_ORDERS_STOCKS,
} from '../../js/constants/route-names';


import store from '../../js/redux/store';

export default function setUpDrawer(isCRM) {
    let menuItems;
    let reduxStore = store.getState();
    let shellsNotSystem = reduxStore.attributes.eavTypes.filter(e => e.node.isSystem === false);

    menuItems = [
            {
                name : 'Dashboard',
                route : ROUTE_DASHBOARD_HUB,
                allRoutes : [ROUTE_DASHBOARD_HUB],
                childrens : [
                    {
                        name : 'Stats. commandes',
                        route : ROUTE_DASHBOARD_HUB,
                        icone : 'ico_stats',
                    }
                ]
            },
            {
                name : 'Commandes',
                route: ROUTE_ORDERS,
                allRoutes : ALL_ROUTE_ORDERS,
                childrens: [
                    {
                        name : 'Commandes',
                        route: ROUTE_ORDERS_LIST,
                        icone:'ico_eshop',
                        childrens:[ROUTE_ORDERS_DETAIL]
                    },
                    {
                        name : 'Virements en attente',
                        route: ROUTE_ORDERS_TRANSFER,
                        icone:'ico_eshop',
                    },
                    // {
                    //     name : 'Créer',
                    //     route: ROUTE_ORDERS_NEW,
                    //     icone:'ico_update'
                    // },
                    // process.env.REACT_APP_DISABLE_RETURNS !== "true" &&{
                    //     name : 'Retours',
                    //     route: ROUTE_ORDERS_LIST_RETURN,
                    //     icone:'ico_update',
                    // },
                    // {
                    //     name : 'Avoirs',
                    //     route: ROUTE_ORDERS_AVOIR,
                    //     icone:'picto-produit'
                    // },
                    {
                        name : 'Stocks',
                        route: ROUTE_ORDERS_STOCKS,
                        icone:'picto-produit'
                    },
                ]
            },
            {
                name : 'Gestion des entrepôts',
                route : ROUTE_WAREHOUHSES,
                allRoutes : ALL_ROUTE_WAREHOUSES,
                childrens : [
                    {
                        name : 'Entrepôts',
                        route : ROUTE_WAREHOUHSES,
                        icone : 'ico_retailer',
                        childrens:[ROUTE_WAREHOUSE_DETAIL,ROUTE_LOCATION_DETAIL]
                    }
                ]
            }
            /*{
                name : process.env.REACT_APP_MODE_SPREAD !== "hub" ? 'Catalogues' : 'Entrepôts',
                route: ROUTE_CATALOGS,
                allRoutes: ALL_ROUTE_CATALOGS, 
                // notAccordion: true,
                childrens:[
                    hasRights(CATALOGS, CATALOGS_DASHBOARD, VIEW) ?
                    {
                        name : 'Dashboard',
                        route:  ROUTE_CATALOGS,
                        icone:'icon-dashboard',
                    } : null,
                    hasRights(CATALOGS, CATALOGS_CATALOGS, VIEW) ?
                    {
                        name : process.env.REACT_APP_MODE_SPREAD !== "hub" ? 'Catalogues' : 'Entrepôts',
                        route:  ROUTE_CATALOGS_LIST,
                        icone:'icon-catalogue',
                        childrens:[ROUTE_CATALOGS_DETAIL]
                    } : null,
                ]
            },*/


            
    ]
    

    // menuItems.push(
    //     // {
    //     //     name : 'Produits',
    //     //     route: ROUTE_PRODUCTS,
    //     //     allRoutes : ALL_ROUTE_PRODUCTS, 
    //     //     childrens:[
    //     //         /*hasRights(PRODUCTS, PRODUCTS_DASHBOARD, VIEW) ?
    //     //         {
    //     //             name : 'Dashboard',
    //     //             route: ROUTE_PRODUCTS,
    //     //             icone:'icon-dashboard'   
    //     //         } : null,
    //     //         hasRights(PRODUCTS, PRODUCTS_CATEGORIES, VIEW) && process.env.REACT_APP_MODE_SPREAD !== "hub" ?
    //     //         {
    //     //             name : 'Catégories',
    //     //             route: ROUTE_PRODUCTS_CATEGORIES,
    //     //             icone:'picto-categorie'   
    //     //         } : null,*/
    //     //         hasRights(PRODUCTS, PRODUCTS_PRODUCTS, VIEW) ?
    //     //             {
    //     //                 name : 'Produits',
    //     //                 route: ROUTE_PRODUCTS_LIST,
    //     //                 icone:'picto-produit',
    //     //                 childrens:[ROUTE_PRODUCTS_ADD, ROUTE_PRODUCTS_LIST_DETAIL]   
    //     //             }
    //     //         : null,
    //     //     ]
    //     // },
    //     // {
    //     //     name : 'Gestion des entrepôt',
    //     //     route : ROUTE_WAREHOUHSES,
    //     //     allRoutes : ALL_ROUTE_WAREHOUSES,
    //     //     childrens : [
    //     //         {
    //     //             name : 'Entrepôts',
    //     //             route : ROUTE_WAREHOUHSES,
    //     //             icone : 'icon-business',
    //     //             childrens:[ROUTE_WAREHOUSE_DETAIL,ROUTE_LOCATION_DETAIL]
    //     //         }
    //     //     ]
    //     // }
    //     /*{
    //         name : 'Brand',
    //         route: ROUTE_BRAND_GUIDELINE,
    //         allRoutes : ALL_ROUTE_BRAND, 
    //         childrens:[
    //             hasRights(BRAND, BRAND_GUIDELINE, VIEW) ?
    //             {
    //                 name : 'Guideline',
    //                 route: ROUTE_BRAND_GUIDELINE,
    //                 icone: 'icon-guideline',
    //                 childrens: [ROUTE_BRAND_GUIDELINE_ADD, ROUTE_BRAND_GUIDELINE_UPDATE]  
    //             } : null,

    //         ]
    //     },
    //     /*{
    //         name : 'Paramètres',
    //         route: ROUTE_SETTINGS,
    //         allRoutes : ALL_ROUTE_SETTINGS, 
    //         childrens:[
    //             hasRights(PRODUCTS, PRODUCTS_PRODUCTS, VIEW) ?
    //             {
    //                 name : 'Collections',
    //                 route: ROUTE_PRODUCTS_COLLECTIONS,
    //                 icone:'picto-collections',
    //             }
    //             : null,
    //             hasRights(SETTINGS, SETTINGS_LANGUAGES, VIEW) && process.env.REACT_APP_MODE_SPREAD !== "hub" ?
    //             {
    //                 name : 'Gestion des langues',
    //                 route: ROUTE_SETTINGS_LANGUAGES,
    //                 icone:'picto-langues'   
    //             } : null,
    //             hasRights(SETTINGS, SETTINGS_CURRENCIES, VIEW) && process.env.REACT_APP_MODE_SPREAD !== "hub" ?
    //             {
    //                 name : 'Gestion des devises',
    //                 route: ROUTE_SETTINGS_CURRENCIES,
    //                 icone:'picto-devises'
    //             } : null,
    //             {
    //                 name : 'Gestion des groupes de taxes',
    //                 route: ROUTE_SETTINGS_GROUPRATES,
    //                 icone:'picto-prix'
    //             },
    //             {
    //                 name : 'Gestion des taxes',
    //                 route: ROUTE_SETTINGS_TAXRATES,
    //                 icone:'picto-prix'
    //             },
    //             hasRights(SETTINGS, SETTINGS_USERS, VIEW) && process.env.REACT_APP_MODE_SPREAD !== "hub" ?
    //             {
    //                 name : 'Gestion des utilisateurs',
    //                 route: ROUTE_SETTINGS_USERS,
    //                 icone:'picto-profil',
    //                 childrens:[ROUTE_SETTINGS_DETAIL_USER] 
    //             } : null,
    //             process.env.REACT_APP_MODE_SPREAD !== "hub" ?
    //             {
    //                 name : 'Gestion des droits',
    //                 route: ROUTE_SETTINGS_ACCESS,
    //                 icone:'verified',
    //             } : null,
    //         ]
    //     },*/
    // )
    return menuItems
}