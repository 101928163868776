export const START_LOADING  = "START_LOADING";
export const STOP_LOADING   = "STOP_LOADING";
export const SET_USER       = "SET_USER";
export const ADD_PRODUCT    = "ADD_PRODUCT";
export const SNACK          = "SNACK";
export const STOP_SNACK     = "STOP_SNACK";
export const SET_LOCALES    = "SET_LOCALES";
export const SET_ATTRIBUTES = "SET_ATTRIBUTES";
export const SET_GUIDELINE  = "SET_GUIDELINE";
export const SET_BREADCRUMB = "SET_BREADCRUMB";
export const SET_NAVIGATION = "SET_NAVIGATION";