import noImageCompany from '../../../../../assets/images/emptyCompany.jpg';
import CardProductHubPicker from '../../../../layouts/Card/cardContent/CardProductHubPicker';
import CardSchema from '../../../../layouts/Card/cardContent/CardSchema';

export default function productListingConfig(datas){
    let config = {
        listSettings : {
            grid : {
                components : CardSchema,
                columns : {
                    xs : 1,
                    sm : 2,
                    laptop : 2,
                    xl : 3
                }
            },

        },

        listMappers : [
            {
                view : 'card',
                mapper : 'productsGridMapper'
            },

        ],

        perPageOptions : {
            'card' : ['6','12','24'],
        },


        searchSettings : {
            inputs : [
                {
                    placeholder : 'Rechercher par SKU',
                    queryField : 'sku',
                    type : 'text',
                    mapper : null,
                    callback : null,
                    label : 'SKU'
                },
                {
                    placeholder : 'Rechercher par nom',
                    queryField : 'productName',
                    type : 'text',
                    mapper : null,
                    callback : null,
                    label : 'Nom du produit'
                },


            ]
        }           
    }

    if(datas?.locations)
        config.searchSettings.inputs.push({
            queryField : 'location',
            type : 'select',
            mapper : null,
            callback : null,
            label : 'Entrepôt',
            value:datas.locations?.edges.map(e => ({
                label : e.node.code  ,
                value : e.node.id
            })),
            disabled :datas.locations?.edges.length === 0 
        })


    return config;
 
}
