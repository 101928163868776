//RETURNS
export const hasReturn = (order) =>{
    let hasReturn = false;
    if(order.salesOrderItems){
        for(let item of order.salesOrderItems.edges){
            if(item.node.requests?.edges?.length >0){
                hasReturn = true;
            }
        }
    }
    console.log('hasRETURN',hasReturn)
    return hasReturn;
}

export const splitItemsByReturn = (items,type) =>{
    let result;
    switch(type){
        case 'orders':
            result = items.filter(item => item.node.requests.edges.length <= 0);
            break;

        case 'returns':
            result = items.filter(item => item.node.requests.edges.length > 0);
            break;
    }
    return result;
    
}