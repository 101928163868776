import TableCell from '@material-ui/core/TableCell';
import Chip from '@material-ui/core/Chip';
function CellArrayToText(props){

    
    return(
        <TableCell 
            style={{
                width : props.width ? props.width :'auto',
                fontWeight : props.fontWeight ? props.fontWeight : 'normal',
            }}
        >
            
            {props.value ? props.value.join(', ') :null}
       
        </TableCell>
    )
}
export default CellArrayToText;