import React from 'react';

export default function PageContainer(props){
    return(
        <div style={{
            width: props.openForm ? `calc(100% - ((50% - ${props.drawerWidth}px / 2) + (${props.drawerWidth}px / 2)))` : "100%", 
            transition: 'all 250ms cubic-bezier(0, 0, 0.2, 1) 0ms', 
            paddingBottom: 32}}
        >
            {props.children}
        </div>
    )
}